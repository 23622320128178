import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addressAction } from "../../Common/State/adressactions";
import { appAction } from "../../App/state/actions";
import { useNavigate } from "react-router-dom";

/**
 * Custom hook to manage side effects for adding addresses.
 * @param {Object} params - Parameters for the hook.
 * @param {Object} params.form - The Ant Design form instance used for address input.
 * @param {Object} params.address - The address object containing details to prefill the form.
 * 
 * @description
 * - Uses `useEffect` to fetch addresses when a new address is added or when all addresses are available.
 * - Uses `useEffect` to retrieve the map token needed for map functionalities.
 * - Uses `useEffect` to prefill the form with address details provided in the `address` object.
 * - Uses `useEffect` to navigate to the home page once user details are available and loading states are inactive.
 * 
 * @returns {void}
 */
const useAddAddressEffects = ({ form, address }) => {
    console.log("use add")
    const dispatch = useDispatch();
    const { userDetails } = useSelector((str) => str.navbarReducer);
    console.log(userDetails, "userDetails")
    const {
        isLoadingMaps,
        isLoadingAddresses,
        isLoadingUserDetails,
    } = useSelector((str) => str.loadingReducer);
    const { alladdress, newAddress } = useSelector((str) => str.addressReducer);
    const navigate = useNavigate();

    // Fetch addresses when a new address is added or all addresses are available
    useEffect(() => {
        if (newAddress || alladdress) {
            dispatch(addressAction.getAddressesEvent());
        }
        // eslint-disable-next-line
    }, [newAddress]);

    useEffect(() => {
        dispatch(appAction.mapTokenEvent());
        // eslint-disable-next-line
    }, []);

    // Prefill the form fields with address data
    useEffect(() => {
        form.setFieldsValue({
            landmark: address?.subLocality || address?.landmark,
            houseNumber: address?.houseNumber,
            street: address?.street,
            houseName: address?.houseName,
            city: address?.city,
            district: address?.district,
            state: address?.state,
            eLoc: address?.eLoc,
            pincode: address?.pincode,
            latitude: address?.lat,
            longitude: address?.lng,
        });
        // eslint-disable-next-line
    }, [address]);

    useEffect(() => {
        if (
            userDetails &&
            !isLoadingUserDetails &&
            !isLoadingAddresses &&
            !isLoadingMaps
        ) {
            navigate("/");
        }
    }, [userDetails]);
};

export default useAddAddressEffects;
