import { Layout, Steps } from "antd";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import styles from "../Styles/bbokAppointmentRoute.module.css";
import { useRoute } from "../../context/RouteContext";
import { useTranslation } from "react-i18next";
const { Content } = Layout;
const BookAppointmentRoute = () => {
  const location = useLocation();
  const { setRouteKey, setSelectedKey } = useRoute();
  const { t } = useTranslation();
  let currentstep = location.pathname.split("/")[2][5] - 1;
  const steps = [
    {
      title: t("select_services"),
      // className: styles.step,
    },
    {
      title: t("details"),
      // className: styles.step,
    },
    {
      title: t("book_appointment"),
      // className: styles.step,
    },
  ];
  const items = steps?.map((item) => ({
    key: uuidv4(),
    title: item.title,
  }));

  useEffect(() => {
    setRouteKey("book_appointment");
    setSelectedKey('CRUD_BOOK_APPOINTMENT');
  }, []);

  return (
    <Layout>
      <Steps
        className={styles.steps}
        // style={{ background: "white" }}
        responsive={false}
        current={currentstep}
        items={items}
        size="small"
      />
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
};

export { BookAppointmentRoute };

// import { Layout, Steps } from "antd";
// import React, { useState } from "react";
// import { Outlet, useLocation, useSearchParams } from "react-router-dom";
// import { v4 as uuidv4 } from "uuid";
// import styles from "../Styles/bbokAppointmentRoute.module.css";
// import { BookAppointment } from "../../BookAppointment";
// import Details from "../../BookAppointment/Components/Details";
// import FinalBookAppointmentEditInfoPage from "../../BookAppointment/Components/FinalBookAppointmentEditInfoPage";
// const { Content } = Layout;
// const BookAppointmentRoute = () => {
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   let step = queryParams.get("step");
//   let currentstep = step - 1;
//   const steps = [
//     {
//       title: "Select Services",
//       className: styles.step,
//     },
//     {
//       title: "Details",
//       className: styles.step,
//     },
//     {
//       title: "Book Appointment",
//       className: styles.step,
//     },
//   ];
//   const items = steps?.map((item) => ({
//     key: uuidv4(),
//     title: item.title,
//   }));
//   return (
//     <Layout>
//       <Steps
//         className={styles.steps}
//         responsive={false}
//         current={currentstep}
//         items={items}
//         size="small"
//       />
//       {
//         step === "1" && <BookAppointment />
//       }
//       {
//         step === "2" && <Details />
//       }
//       {
//         step === "3" && <FinalBookAppointmentEditInfoPage />
//       }
//     </Layout>
//   );
// };

// export { BookAppointmentRoute };
