import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { crudPlansAction } from "../State/actions";
import { Button, Table, Typography } from "antd";
import dayjs from "dayjs";
import useWarnings from "../../Common/Components/useWarnings";
import { crudPlansApi } from "../utils/api";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

const PendingPlans = () => {
  const dispatch = useDispatch();
  const { getAllPendingPlans } = useSelector((str) => str.crudPlansReducer);
  const { isLoadingGetAllQuestions } = useSelector((str) => str.loadingReducer);
  const { error, success, contextHolder } = useWarnings();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(crudPlansAction.getAllPendingPlansEvent());
  }, []);

  const handleAction = async (planId, data) => {
    setLoading(true);
    try {
      const updatePlanRequest = await crudPlansApi.updatePlanRequest(planId, data);
      if (updatePlanRequest?.data?.info) {
        // console.log(updatePlanRequest.data.info, "dfjd");
        success("Success");
      }
      setLoading(false);
      dispatch(crudPlansAction.getAllPendingPlansEvent());
    } catch (err) {
      error(err?.response?.data?.message);
      setLoading(false)
    }
  }

  const columns = [
    {
      title: "Created on",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      align: "center",
      render: (_, { createdAt }) => (
        <Text>{dayjs(createdAt).format("MMM D, YYYY h:mm A")}</Text>
      )
    },
    {
      title: "Plan Name",
      dataIndex: "planName",
      key: "planName",
      width: 180,
      align: "center",
    },
    {
      title: "Plan Description",
      dataIndex: "planDescription",
      key: "planDescription",
      width: 250,
      align: "center",
    },
    {
      title: "Plan amount",
      dataIndex: "amount",
      key: "amount",
      width: 150,
      align: "center",
      render: (_, { currency, amount, period }) => (
        <Text>{currency + " " + amount}/{period === "monthly" ? "month" : "weekly"}</Text>
      )
    },
    {
      title: "Accessibility",
      dataIndex: "location",
      key: "location",
      width: 150,
      align: "center",
      render: (_, { location, planScope }) => (
        <Text>{planScope}-{location[planScope]}</Text>
      )
    },
    {
      title: "Services offered",
      dataIndex: "services",
      key: "services",
      width: 150,
      align: "center",
      render: (_, { services }) => (
        services?.map(e => t(e?.service)).join(", ")
      )
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 150,
      align: "center",
    },
    {
      title: "Expires at",
      dataIndex: "expiresAt",
      key: "expiresAt",
      width: 150,
      align: "center",
      render: (_, { expiresAt }) => (
        <Text>{dayjs(expiresAt).format("MMM D, YYYY h:mm A")}</Text>
      )
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      align: "center",
      render: (_, { planUniqueId }) => (
        <>
          <Button size="small" onClick={() => handleAction(planUniqueId, { action: "approve" })}>Approve</Button>
          <Button size="small" onClick={() => handleAction(planUniqueId, { action: "reject" })}>Decline</Button>
        </>
      )
    },
  ];

  return (
    <div>
      {contextHolder}
      <Table
        loading={isLoadingGetAllQuestions || loading}
        columns={columns}
        dataSource={getAllPendingPlans}
        pagination={false}
        scroll={{
          y: 500
        }}
      />
    </div>
  );
};

export default PendingPlans;
