import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { captainAction } from "../State/actions";
import useWarnings from "../../Common/Components/useWarnings";
/**
 * Custom hook for managing the verification steps and their side effects in the captain's workflow.
 * 
 * @function useVerificationStepsForCaptainEffects
 * @description
 * Handles various side effects related to the verification steps for the captain, including:
 * - Updating the current step based on the status of order and parent verification.
 * - Updating user ref based on captain details from the Redux store.
 * - Handling success and error messages for OTP requests and verifications.
 * - Clearing OTP state after handling response.
 * 
 * @returns {void}
 */

const useVerificationStepsForCaptainEffects = ({
  typeofroute,
  user,
  setCurrent,
}) => {
  const dispatch = useDispatch();
  const { success, error } = useWarnings();

  const {
    verifyOtpforVerificationOrderParentErr,
    requestOtpforVerificationOrderParentSuccess,
    verifyOtpforVerificationOrderParentSuccess,
  } = useSelector((str) => str.captainReducer);
  const { captainDetails } = useSelector((str) => str.dashboardReducer);

  let {
    order_verification,
    parent_verification,
    pet_verification_by_captain,
  } = user.current?.bookingStatus?.petPickupAndDrop_status?.boarding_center_to_parent_location_to_pickup_pet;

  useEffect(() => {
    if (typeofroute.current === "pickup") {
      if (order_verification?.status === "accept") {
        setCurrent(2);
      } else if (parent_verification?.status === "accept") {
        setCurrent(2);
      }
    }
  }, [parent_verification, pet_verification_by_captain]);

  useEffect(() => {
    user.current = captainDetails?.find(
      (e) => e.bookingId === user.current.bookingId
    );
  }, [captainDetails]);

  useEffect(() => {
    if (typeofroute.current === "pickup") {
      if (requestOtpforVerificationOrderParentSuccess) {
        success("Otp sent successfully!");
      } else if (verifyOtpforVerificationOrderParentSuccess) {
        setCurrent(1);
        success("Verification successfull");
      } else if (verifyOtpforVerificationOrderParentErr) {
        error("Verification unsuccessful");
      }
      dispatch(captainAction.clearOtp());
    }
  }, [
    verifyOtpforVerificationOrderParentSuccess,
    requestOtpforVerificationOrderParentSuccess,
    verifyOtpforVerificationOrderParentErr,
  ]);
};

export default useVerificationStepsForCaptainEffects;
