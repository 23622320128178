import { message } from "antd";


const useWarnings = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const warning = () => {
        messageApi.open({
            type: "warning",
            content: "Please select a reason.",
        });
    };

    const otherWarning = () => {
        messageApi.open({
            type: "warning",
            content: "Please type your reason behind declining the booking.",
        });
    };

    const success = (content) => {
        messageApi.open({
            type: "success",
            content: content,
        })
    }

    const error = (content) => {
        messageApi.open({
            type: "error",
            content: content,
        })
    }

    const commonwarning = (content) => {
        messageApi.open({
            type: "warning",
            content: content,
        })
    }

    return {
        warning, otherWarning, contextHolder, success, error,commonwarning
    }
}

export default useWarnings;