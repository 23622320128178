import { startLoading, startLoadingAllQuestions, startLoadingQuestionById, stopLoading, stopLoadingAllQuestions, stopLoadingQuestionById } from "../../state/actions";
import { helpApi } from "../utils/api";
import { helpConstants } from "./action-types";

const addQuestionSuccess = (data) => {
  return {
    type: helpConstants.ADD_QUESTION_SUCCESS,
    data: data,
  }
}

const addQuestionError = (err) => {
  return {
    type: helpConstants.ADD_QUESTION_ERROR,
    valuesError: err,
  }
}

const addQuestionEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoading());
    helpApi.addQuestion(data).then(response => {
      dispatch(addQuestionSuccess(response.data.info));
      dispatch(getAllQuestionEvent());
      dispatch(stopLoading())
    })
      .catch(err => {
        dispatch(addQuestionError(err));
        dispatch(stopLoading());
      })
  }
}

const getAllQuestionSuccess = (data) => {
  return {
    type: helpConstants.GET_ALL_QUESTIONS_SUCCESS,
    data: data,
  }
}

const getAllQuestionError = (err) => {
  return {
    type: helpConstants.GET_ALL_QUESTIONS_ERROR,
    valuesError: err,
  }
}

const getAllQuestionEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoadingAllQuestions());
    helpApi.getAllQuestions(data).then(response => {
      dispatch(getAllQuestionSuccess(response.data.info));
      dispatch(stopLoadingAllQuestions())
    })
      .catch(err => {
        dispatch(getAllQuestionError(err));
        dispatch(stopLoadingAllQuestions())
      })
  }
}

const updateQuestionSuccess = (data) => {
  return {
    type: helpConstants.UPDATE_QUESTION_SUCCESS,
    data: data,
  }
}

const updateQuestionError = (err) => {
  return {
    type: helpConstants.UPDATE_QUESTION_ERROR,
    valuesError: err,
  }
}

const updateQuestionByIdEvent = (id, data) => {
  return (dispatch) => {
    dispatch(startLoading());
    helpApi.updateQuestionById(id, data).then(response => {
      dispatch(updateQuestionSuccess(response.data.info));
      dispatch(stopLoading());
      dispatch(getAllQuestionEvent());
    })
      .catch(err => {
        dispatch(updateQuestionError(err));
        dispatch(stopLoading())
      })
  }
}

const getQuestionByIdSuccess = (data) => {
  return {
    type: helpConstants.GET_QUESTION_BY_ID_SUCCESS,
    data: data,
  }
}

const getQuestionByIdError = (err) => {
  return {
    type: helpConstants.GET_QUESTION_BY_ID_ERROR,
    valuesError: err,
  }
}

const getQuestionByIdEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoadingQuestionById());
    helpApi.getQuestionById(data).then(response => {
      dispatch(getQuestionByIdSuccess(response.data.info));
      dispatch(stopLoadingQuestionById())
    })
      .catch(err => {
        dispatch(getQuestionByIdError(err));
        dispatch(stopLoadingQuestionById())
      })
  }
}

const getQuestionByKeySuccess = (data) => {
  return {
    type: helpConstants.GET_QUESTIONS_KEY_PLATFORM_SUCCESS,
    data: data,
  }
}

const getQuestionByKeyError = (err) => {
  return {
    type: helpConstants.GET_QUESTIONS_KEY_PLATFORM_ERROR,
    valuesError: err,
  }
}

const getQuestionByKeyEvent = (key, platform) => {
  return (dispatch) => {
    dispatch(startLoadingQuestionById());
    helpApi.getQuestionByKey(key, platform).then(response => {
      dispatch(getQuestionByKeySuccess(response.data.info));
      dispatch(stopLoadingQuestionById())
    })
      .catch(err => {
        dispatch(getQuestionByKeyError(err));
        dispatch(stopLoadingQuestionById())
      })
  }
}

const deleteQuestionSuccess = (data) => {
  return {
    type: helpConstants.DELETE_QUESTION_SUCCESS,
    data: data,
  }
}

const deleteQuestionError = (err) => {
  return {
    type: helpConstants.DELETE_QUESTION_ERROR,
    valuesError: err,
  }
}

const deleteQuestionEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoadingQuestionById());
    helpApi.deleteQuestionById(data).then(response => {
      dispatch(deleteQuestionSuccess(response.data.info));
      dispatch(getAllQuestionEvent())
      dispatch(stopLoadingQuestionById())
    })
      .catch(err => {
        dispatch(deleteQuestionError(err));
        dispatch(stopLoadingQuestionById())
      })
  }
}

export const helpAction = {
  addQuestionEvent,
  getAllQuestionEvent,
  updateQuestionByIdEvent,
  getQuestionByIdEvent,
  deleteQuestionEvent,
  getQuestionByKeyEvent
};
