import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { bookAppointmentAction } from '../State/bookAppointmentActions';

/**
 * @function useDetailsEffects
 * @description Custom React hook that manages side effects related to booking details and address updates.
 * - Fetches booking details by ID when the component mounts or `booking_id` changes.
 * - Updates address and date-time state based on the fetched booking details or user details.
 *
 * @param {Function} setAddress - Function to update the address state. Receives a function that updates the state with pickup address, drop address, and boarding center address.
 * @param {Function} setDateTime - Function to update the date-time state. Receives an object with service names as keys and their respective check-in and check-out date-times as values.
 *
 * @returns {void} - This hook does not return anything.
 */

const useDetailsEffects = ({ setAddress, setDateTime }) => {
    const dispatch = useDispatch();
    const booking_id = localStorage.getItem("bookingID");
    const { bookappointmentdetailsbyID } = useSelector(
        (str) => str.bookAppointmentReducer
    );
    const { userDetails } = useSelector(str => str.navbarReducer);

    useEffect(() => {
        if (booking_id) {
            dispatch(
                bookAppointmentAction.getBookAppointmentdetailsByIdEvent(booking_id)
            );
        }
        // eslint-disable-next-line
    }, [booking_id]);

    useEffect(() => {
        if (bookappointmentdetailsbyID?.pickUpAddress) {
            setAddress((prev) => ({
                ...prev,
                pickupaddress: bookappointmentdetailsbyID?.pickUpAddress,
            }));
        } else {
            setAddress((prev) => ({
                ...prev,
                pickupaddress: userDetails?.usersCurrentAddressDetails,
            }));
        }
        if (bookappointmentdetailsbyID?.dropAddress) {
            setAddress((prev) => ({
                ...prev,
                dropaddress: bookappointmentdetailsbyID?.dropAddress,
            }));
        } else {
            setAddress((prev) => ({
                ...prev,
                dropaddress: userDetails?.usersCurrentAddressDetails,
            }));
        }
        if (bookappointmentdetailsbyID?.completeBoardingCenterAddress) {
            setAddress((prev) => ({
                ...prev,
                boardingCenterAddress:
                    bookappointmentdetailsbyID?.completeBoardingCenterAddress,
            }));
        } else {
            setAddress((prev) => ({
                ...prev,
                boardingCenterAddress:
                    userDetails?.boardingCenterLocation?.completeBoardingAddress,
            }));
        }
        if (bookappointmentdetailsbyID?.services) {
            const updatedDateTime = {};
            bookappointmentdetailsbyID?.services
            ?.filter((service) => service.serviceName !== 'petPickupAndDrops')
            ?.forEach((service) => {
                const { serviceName, slots } = service;
                updatedDateTime[serviceName] = {
                    checkInDateTime: slots?.checkInDateTime,
                    checkOutDateTime: slots?.checkOutDateTime,
                };
            });
            setDateTime(updatedDateTime);
        }
        // eslint-disable-next-line
    }, [bookappointmentdetailsbyID]);
}

export default useDetailsEffects
