import { Card, Col, Image, Row, theme, Tooltip, Typography } from "antd";
import React from "react";
import { services } from "../../Common/Components/Services";
import { PlusCircleFilled } from "@ant-design/icons";
import DateTime from "./DateTime";
import { v4 as uuidv4 } from "uuid";
const { Title } = Typography;

/**
 * @component
 * @description This component renders a card displaying the details of a specific service, including its name, type, and the ability to select a date and time.
 * The card is interactive, allowing users to choose start and end times for the service through a date-time picker.
 *
 * @param {Object} service - The service object containing details like serviceName and serviceType.
 * @param {Function} setDateTime - Function to update the selected date and time in the parent state.
 * @param {Object} dateTime - The current selected date and time for the service, containing `checkInDateTime` and `checkOutDateTime`.
 * @param {Function} getTimeSlotsForParticularMonth - Function to fetch available time slots for the selected month.
 */

const EachDetailsServiceCard = ({
  service,
  setDateTime,
  dateTime,
  getTimeSlotsForParticularMonth,
}) => {
  const { useToken } = theme;
  const { token } = useToken();
  return (
    <Card hoverable style={{ borderRadius: 0, marginBottom: "10px" }}>
      <Row style={{ justifyContent: "center" }}>
        <Col span={12}>
          <Title
            key={uuidv4()}
            level={4}
            style={{ margin: "0", color: token.Primary }}
          >
            {/* {service?.serviceName}{" "} */}
            {services?.find((e) => e.key === service.serviceName).name}
            {service.serviceType === "addon" ? (
              <Tooltip title="Add On">
                <PlusCircleFilled style={{ fontSize: "15px" }} />
              </Tooltip>
            ) : (
              ""
            )}
          </Title>
          <Title level={5}>Date and Time</Title>
          <DateTime
            setDateTime={setDateTime}
            dateTime={dateTime}
            getTimeSlotsForParticularMonth={getTimeSlotsForParticularMonth}
            serviceName={service?.serviceName}
          />
        </Col>
        <Col span={8}>
          <Image
            preview={false}
            src={
              "https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
            }
          />
        </Col>
      </Row>
    </Card>
  );
};

export default EachDetailsServiceCard;
