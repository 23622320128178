import { useDispatch } from "react-redux";
import { adminAction } from "../../ServiceDetails/state/actions";
import { useRef, useState } from "react";
import {
    commonBodyForpetBoarding_status,
    commonBodyForpetPickupAndDrop_status,
    commonBodyForpetPickupAndDrop_status_drop,
} from "../../Common/Components/commonBodyForUpdatingStatus";
import { useSocket } from "../../context/SocketContext";
/**
 * Custom hook for managing pet transportation and verification processes.
 * 
 * @function useMethods
 * @description Provides methods and states for handling various stages of pet transportation, including starting navigation, verifying locations, and updating statuses. Manages geolocation tracking and modal visibility.
 * 
 * @returns {Object} Methods and states for pet transportation management.
 * @returns {Function} handleStart - Starts navigation and geolocation tracking for a given booking.
 * @returns {Function} handleStopAtBoardingCenter - Stops geolocation tracking and updates status when arriving at the boarding center.
 * @returns {Function} handleVerify - Updates status and shows modal for verification based on the type (pickup/drop).
 * @returns {Function} handleHandOverPetToBoardHead - Updates status when pet is handed over to the boarding center.
 * @returns {Function} handlePickPetFromBoardingCenter - Updates status when pet is picked up from the boarding center.
 * @returns {Function} handleHandOverPetToParent - Stops geolocation tracking and updates status when pet is handed over to the parent.
 * @returns {boolean} isModalOpen - Boolean state indicating if the modal is open.
 * @returns {Function} setIsModalOpen - Function to set the modal open state.
 * @returns {Function} showModal - Function to show the modal and set the current user and route type.
 * @returns {Object} user - A ref to hold the user object.
 * @returns {Object} typeofroute - A ref to hold the type of route (pickup/drop).
 * @returns {Object} watchId - A ref to hold the geolocation watch ID.
 */

const useMethods = () => {
    const user = useRef(null);
    const typeofroute = useRef(null);

    const [startName, setStartName] = useState("Nehru Place");
    const [destinationName, setDestinationName] = useState("Mool Chand");
    const [startCoordinates, setStartCoordinates] = useState(
        "26.884649,75.801128"
    );
    const [destinationCoordinates, setDestinationCoordinates] = useState(
        "28.566677,77.234995"
    );
    const dispatch = useDispatch();
    let watchId = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { currentSocket } = useSocket();

    const showModal = (e, type) => {
        setIsModalOpen(true);
        user.current = e;
        typeofroute.current = type;
    };

    const handleStart = (bookingUniqueId, bookingId, type) => {
        currentSocket.emit("booking_connected", {
            bookingUniqueId: bookingUniqueId,
        });
        window.open(
            `https://www.mappls.com/navigation?places=${startCoordinates},${startName};${destinationCoordinates},${destinationName}`,
            "_blank"
        );
        watchId.current = navigator.geolocation.watchPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                console.log(position, "po");
                currentSocket.emit(`driverLocation_${bookingUniqueId}`, {
                    room: `room_${bookingUniqueId}`,
                    lat: latitude,
                    lng: longitude,
                });
            },
            (error) => {
                console.log(error);
            },
            {
                enableHighAccuracy: true,
                distanceFilter: 0,
            }
        );
        let body;
        if (type === "pickup") {
            body = commonBodyForpetPickupAndDrop_status(
                bookingId,
                "on_the_way_to_parent_location"
            );
        } else {
            body = commonBodyForpetPickupAndDrop_status_drop(
                bookingId,
                "on_the_way_to_parent_location"
            );
        }

        dispatch(adminAction.updateBookingStatus(bookingUniqueId, body));
    };

    const handleStopAtBoardingCenter = (bookingUniqueId, bookingId) => {
        navigator.geolocation.clearWatch(watchId.current);
        watchId.current = null;
        let body = commonBodyForpetPickupAndDrop_status(
            bookingId,
            "pet_reached_boarding_center"
        );
        dispatch(adminAction.updateBookingStatus(bookingUniqueId, body));
    };

    const handleVerify = (bookingUniqueId, bookingId, e, type) => {
        let body;
        if (type === "pickup")
            body = commonBodyForpetPickupAndDrop_status(
                bookingId,
                "reached_parent_location"
            );
        else
            body = commonBodyForpetPickupAndDrop_status_drop(
                bookingId,
                "reached_parent_location"
            );

        dispatch(adminAction.updateBookingStatus(bookingUniqueId, body));
        showModal(e, type);
    };

    const handleHandOverPetToBoardHead = (bookingUniqueId, bookingId) => {
        let body = commonBodyForpetPickupAndDrop_status(
            bookingId,
            "pet_handed_over_to_the_boarding_center"
        );
        dispatch(adminAction.updateBookingStatus(bookingUniqueId, body));
    };

    const handlePickPetFromBoardingCenter = (bookingUniqueId, bookingId) => {
        let body = commonBodyForpetBoarding_status(
            bookingId,
            "pet_picked_up_from_boarding_center"
        );
        dispatch(adminAction.updateBookingStatus(bookingUniqueId, body));
    };

    const handleHandOverPetToParent = (bookingUniqueId, bookingId) => {
        navigator.geolocation.clearWatch(watchId.current);
        watchId.current = null;
        const body = commonBodyForpetPickupAndDrop_status_drop(
            bookingId,
            "handed_over_the_pet_to_the_parent"
        );
        dispatch(adminAction.updateBookingStatus(bookingUniqueId, body));
    };

    return {
        handleStart,
        handleVerify,
        isModalOpen,
        setIsModalOpen,
        showModal,
        user,
        typeofroute,
        handleStopAtBoardingCenter,
        handleHandOverPetToBoardHead,
        handlePickPetFromBoardingCenter,
        handleHandOverPetToParent,
        watchId,
    };
};

export default useMethods;
