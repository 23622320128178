import { appconstants } from "./action-types";
const initialState = {
  isLoading: false,
  isLoadingFinalAppointDetails: false,
  isLoadingMaps: false,
  isLoadingAddresses: false,
  isLoadingBoardingCenter: false,
  isLoadingUserDetails: false,
  name: "",
  drawerOpen: false,
  option: "",
  editAddress: false,
  checkedPickupAdress: false,
  checkedDropAdress: false,
  isLoadingBooking: false,
  isLoadingSlots: false,
  isLoadingTracking: false,
  isLoadingNotifications: false,
  isLoadingRequestOtp: false,
  isLoadingGetAllQuestions: false,
  isLoadingQuestionById: false,
  isLoadingVerifyOtp: false,
  isLoadingCaptainDetails: false,
  isLoadingKennels: false,
  isLoadingParentBoardingCenter: false,
  isLoadingAllBoardCenters: false,
  isLoadingAllBookings: false,
  isLoadingAllConfirmedBookings: false,
  isLoadingBookingsSupport: false
};
const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case appconstants.START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case appconstants.STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case appconstants.START_LOADING_FINALBOOKAPPOINTMENT_DETAILS:
      return {
        ...state,
        isLoadingFinalAppointDetails: true,
      };
    case appconstants.STOP_LOADING_FINALBOOKAPPOINTMENT_DETAILS:
      return {
        ...state,
        isLoadingFinalAppointDetails: false,
      };
    case appconstants.START_LOADING_MAPS:
      return {
        ...state,
        isLoadingMaps: true,
      };
    case appconstants.STOP_LOADING_MAPS:
      return {
        ...state,
        isLoadingMaps: false,
      };
    case appconstants.START_LOADING_ALL_ADDRESSES:
      return {
        ...state,
        isLoadingAddresses: true,
      };
    case appconstants.STOP_LOADING_ALL_ADDRESSES:
      return {
        ...state,
        isLoadingAddresses: false,
      };
    case appconstants.START_LOADING_BOARDING_CENTER:
      return {
        ...state,
        isLoadingBoardingCenter: true,
      };
    case appconstants.STOP_LOADING_BOARDING_CENTER:
      return {
        ...state,
        isLoadingBoardingCenter: false,
      };
    case appconstants.START_LOADING_USER_DETAILS:
      return {
        ...state,
        isLoadingUserDetails: true,
      };
    case appconstants.STOP_LOADING_USER_DETAILS:
      return {
        ...state,
        isLoadingUserDetails: false,
      };

    case appconstants.SET_NAME:
      return {
        ...state,
        name: action.name
      }
    case appconstants.RESET_NAME:
      return {
        ...state,
        name: ""
      }
    case appconstants.SET_DRAWER_OPEN:
      return {
        ...state,
        drawerOpen: true,
        option: action.payload,
        editAddress: action.address,
        checkedPickupAdress: action.payload === "setpickup" ? action.address : state.checkedPickupAdress,
        checkedDropAdress: action.payload === "setDrop" ? action.address : state.checkedDropAdress
      }
    case appconstants.RESET_DRAWER:
      return {
        ...state,
        drawerOpen: false,
        option: "",
        editAddress: false
      }
    case appconstants.START_LOADING_BOOKING:
      return {
        ...state,
        isLoadingBooking: action.payload,
      };
    case appconstants.STOP_LOADING_BOOKING:
      return {
        ...state,
        isLoadingBooking: action.payload,
      };
    case appconstants.START_LOADING_SLOTS:
      return {
        ...state,
        isLoadingSlots: true,
      };
    case appconstants.STOP_LOADING_SLOTS:
      return {
        ...state,
        isLoadingSlots: false,
      };
    case appconstants.START_TRACKING_LOADING:
      return {
        ...state,
        isLoadingTracking: true
      };
    case appconstants.STOP_LOADING_TRACKING:
      return {
        ...state,
        isLoadingTracking: false
      }
    case appconstants.START_LOADING_NOTIFICATIONS:
      return {
        ...state,
        isLoadingNotifications: true
      }
    case appconstants.STOP_LOADING_NOTIFICATIONS:
      return {
        ...state,
        isLoadingNotifications: false
      }
    case appconstants.START_LOADING_REQUEST_OTP:
      return {
        ...state,
        isLoadingRequestOtp: true
      }
    case appconstants.STOP_LOADING_REQUEST_OTP:
      return {
        ...state,
        isLoadingRequestOtp: false
      }
    case appconstants.START_LOADING_GET_ALL_QUESTIONS:
      return {
        ...state,
        isLoadingGetAllQuestions: true
      }
    case appconstants.STOP_LOADING_GET_ALL_QUESTIONS:
      return {
        ...state,
        isLoadingGetAllQuestions: false
      }
    case appconstants.START_LOADING_QUESTION_BY_ID:
      return {
        ...state,
        isLoadingQuestionById: true
      }
    case appconstants.STOP_LOADING_QUESTION_BY_ID:
      return {
        ...state,
        isLoadingQuestionById: false
      }
    case appconstants.START_LOADING_VERIFY_OTP:
      return {
        ...state,
        isLoadingVerifyOtp: true
      }
    case appconstants.STOP_LOADING_VERIFY_OTP:
      return {
        ...state,
        isLoadingVerifyOtp: false
      }
    case appconstants.START_LOADING_CAPTAIN_DETAILS_EVENT:
      return {
        ...state,
        isLoadingCaptainDetails: true
      }
    case appconstants.STOP_LOADING_CAPTAIN_DETAILS_EVENT:
      return {
        ...state,
        isLoadingCaptainDetails: false
      }
    case appconstants.START_LOADING_KENNELS:
      return {
        ...state,
        isLoadingKennels: true
      }
    case appconstants.STOP_LOADING_KENNELS:
      return {
        ...state,
        isLoadingKennels: false
      }
    case appconstants.START_LOADING_PARENT_BOARDING_CENTER_DETAILS:
      return {
        ...state,
        isLoadingParentBoardingCenter: true
      }
    case appconstants.STOP_LOADING_PARENT_BOARDING_CENTER_DETAILS:
      return {
        ...state,
        isLoadingParentBoardingCenter: false
      }
    case appconstants.START_LOADING_ALL_BOARD_CENTERS:
      return {
        ...state,
        isLoadingAllBoardCenters: true
      }
    case appconstants.STOP_LOADING_ALL_BOARD_CENTERS:
      return {
        ...state,
        isLoadingAllBoardCenters: false
      }
    case appconstants.START_LOADING_ALL_BOOKINGS:
      return {
        ...state,
        isLoadingAllBookings: true
      }
    case appconstants.STOP_LOADING_ALL_BOOKINGS:
      return {
        ...state,
        isLoadingAllBookings: false
      }
    case appconstants.START_LOADING_ALL_CONFIRMED_BOOKINGS:
      return {
        ...state,
        isLoadingAllConfirmedBookings: true
      }
    case appconstants.STOP_LOADING_ALL_CONFIRMED_BOOKINGS:
      return {
        ...state,
        isLoadingAllConfirmedBookings: false
      }
    case appconstants.START_LOADING_ALL_BOOKINGS_SUPPORT:
      return {
        ...state,
        isLoadingBookingsSupport: true
      }
    case appconstants.STOP_LOADING_ALL_BOOKINGS_SUPPORT:
      return {
        ...state,
        isLoadingBookingsSupport: false
      }
    default:
      return state;
  }
};
export { loadingReducer };
