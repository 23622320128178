import axios from "axios";


const apiURL = process.env.REACT_APP_BACKEND_URL;

// get all cancelRequests for a boarding center head
const getAllCancelRequests = () => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        }
    };
    return axios.get(`${apiURL}/allBookingCancelRequests`, config);
}

// approve cancellation request
const approveCancelRequest = (bookingId) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        }
    };
    return axios.get(`${apiURL}/approveCancellation/${bookingId}`, config);
}

export const cancelRequestsApi = {
    getAllCancelRequests,
    approveCancelRequest
};
