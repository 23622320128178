import { startLoading, startLoadingAllBookingsForSupport, startLoadingAllQuestions, stopLoading, stopLoadingAllBookingsForSupport, stopLoadingAllQuestions } from "../../state/actions"
import { supportApi } from '../utils/api';
import { supportConstants } from "./action-types"

const createTicketSuccess = (data) => {
  return {
    type: supportConstants.CREATE_TICKET_SUCCESS,
    data: data,
  }
}

const createTicketErr = (err) => {
  return {
    type: supportConstants.CREATE_TICKET_ERROR,
    valuesError: err,
  }
}

const createTicketEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoadingAllBookingsForSupport());
    supportApi.createTicket(data).then(response => {
      dispatch(createTicketSuccess(response.data.info));
      dispatch(stopLoadingAllBookingsForSupport())
    })
      .catch(err => {
        dispatch(createTicketErr(err));
        dispatch(stopLoadingAllBookingsForSupport());
      })
  }
}

const getTicketsSuccess = (data) => {
  return {
    type: supportConstants.GET_ALL_TICKETS_SUCCESS,
    data: data,
  }
}

const getTicketsErr = (err) => {
  return {
    type: supportConstants.GET_ALL_TICKETS_ERROR,
    valuesError: err,
  }
}

const getTicketsEvent = (page, boardCenterId, ticketId) => {
  return (dispatch) => {
    dispatch(startLoadingAllQuestions());
    supportApi.getAllTickets(page, boardCenterId, ticketId).then(response => {
      dispatch(getTicketsSuccess(response.data.info));
      dispatch(stopLoadingAllQuestions())
    })
      .catch(err => {
        dispatch(getTicketsErr(err));
        dispatch(stopLoadingAllQuestions());
      })
  }
}

const getAllBookingsOfUserSuccess = (data) => {
  return {
    type: supportConstants.GET_ALL_BOOKINGS_OF_USER_SUCCESS,
    data: data,
  }
}

const getAllBookingsOfUserErr = (err) => {
  return {
    type: supportConstants.GET_ALL_BOOKINGS_OF_USER_ERROR,
    valuesError: err,
  }
}

const getAllBookingsOfUserEvent = () => {
  return (dispatch) => {
    dispatch(startLoadingAllBookingsForSupport());
    supportApi.getBookingsOfUser().then(response => {
      dispatch(getAllBookingsOfUserSuccess(response.data.info));
      dispatch(stopLoadingAllBookingsForSupport());
    })
      .catch(err => {
        dispatch(getAllBookingsOfUserErr(err));
        dispatch(stopLoadingAllBookingsForSupport());

      })
  }
}

const updateTicketsSuccess = (data) => {
  return {
    type: supportConstants.UPDATE_TICKET_SUCCESS,
    data: data,
  }
}

const updateTicketsErr = (err) => {
  return {
    type: supportConstants.UPDATE_TICKET_ERROR,
    valuesError: err,
  }
}

const updateTicketEvent = (ticketId, data) => {
  return (dispatch) => {
    dispatch(startLoading());
    supportApi.updateTicket(ticketId, data).then(response => {
      dispatch(updateTicketsSuccess(response.data.info));
      dispatch(stopLoading())
    })
      .catch(err => {
        dispatch(updateTicketsErr(err));
        dispatch(stopLoading());
      })
  }
}

const clearData = () => {
  return {
    type: supportConstants.CLEAR_SUPPORT_DATA,
  };
}

export const supportAction = {
  createTicketEvent,
  getTicketsEvent,
  updateTicketEvent,
  getAllBookingsOfUserEvent,
  clearData
};
