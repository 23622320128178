import { startLoading, stopLoading } from "../../state/actions";
import { signupApi } from "../utils/api";
import { signupinConstants } from "./action-types";

const ssoGoogleSuccess = (data) => {
  return {
    type: signupinConstants.LOGIN_GOOGLE_SUCCESS,
    data: data,
  };
};

const ssoGoogleError = (err) => {
  return {
    type: signupinConstants.LOGIN_GOOGLE_FAILURE,
    dataError: err,
  };
};

const ssoGoogleEvent = (code) => {
  return (dispatch) => {
    // dispatch(startLoading());
    signupApi
      .ssoGoogle(code, "Google")
      .then((response) => {
        // console.log(response, "response");
        if (
          (response.data.hasOwnProperty("statusCode") &&
            response.data.statusCode === 401) ||
          response.data.statusCode === 404
        ) {
          dispatch(ssoGoogleError(response.data.info.message));
          // dispatch(stopLoading());
        } else {
          dispatch(ssoGoogleSuccess(response.data.info));
          // dispatch(stopLoading());
        }
      })
      .catch((err) => {
        console.log(err, "error");
        dispatch(ssoGoogleError(err?.response?.data?.message));
        dispatch(stopLoading());
      });
  };
};

const clearGoogleData = () => {
  return {
    type: signupinConstants.LOGIN_GOOGLE_DATA_RESET,
  };
};

export const googleAction = {
  ssoGoogleEvent,
  clearGoogleData,
};
