import React from "react";
import recentservicesname from "../../Assets/BoardHeadDashboard/recentservices.png";
import styles from "../Styles/todayhistory.module.css";
import HorizontalCard from "../../Common/Components/HorizontalCard";
import { Divider, Image, Card } from "antd";
const RecentServices = () => {
    const recentServices = [
        {
            name: "Golden Sheperd",
            nickname: "Rolex",
            service: "Boarding",
            online: true,
        },
        {
            name: "Golden Sheperd",
            nickname: "Jimmy",
            service: "Boarding",
            online: true,
        },
        {
            name: "Pomeranian",
            nickname: "Scarlet",
            service: "SPA & grooming",
            online: true,
        },
        {
            name: "Doberman Pischers",
            nickname: "Rolex",
            service: "Boarding",
            online: false,
        },
    ];
    return (
        <Card hoverable className={styles.entireHistoryPage}>
            {recentServices?.map((e) => (
                <>
                    <HorizontalCard service={e} />
                    <Divider style={{ margin: "5px" }} />
                </>
            ))}
            <div style={{ position: "absolute", top: 5, left: "0px" }}>
                <Image src={recentservicesname} alt="Recent Services" preview={false} width={150} />
            </div>
        </Card>
    );
};

export default RecentServices;
