export const profileConstants = {
  GET_PARENT_DETAILS_SUCCESS: "GET_PARENT_DETAILS_SUCCESS",
  GET_PARENT_DETAILS_ERROR: "GET_PARENT_DETAILS_ERROR",

  POST_PARENT_DETAILS_SUCCESS: "POST_PARENT_DETAILS_SUCCESS",
  POST_PARENT_DETAILS_ERROR: "POST_PARENT_DETAILS_ERROR",

  UPDATE_PARENT_DETAILS_SUCCESS: "UPDATE_PARENT_DETAILS_SUCCESS",
  UPDATE_PARENT_DETAILS_ERROR: "UPDATE_PARENT_DETAILS_ERROR",

  POST_PET_DETAILS_SUCCESS: "POST_PET_DETAILS_SUCCESS",
  POST_PET_DETAILS_ERROR: "POST_PET_DETAILS_ERROR",

  UPDATE_PET_DETAILS_SUCCESS: "UPDATE_PET_DETAILS_SUCCESS",
  UPDATE_PET_DETAILS_ERROR: "UPDATE_PET_DETAILS_ERROR",

  GET_PET_DETAILS_SUCCESS: "GET_PET_DETAILS_SUCCESS",
  GET_PET_DETAILS_ERROR: "GET_PET_DETAILS_ERROR",

  SELECT_SERVICES_FAILURE:"SELECT_SERVICES_FAILURE",
  SELECT_SERVICES_SUCCESS:"SELECT_SERVICES_SUCCESS",

  ADD_ADDRESS_SUCCESS:"ADD_ADDRESS_SUCCESS",
  ADD_ADDRESS_FAILURE:"ADD_ADDRESS_FAILURE",

  ALL_ADDRESS_SUCCESS:"ALL_ADDRESS_SUCCESS",
  ALL_ADDRESS_FAILURE:"ALL_ADDRESS_FAILURE",

  UPDATE_ADDRESS_SUCCESS:"UPDATE_ADDRESS_SUCCESS",
  UPDATE_ADDRESS_FAILURE:"UPDATE_ADDRESS_FAILURE",

  GET_BOARDINGCENTER_BY_ID_SUCCESS:"GET_BOARDINGCENTER_BY_ID_SUCCESS",
  GET_BOARDINGCENTER_BY_ID_FAILURE:"GET_BOARDINGCENTER_BY_ID_FAILURE",

  UPDATE_BOARDING_CENTER_SUCCESS:"UPDATE_BOARDING_CENTER_SUCCESS",
  UPDATE_BOARDING_CENTER_FAILURE:"UPDATE_BOARDING_CENTER_FAILURE",

  GET_PARENT_BOARDINGCENTER_DETAILS_SUCCESS:"GET_PARENT_BOARDINGCENTER_DETAILS_SUCCESS",
  GET_PARENT_BOARDINGCENTER_DETAILS_FAILURE:"GET_PARENT_BOARDINGCENTER_DETAILS_FAILURE",

  CLEAR_PROFILE_DETAILS:"CLEAR_PROFILE_DETAILS"
};
