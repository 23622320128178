import React from "react";
import { Modal, Select, Input } from "antd";

/** @description- modal to display all the decline reasons where captain can select any to decline the booking */
const DeclineModal = ({
  isModalOpen,
  declineBooking,
  setDeclineBooking,
  handleOk,
  handleCancel,
  handleSelectChange,
  contextHolder
}) => {
  return (
    <>
    {contextHolder}
      <Modal
        title="Decline reason"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
      >
        <Select
          defaultValue="Select your reason"
          style={{ width: "90%" }}
          onChange={handleSelectChange}
          options={[
            { value: "Select your reason", label: "Select your reason" },
            { value: "Cab in repair", label: "Cab in repair" },
            { value: "Too far", label: "Too far" },
            {
              value: "Route blocked due to maintenance",
              label: "Route blocked due to maintenance",
            },
            { value: "other", label: "other" },
          ]}
        />
        <Input
          placeholder="Specify your decline reason"
          value={declineBooking.customReason}
          onChange={(e) =>
            setDeclineBooking({
              ...declineBooking,
              customReason: e.target.value,
            })
          }
          style={{
            marginTop: "1em",
            width: "90%",
            display:
              declineBooking.declineReason === "other" ? "initial" : "none",
          }}
        />
      </Modal>
    </>
  );
};

export default DeclineModal;
