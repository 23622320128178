import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mappls } from "mappls-web-maps";
import Spinner from "../../Spinner/Spinner";
import { addressApi } from "../utils/api";
import { Col, Row } from "antd";
import { resetName, setName } from "../../state/actions";
import UserStatus from "./UserStatus";
import { useMediaQuery } from "react-responsive";
import { useSocket } from "../../context/SocketContext";
import { useParams } from "react-router-dom";
import { bookAppointmentAction } from "../../BookAppointment/State/bookAppointmentActions";
const mapplsClassObject = new mappls();

const Tracking = ({ showStatus, bookingIdFromAdmin }) => {
  const { currentSocket } = useSocket();
  const dispatch = useDispatch();
  const { bookingId } = useParams();
  const { tokendata } = useSelector((state) => state.appReducer);
  const [location, setLocation] = useState({ lat: 22.3253524, lng: 87.3303814 }); // Default location
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const [destCoordinates, setDestCoordinates] = useState(null);
  const { bookappointmentdetailsbyID } = useSelector(
    (str) => str.bookAppointmentReducer
  );
  const [pts, setPts] = useState([]);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const polylineRef = useRef(null);
  const ismediumScreen = useMediaQuery({ maxWidth: 992 });
  const {
    isLoadingTracking,
    isLoadingMaps,
    isLoading,
    isLoadingFinalAppointDetails,
    isLoadingUserDetails,
  } = useSelector((str) => str.loadingReducer);

  useEffect(() => {
    dispatch(
      bookAppointmentAction.getBookAppointmentdetailsByIdEvent(bookingIdFromAdmin? bookingIdFromAdmin :bookingId)
    );
  }, []);
  useEffect(() => {
    if (currentSocket) {
      currentSocket.on(
        bookingIdFromAdmin
          ? `driverLocationUpdate_${bookingIdFromAdmin}`
          : `driverLocationUpdate_${bookingId}`,
        (data) => {
          console.log(data, "suahdin");
          setLocation({ lat: data.lat, lng: data.lng });
        }
      );
    }
    return () => {
      currentSocket?.off(`driverLocationUpdate_${bookingId}`);
    };
  }, [currentSocket]);

  useEffect(() => {
    const getCoordinates = async () => {
      let destinationParams;
      if (typeof destCoordinates === "string") {
        destinationParams = { desteLoc: destCoordinates };
      } else if (
        typeof destCoordinates === "object" &&
        destCoordinates?.lat &&
        destCoordinates?.lng
      ) {
        destinationParams = {
          destlat: destCoordinates.lat,
          destLng: destCoordinates.lng,
        };
      } else {
        console.error("Invalid destination coordinates");
        return;
      }
      let results = await addressApi.getCoordinates({
        souceLat: location.lat,
        sourcelng: location.lng,
        ...destinationParams,
      });
      if (results?.data?.info?.message) {
        return;
      }
      let allcooredinates = results?.data?.info;
      const reversedCoordinates = allcooredinates?.map((coord) => ({
        lat: coord[1],
        lng: coord[0],
      }));
      setPts(reversedCoordinates);
    };
    if (destCoordinates) getCoordinates();
  }, [location, destCoordinates]);

  useEffect(() => {
    if (bookappointmentdetailsbyID) {
      if (
        bookappointmentdetailsbyID?.status?.petPickupAndDrop_status
          ?.boarding_center_to_parent_location_drop_pet
      ) {
        // if(bookappointmentdetailsbyID?.status?.petPickupAndDrop_status?.boarding_center_to_parent_location_drop_pet)
        if (bookappointmentdetailsbyID?.dropAddress?.eLoc) {
          setDestCoordinates(bookappointmentdetailsbyID?.dropAddress?.eLoc);
        } else {
          setDestCoordinates({
            lat: bookappointmentdetailsbyID?.dropAddress?.latitude,
            lng: bookappointmentdetailsbyID?.dropAddress?.longitude,
          });
        }
      } else {
        if (bookappointmentdetailsbyID?.pickUpAddress?.eLoc) {
          if (
            bookappointmentdetailsbyID?.status?.petPickupAndDrop_status
              ?.boarding_center_to_parent_location_to_pickup_pet
              ?.reached_parent_location
          ) {
            if (userDetails?.boardingCenterLocation?.locationCoords) {
              setDestCoordinates({
                lat: userDetails.boardingCenterLocation?.locationCoords?.lat,
                lng: userDetails.boardingCenterLocation?.locationCoords?.lang,
              });
            } else {
              setDestCoordinates(userDetails?.boardingCenterLocation?.eLoc);
            }
          } else
            setDestCoordinates(bookappointmentdetailsbyID?.pickUpAddress?.eLoc);
        } else {
          if (
            bookappointmentdetailsbyID?.status?.petPickupAndDrop_status
              ?.boarding_center_to_parent_location_to_pickup_pet
              ?.reached_parent_location
          ) {
            if (userDetails?.boardingCenterLocation?.locationCoords) {
              setDestCoordinates({
                lat: userDetails.boardingCenterLocation?.locationCoords?.lat,
                lng: userDetails.boardingCenterLocation?.locationCoords?.lang,
              });
            } else {
              setDestCoordinates(userDetails?.boardingCenterLocation?.eLoc);
            }
          } else
            setDestCoordinates({
              lat: bookappointmentdetailsbyID?.pickUpAddress?.latitude,
              lng: bookappointmentdetailsbyID?.pickUpAddress?.longitude,
            });
        }
      }
    }
  }, [bookappointmentdetailsbyID]);

  useEffect(() => {
    if (mapRef.current && pts.length > 0) {
      if (polylineRef.current) {
        polylineRef.current.setPath(pts);
      } else {
        polylineRef.current = new mapplsClassObject.Polyline({
          map: mapRef.current,
          path: pts,
          strokeColor: "black",
          fitbounds: true,
          strokeWeight: 3,
          strokeOpacity: 1.0,
          animate: {
            path: true,
            speed: 5,
          },
        });
      }
    }
  }, [pts]);

  useEffect(() => {
    if (tokendata && tokendata?.access_token && !mapRef.current && destCoordinates) {
      const loadObject = { map: true, plugins: ["direction"] };
      mapplsClassObject.initialize(tokendata?.access_token, loadObject, () => {
        let map = new mapplsClassObject.Map({
          id: "trackmap",
          properties: {
            // center: [location.lat, location.lng],
            zoom: 18,
            // geolocation: true
          },
        });
        map?.on("load", () => {
          const marker = new mapplsClassObject.Marker({
            position: location,
            map: map,
          });
          markerRef.current = marker;
          polylineRef.current = new mapplsClassObject.Polyline({
            map: map,
            path: pts,
            strokeColor: "black",
            fitbounds: true,
            strokeWeight: 3,
            strokeOpacity: 1.0,
            animate: {
              path: true,
              speed: 5,
            },
          });
        });
        mapRef.current = map;
      });
    }
  }, [tokendata, destCoordinates]);

  useEffect(() => {
    if (markerRef.current && mapRef.current) {
      markerRef.current.setPosition(location);
      // markerRef.current.setIcon(
      //   "https://www.mapmyindia.com/api/advanced-maps/doc/sample/map_sdk/car.png"
      // );
      // markerRef.current.setIcon("https://apis.mappls.com/map_v3/mkr_end.png?1")
      mapRef.current.easeTo({
        center: location,
        duration: 500,
      });
    }
  }, [location]);

  useEffect(() => {
    dispatch(setName("Track your status"));
    return () => {
      dispatch(resetName());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Cleanup function to stop the map
    return () => {
        markerRef.current = null;
        polylineRef.current = null;
        mapRef.current = null;
        setPts([]);
        setDestCoordinates(null);
        dispatch(bookAppointmentAction.bookappointmentByIDresetEvent());
    };
  }, []);

  if (
    isLoadingFinalAppointDetails ||
    isLoadingTracking ||
    isLoadingMaps ||
    isLoadingUserDetails ||
    isLoading
  )
    return <Spinner />;

  return (
    <Row gutter={16}>
      <Col
        id="trackmap"
        xs={24}
        sm={24}
        md={24}
        lg={showStatus ? 24 : 10}
        style={{
          width: "100%",
          height: "80vh",
          position: ismediumScreen ? "" : "sticky",
          top: ismediumScreen ? "" : "100px",
        }}
      >
        {(isLoadingTracking || isLoadingMaps || isLoading) && <Spinner />}
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={14}
        style={{ display: showStatus ? "none" : "" }}
      >
        <UserStatus />
      </Col>
    </Row>
  );
};
export default Tracking;
