import { Button, Input, Space } from "antd";
import React from "react";
import useVerificationMethods from "./useVerificationMethods";
/**
 * @function ParentVerificationDropSteps
 * @description Renders the UI for verifying the parent OTP during the drop-off process. Provides functionality to send OTP to the parent and verify it.
 * - Displays buttons to send OTP and verify the OTP.
 *
 * @param {Object} props - The component's props.
 * @param {Object} props.user - The user object containing information needed for OTP and verification.
 * @param {string} props.otp - The OTP entered by the user.
 * @param {Function} props.setIsModalOpen - Function to open or close the modal.
 * @param {React.CSSProperties} props.contentStyle - Style object for the content container.
 * @param {Function} props.setOtp - Function to update the OTP state.
 * @param {String} props.otptypesend - type of OTP to send (captain or admin)
 * @param {String} props.otptypeverify - type of OTP to verify (captain or admin)
 * @returns {JSX.Element} The rendered component, including buttons for sending OTP and verifying it.
 */

const ParentVerificationDropSteps = ({
  user,
  otp,
  setIsModalOpen,
  contentStyle,
  setOtp,
  otptypesend,
  otptypeverify,
}) => {
  const { handleSendOtp, handleVerifyOtp } = useVerificationMethods();

  return (
    <Space size={"middle"} direction="vertical" style={contentStyle}>
      <Button size="small" onClick={() => handleSendOtp(otptypesend, user)}>
        Send OTP to parent
      </Button>
      <Input.OTP length={6} onChange={(e) => setOtp(e)} />
      <Button
        size="large"
        type="link"
        onClick={() =>
          handleVerifyOtp(otptypeverify, user, otp, setIsModalOpen)
        }
      >
        Verify and Next
      </Button>
    </Space>
  );
};

export default ParentVerificationDropSteps;
