import React, { useState } from "react";
import { Row, Col, Typography, Form, theme } from "antd";
import { EditOutlined } from "@ant-design/icons";
import CommonModal from "../../CommonModal/CommonModal";
import PetDetails from "../../Common/Components/PetDetails";
import { profileAction } from "../../Common/State/actions";
import { useDispatch } from "react-redux";
import styles from "../Styles/finalPage.module.css";

const { Title } = Typography;

/**
 * @component FinalPetDetails
 * @description Displays and manages the pet details, allowing the user to view and edit information
 * about their pet, such as name, type, age, breed, gender, and more.
 * @param {Object} details - The pet details to display and edit.
 * @param {Object} parentBoardingDetails -pets allowed for that boarding center.
 */

const FinalPetDetails = ({ details, parentBoardingDetails }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { useToken } = theme;
  const { token } = useToken();

  /* Handles the form submission for updating or creating pet details.*/
  const handleFinish = (values) => {
    const action = details?._id
      ? profileAction.updatePetProfileEvent(values, details._id)
      : profileAction.postPetProfileEvent(values);
    dispatch(action);
  };

  const normFile = (e) =>
    Array.isArray(e) ? e : e?.fileList[0]?.originFileObj;

  return (
    <div className={styles.displayedit}>
      <Row justify="space-between" align="middle">
        <Title level={3} style={{ marginTop: 0, color: token.Primary }}>
          Pet Details
        </Title>
        <EditOutlined
          style={{ fontSize: "1.2em", color: token.Primary }}
          onClick={() => setIsModalOpen(true)}
        />
      </Row>

      {/* Modal for editing pet details */}
      <CommonModal
        width={1000}
        body={() => (
          <PetDetails
            petDetails={details}
            form={form}
            boardingCenterallowedPets={parentBoardingDetails}
            handleFinish={handleFinish}
            normFile={normFile}
          />
        )}
        handleOk={() => setIsModalOpen(false)}
        handleCancel={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
      />

      {/* Displaying pet details */}
      <Row>
        {[
          { label: "Pet Name", value: details?.name },
          { label: "Pet Type", value: details?.type },
          { label: "Pet Age", value: details?.age },
          { label: "Pet Breed", value: details?.breed },
          { label: "Gender", value: details?.gender },
          { label: "Favorite Foods", value: details?.favoriteFood },
          { label: "Pet Character", value: details?.character },
        ].map((item, index) => (
          <React.Fragment key={index}>
            <Col span={10} className={styles.col}>
              {item.label}
            </Col>
            <Col span={2} className={styles.col}>
              :
            </Col>
            <Col span={12} className={styles.col}>
              {item.value || "--"}
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </div>
  );
};

export default FinalPetDetails;
