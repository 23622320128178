// import React from "react";
// import { Popconfirm, Tag, theme, Typography } from "antd";
// import useMethods from "./useMethods";
// import VerificationStepsForCaptain from "./VerificationStepsForCaptain";
// import CommonModal from "../../CommonModal/CommonModal";
// const { Text } = Typography;

// const BookingsFortheDay = ({ booking }) => {
//   const {
//     handleStart,
//     handleVerify,
//     setIsModalOpen,
//     isModalOpen,
//     user,
//     typeofroute,
//     handleStopAtBoardingCenter,
//     handleHandOverPetToBoardHead,
//     handlePickPetFromBoardingCenter,
//     handleHandOverPetToParent,
//     watchId,
//     showMap,
//   } = useMethods();
//   const { useToken } = theme;
//   const { token } = useToken();

//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   const {
//     petPickupAndDrop_status,
//     petBoarding_status,
//   } = booking?.bookingStatus;
//   const {
//     boarding_center_to_parent_location_to_pickup_pet,
//     boarding_center_to_parent_location_drop_pet,
//   } = petPickupAndDrop_status;
//   if (boarding_center_to_parent_location_drop_pet) {
//     const {
//       handed_over_the_pet_to_the_parent,
//       parent_verification_while_releasing_pet,
//       reached_parent_location,
//       on_the_way_to_parent_location,
//     } = boarding_center_to_parent_location_drop_pet;
//     if (handed_over_the_pet_to_the_parent?.status === "accept") {
//       return <Text style={{ color: token.Green }}>Completed</Text>;
//     } else if (handed_over_the_pet_to_the_parent?.status === "decline") {
//       return <Text>Pet not handed over to parent.</Text>;
//     } else if (parent_verification_while_releasing_pet?.status === "accept") {
//       return (
//         <Popconfirm
//           onConfirm={() =>
//             handleHandOverPetToParent(
//               booking?.bookingUniqueId,
//               booking?.bookingId
//             )
//           }
//         >
//           <Tag style={{ cursor: "pointer" }}>Handover pet to parent?</Tag>
//         </Popconfirm>
//       );
//     } else if (parent_verification_while_releasing_pet?.status === "decline") {
//       return (
//         <Text> Parent verification unsuccessful while releasing pet.</Text>
//       );
//     } else if (reached_parent_location?.status == "decline") {
//       return (
//         <Text>Captain has not reached parent location to drop the pet.</Text>
//       );
//     } else if (on_the_way_to_parent_location?.status === "accept") {
//       return (
//         <Popconfirm
//           okText={"Yes"}
//           onConfirm={() =>
//             handleVerify(
//               booking?.bookingUniqueId,
//               booking?.bookingId,
//               booking,
//               "drop"
//             )
//           }
//         >
//           <Tag style={{ cursor: "pointer" }}>Reached parent location?</Tag>
//           {user.current?.bookingUniqueId === booking?.bookingUniqueId && (
//             <CommonModal
//               maskClosable={false}
//               open={isModalOpen}
//               onOk={handleCancel}
//               handleCancel={handleCancel}
//               isModalOpen={isModalOpen}
//               closable={false}
//               body={() => (
//                 <VerificationStepsForCaptain
//                   typeofroute={typeofroute}
//                   user={user}
//                   setIsModalOpen={setIsModalOpen}
//                   watchId={watchId}
//                 />
//               )}
//             />
//           )}
//         </Popconfirm>
//       );
//     } else if (on_the_way_to_parent_location?.status === "decline") {
//       return <Text>Captain has not started his ride to drop the pet.</Text>;
//     }
//   } else if (boarding_center_to_parent_location_to_pickup_pet) {
//     const {
//       accepts_ride,
//       on_the_way_to_parent_location,
//       reached_parent_location,
//       order_verification,
//       parent_verification,
//       pet_verification_by_captain,
//       pet_reached_boarding_center,
//       pet_handed_over_to_the_boarding_center,
//     } = boarding_center_to_parent_location_to_pickup_pet;
//     if (
//       petBoarding_status?.pet_picked_up_from_boarding_center?.status ===
//       "accept"
//     ) {
//       return (
//         <Tag
//           style={{ cursor: "pointer" }}
//           onClick={() =>
//             handleStart(booking?.bookingUniqueId, booking?.bookingId, "drop")
//           }
//         >
//           Start ride to drop pet.
//         </Tag>
//       );
//     } else if (
//       petBoarding_status?.pet_picked_up_from_boarding_center?.status ===
//       "decline"
//     ) {
//       return <Text>Captain did not pick up pet from boarding center.</Text>;
//     } else if (pet_handed_over_to_the_boarding_center?.status === "accept") {
//       return (
//         <Popconfirm
//           onConfirm={() =>
//             handlePickPetFromBoardingCenter(
//               booking?.bookingUniqueId,
//               booking?.bookingId
//             )
//           }
//         >
//           <Tag style={{ cursor: "pointer" }}>
//             Pickup pet from boarding center
//           </Tag>
//         </Popconfirm>
//       );
//     } else if (pet_handed_over_to_the_boarding_center?.status === "decline") {
//       return <Text>Pet not handed over to the boardhead by captain</Text>;
//     } else if (pet_reached_boarding_center?.status === "accept") {
//       return (
//         <Popconfirm
//           okText={"Yes"}
//           onConfirm={() =>
//             handleHandOverPetToBoardHead(
//               booking?.bookingUniqueId,
//               booking?.bookingId
//             )
//           }
//         >
//           <Tag style={{ cursor: "pointer" }}>Handover pet to boardhead</Tag>
//         </Popconfirm>
//       );
//     } else if (pet_reached_boarding_center?.status === "decline") {
//       return <Text>Pet did not reach boarding center</Text>;
//     } else if (pet_verification_by_captain?.status === "accept") {
//       return (
//         <>
//           <Popconfirm
//             okText={"Yes"}
//             onConfirm={() =>
//               handleStopAtBoardingCenter(
//                 booking?.bookingUniqueId,
//                 booking?.bookingId
//               )
//             }
//           >
//             <Tag style={{ cursor: "pointer" }}>Reached boarding center?</Tag>
//           </Popconfirm>
//         </>
//       );
//     } else if (pet_verification_by_captain?.status === "decline") {
//       return (
//         <Text>
//           Pet verification unsuccessfull due to{" "}
//           {pet_verification_by_captain?.reason}
//         </Text>
//       );
//     } else if (parent_verification?.status === "decline") {
//       return (
//         <Text>
//           Parent verification unsuccessfull due to {parent_verification?.reason}
//         </Text>
//       );
//     } else if (order_verification?.status === "decline") {
//       return (
//         <Text>
//           Order verification unsuccessful due to {order_verification?.reason}
//         </Text>
//       );
//     } else if (on_the_way_to_parent_location?.status === "accept") {
//       return (
//         <>
//           <Popconfirm
//             onConfirm={() =>
//               handleVerify(
//                 booking.bookingUniqueId,
//                 booking.bookingId,
//                 booking,
//                 "pickup"
//               )
//             }
//             okText={"Yes"}
//           >
//             <Tag style={{ cursor: "pointer" }}>Reached parent location?</Tag>
//           </Popconfirm>
//           {user.current?.bookingUniqueId === booking?.bookingUniqueId && (
//             <CommonModal
//               maskClosable={false}
//               open={isModalOpen}
//               onOk={handleCancel}
//               handleCancel={handleCancel}
//               isModalOpen={isModalOpen}
//               closable={false}
//               body={() => (
//                 <VerificationStepsForCaptain
//                   typeofroute={typeofroute}
//                   user={user}
//                   setIsModalOpen={setIsModalOpen}
//                   watchId={watchId}
//                 />
//               )}
//             />
//           )}
//           {showMap.current && (
//             <div style={{ width: "100%", height: "100%", zIndex: 1000 }}>
//               <iframe
//                 src={`https://embed.mappls.com/direction?places=28.5503,77.2502;28.5507,77.2689&token=${process.env.REACT_APP_API_KEY}`}
//               ></iframe>
//             </div>
//           )}
//         </>
//       );
//     } else if (accepts_ride?.status === "accept") {
//       return (
//         <>
//           <Tag
//             style={{ cursor: "pointer" }}
//             onClick={() =>
//               handleStart(booking.bookingUniqueId, booking.bookingId, "pickup")
//             }
//           >
//             Start Ride to pickup pet
//           </Tag>
//         </>
//       );
//     }
//   }
// };

// export default BookingsFortheDay;


import useMethods from "./useMethods";
import useToPickupPet from "./useToPickupPet";
import useToDropPet from "./useToDropPet";

/**
 * @description
 * The `BookingsFortheDay` component is responsible for rendering the appropriate UI 
 * based on the status of a booking related to pet pickup and drop-off. 
 * This component leverages custom hooks (`useMethods`, `useToPickupPet`, and `useToDropPet`)
 * to manage the booking flow, including handling interactions and status updates 
 * for the various stages of pet pickup and drops.
 */

const BookingsFortheDay = ({ booking }) => {
  const {
    handleStart,
    handleVerify,
    setIsModalOpen,
    isModalOpen,
    user,
    typeofroute,
    handleStopAtBoardingCenter,
    handleHandOverPetToBoardHead,
    handlePickPetFromBoardingCenter,
    handleHandOverPetToParent,
    watchId,
    showMap,
  } = useMethods();

  const handleCancel = () => setIsModalOpen(false);

  const {
    petPickupAndDrop_status,
    petBoarding_status,
  } = booking?.bookingStatus;
  const {
    boarding_center_to_parent_location_to_pickup_pet,
    boarding_center_to_parent_location_drop_pet,
  } = petPickupAndDrop_status;

  const dropPetDetails = useToDropPet({
    boarding_center_to_parent_location_drop_pet,
    booking,
    handleHandOverPetToParent,
    handleVerify,
    isModalOpen,
    setIsModalOpen,
    user,
    typeofroute,
    watchId,
    showMap,
    handleCancel,
  });

  const pickupPetDetails = useToPickupPet({
    booking,
    isModalOpen,
    boarding_center_to_parent_location_to_pickup_pet,
    handlePickPetFromBoardingCenter,
    handleHandOverPetToBoardHead,
    handleStopAtBoardingCenter,
    setIsModalOpen,
    handleVerify,
    handleCancel,
    showMap,
    watchId,
    typeofroute,
    user,
    petBoarding_status,
    handleStart
  });

  if (boarding_center_to_parent_location_drop_pet) {
    if (dropPetDetails) {
      return dropPetDetails;
    }
  } else if (boarding_center_to_parent_location_to_pickup_pet) {
    if (pickupPetDetails) {
      return pickupPetDetails;
    }
  }
  return null;
};

export default BookingsFortheDay;
