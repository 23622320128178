import { Space, Tag, theme } from "antd";
import React from "react";
import useAcceptDeclineDashboardCaptain from "./useAcceptDeclineDashboardCaptain";
import DeclineModal from "./DeclineModal";
import BookingsFortheDay from "../../Captain/Components/BookingsFortheDay";
import { useSelector } from "react-redux";

// takes the particular booking and show the status of that particular booking for a captain in his dashboard
const CaptainDashboardStatus = ({ status, booking }) => {
    const { useToken } = theme;
    const { token } = useToken();
    const { captainDetails } = useSelector((state) => state.dashboardReducer);
    const {
        showModal,
        isModalOpen,
        handleCancel,
        handleAccept,
        handleSelectChange,
        handleOk,
        declineBooking,
        setDeclineBooking,
        contextHolder
    } = useAcceptDeclineDashboardCaptain();
    
    return status === "in_process" ? (
        <>
            <Space size={"small"} direction="vertical" align="center">
                <Tag
                    style={{
                        background: token.colorPrimary,
                        color: token.White,
                        cursor: "pointer",
                    }}
                    onClick={() => handleAccept(booking)}
                >
                    Accept
                </Tag>
            </Space>
            <Space size={"small"} direction="vertical" align="center">
                <Tag
                    style={{
                        background: token.colorPrimary,
                        color: token.White,
                        cursor: "pointer",
                    }}
                    onClick={() => showModal(booking, "acceptOrDecline")}
                >
                    Decline
                </Tag>
            </Space>
            <DeclineModal
                declineBooking={declineBooking}
                handleOk={handleOk}
                isModalOpen={isModalOpen}
                setDeclineBooking={setDeclineBooking}
                handleCancel={handleCancel}
                contextHolder={contextHolder}
                handleSelectChange={handleSelectChange}
            />
        </>
    ) : status === "accept" ? (
        <Space size={"small"} direction="vertical" align="center">
            {captainDetails && <BookingsFortheDay booking={booking} />}
            {!booking.declined &&
                !booking?.bookingStatus?.petPickupAndDrop_status
                    ?.boarding_center_to_parent_location_to_pickup_pet
                    ?.on_the_way_to_parent_location && (
                    <>
                        <Tag
                            style={{
                                background: token.appbluecolor,
                                color: token.White,
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                showModal(booking, "accepted");
                            }}
                        >
                            Decline
                        </Tag>

                        <DeclineModal
                            declineBooking={declineBooking}
                            handleOk={handleOk}
                            contextHolder={contextHolder}
                            isModalOpen={isModalOpen}
                            setDeclineBooking={setDeclineBooking}
                            handleCancel={handleCancel}
                            handleSelectChange={handleSelectChange}
                        />
                    </>
                )}
        </Space>
    ) : status === "decline" ? (
        <Tag
            style={{
                background: token.Primary,
                color: token.White,
            }}
        >
            Declined
        </Tag>
    ) : status === "decline_request" ? (
        <Tag
            style={{
                background: token.appbluecolor,
                color: token.White,
            }}
        >
            Requested for decline
        </Tag>
    ) : (
        <Tag
            style={{
                background: token.appbluecolor,
                color: token.White,
            }}
        >
            Cancelled by Admin
        </Tag>
    );
};

export default CaptainDashboardStatus;
