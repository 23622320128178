export function formatDateToUTC(dateString, timeString, key) {
  // Create a Date object with the provided date and time in IST.
  let date;
  if (key === "startDate") {
    date = new Date(
      dateString + "T" + timeString.split("-")[0].trim() + "+05:30"
    ); // IST offset is +05:30
  } else {
    date = new Date(
      dateString + "T" + timeString.split("-")[1].trim() + "+05:30"
    );
    // console.log(date,"date")
  }

  // Convert the date to UTC and format it as desired.
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month (0-indexed)
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(date.getUTCMilliseconds()).padStart(3, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
}
