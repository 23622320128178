import { Button, Space, Steps, Typography } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner/Spinner";
import { CheckCircleOutlined } from "@ant-design/icons";
import useVerificationMethods from "./useVerificationMethods";
import OrderVerificationPickupSteps from "./OrderVerificationPickupSteps";
import PetVerificationPickupSteps from "./PetVerificationPickupSteps";
import ParentVerificationDropSteps from "./ParentVerificationDropSteps";
import useVerificationStepsForCaptainEffects from "./useVerificationStepsForCaptainEffects";
const { Text } = Typography;

/**
 * `VerificationStepsForCaptain` Component
 *
 * @description - This component manages the verification process for captains. It displays a series of steps
 * depending on the route type (pickup or drop-off) and the verification status of orders, parents,
 * and pets. The component handles sending and verifying OTPs, and allows captains to continue
 * or decline verification processes based on the status of the booking.
 *
 * Props:
 * - `typeofroute`: Object containing the current route type (pickup or drop-off).
 * - `user`: The user object containing booking status and details.
 * - `setIsModalOpen`: control the modal visibility.
 * - `watchId`: ID for tracking purposes.
 */

const VerificationStepsForCaptain = ({
  typeofroute,
  user,
  setIsModalOpen,
  watchId,
}) => {
  const [current, setCurrent] = useState(0);
  const [otp, setOtp] = useState("");
  const {
    isLoadingRequestOtp,
    isLoadingVerifyOtp,
    isLoadingCaptainDetails,
  } = useSelector((str) => str.loadingReducer);
  const { contextHolder } = useVerificationMethods();

  const contentStyle = {
    marginTop: 16,
    width: "100%",
    textAlign: "center",
  };

  useVerificationStepsForCaptainEffects({ user, typeofroute, setCurrent });

  const steps = [
    {
      title: "Order Verification",
      content: (
        <OrderVerificationPickupSteps
          user={user}
          setIsModalOpen={setIsModalOpen}
          watchId={watchId}
          otp={otp}
          setOtp={setOtp}
          contentStyle={contentStyle}
        />
      ),
    },
    {
      title: "Parent Verification",
      content: (
        <Space size={"middle"} direction="vertical" style={contentStyle}>
          <Button size="large" type="link" icon={<CheckCircleOutlined />}>
            Verified
          </Button>
          <Button size="small" type="primary" onClick={() => setCurrent(2)}>
            Next
          </Button>
        </Space>
      ),
    },
    {
      title: "Pet Verification",
      content: (
        <PetVerificationPickupSteps
          user={user}
          setIsModalOpen={setIsModalOpen}
          watchId={watchId}
          contentStyle={contentStyle}
        />
      ),
    },
  ];

  const dropSteps = [
    {
      title: "Parent Verification before handover the pet",
      content: (
        <ParentVerificationDropSteps
          user={user}
          otp={otp}
          setIsModalOpen={setIsModalOpen}
          contentStyle={contentStyle}
          setOtp={setOtp}
          otptypesend={"send_otp_parent_cap_drop"}
          otptypeverify={"parent_otp_verification_cap_drop"}
        />
      ),
    },
  ];

  if (isLoadingRequestOtp || isLoadingVerifyOtp || isLoadingCaptainDetails) {
    return <Spinner />;
  }

  return (
    <>
      <Steps
        labelPlacement="vertical"
        current={current}
        size="small"
        items={typeofroute.current === "pickup" ? steps : []}
      />
      {contextHolder}
      <>
        <Text style={{ display: "block" }}>
          {typeofroute.current === "pickup"
            ? steps[current].content
            : dropSteps[0].content}
        </Text>
      </>
    </>
  );
};

export default VerificationStepsForCaptain;
