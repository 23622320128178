import React, { useState } from "react";
import { Col, Row, Select, Input, Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;

const IdentificationInfo = ({
  Form,
  form,
  idType,
  setIdType,
  idPattern,
  setIdPattern,
}) => {
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList[0]?.originFileObj;
  };

  // Define custom validation rules for identification details based on the selected identification type
  const validateIdDetails = (_, value) => {
    const pattern =
      idPattern === "aadhaar"
        ? /^\d{12}$/
        : idPattern === "pan"
        ? /^[A-Z]{5}[0-9]{4}[A-Z]$/
        : /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
    if (!value || pattern.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Invalid identification Number");
  };

  const handleIdTypeChange = (value) => {
    setIdType(value);
    switch (value) {
      case "aadhaar":
        setIdPattern("aadhaar");
        break;
      case "pan":
        setIdPattern("pan");
        break;
      case "dl":
        setIdPattern("dl");
        break;
      default:
        setIdPattern("");
    }
    form.setFieldsValue({ indentificationValue: "" }); // Reset identification value on type change
  };

  return (
    <Row gutter={[16, 0]} justify={"center"} style={{ alignItems: "center" }}>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Form.Item
          label="Select Identification Type"
          name="identification"
          rules={[
            { required: true, message: "Please select identification type" },
          ]}
        >
          <Select onChange={handleIdTypeChange}>
            <Option value="aadhaar">Adhar Card</Option>
            <Option value="pan">Pan Card</Option>
            <Option value="dl">Driving License</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Form.Item
          name="indentificationValue"
          label="Enter Identification Number"
          rules={[
            { required: true, message: "Please enter identification Number" },
            { validator: validateIdDetails },
          ]}
        >
          <Input placeholder="Identification Details" disabled={!idType} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default IdentificationInfo;
