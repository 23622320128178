import { startLoadingAllQuestions, stopLoadingAllQuestions } from "../../state/actions";
import { crudPlansApi } from "../utils/api";
import { crudPlanConstants } from "./action-types";


const getAllPendingPlansError = (err) => {
  return {
    type: crudPlanConstants.GET_ALL_PENDING_PLANS_ERROR,
    error: err,
  };
};

const getAllPendingPlansSuccess = (data) => {
  return {
    type: crudPlanConstants.GET_ALL_PENDING_PLANS_SUCCESS,
    data: data,
  };
};

const getAllPendingPlansEvent = () => {
  return (dispatch) => {
    dispatch(startLoadingAllQuestions());
    crudPlansApi
      .getPendingPlans()
      .then((response) => {
        dispatch(getAllPendingPlansSuccess(response?.data?.info));
        dispatch(stopLoadingAllQuestions());
      })
      .catch((err) => {
        dispatch(getAllPendingPlansError(err));
        dispatch(stopLoadingAllQuestions());
      });
  };
};


// const approveCancellationRequestError = (err) => {
//   return {
//     type: cancellationRequestsConstants.APPROVE_CANCELLATION_ERROR,
//     error: err,
//   };
// };

// const approveCancellationRequestSuccess = (data) => {
//   return {
//     type: cancellationRequestsConstants.APPROVE_CANCELLATION_SUCCESS,
//     data: data,
//   };
// };

// const approveCancellationRequestEvent = (bookingId) => {
//   return (dispatch) => {
//     dispatch(startLoading());
//     cancelRequestsApi
//       .approveCancelRequest(bookingId)
//       .then((response) => {
//         dispatch(approveCancellationRequestSuccess(response?.data?.info));
//         dispatch(stopLoading());
//       })
//       .catch((err) => {
//         dispatch(approveCancellationRequestError(err));
//         dispatch(stopLoading());
//       });
//   };
// };

export const crudPlansAction = {
  getAllPendingPlansEvent
};
