import { Typography } from "antd";
import React from "react";
import { HandoverPetToParent, ReachedParentLocation } from "./CommonFunctions";
const { Text } = Typography;

/**
 * Custom hook that handles the logic for displaying and managing the drop-off process of a pet 
 * from the boarding center to the parent's location. It determines what UI to render 
 * based on the current status of the drop-off process.
 * 
 * @param {Object} params - The parameters needed for the hook to function.
 * @param {Object} params.boarding_center_to_parent_location_drop_pet - Object containing the status of the drop-off process.
 * @param {Object} params.booking - The booking details related to the drop-off.
 * @param {Function} params.handleHandOverPetToParent - Function to handle handing over the pet to the parent.
 * @param {Function} params.handleVerify - Function to handle verification processes.
 * @param {Boolean} params.isModalOpen - Boolean to control the visibility of the modal.
 * @param {Function} params.setIsModalOpen - Function to toggle the modal visibility.
 * @param {Object} params.user - Object containing the details of the current user.
 * @param {String} params.typeofroute - String indicating the type of route (e.g., pickup, drop-off).
 * @param {String} params.watchId - Identifier for the geolocation watch process.
 * @param {Function} params.showMap - Function to display the map with the current route.
 * @param {Function} params.handleCancel - Function to handle canceling actions.
 * 
 * @returns {JSX.Element | null} The component or null, depending on the current status of the drop-off process.
 */

const useToDropPet = ({
  boarding_center_to_parent_location_drop_pet,
  booking,
  handleHandOverPetToParent,
  handleVerify,
  isModalOpen,
  setIsModalOpen,
  user,
  typeofroute,
  watchId,
  showMap,
  handleCancel,
}) => {
  if (boarding_center_to_parent_location_drop_pet) {
    const {
      handed_over_the_pet_to_the_parent,
      parent_verification_while_releasing_pet,
      reached_parent_location,
      on_the_way_to_parent_location,
    } = boarding_center_to_parent_location_drop_pet;

    if (handed_over_the_pet_to_the_parent?.status === "accept")
      return (
        <Text style={{ color: "green" }}>
          Completed
        </Text>
      );
    if (handed_over_the_pet_to_the_parent?.status === "decline")
      return <Text>Pet not handed over to parent.</Text>;
    if (parent_verification_while_releasing_pet?.status === "accept")
      return (
        <HandoverPetToParent
          booking={booking}
          handleHandOverPetToParent={handleHandOverPetToParent}
        />
      );
    if (parent_verification_while_releasing_pet?.status === "decline")
      return <Text> Parent verification unsuccessful.</Text>;
    if (reached_parent_location?.status === "decline")
      return <Text>Captain has not reached parent location.</Text>;
    if (on_the_way_to_parent_location?.status === "accept")
      return (
        <ReachedParentLocation
          booking={booking}
          handleVerify={handleVerify}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          user={user}
          typeofroute={typeofroute}
          watchId={watchId}
          showMap={showMap}
          handleCancel={handleCancel}
          type={"drop"}
        />
      );
    if (on_the_way_to_parent_location?.status === "decline") {
      return <Text>Captain has not started his ride to drop the pet.</Text>;
    }
    return null;
  }
};

export default useToDropPet;
