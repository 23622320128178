export const supportConstants = {
  CREATE_TICKET_SUCCESS: "CREATE_TICKET_SUCCESS",
  CREATE_TICKET_ERROR: "CREATE_TICKET_ERROR",

  GET_ALL_TICKETS_SUCCESS: "GET_ALL_TICKETS_SUCCESS",
  GET_ALL_TICKETS_ERROR: "GET_ALL_TICKETS_ERROR",

  UPDATE_TICKET_SUCCESS: "UPDATE_TICKET_SUCCESS",
  UPDATE_TICKET_ERROR: "UPDATE_TICKET_ERROR",

  GET_ALL_BOOKINGS_OF_USER_SUCCESS: "GET_ALL_BOOKINGS_OF_USER_SUCCESS",
  GET_ALL_BOOKINGS_OF_USER_ERROR: "GET_ALL_BOOKINGS_OF_USER_ERROR",
  
  CLEAR_SUPPORT_DATA:'CLEAR_SUPPORT_DATA'
};
