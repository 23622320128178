import { Badge, Button, Card, theme, Typography } from "antd";
import React from "react";
import Spinner from "../../Spinner/Spinner";
import { ArrowRightOutlined, CaretRightFilled, DeleteOutlined, EditOutlined, EllipsisOutlined, PlusCircleOutlined, SettingOutlined, StarFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";
const { Title, Text } = Typography;

const SearchComponent = ({ setInfo, handleCard }) => {
  const { alladdress } = useSelector((str) => str.addressReducer);
  const { isLoadingAddresses } = useSelector((str) => str.loadingReducer);
  const { userDetails } = useSelector(str => str.navbarReducer);
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <>
      <Button
        style={{ width: "100%" }}
        icon={<PlusCircleOutlined />}
        size="small"
        onClick={() => setInfo(prev => ({ ...prev, showForm: true }))}
      >
        Add New Location
      </Button>
      <Title
        style={{ fontSize: "20px", textAlign: "center", marginTop: "30px" }}
      >
        Available Address
      </Title>
      {isLoadingAddresses ? (
        <Spinner />
      ) : (
        alladdress?.map((address) => {
          const currentAddress = userDetails?.address?.find(e => e.currentLocation === true);
          return <div
            key={uuidV4()}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Badge.Ribbon placement="start" color={token.Secondary} style={{ display: address?.addressUniqueId === currentAddress?.addressId?.addressUniqueId ? "block" : "none" }} text={<><StarFilled style={{ color: "gold" }} /></>}>
              <Card
                hoverable
                actions={[<Button type="link" icon={<CaretRightFilled />} onClick={() => handleCard(address)} iconPosition="end" size="small">Continue</Button>]}
                style={{ marginTop: "10px", width: 400, textAlign: "center" }}
              >
                {address?.fullAddress}
              </Card>
            </Badge.Ribbon>
          </div>
        })
      )}
    </>
  );
};

export default SearchComponent;
