import React, { useEffect } from "react";
import { Button, Col, Row, Upload, Card, Typography, Form, theme } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import PetInputFields from "./PetInputFields";
import { useDispatch, useSelector } from "react-redux";
import { profileAction } from "../State/actions";
/**
 * @description
 * The `PetDetails` component is a form for capturing and editing pet details. The form is prefilled
 * with existing pet details if available, and includes validation for required fields.
 */
const PetDetails = ({
  normFile,
  handleFinish,
  petDetails,
  boardingCenterallowedPets,
}) => {
  const { useToken } = theme;
  const { token } = useToken();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { isLoadingPostUpdatePetDetails } = useSelector(
    (str) => str.additionalloadingReducer
  );

  useEffect(() => {
    if (petDetails && Object.keys(petDetails).length > 0) {
      form.setFieldsValue(petDetails?.[0] || petDetails);
    }
  }, [petDetails]);

  useEffect(() => {
    return () => {
      dispatch(profileAction.cleardetailsProfile());
    };
  }, []);

  return (
    <>
      <Card hoverable>
        <Typography.Title
          level={2}
          style={{ marginTop: 0, color: token.colorPrimary }}
        >
          Pet Details
        </Typography.Title>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Row gutter={[16, 0]} style={{ marginTop: "30px" }}>
            {/* inputs like name, sex, age, fav foods,type,breed */}
            <PetInputFields
              boardingCenterallowedPets={boardingCenterallowedPets}
              Form={Form}
              form={form}
            />
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name={"certificate"}
                valuePropName="file"
                getValueFromEvent={normFile}
                label="Upload Vaccine Certificate"
              >
                <Upload maxCount={1} beforeUpload={() => false}>
                  <Button icon={<UploadOutlined />}>Click to Upload...</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "end" }}>
              <Button
                type="primary"
                loading={isLoadingPostUpdatePetDetails}
                htmlType="submit"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default PetDetails;
