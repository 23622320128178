import React from "react";
import TableComponent from "../../Common/Components/TableComponent";
import centervehicle from '../../Assets/BoardHeadDashboard/centervehicle.png';
import styles from '../Styles/dashboard.module.css';
import { Image, Table, Card } from 'antd';
const CenterVehicles = () => {
    const dataSource = [
        {
            key: "1",
            vmodel: "TATA ACE",
            fcdate: "02.03.2037",
            rgno: "MH-12 F 3035",
            cages: "01",
            status: "active",
            inhand: "Deepak",
        },
        {
            key: "2",
            vmodel: "TATA ACE",
            fcdate: "02.03.2037",
            rgno: "MH-12 F 3035",
            cages: "02",
            status: "active",
            inhand: "e",
        },
        {
            key: "3",
            vmodel: "TATA INTRA",
            fcdate: "02.03.2037",
            rgno: "MH-12 F 3035",
            cages: "04",
            status: "active",
            inhand: "e",
        },
        {
            key: "4",
            vmodel: "TATA ACE",
            fcdate: "02.03.2037",
            rgno: "MH-12 F 3035",
            cages: "05",
            status: "active",
            inhand: "e",
        },
    ];

    const columns = [
        {
            title: "Vehicle Model",
            dataIndex: "vmodel",
            key: "1",
        },
        {
            title: "FC date",
            dataIndex: "fcdate",
            key: "2",
        },
        {
            title: "Registration Number",
            dataIndex: "rgno",
            key: "3",
        },
        {
            title: "No. of cages",
            dataIndex: "cages",
            key: "4",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "5",
        },
        {
            title: "In hand",
            dataIndex: "inhand",
            key: "6",
        },
    ];

    return (
        <Card hoverable className={styles.entirePage}>
            <Table dataSource={dataSource} columns={columns} pagination={false} />
            <div style={{ position: "absolute", top: 10, left: 0 }}>
                <Image src={centervehicle} preview={false} width={150} />
            </div>
        </Card>
    );
};

export default CenterVehicles;
