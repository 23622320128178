import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { boardCenterAction } from "../State/actions";
import AddBoardCenterFormComponent from "./AddBoardCenterFormComponent";

/**
 * `AddBoardCenterForm` component for managing the addition and update of a boarding center.
 * @description
 * The `AddBoardCenterForm` component provides a form interface for adding or updating a boarding center. It handles modal display, address selection, and form submission. 
 * 
 * @param {Object} props - The props for the component.
 * @param {Object} props.info - Additional information or details for the boarding center.
 * @param {Array} props.defaultValue - Default values for the form fields.
 * @param {Function} props.handleServicesChange - Callback function for handling changes in service options.
 * @param {Object} props.form - The Ant Design `Form` instance used for managing form data.
 * @param {Object} props.maps - State object containing location and address details.
 * @param {Function} props.setMaps - Function to update the `maps` state.
 * @param {Function} props.setSelectedValues - Function to update selected values for breeds.
 * @param {Function} props.updateSelectedPreview - Function to update the preview of selected items.
 
 * @method showModal - Opens the modal for location selection.
 * @method handleCancel - Closes the modal for location selection.
 * @method handleAddressSelect - Updates the `maps` state and form field with the selected address.
 * @method handleLocationFinish - Dispatches an update action with the new location data.
 */

const AddBoardCenterForm = ({
  info,
  defaultValue,
  handleServicesChange,
  form,
  maps,
  setMaps,
  setSelectedValues,
  updateSelectedPreview,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  const handleAddressSelect = (item) => {
    if (item?.placeAddress) {
      let splitt_place_address = item?.placeAddress?.split(", ");
      if (item?.type) {
        setMaps((prev) => ({
          ...prev,
          location: item?.placeAddress,
          fullLocation: {
            pincode: splitt_place_address[splitt_place_address.length - 1],
            eLoc: item?.eLoc,
            state: splitt_place_address[splitt_place_address.length - 2],
            city: splitt_place_address[splitt_place_address.length - 3],
            district: splitt_place_address?.[splitt_place_address?.length - 4],
          },
        }));
      } else if (item.type === "" || !item?.type) {
        setMaps((prev) => ({
          ...prev,
          location: item?.placeAddress,
          fullLocation: {
            pincode: item?.placeAddress.match(/\b\d{6}\b/)[0],
            eLoc: item?.eLoc,
          },
        }));
      }
      form.setFieldValue("address", item?.placeAddress);
    } else {
      setMaps((prev) => ({
        ...prev,
        location: item?.formatted_address || item?.data?.formatted_address,
        fullLocation: item?.data,
      }));
      form.setFieldValue(
        "address",
        item?.formatted_address || item?.data?.formatted_address
      );
    }
  };

  const handleLocationFinish = (values) => {
    const data = {
      newLocation: {
        locationName: values.locationName,
        pincode: values.pincode,
      },
    };
    dispatch(boardCenterAction.boardCenterUpdateEvent(id, data));
  };

  return (
    <AddBoardCenterFormComponent
      showModal={showModal}
      handleCancel={handleCancel}
      isModalOpen={isModalOpen}
      handleLocationFinish={handleLocationFinish}
      maps={maps}
      setMaps={setMaps}
      info={info}
      defaultValue={defaultValue}
      handleServicesChange={handleServicesChange}
      handleAddressSelect={handleAddressSelect}
      id={id}
      updateSelectedPreview={updateSelectedPreview}
      setSelectedValues={setSelectedValues}
      form={form}
    />
  );
};

export default AddBoardCenterForm;
