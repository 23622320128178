import React, { useEffect, useState } from "react";
import { Layout, Menu, Space, theme } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import OptionsComponent from "../../Common/Components/MenuData";
import { useRoute } from "../../context/RouteContext";

const { Sider } = Layout;
/**
 * Sidebar component renders a collapsible sidebar with navigation menu items.
 * The visibility and collapse state of the sidebar are responsive and based on the current route.
 * 
 * @returns {JSX.Element} The rendered Sidebar component.
 *
 * @description
 * - Toggles visibility based on the current route (e.g., hides on "/addAddress").
 * - Sidebar visibility adjusts based on screen size using `react-responsive`'s `useMediaQuery`.
 */

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(true);
  const istab = useMediaQuery({ maxWidth: 768 });
  const [displaySidebar, setDisplaySidebar] = useState(true);
  const location = useLocation();
  const { selectedKey, setSelectedKey } = useRoute()

  const { useToken } = theme;
  const { token } = useToken();

  // useEffect(() => {
  //   if (location.pathname === "/addAddress") {
  //     setDisplaySidebar(false);
  //   } else {
  //     setDisplaySidebar(true);
  //   }
  // }, [location]);

  const navigate = useNavigate();
  const onClick = (e) => {
    console.log(e, 'eee');
    setSelectedKey(e?.key);
    localStorage.setItem("newBookAppointment", true);
    localStorage.removeItem("bookingID");
    navigate(`/${e?.item?.props?.path}`);
  };
  return (
    <Space
      style={{
        display: istab ? "none" : "",
        paddingLeft: "15px",
        height: "max-content",
        position: "sticky",
        top: "100px",
        zIndex: 100
      }}
    >
      <Sider
        trigger={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        theme="light"
        style={{ boxShadow: "1px 1px 5px grey" }}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Menu
          onSelect={onClick}
          selectedKeys={[selectedKey]}
          inlineIndent={10}
          style={{ background: token.Secondary, height: "78vh" }}
          mode="inline"
          items={OptionsComponent()}
        />
      </Sider>
    </Space>
  );
};

export default Sidebar;
