import { Button, Popconfirm, theme } from "antd";
import React from "react";
import styles from "../Styles/subscriptionDetails.module.css";
import { QuestionCircleOutlined, RightOutlined } from "@ant-design/icons";
import useWarnings from "../../Common/Components/useWarnings";
import { settingsApi } from "../utils/api";
import { navbarAction } from "../../Navbar/State/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

/**
 * AdditionalFeatures component to handle subscription actions such as pause, resume, or cancel.
 *
 * @component
 * @param {Object} props - React props.
 * @param {Function} props.handlePause - Function to handle pausing or resuming the subscription.
 * @param {Object} props.subscription - The subscription details.
 * @param {string} props.subscription.id - The ID of the subscription.
 * @param {string} props.subscription.status - The current status of the subscription ("active", "paused", etc.).
 * @param {Function} props.setSubscription - Function to update the subscription state.
 * @returns {JSX.Element} The rendered AdditionalFeatures component.
 */
const AdditionalFeatures = ({ handlePause, subscription, setSubscription }) => {
    const { success, error, contextHolder } = useWarnings();
    const { useToken } = theme;
    const { token } = useToken();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCancel = async () => {
        try {
            const responseFromCancel = await settingsApi.cancelSubscription(
                subscription?.id
            );
            success("Cancelled subscription");
            setSubscription(responseFromCancel?.data?.info);
            dispatch(navbarAction.getUserDetails());
        } catch (err) {
            error(err?.response?.data?.message);
        }
    };

    return (
        <>
            {contextHolder}
            <Button
                className={styles.cancelSub}
                icon={<RightOutlined />}
                iconPosition="end"
                size="large"
                onClick={() => navigate(`/billingActivity/${subscription.id}`)}
            >
                View Payment history
            </Button>
            <Popconfirm
                title={`Are you sure to ${subscription?.status === "active"
                    ? "pause"
                    : subscription?.status === "paused"
                        ? "resume"
                        : ""
                    } subscription?`}
                okText={"Yes"}
                onConfirm={handlePause}
            >
                <Button
                    className={styles.cancelSub}
                    icon={<RightOutlined />}
                    iconPosition="end"
                    size="large"
                >
                    {subscription?.status === "active"
                        ? "Pause Subscription"
                        // : subscription?.status === "paused" 
                        : "Resume Subscription"}
                </Button>
            </Popconfirm>
            <Popconfirm
                title="Are you sure to cancel subscription immediately?"
                description={"Once cancelled, you cannot renew or reactivate it."}
                okText={"Yes"}
                onConfirm={handleCancel}
                icon={<QuestionCircleOutlined />}
            >
                <Button
                    className={styles.cancelSub}
                    style={{ color: token.red }}
                    icon={<RightOutlined />}
                    iconPosition="end"
                    size="large"
                >
                    Cancel Subscription
                </Button>
            </Popconfirm>
        </>
    );
};

export default AdditionalFeatures;
