import axios from "axios";


const apiURL = process.env.REACT_APP_BACKEND_URL;

// update board center
const updateBoardCenter = (id, data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.put(`${apiURL}/updateBoardCenter/${id}`, data, config);
}

// get all board centers
const getBoardCenters = () => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/getAllBoardingCenters`, config);
}

// add a board centers
const addBoardCenter = (data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.post(`${apiURL}/addBoardCenter`, data, config);
}

//get board center based on id
const getBoardCenterById = (id) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/boardingCenter/${id}`, config);
}


export const boardCenterApi = {
    updateBoardCenter,
    getBoardCenters,
    addBoardCenter,
    getBoardCenterById
};
