// NavbarOnlyLayout.js
import React, { useState } from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import AddressDrawer from "./AddressDrawer";
import { Navbar } from "../../Navbar";
import { useSelector } from "react-redux";

const { Content } = Layout;

const NavbarOnlyLayout = () => {
  const [finalAddress, setFinalAddress] = useState("");
  const { userDetails } = useSelector((str) => str.navbarReducer);
  return (
    <Layout>
      <Navbar
        displayName={userDetails?.firstName}
        finalAddress={finalAddress}
      />
      <Layout>
        <Content style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Outlet />
        </Content>
      </Layout>
      {/* <AddressDrawer setFinalAddress={setFinalAddress} /> */}
    </Layout>
  );
};

export default NavbarOnlyLayout;
