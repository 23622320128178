import { staticOptions } from "../../Common/Components/allowedPets";

export const handleChange = (
  value,
  info,
  updateSelectedPreview,
  setSelectedValues
) => {
  let expandedValues = [];
  value.forEach((path) => {
    if (path.length === 1) {
      // Expand category
      const category = path[0];
      const categoryOptions = staticOptions.find(
        (opt) => opt.value === category
      );
      if (categoryOptions && categoryOptions.children) {
        categoryOptions.children.forEach((petType) => {
          if (petType.children) {
            petType.children.forEach((breed) => {
              expandedValues.push([category, petType.value, breed.value]);
            });
          } else {
            expandedValues.push([category, petType.value]);
          }
        });
      }
    } else if (path.length === 2) {
      // Expand pet type
      const [category, petType] = path;
      const categoryOptions = staticOptions.find(
        (opt) => opt.value === category
      );
      if (categoryOptions && categoryOptions.children) {
        const petTypeOptions = categoryOptions.children.find(
          (pt) => pt.value === petType
        );
        if (petTypeOptions && petTypeOptions.children) {
          petTypeOptions.children.forEach((breed) => {
            expandedValues.push([category, petType, breed.value]);
          });
        } else {
          expandedValues.push([category, petType]);
        }
      }
    } else {
      expandedValues.push(path);
    }
  });

  const selectedFlatValues = expandedValues.map((path) => path.join("/"));
  const initialFlatValues = info.initialSelectedValues.map((path) =>
    path.join("/")
  );

  const addBreeds = [];
  const removeBreeds = [];

  // Determine added breeds
  selectedFlatValues.forEach((currentValue) => {
    if (!initialFlatValues.includes(currentValue)) {
      const [category, petType, breed] = currentValue.split("/");
      let categoryObj = addBreeds.find((item) => item.category === category);
      if (!categoryObj) {
        categoryObj = { category, petType: [] };
        addBreeds.push(categoryObj);
      }
      let petTypeObj = categoryObj.petType.find(
        (item) => item.name === petType
      );
      if (!petTypeObj) {
        petTypeObj = { name: petType, addBreeds: [] };
        categoryObj.petType.push(petTypeObj);
      }
      petTypeObj.addBreeds.push(breed);
    }
  });

  // Determine removed breeds
  initialFlatValues.forEach((initialValue) => {
    if (!selectedFlatValues.includes(initialValue)) {
      const [category, petType, breed] = initialValue.split("/");
      let categoryObj = removeBreeds.find((item) => item.category === category);
      if (!categoryObj) {
        categoryObj = { category, petType: [] };
        removeBreeds.push(categoryObj);
      }
      let petTypeObj = categoryObj.petType.find(
        (item) => item.name === petType
      );
      if (!petTypeObj) {
        petTypeObj = { name: petType, removeBreeds: [] };
        categoryObj.petType.push(petTypeObj);
      }
      petTypeObj.removeBreeds.push(breed);
    }
  });
  setSelectedValues({ addBreeds: addBreeds, removeBreeds: removeBreeds });

  // Update selected preview based on current selections
  updateSelectedPreview(expandedValues);
};
