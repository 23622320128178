import { Collapse, Drawer, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner/Spinner";
import { CaretRightOutlined } from "@ant-design/icons";

/**
 * A drawer component that displays a list of help questions with their descriptions.
 * @param {Object} props - The component props.
 * @param {Function} props.onClose - Callback function to handle closing the drawer.
 * @param {boolean} props.open - Boolean flag to control whether the drawer is open or not.
 * 
 * @returns {JSX.Element} A drawer component containing a list of questions. Displays a spinner if data is loading, or "No Data" if there are no questions.
 */


const HelpDrawer = ({ onClose, open }) => {
  const { isLoadingQuestionById } = useSelector((str) => str.loadingReducer);
  const { allQuestionsForscreen } = useSelector((str) => str.helpReducer);

  if (!open) {
    return;
  }

  // if (isLoadingQuestionById) {
  //   return <Spinner />;
  // }

  const items = allQuestionsForscreen && allQuestionsForscreen?.map((e) => {
    return {
      key: e.questionUniqueId,
      label: (
        <Typography.Text style={{ fontWeight: 500 }}>{e.title}</Typography.Text>
      ),
      children: <p dangerouslySetInnerHTML={{ __html: e.description }} />,
    };
  });

  return (
    <Drawer
      size="large"
      onClose={onClose}
      open={open}
      placement="left"
      destroyOnClose={true}
    >
      {isLoadingQuestionById ? (
        <Spinner />
      ) : allQuestionsForscreen && allQuestionsForscreen?.length > 0 ? (
        <Collapse
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          bordered={false}
          size="small"
          items={items}
        />
      ) : (
        "No Data"
      )}
    </Drawer>
  );
};

export default HelpDrawer;
