import { theme } from "antd";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    const { useToken } = theme;
    const { token } = useToken();
    return (
        <div
            className={className}
            style={{
                ...style,
                background: token.Secondary,
                borderRadius: "50%",
                right: 0,
            }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    const { useToken } = theme;
    const { token } = useToken();
    return (
        <div
            className={className}
            style={{
                ...style,
                background: token.Secondary,
                borderRadius: "50%",
                left: 0,
                zIndex: 1,
            }}
            onClick={onClick}
        />
    );
}

export const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3.3,
    centerMode: false,
    autoplay: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    useCSS: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
};
