import { dashboardAction } from "../../Dashboard/State/actions";
import { startLoading, startLoadingRequestOtp, startLoadingVerifyOtp, stopLoading, stopLoadingRequestOtp, stopLoadingVerifyOtp } from "../../state/actions";
import { captainApi } from "../utils/api";
import { captainConstants } from "./action-types";


const cancelCaptainBookingError = (err) => {
    return {
        type: captainConstants.CANCELCAPTAINBOOKING_ERROR,
        error: err,
    };
};

const cancelCaptainBookingSuccess = (data) => {
    return {
        type: captainConstants.CANCELCAPTAINBOOKING_SUCCESS,
        data: data,
    };
};

const cancelCaptainBookingEvent = (captainId, bookingId, data) => {
    return (dispatch) => {
        dispatch(startLoading());
        captainApi.cancelCaptainBooking(captainId, bookingId, data)
            .then((response) => {
                dispatch(cancelCaptainBookingSuccess(response?.data?.info));
                dispatch(dashboardAction.getCaptainsBookings(captainId))
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(cancelCaptainBookingError(err));
                dispatch(stopLoading());
            });
    };
};

const sendOtpforVerificationOrderParentError = (err) => {
    return {
        type: captainConstants.SEND_OTP_FOR_VERIFICATION_ORDER_PARENT_ERROR,
        error: err,
    };
};

const sendOtpforVerificationOrderParentSuccess = (data) => {
    return {
        type: captainConstants.SEND_OTP_FOR_VERIFICATION_ORDER_PARENT_SUCCESS,
        data: data,
    };
};

const sendOtpforVerificationOrderParent = (userId, type, captainId) => {
    return (dispatch) => {
        dispatch(startLoadingRequestOtp());
        captainApi.sendOtpForVerification(userId, type, captainId)
            .then(response => {
                dispatch(sendOtpforVerificationOrderParentSuccess(response.data.info));
                dispatch(stopLoadingRequestOtp());
            })
            .catch((err) => {
                dispatch(sendOtpforVerificationOrderParentError(err));
                dispatch(stopLoadingRequestOtp());
            });
    }
}

const verifyOtpforVerificationOrderParentError = (err) => {
    return {
        type: captainConstants.VERIFY_OTP_FOR_VERIFICATION_ORDER_PARENT_ERROR,
        error: err,
    };
};

const verifyOtpforVerificationOrderParentSuccess = (data) => {
    return {
        type: captainConstants.VERIFY_OTP_FOR_VERIFICATION_ORDER_PARENT_SUCCESS,
        data: data,
    };
};

const verifyOtpforVerificationOrderParent = (userId, bookingId, userOtp, type) => {
    return (dispatch) => {
        dispatch(startLoadingVerifyOtp());
        captainApi.verifyOtpVerification(userId, bookingId, userOtp, type)
            .then(response => {
                dispatch(verifyOtpforVerificationOrderParentSuccess(response.data.info));
                dispatch(stopLoadingVerifyOtp());
            })
            .catch((err) => {
                dispatch(verifyOtpforVerificationOrderParentError(err));
                dispatch(stopLoadingVerifyOtp());
            });
    }
}

const clearOtp = () => {
    return {
        type: captainConstants.CLEAR_OTPDATA,
    };
}

export const captainAction = {
    cancelCaptainBookingEvent,
    sendOtpforVerificationOrderParent,
    verifyOtpforVerificationOrderParent,
    clearOtp
};
