import { profileConstants } from "./action-types";

const initialState = {
    newAddress: null,
    alladdress: null,
    addressError: null,
    alladdressError: null,
    boardingcenter:null,
    boardingcenterError: null,
    updatedboardingcenter:null,
    updatedboardingcenterError: null,
};

const addressReducer = (state = initialState, action) => {
    switch (action.type) {
        case profileConstants.ADD_ADDRESS_SUCCESS:
            return {
                ...state,
                newAddress: action.data,
                addressError: false,
            };
        case profileConstants.ADD_ADDRESS_FAILURE:
            return {
                ...state,
                newAddress: null,
                addressError: action.valuesError,
            };
        case profileConstants.ALL_ADDRESS_SUCCESS:
            return {
                ...state,
                alladdress: action.data,
                alladdressError: false,
            };
        case profileConstants.ALL_ADDRESS_FAILURE:
            return {
                ...state,
                alladdress: false,
                alladdressError: action.valuesError,
            };
        case profileConstants.UPDATE_ADDRESS_SUCCESS:
            return {
                ...state,
                newAddress: action.data,
                addressError: false,
            };
        case profileConstants.UPDATE_ADDRESS_FAILURE:
            return {
                ...state,
                newAddress: null,
                addressError: action.valuesError,
            };
        case profileConstants.GET_BOARDINGCENTER_BY_ID_SUCCESS:
            return {
                ...state,
                boardingcenter: action.data,
                boardingcenterError: false,
            };
        case profileConstants.GET_BOARDINGCENTER_BY_ID_FAILURE:
            return {
                ...state,
                boardingcenter: null,
                boardingcenterError: action.valuesError,
            };
            case profileConstants.UPDATE_BOARDING_CENTER_SUCCESS:
            return {
                ...state,
                updatedboardingcenter: action.data,
                updatedboardingcenterError: false,
            };
        case profileConstants.UPDATE_BOARDING_CENTER_FAILURE:
            return {
                ...state,
                updatedboardingcenter: null,
                updatedboardingcenterError: action.valuesError,
            };
        default:
            return state;
    }
};
export { addressReducer };