import { settingConstants } from "./action-types";

const initialState = {
  paymentdetails: false,
  paymentError: false,
  allBookings: false,
  allBookingsErr: false,
  allTicketsForUser: false,
  allTicketsForUserErr: false,
  billingSub: false,
  billingSubErr: false,
  totalBookings: 0,
  subscriptionPlans: false,
  subscriptionPlansErr: false,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingConstants.PAYMENT_SUCCESS:
      return {
        ...state,
        paymentdetails: action.data,
        paymentError: false,
      };
    case settingConstants.PAYMENT_FAILURE:
      return {
        ...state,
        paymentdetails: false,
        paymentError: action.error,
      };
    case settingConstants.GET_ALL_BOOKINGS_SUCCESS:
      return {
        ...state,
        allBookings:
          action.data.currentPage === 1
            ? action.data.docs
            : [...state.allBookings, ...action.data.docs],
        allBookingsErr: false,
        totalBookings: action.data.totalDocs,
      };
    case settingConstants.GET_ALL_BOOKINGS_ERROR:
      return {
        ...state,
        allBookings: false,
        allBookingsErr: action.error,
      };
    case settingConstants.GET_ALL_TICKETS_USER_SUCCESS:
      return {
        ...state,
        allTicketsForUser: action.data,
        allTicketsForUserErr: false,
      };
    case settingConstants.GET_ALL_TICKETS_USER_ERROR:
      return {
        ...state,
        allTicketsForUser: false,
        allTicketsForUserErr: action.error,
      };
    case settingConstants.GET_SUBSCRIPTION_BILLING_SUCCESS:
      return {
        ...state,
        billingSub: action.data,
        billingSubErr: false,
      };
    case settingConstants.GET_SUBSCRIPTION_BILLING_ERROR:
      return {
        ...state,
        billingSub: false,
        billingSubErr: action.error,
      };
    case settingConstants.GET_ALL_SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        subscriptionPlans: action.data,
        subscriptionPlansErr: false,
      };
    case settingConstants.GET_ALL_SUBSCRIPTION_PLANS_ERROR:
      return {
        ...state,
        subscriptionPlans: false,
        subscriptionPlansErr: action.error,
      };
    case settingConstants.CLEAR_ALL_BOOKINGS:
      return {
        ...state,
        allBookings: false,
        allBookingsErr: false,
        totalBookings: 0,
      };
    default:
      return state;
  }
};
export { settingsReducer };
