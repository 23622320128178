import { Card, Col, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
import { setDrawerOpen } from "../../state/actions";
import { useTranslation } from "react-i18next";
const { Paragraph } = Typography;

// displays list of cards having pickup, drop and boarding center addresses and the user can change the pickup and drop address
const LocationDetails = ({ address, setAddress }) => {
  // eslint-disable-next-line
  const { checkedPickupAdress, checkedDropAdress } = useSelector(
    (str) => str.loadingReducer
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pickupaddress, dropaddress, boardingCenterAddress } = address;

  // when user clciks on pencil(edit) drawer opens.
  const handleNewLocation = (value) => {
    if (value === "pickup") {
      dispatch(setDrawerOpen("setpickup"));
    } else {
      dispatch(setDrawerOpen("setDrop"));
    }
  };

  useEffect(() => {
    setAddress((prev) => ({ ...prev, pickupaddress: checkedPickupAdress }));
    // eslint-disable-next-line
  }, [checkedPickupAdress]);

  useEffect(() => {
    setAddress((prev) => ({ ...prev, dropaddress: checkedDropAdress }));

    // eslint-disable-next-line
  }, [checkedDropAdress]);

  return (
    <>
      <Row
        gutter={16}
        style={{ borderLeft: "1px solid lightGrey", paddingLeft: "15px" }}
      >
        <Col span={24}>
          {/* pickup address */}
          <Card
            title="PickUp Address"
            extra={
              <div onClick={() => handleNewLocation("pickup")}>
                <EditOutlined />
              </div>
            }
            hoverable
            style={{
              borderRadius: 0,
              marginBottom: "2em",
            }}
          >
            <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: "more" }}>
              {pickupaddress?.fullAddress || "Please select pickup location..."}
            </Paragraph>
          </Card>
        </Col>
        <Col span={24}>
          {/* drop address */}
          <Card
            title={t("Drop Address")}
            extra={
              <div onClick={() => handleNewLocation("drop")}>
                <EditOutlined />
              </div>
            }
            hoverable
            style={{
              borderRadius: 0,
              // borderColor: "lightGrey",
              marginBottom: "2em",
            }}
          >
            <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: "more" }}>
              {dropaddress?.fullAddress || "Please select drop location..."}
            </Paragraph>
          </Card>
        </Col>
        <Col span={24}>
          {/* boarding center address */}
          {boardingCenterAddress && (
            <Card
              title="Boarding center Address"
              hoverable
              style={{ borderRadius: 0 }}
            >
              <Paragraph
                ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
              >
                {boardingCenterAddress || ""}
              </Paragraph>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};
export default LocationDetails;
