import { Button, Col, Form, Input, Row } from "antd";
import React from "react";

const AddressForm = () => {
  return (
    <Row gutter={[16, 0]} style={{ fontWeight: "normal" }}>
      <Col span={12}>
        <Form.Item
          name={"houseNumber"}
          label="House / Flat no."
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={"houseName"}
          label="House Name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={"street"}
          label="Street"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={"landmark"}
          label="Landmark"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12} >
        <Form.Item name={"city"} label="City" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Col>
      <Col span={12} >
        <Form.Item name={"district"} label="District">
          <Input />
        </Form.Item>
      </Col>
      <Col span={12} >
        <Form.Item name={"state"} label="State" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={"pincode"}
          label="Pincode"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24} style={{ display: "none" }}>
        <Form.Item name={"eLoc"} label="eLoc">
          <Input disabled />
        </Form.Item>
      </Col>
      <Col span={24} style={{ display: "none" }}>
        <Form.Item name={"latitude"} label="latitude">
          <Input disabled />
        </Form.Item>
      </Col>
      <Col span={24} style={{ display: "none" }}>
        <Form.Item name={"longitude"} label="longitude">
          <Input disabled />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item>
          <Button type="primary" style={{ width: "100%" }} htmlType="submit">
            SAVE ADDRESS & PROCEED
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default AddressForm;
