import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { supportAction } from "./State/actions";
import { Button, Form, Input, Typography } from "antd";
import CommonModal from "../CommonModal/CommonModal";
import useSupportColumns from "./Components/useSupportColumns";
import { useSearchParams } from "react-router-dom";
import SupportTable from "./Components/SupportTable";
import { useRoute } from "../context/RouteContext";

const { Title } = Typography;
const { TextArea } = Input;

/**
 * Support component manages and displays support tickets for a boarding center.
 * It handles ticket retrieval, updates (such as reopening or closing tickets), and renders a table of tickets.
 * The component also includes a modal for providing reasons when updating ticket statuses.
 *
 * @returns {JSX.Element} The rendered Support component.
 *
 * @description
 * - **Ticket Retrieval**: Fetches support tickets from the server based on the current user's boarding center and any search parameters.
 * - **Ticket Update**: Allows admin to update the status of a ticket (open or close) with a reason, via a modal form.
 * - **Search Parameters**: If search parameters are provided (e.g., ticketId), they are used to filter the tickets fetched.
 */


const Support = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { userDetails } = useSelector((str) => str.navbarReducer);
    const { updateTicket } = useSelector((str) => str.supportReducer);
    const { contextHolder, modal, setModal, setFiltered, columns } = useSupportColumns();
    const { setSelectedKey } = useRoute();

    const [searchParams] = useSearchParams();

    // function to get all the support tickets
    const handleSupportDetails = () => {
        if (searchParams.size === 0) {
            setFiltered({ ticketId: false });
            dispatch(
                supportAction.getTicketsEvent(1, userDetails.boardCenterUniqueId)
            );
        } else {
            const search = Array.from(searchParams.entries()).reduce(
                (acc, [key, val]) => {
                    acc[key] = val;
                    return acc;
                },
                {}
            );
            dispatch(
                supportAction.getTicketsEvent(
                    1,
                    userDetails.boardCenterUniqueId,
                    search.ticketId
                )
            );
        }
    };

    // function to update a ticket
    const handleSubmit = (values) => {
        setModal((prev) => ({ ...prev, open: false, ticketId: "", type: "" }));
        const data = {
            status: modal.type,
            ...(modal.type === "open"
                ? { reopenReason: values.reason }
                : { closingReason: values.reason }),
        };
        dispatch(supportAction.updateTicketEvent(modal.ticketId, data));
        form.resetFields();
    };

    useEffect(() => {
        if (userDetails.boardCenterUniqueId) handleSupportDetails(1);
    }, [userDetails]);

    useEffect(() => {
        if (updateTicket) {
            handleSupportDetails(1);
            dispatch(supportAction.clearData());
        }
    }, [updateTicket]);

    useEffect(() => {
        setSelectedKey("CRUD_TICKET");
    }, [])

    return (
        <>
            {contextHolder}
            <SupportTable
                handleSupportDetails={handleSupportDetails}
                columns={columns}
            />
            <CommonModal
                isModalOpen={modal.open}
                handleCancel={() =>
                    setModal((prev) => ({ open: false, ticketId: "", type: "" }))
                }
                body={() => {
                    return (
                        <>
                            <Title style={{ fontSize: "1.2em" }}>{`Reason for ${modal.type === "open" ? "reopening" : "Closing"
                                } the ticket`}</Title>
                            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                                <Form.Item name={"reason"} rules={[{ required: true }]}>
                                    <TextArea rows={3} />
                                </Form.Item>
                                <Button type="primary" htmlType="submit" size="small">
                                    Submit
                                </Button>
                            </Form>
                        </>
                    );
                }}
            />
        </>
    );
};

export { Support };
