import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { googleAction } from '../State/googleactions';

/**
 * GoogleAuthCallback component handles the callback from Google OAuth2 authentication.
 * 
 * @component
 * 
 * @returns {React.ReactElement} - This component does not render anything directly. It performs side effects such as redirecting and showing messages based on the OAuth2 response.
 * 
 * @description
 * - Handles the response from Google OAuth2 authentication.
 * - Extracts the authorization code or error from the query parameters in the URL.
 * - Dispatches an action to handle the Google SSO process if the authorization code is present.
 * - Displays an error message and redirects to the login page if there is an error.
 * - Redirects to the home page upon successful handling of the authorization code.

 * @returns {React.ReactElement} - The component performs side effects but does not render any UI elements.
 */

const GoogleAuthCallback = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [messageApi, contextHolder] = message.useMessage();
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        const error = params.get('error');

        if (code) {
            dispatch(googleAction.ssoGoogleEvent(code));
            navigate('/');
            return;
        }
        
        if (error) {
            messageApi.open({
                type: 'error',
                content: `Google Login Failed: ${error}`,
            });
            navigate('/login');
            return;
        }

    }, [location, dispatch, navigate, messageApi]);
};

export { GoogleAuthCallback };
