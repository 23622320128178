import React from "react";
import { Typography, Image, Avatar } from "antd";
import { v4 as uuidv4 } from "uuid";
import TableComponent from "../Common/Components/TableComponent";
import vetname from "../Assets/Supporters/vet.png";
import { StarFilled } from "@ant-design/icons";
const { Text } = Typography;
const Veterinarians = () => {
  const vetColumns = [
    {
      title: () => <Text style={{ fontSize: "1.2em" }}>Veterinarian photo</Text>,
      dataIndex: "vetphoto",
      key: "vetphoto",
      width: 150,
      align: "center",
      render: (e) => <Avatar size={"large"} src={e} shape="circle" />,
    },
    {
      title: () => <Text style={{ fontSize: "1.2em" }}>Veterinarian Name</Text>,
      dataIndex: "vetname",
      key: "vetname",
      width: 200,
      align: "center",
    },
    {
      title: () => <Text style={{ fontSize: "1.2em" }}>Emp ID</Text>,
      dataIndex: "empid",
      key: "empid",
      width: 200,
      align: "center",
    },
    {
      title: () => <Text style={{ fontSize: "1.2em" }}>Contact Number</Text>,
      dataIndex: "contact",
      key: "contact",
      width: 200,
      align: "center",
    },
    {
      title: () => <Text style={{ fontSize: "1.2em" }}>Email ID</Text>,
      dataIndex: "emailID",
      width: 250,
      key: "emailID",
      align: "center",
    },
    {
      title: () => <Text style={{ fontSize: "1.2em" }}>Status</Text>,
      dataIndex: "status",
      width: 150,
      key: "status",
      align: "center",
    },
    {
      title: () => <Text style={{ fontSize: "1.2em" }}>Rate Supporter</Text>,
      dataIndex: "ratesupporter",
      key: "ratesupporter",
      width: 200,
      align: "center",
      render: (e) => (
        <Text>
          {e}&nbsp;
          <StarFilled style={{ color: "gold" }} />
        </Text>
      ),
    },
  ];
  const vetdata = [
    {
      key: uuidv4(),
      vetphoto:
        "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      vetname: "Deepak",
      empid: "HHT015",
      contact: "56787 34356",
      emailID: "deepak@gmail.com",
      status: "On appointment",
      ratesupporter: "4.5",
    },
    {
      key: uuidv4(),
      vetphoto:
        "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      vetname: "Sasi",
      empid: "HHT023",
      contact: "90989 34356",
      emailID: "sasi@gmail.com",
      status: "On appointment",
      ratesupporter: "5",
    },
    {
      key: uuidv4(),
      vetphoto:
        "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      vetname: "Deepa",
      empid: "HHT015",
      contact: "56787 34356",
      emailID: "deepa@gmail.com",
      status: "On appointment",
      ratesupporter: "5",
    },
    {
      key: uuidv4(),
      vetphoto:
        "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      vetname: "Deepa",
      empid: "HHT015",
      contact: "56787 34356",
      emailID: "deepa@gmail.com",
      status: "On appointment",
      ratesupporter: "5",
    },
    {
      key: uuidv4(),
      vetphoto:
        "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      vetname: "Deepa",
      empid: "HHT015",
      contact: "56787 34356",
      emailID: "deepa@gmail.com",
      status: "On appointment",
      ratesupporter: "4.5",
    },
    {
      key: uuidv4(),
      vetphoto:
        "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      vetname: "Deepa",
      empid: "HHT015",
      contact: "56787 34356",
      emailID: "deepa@gmail.com",
      status: "On appointment",
      ratesupporter: "4.5",
    },
  ];
  return (
    <div style={{ position: "relative", top: 15, marginBottom: "2em" }}>
      <TableComponent columns={vetColumns} data={vetdata} />
      <div style={{ position: "absolute", top: -15, left: -10 }}>
        <Image width={200} src={vetname} preview={false} />
      </div>
    </div>
  );
};

export { Veterinarians };
