export const paymentLinksConstants = {
    GET_ALL_PAYMENT_LINKS_SUCCESS: "GET_ALL_PAYMENT_LINKS_SUCCESS",
    GET_ALL_PAYMENT_LINKS_ERROR: "GET_ALL_PAYMENT_LINKS_ERROR",

    UPDATE_PAYMENT_LINKS_SUCCESS: "UPDATE_PAYMENT_LINKS_SUCCESS",
    UPDATE_PAYMENT_LINKS_ERROR: "UPDATE_PAYMENT_LINKS_ERROR",

    CANCEL_PAYMENT_LINKS_SUCCESS: "CANCEL_PAYMENT_LINKS_SUCCESS",
    CANCEL_PAYMENT_LINKS_ERROR: "CANCEL_PAYMENT_LINKS_ERROR",

    CREATE_PAYMENT_LINKS_SUCCESS: "CREATE_PAYMENT_LINKS_SUCCESS",
    CREATE_PAYMENT_LINKS_ERROR: "CREATE_PAYMENT_LINKS_ERROR",

    CLEAR_PAYMENT_LINKS: "CLEAR_PAYMENT_LINKS",
}