import { CheckOutlined, CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, Row, Space } from 'antd';
import React from 'react'
import useVerificationMethods from '../../Captain/Components/useVerificationMethods';
const {TextArea} = Input;

/**
 * Component for handling pet verification steps by an admin.
 * Displays different actions based on whether the decline box is shown or not.
 * @param {Object} props - Component properties.
 * @param {Object} props.userTobeVerifiedByAdmin - Details of the user or pet to be verified.
 * @param {Function} props.setIsModalOpen - Function to control the modal visibility.
 * @param {Object} props.contentStyle - Inline style for the content container.
 * @returns {JSX.Element} The rendered component.
 */

const PetVerificationStepsForAdmin = ({ userTobeVerifiedByAdmin, setIsModalOpen, contentStyle }) => {
    const {
        handleVerifyPet,
        handleDeclineOrderVerification,
        decline,
        handleBackPress,
        handleDeclineVerification,
        contextHolder,
        setDecline,
    } = useVerificationMethods();

    return decline?.showBox ? (
        <Space size={"middle"} direction="vertical" style={contentStyle}>
            {contextHolder}
            <TextArea
                rows={3}
                status="error"
                style={{ width: "100%" }}
                onChange={(e) =>
                    setDecline((prevDecline) => ({
                        ...prevDecline,
                        reason: e.target.value,
                    }))
                }
            />
            <Row style={{ justifyContent: "space-between" }}>
                <Button
                    size="small"
                    icon={<LeftOutlined />}
                    onClick={handleBackPress}
                >
                    Back
                </Button>
                <Popconfirm
                    title="Sure to decline?"
                    onConfirm={() =>
                        handleDeclineVerification(
                            userTobeVerifiedByAdmin,
                            "pet_verification_by_admin",
                            setIsModalOpen,
                            "boarding"
                        )
                    }
                >
                    <Button size="small" iconPosition="end" icon={<RightOutlined />}>
                        Continue
                    </Button>
                </Popconfirm>
            </Row>
        </Space>
    ) : (
        <Space size={"middle"} direction="vertical" style={contentStyle}>
            <Row style={{ justifyContent: "space-between" }}>
                <Button
                    size="small"
                    type="link"
                    icon={<CloseOutlined />}
                    onClick={() => handleDeclineOrderVerification()}
                >
                    Decline
                </Button>
                <Button
                    size="small"
                    type="link"
                    icon={<CheckOutlined />}
                    onClick={() =>
                        handleVerifyPet(
                            userTobeVerifiedByAdmin,
                            "pet_verification_by_admin",
                            setIsModalOpen
                        )
                    }
                >
                    Verify pet
                </Button>
            </Row>
        </Space>
    )
}

export default PetVerificationStepsForAdmin
