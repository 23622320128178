import { paymentLinksConstants } from "./action-types";


const initialState = {
  allPaymentLinks: false,
  allPaymentLinksErr: false,
  cancelPaymentLink: false,
  cancelPaymentLinkErr: false,
  updatePaymentLink: false,
  updatePaymentLinkErr: false,
  createPaymentLink: false,
  createPaymentLinkErr: false
};

const paymentLinksReducer = (state = initialState, action) => {
  switch (action.type) {
    case paymentLinksConstants.GET_ALL_PAYMENT_LINKS_SUCCESS:
      return {
        ...state,
        allPaymentLinks: action.data,
        allPaymentLinksErr: false
      }
    case paymentLinksConstants.GET_ALL_PAYMENT_LINKS_ERROR:
      return {
        ...state,
        allPaymentLinks: false,
        allPaymentLinksErr: action.valuesError
      }
    case paymentLinksConstants.CANCEL_PAYMENT_LINKS_SUCCESS:
      return {
        ...state,
        cancelPaymentLink: action.data,
        cancelPaymentLinkErr: false
      }
    case paymentLinksConstants.CANCEL_PAYMENT_LINKS_ERROR:
      return {
        ...state,
        cancelPaymentLink: false,
        cancelPaymentLinkErr: action.valuesError
      }
    case paymentLinksConstants.UPDATE_PAYMENT_LINKS_SUCCESS:
      return {
        ...state,
        updatePaymentLink: action.data,
        updatePaymentLinkErr: false
      }
    case paymentLinksConstants.UPDATE_PAYMENT_LINKS_ERROR:
      return {
        ...state,
        updatePaymentLink: false,
        updatePaymentLinkErr: action.valuesError
      }
    case paymentLinksConstants.CREATE_PAYMENT_LINKS_SUCCESS:
      return {
        ...state,
        createPaymentLink: action.data,
        createPaymentLinkErr: false
      }
    case paymentLinksConstants.CREATE_PAYMENT_LINKS_ERROR:
      return {
        ...state,
        createPaymentLink: false,
        createPaymentLinkErr: action.valuesError
      }
    case paymentLinksConstants.CLEAR_PAYMENT_LINKS:
      return {
        ...state,
        cancelPaymentLink: false,
        cancelPaymentLinkErr: false,
        updatePaymentLink: false,
        updatePaymentLinkErr: false,
      }
    default:
      return state;
  }
};
export { paymentLinksReducer };
