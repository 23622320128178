import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, Tag, Typography } from "antd";
import { cancellationAction } from "./State/actions";
import { services } from "../Common/Components/Services";
import dayjs from "dayjs";
import { useRoute } from "../context/RouteContext";
const { Text } = Typography;

/**
 * @component CancellationOfBookings
 * @description Displays a table of cancellation requests for bookings, allowing approval of pending requests.
 */

const CancellationOfBookings = () => {
  const dispatch = useDispatch();
  const { setSelectedKey } = useRoute()
  const { getAllcancelRequests, approveCancelRequest } = useSelector(
    (str) => str.cancellationRequestsReducer
  );
  const { isLoading } = useSelector((str) => str.loadingReducer);

  //  Dispatches an action to approve a cancellation request.
  const handleApprove = (id) => {
    dispatch(cancellationAction.approveCancellationRequestEvent(id));
  };

  // Dispatch an action to fetch all cancellation requests
  useEffect(() => {
    dispatch(cancellationAction.getAllCancellationRequestsEvent());
  }, [approveCancelRequest]);

  useEffect(() => {
    setSelectedKey('BOOKING_CANCELLATION_APPROVAL')
  }, []);

  const columns = [
    {
      title: "Requested Date",
      dataIndex: "requestedDate",
      render: (_, { status }) => (
        <Text>{dayjs(status?.dateRequested).format("MMM D, YYYY h:mm A")}</Text>
      ),
    },
    {
      title: "Booking ID",
      dataIndex: "bookingUniqueId",
      key: "bookingUniqueId",
    },
    {
      title: "Services selected",
      dataIndex: "servicesSelected",
      key: "servicesSelected",
      width: "20%",
      render: (_, record) =>
        record.services?.map((e) => (
          <Tag>{`${services.find((service) => service.key === e.serviceName).name
            } FROM ${dayjs(e.slots.checkInDateTime).format(
              "MMM D, YYYY h:mm A"
            )} TO ${dayjs(e.slots.checkOutDateTime).format(
              "MMM D, YYYY h:mm A"
            )}`}</Tag>
        )),
    },
    {
      title: "Reason for cancellation",
      dataIndex: "reasonForCancellation",
      key: "reasonForCancellation",
      render: (_, { status }) => {
        return <Text>{status?.cancellation.reason}</Text>;
      },
    },
    {
      title: "Status of cancellation",
      dataIndex: "status",
      key: "status",
      render: (_, { status, bookingUniqueId }) => {
        return !status?.cancellation?.approved ? (
          <Button size="small" onClick={() => handleApprove(bookingUniqueId)}>
            Approve
          </Button>
        ) : (
          <Button size="small" disabled>
            {" "}
            Approved
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      pagination={false}
      loading={isLoading}
      bordered
      columns={columns}
      dataSource={getAllcancelRequests}
    />
  );
};

export { CancellationOfBookings };
