import React from "react";
import {
  PickupPetFromBoardingCenter,
  ReachedParentLocation,
  StartRide,
} from "./CommonFunctions";
import { Tag, Typography } from "antd";
const { Text } = Typography;

/**
 * Custom hook that manages the logic for the pet pickup process from the boarding center.
 * It determines which component to render based on the current status of the pet's journey.
 * @param {Object} params - The parameters needed for the hook to function.
 * @param {Object} params.booking - The booking details related to the pet pickup.
 * @param {Boolean} params.isModalOpen - Boolean to control the visibility of the modal.
 * @param {Object} params.boarding_center_to_parent_location_to_pickup_pet - Object containing the status of the pet pickup process.
 * @param {Function} params.handlePickPetFromBoardingCenter - Function to handle the action of picking up the pet from the boarding center.
 * @param {Function} params.handleHandOverPetToBoardHead - Function to handle the handover of the pet to the boarding center head.
 * @param {Function} params.handleStopAtBoardingCenter - Function to stop the geolocation tracking when reaching the boarding center.
 * @param {Function} params.setIsModalOpen - Function to toggle the modal visibility.
 * @param {Function} params.handleVerify - Function to handle verification processes.
 * @param {Function} params.handleCancel - Function to handle canceling modal.
 * @param {String} params.watchId - Identifier for the geolocation watch process.
 * @param {String} params.typeofroute - String indicating the type of route (e.g., pickup, drop-off).
 * @param {Object} params.user - Object containing the details of the current user.
 * @param {Object} params.petBoarding_status - Object containing the status of the pet boarding process.
 * @param {Function} params.handleStart - Function to start the ride to pick up pet.
 * 
 * @returns {JSX.Element | null} The component or null, depending on the current status of the pet pickup process.
 */

const useToPickupPet = ({
  booking,
  isModalOpen,
  boarding_center_to_parent_location_to_pickup_pet,
  handlePickPetFromBoardingCenter,
  handleHandOverPetToBoardHead,
  handleStopAtBoardingCenter,
  setIsModalOpen,
  handleVerify,
  handleCancel,
  // showMap,
  watchId,
  typeofroute,
  user,
  petBoarding_status,
  handleStart,
}) => {
  if (boarding_center_to_parent_location_to_pickup_pet) {

    const {
      accepts_ride,
      on_the_way_to_parent_location,
      reached_parent_location,
      order_verification,
      parent_verification,
      pet_verification_by_captain,
      pet_reached_boarding_center,
      pet_handed_over_to_the_boarding_center,
    } = boarding_center_to_parent_location_to_pickup_pet;
    if (
      petBoarding_status?.pet_picked_up_from_boarding_center?.status ===
      "accept"
    )
      return <StartRide booking={booking} handleStart={handleStart} type={"drop"} />;
    if (
      petBoarding_status?.pet_picked_up_from_boarding_center?.status ===
      "decline"
    )
      return <Text>Captain did not pick up pet from boarding center.</Text>;
    if (pet_handed_over_to_the_boarding_center?.status === "accept")
      return (
        <PickupPetFromBoardingCenter
          booking={booking}
          handlePickPetFromBoardingCenter={handlePickPetFromBoardingCenter}
        />
      );
    if (pet_handed_over_to_the_boarding_center?.status === "decline")
      return <Text>Pet not handed over to the boardhead.</Text>;
    if (pet_reached_boarding_center?.status === "accept")
      return (
        <Tag
          style={{ cursor: "pointer" }}
          onClick={() =>
            handleHandOverPetToBoardHead(
              booking?.bookingUniqueId,
              booking?.bookingId
            )
          }
        >
          Handover pet to boardhead
        </Tag>
      );
    if (pet_reached_boarding_center?.status === "decline")
      return <Text>Pet did not reach boarding center</Text>;
    if (pet_verification_by_captain?.status === "accept")
      return (
        <Tag
          style={{ cursor: "pointer" }}
          onClick={() =>
            handleStopAtBoardingCenter(
              booking?.bookingUniqueId,
              booking?.bookingId
            )
          }
        >
          Reached boarding center?
        </Tag>
      );
    if (pet_verification_by_captain?.status === "decline")
      return <Text>Pet verification unsuccessful.</Text>;
    if (parent_verification?.status === "decline")
      return <Text>Parent verification unsuccessful.</Text>;
    if (order_verification?.status === "decline")
      return <Text>Order verification unsuccessful.</Text>;
    if (on_the_way_to_parent_location?.status === "accept")
      return (
        <ReachedParentLocation
          booking={booking}
          handleVerify={handleVerify}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          user={user}
          typeofroute={typeofroute}
          watchId={watchId}
          // showMap={showMap}
          handleCancel={handleCancel}
          type={"pickup"}
        />
      );
    if (accepts_ride?.status === "accept")
      return (
        <StartRide booking={booking} handleStart={handleStart} type={"pickup"} />
      );
  }
};

export default useToPickupPet;
