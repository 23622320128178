// import React, { useState } from "react";
// import { Navigate, Outlet } from "react-router-dom";
// import { verifySignature } from "../../Common/Verify";
// import { Navbar } from "../../Navbar";
// import { Layout } from "antd";
// import Sidebar from "../../Navbar/Components/Sidebar";
// import { useSelector } from "react-redux";
// import AddressDrawer from "./AddressDrawer";
// const { Content } = Layout;

// const PrivateRoute = () => {
//   const [finalAddress, setFinalAddress] = useState("");
//   const token = localStorage.getItem("authToken");
//   const authentication = verifySignature(token);
//   const { userDetails } = useSelector((str) => str.navbarReducer);

//   return authentication ? (
//     <Layout>
//       <Navbar
//         displayName={userDetails?.firstName}
//         finalAddress={finalAddress}
//       />
//       <Layout>
//         <Sidebar />
//         <Content style={{ marginLeft: "20px", marginRight: "20px" }}>
//           <Outlet />
//         </Content>
//       </Layout>
//       <AddressDrawer setFinalAddress={setFinalAddress} />
//     </Layout>
//   ) : (
//     <Navigate to="/login" />
//   );
// };

// export { PrivateRoute };

import React, { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { verifySignature } from "../../Common/Verify";
import Spinner from "../../Spinner/Spinner";
import { NotFound } from "../../Common/Components/NotFound";
import DecodeToken from "../../Common/Components/DecodeToken";

const PrivateRoute = ({ layout: LayoutComponent, allowedRoles }) => {
  const token = localStorage.getItem("authToken");
  const authentication = verifySignature(token);


  if (!authentication) {
    return <Navigate to="/login" />;
  }

  // Check if the user's role is in the allowedRoles array
  if (
    allowedRoles.length > 0 &&
    !allowedRoles.includes(DecodeToken(token))
  ) {
    return (
      <NotFound
        status={"403"}
        title={"403"}
        subTitle={"Sorry, you are not authorized to access this page"}
        continueTo={"/"}
      />
    ); // Redirect to a 'Not Authorized' page
  }

  return (
    <LayoutComponent>
      <Outlet /> {/* Renders nested routes */}
    </LayoutComponent>
  );
};

export { PrivateRoute };
