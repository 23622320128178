import { Row } from "antd";
import React from "react";
import styles from "../Styles/mycalendar.module.css";

/**
 * @function useCalendarMethods
 * @description Custom React hook that provides methods to manage calendar interactions, 
 * such as handling panel changes, rendering custom headers, and rendering date cells 
 * with time slot information.
 *
 * @param {Object} params - Parameters for the hook.
 * @param {Function} params.setCurrentMonth - Function to set the currently selected month.
 * @param {Function} params.getTimeSlotsForParticularMonth - Function to fetch time slots for a specific month and service.
 * @param {Object} params.slotDetails - Details of the available slots for the selected service and date.
 * @param {string} params.serviceName - The name of the service for which time slots are being fetched.
 * @param {Function} params.setShowTimeSlots - Function to toggle the visibility of the time slots view.
 * @param {Function} params.setDate - Function to set the currently selected date.
 * @param {Function} params.setSelectedTimeSlots - Function to set the selected time slots for the chosen date.
 *
 * @returns {Object} - Returns an object containing methods for handling calendar interactions.
 * @returns {Function} return.onPanelChange - Method to handle changes in the calendar panel (month/year).
 * @returns {Function} return.headerRender - Method to render a custom header for the calendar.
 * @returns {Function} return.renderCell - Method to render each date cell with appropriate time slot data.
 */

const useCalendarMethods = ({
  setCurrentMonth,
  getTimeSlotsForParticularMonth,
  slotDetails,
  serviceName,
  setShowTimeSlots,
  setDate,
  setSelectedTimeSlots,
}) => {
  const onPanelChange = (value) => {
    setCurrentMonth(value);
    getTimeSlotsForParticularMonth({ date: value, serviceName: serviceName });
  };

  const headerRender = ({ value, onChange }) => {
    const month = value?.format("MMMM YYYY");
    return (
      <Row style={{ justifyContent: "center", marginBottom: "15px" }}>
        <div style={{ marginRight: "20px" }}>
          <a onClick={(e) => onChange(value?.subtract(1, "month"))}>❮</a>
        </div>
        <div>{month}</div>
        <div style={{ marginLeft: "20px" }}>
          <a onClick={(e) => onChange(value?.add(1, "month"))}>❯</a>
        </div>
      </Row>
    );
  };

  const renderCell = (cellValue) => {
    const matchingData = slotDetails?.data?.find(
      (obj) => obj.date === cellValue.toISOString().split("T")[0]
    );

    if (matchingData) {
      if (matchingData?.isAvailable) {
        return (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div
              className={styles.date}
              style={{ background: "green" }}
              onClick={() => onSelectDate(cellValue)}
            >
              {cellValue?.format("DD")}
            </div>
          </div>
        );
      } else {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              cursor: "not-allowed",
            }}
          >
            <div className={styles.date} style={{ background: "red" }}>
              {cellValue?.format("DD")}
            </div>
          </div>
        );
      }
    } else {
      return (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div style={{ height: "30px", width: "30px" }}>
            {cellValue?.format("DD")}
          </div>
        </div>
      );
    }
  };

  const onSelectDate = (value) => {
    setShowTimeSlots(true);
    const selectedDate = value?.format("YYYY-MM-DD");
    setDate(selectedDate);
    const selectedTimeSlots = slotDetails?.data?.find(
      (slot) => slot.date === selectedDate
    );
    setSelectedTimeSlots(selectedTimeSlots?.timeSlots);
  };

  return {
    onPanelChange,
    headerRender,
    renderCell,
  };
};

export default useCalendarMethods;
