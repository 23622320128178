export const settingConstants = {
  PAYMENT_SUCCESS: "PAYMENT_SUCCESS",
  PAYMENT_FAILURE: "PAYMENT_FAILURE",

  GET_ALL_BOOKINGS_SUCCESS: "GET_ALL_BOOKINGS_SUCCESS",
  GET_ALL_BOOKINGS_ERROR: "GET_ALL_BOOKINGS_ERROR",

  GET_ALL_TICKETS_USER_SUCCESS: "GET_ALL_TICKETS_USER_SUCCESS",
  GET_ALL_TICKETS_USER_ERROR: "GET_ALL_TICKETS_USER_ERROR",

  GET_SUBSCRIPTION_BILLING_SUCCESS: "GET_SUBSCRIPTION_BILLING_SUCCESS",
  GET_SUBSCRIPTION_BILLING_ERROR: "GET_SUBSCRIPTION_BILLING_ERROR",

  GET_ALL_SUBSCRIPTION_PLANS_SUCCESS: "GET_ALL_SUBSCRIPTION_PLANS_SUCCESS",
  GET_ALL_SUBSCRIPTION_PLANS_ERROR: "GET_ALL_SUBSCRIPTION_PLANS_ERROR",

  CLEAR_ALL_BOOKINGS: "CLEAR_ALL_BOOKINGS",
};
