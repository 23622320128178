import axios from "axios";

const apiURL = process.env.REACT_APP_BACKEND_URL;

// get action center details
const actionCenterDetails = () => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/getActionCenterDetails`, config);
};

// const getCaptainDetails = (page, data) => {
//   const token = localStorage.getItem("authToken");
//   const config = {
//     headers: {
//       Authorization: token,
//       "Content-Type": "application/json",
//     },
//   };
//   let url;
//   if (data) {
//     url = `${apiURL}/getCaptainDetail?appointmentStatus=${data}&page=${page}`
//   } else {
//     url = `${apiURL}/getCaptainDetail?page=${page}`
//   }
//   return axios.get(url, config);
// };

const getCaptainDetails = (page, data) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };

  // Base URL
  let url = `${apiURL}/getCaptainDetail?page=${page}`;

  // Append query parameters dynamically if `data` is provided
  if (data && Object.keys(data).length > 0) {
    const params = new URLSearchParams();

    // Add each property from `data` as query parameters
    Object.keys(data).forEach(key => {
      params.append(key, data[key]);
    });

    // Append parameters to the base URL
    url += `&${params.toString()}`;
  }

  return axios.get(url, config);
};


const notifyAdminOfDecliningBooking = (bookingId, data) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.put(`${apiURL}/notifyAdmin/${bookingId}`, data, config);
};

const getCaptainsBookings = (captainId, page) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/getCaptainsBookings/${captainId}?page=${page}`, config);
};

const acceptOrRejectCaptainsDeclineRequest = (captainId, bookingId, data) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(
    `${apiURL}/acceptOrRejectDeclineRequest/${captainId}/${bookingId}?key=${data}`,
    config
  );
};

const requestCancellation = (bookingId, data) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.put(`${apiURL}/requestCancellation/${bookingId}`, data, config)
}

export const dashboardapi = {
  actionCenterDetails,
  getCaptainDetails,
  getCaptainsBookings,
  notifyAdminOfDecliningBooking,
  acceptOrRejectCaptainsDeclineRequest,
  requestCancellation
};
