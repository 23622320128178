// import { mappls, mappls_plugin } from "mappls-web-maps";
// import { useEffect, useRef, useState } from "react";
// import { useSelector } from "react-redux";

// const mapplsClassObject = new mappls();
// const mapplsPluginObject = new mappls_plugin();

// const DirectionPlugin = ({ map, currentLocation }) => {
//     const directionRef = useRef(null);

//     useEffect(() => {
//         if (map && directionRef.current) {
//             directionRef.current.remove();
//             mapplsClassObject.removeLayer({ map, layer: directionRef.current });
//         }

//         directionRef.current = mapplsPluginObject.direction(
//             {
//                 Resource: "route_eta",
//                 annotations: "nodes,congestion",
//                 map: map,
//                 start: currentLocation,
//                 end: { label: "India Gate, Delhi", geoposition: "1T182A" },
//             },
//             (e) => {
//                 console.log(e, "eee");
//             }
//         );

//         return () => {
//             if (map && directionRef.current) {
//                 mapplsClassObject.removeLayer({ map, layer: directionRef.current });
//             }
//         };
//     }, [map, currentLocation]);

//     return null;
// };

// const Navigation = () => {
//     const mapRef = useRef(null);
//     const [isMapLoaded, setIsMapLoaded] = useState(false);
//     const [currentLocation, setCurrentLocation] = useState("28.545,77.545");
//     const { tokendata, valuesofmap } = useSelector((str) => str.appReducer);
//     const loadObject = { map: true, plugins: ["direction"] };

//     useEffect(() => {
//         mapplsClassObject.initialize(tokendata.access_token, loadObject, () => {
//             const newMap = mapplsClassObject.Map({
//                 id: "mapForNavigation",
//                 properties: {
//                     center: [28.633, 77.2194],
//                     zoom: 4,
//                 },
//             });

//             newMap.on("load", () => {
//                 setIsMapLoaded(true);
//             });
//             mapRef.current = newMap;
//         });
//         return () => {
//             if (mapRef.current) {
//                 mapRef.current.remove();
//             }
//         };
//     }, []);

//     useEffect(() => {
//         const watchId = navigator.geolocation.watchPosition(
//             (position) => {
//                 const { latitude, longitude } = position.coords;
//                 console.log(position,"pooos")
//                 setCurrentLocation(`${latitude},${longitude}`);
//                 if (mapRef.current) {
//                     mapRef.current.setCenter([longitude, latitude]);
//                 }
//             },
//             (error) => console.error(error),
//             { enableHighAccuracy: true, distanceFilter: 0 }
//         );

//         return () => {
//             console.log("object")
//             navigator.geolocation.clearWatch(watchId);
//         };
//     }, []);

//     return (
//         <div
//             id="mapForNavigation"
//             style={{ width: "100%", height: "80vh", display: "inline-block" }}
//         >
//             {isMapLoaded && <DirectionPlugin map={mapRef.current} currentLocation={currentLocation} />}
//         </div>
//     );
// };

// export default Navigation;

import { mappls, mappls_plugin } from "mappls-web-maps";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const mapplsClassObject = new mappls();
const mapplsPluginObject = new mappls_plugin();

const DirectionPlugin = ({ map }) => {
    const directionRef = useRef(null);

    useEffect(() => {
        if (map && directionRef.current) {
            directionRef.current.remove();
            mapplsClassObject.removeLayer({ map, layer: directionRef.current });
        }

        directionRef.current = mapplsPluginObject.direction(
            {
                Resource: "route_eta",
                annotations: "nodes,congestion",
                map: map,
                start: "28.545,77.545",
                end: { label: "India Gate, Delhi", geoposition: "1T182A" },
            },
            (e) => {
                console.log(e,"d");
            }
        );
        return () => {
            if (map && directionRef.current) {
                mapplsClassObject.removeLayer({ map, layer: directionRef.current });
            }
        };
    }, [map]);
};

const Navigation = () => {
    const mapRef = useRef(null);
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const { tokendata, valuesofmap } = useSelector((str) => str.appReducer);

    const loadObject = { map: true, plugins: ["direction"] };

    useEffect(() => {
        mapplsClassObject.initialize(tokendata.access_token, loadObject, () => {
            const newMap = mapplsClassObject.Map({
                id: "map",
                properties: {
                    center: [28.633, 77.2194],
                    zoom: 4,
                },
            });

            newMap.on("load", () => {
                setIsMapLoaded(true);
            });
            mapRef.current = newMap;
        });
        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, []);

    return (
        <div
            id="map"
            style={{ width: "100%", height: "99vh", display: "inline-block" }}
        >
            {isMapLoaded && <DirectionPlugin map={mapRef.current} />}
        </div>
    );
};

export default Navigation;