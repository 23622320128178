import { Button, Popover, Row, Typography } from "antd";
import React from "react";
const { Text } = Typography;

/**
 * @description This component displays information below a calendar, indicating the availability of time slots.
 * It shows a legend with color indicators for available and unavailable slots and includes a button to refresh slot availability.
 * The button is accompanied by a countdown timer and a popover that informs users when slots have been updated.
 *
 * @param {Object} buttonState - An object representing the state of the button, including visibility, countdown timer, and popover visibility.
 * @param {Function} setButtonState - Function to update the button's state.
 * @param {Function} handleButtonClick - Function to handle the button click event, typically used to refresh slot availability.
 *
 * @returns {JSX.Element} The `InfoBelowCalendar` component.
 */

const InfoBelowCalendar = ({ buttonState, setButtonState, handleButtonClick }) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <div>
                <Row style={{ alignItems: "center" }}>
                    <div
                        style={{ background: "red", width: "10px", height: "10px" }}
                    ></div>
                    &nbsp;
                    <Text>No slots available</Text>
                </Row>
                <Row style={{ alignItems: "center" }}>
                    <div
                        style={{ background: "green", width: "10px", height: "10px" }}
                    ></div>
                    &nbsp;
                    <Text>Slots are available</Text>
                </Row>
            </div>
            <div>
                {buttonState.isVisible && (
                    <>
                        <Text>
                            Updates in {Math.floor(buttonState.countdownTimer / 60)}:
                            {("0" + (buttonState.countdownTimer % 60)).slice(-2)}
                        </Text>
                        &nbsp; &nbsp;
                        <Popover
                            content="Slots have been updated. Please refresh"
                            open={buttonState.popoverVisible}
                            placement="right"
                            trigger="click"
                            onOpenChange={(open) =>
                                setButtonState({ ...buttonState, popoverVisible: open })
                            }
                        >
                            <Button
                                type="primary"
                                onClick={() => {
                                    handleButtonClick();
                                }}
                            >
                                Refresh
                            </Button>
                        </Popover>
                    </>
                )}
            </div>
        </div>
    );
};

export default InfoBelowCalendar;
