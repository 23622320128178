import React from 'react'
import TodayHistory from './TodayHistory'
import NewAppointmentsForAdmin from './NewAppointmentsForAdmin'
import ServiceHistoryBarChart from './ServiceHistoryBarChart'
import CenterVehicles from './CenterVehicles'
import { Col, Row } from 'antd'

const AdminHome = () => {
    return (
        <>
            <Row gutter={[32, 16]}>
                <Col xs={24} sm={24} md={12} lg={10} xl={8}>
                    <TodayHistory />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                    {/* <RecentServices /> */}
                    <TodayHistory />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                    {/* <RecentServices /> */}
                    <TodayHistory />
                </Col>
                <Col xs={24} sm={24} md={12} lg={10} xl={8}>
                    <NewAppointmentsForAdmin />
                </Col>
                <Col xs={24} sm={24} md={24} lg={14} xl={8}>
                    <ServiceHistoryBarChart />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                    <TodayHistory />
                </Col>
                <Col xs={24} sm={24} md={12} lg={18} xl={18}>
                    <CenterVehicles />
                </Col>
            </Row>
        </>
    )
}

export default AdminHome
