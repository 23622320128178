import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { resetDrawer, resetName, setName } from '../../state/actions';

/**
 * Custom hook for setting and resetting the page name and drawer state in Redux.
 * 
 * @param {string} pageName - The name of the page to set in the Redux store.
 * 
 * @description
 * This hook performs the following actions:
 * - Sets the page name in the Redux store using the `setName` action.
 * - Resets the page name and drawer state when the component using this hook is unmounted.
 * 
 * @returns {void} - This hook does not return any value. It performs side effects related to Redux state management.
 */

const useSettingName = (pageName) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setName(pageName));
        return () => {
            dispatch(resetName());
            dispatch(resetDrawer());
        };
        // eslint-disable-next-line
    }, []);

}

export default useSettingName
