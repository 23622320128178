import { signupinConstants } from "./action-types";
import { jwtDecode } from "jwt-decode";
const initialState = {
  googledata: false,
  googleLoading: true,
  googleError: null,
};
const googleReducer = (state = initialState, action) => {
  switch (action.type) {
    case signupinConstants.LOGIN_GOOGLE_SUCCESS:
      localStorage.setItem("authToken", action.data.token);
      // let decodedRole = jwtDecode(action.data.token);
      // localStorage.setItem("role", decodedRole.currentRole);
      return {
        ...state,
        googledata: action.data,
        googleLoading: true,
        googleError: null,
      };
    case signupinConstants.LOGIN_GOOGLE_FAILURE:
      return {
        ...state,
        googledata: false,
        googleLoading: false,
        googleError: action.dataError,
      };
    case signupinConstants.LOGIN_GOOGLE_DATA_RESET:
      return {
        ...state,
        googledata: false,
        googleLoading: true,
        googleError: null,
      };
    default:
      return state;
  }
};
export { googleReducer };
