import React, { useEffect } from "react";
import { Table, Button, Tag, Avatar, theme, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { adminAction } from "../ServiceDetails/state/actions";
const { Text } = Typography;

/**
 * @description
 * The `Captain` component displays a list of captains. It also provides functionality to assign a captain
 * to a booking if a `bookingId` is present. The "Assign" button is conditionally enabled
 * based on the captain's status and assignment conflicts.
 */

const Captain = () => {
  const dispatch = useDispatch();
  const { bookingId } = useParams();
  const { useToken } = theme;
  const { token } = useToken();
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.navbarReducer);
  const { getCaptains } = useSelector((state) => state.adminDashboardReducer);
  const { isLoading } = useSelector((state) => state.loadingReducer);

  useEffect(() => {
    dispatch(adminAction.getCaptainsOfBoardingCenter(bookingId));
  }, [dispatch]);

  // when a booking is assigned, this is called
  const handleSubmit = (captainId, captainMongoId) => {
    dispatch(
      adminAction.notifyCaptain(
        captainId,
        bookingId,
        { captainId: captainMongoId },
        userDetails.boardCenterUniqueId,
        { boardCenterId: userDetails.boardingCenter }
      )
    );
    navigate("/services/inProcessAppointments");
  };

  const columns = [
    {
      title: "Captain photo",
      dataIndex: "photo",
      key: "photo",
      render: (text, record) => (
        <Avatar
          src={record.photo}
          style={{
            border:
              record.online_status === true
                ? `4px solid #3dd979`
                : "3.5px solid #a3999c",
          }}
        />
      ),
    },
    {
      title: "Captain name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Emp ID",
      dataIndex: "empId",
      key: "empId",
      render: (_, { captainUniqueId, empId }) => (
        <Text
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            color: token.appbluecolor,
          }}
          onClick={() => navigate(`/captainsBookings/${captainUniqueId}`)}
        >
          {empId}
        </Text>
      ),
    },
    {
      title: "Contact number",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "In-appointment",
      dataIndex: "in_appointment_status",
      key: "in_appointment_status",
      render: (text, record) => {
        return (
          <Tag
            color={
              record.in_appointment_status === true
                ? token.appbluecolor
                : token.green
            }
          >
            {record.in_appointment_status === true
              ? "In appointment"
              : "Available"}
          </Tag>
        );
      },
    },
  ];

  if (bookingId) {
    columns.push({
      title: "Assign",
      key: "assign",
      render: (_, { captainUniqueId, captainId, status }) => {
        return (
          <Button
            size="small"
            disabled={status === "conflict"}
            onClick={() => handleSubmit(captainUniqueId, captainId)}
          >
            Assign
          </Button>
        );
      },
    });
  }

  return (
    <Table columns={columns} loading={isLoading} dataSource={getCaptains} />
  );
};

export { Captain };
