import { startLoadingAllAddress, startLoadingBoardingCenter, stopLoadingAllAddress, stopLoadingBoardingCenter } from "../../state/actions";
import { GlobalDecrypt } from "../GlobalDecrypt";
import { addressApi } from "../utils/api";
import { profileConstants } from "./action-types";

/*----- add address ----- */

const addAddressError = (err) => {
    return {
        type: profileConstants.ADD_ADDRESS_FAILURE,
        valuesError: err,
    };
};

const addAddressSuccess = (data) => {
    return {
        type: profileConstants.ADD_ADDRESS_SUCCESS,
        data: data,
    };
};

const addAddressEvent = (data) => {
    return (dispatch) => {
        addressApi
            .addAddress(data)
            .then((response) => {
                // let decryptedData = GlobalDecrypt(response.data.info.data, response.data.info.iv, response.data.info.key)
                dispatch(addAddressSuccess(response.data.info));
            })
            .catch((err) => {
                dispatch(addAddressError(err));
            });
    };
};

/*----- get address ----- */

const getAddressesError = (err) => {
    return {
        type: profileConstants.ALL_ADDRESS_FAILURE,
        valuesError: err,
    };
};

const getAddressesSuccess = (data) => {
    return {
        type: profileConstants.ALL_ADDRESS_SUCCESS,
        data: data,
    };
};

const getAddressesEvent = () => {
    return (dispatch) => {
        dispatch(startLoadingAllAddress());
        addressApi
            .getAllAddresses()
            .then((response) => {
                // let decrypteddata = GlobalDecrypt(response.data.info.data, response.data.info.iv, response.data.info.key)
                dispatch(getAddressesSuccess(response.data.info));
                dispatch(stopLoadingAllAddress());
            })
            .catch((err) => {
                dispatch(getAddressesError(err));
                dispatch(stopLoadingAllAddress());
            });
    };
};

/*----- update address ----- */

const putAddressesError = (err) => {
    return {
        type: profileConstants.UPDATE_ADDRESS_FAILURE,
        valuesError: err,
    };
};

const putAddressesSuccess = (data) => {
    return {
        type: profileConstants.UPDATE_ADDRESS_SUCCESS,
        data: data,
    };
};

const putAddressesEvent = (data, id) => {
    return (dispatch) => {
        addressApi
            .updateAddress(data, id)
            .then((response) => {
                dispatch(putAddressesSuccess(response?.data?.info));
            })
            .catch((err) => {
                dispatch(putAddressesError(err));
            });
    };
};

/*----- get boarding center by address id ----- */
const getBoardingError = (err) => {
    return {
        type: profileConstants.GET_BOARDINGCENTER_BY_ID_FAILURE,
        valuesError: err,
    };
};

const getBoardingSuccess = (data) => {
    return {
        type: profileConstants.GET_BOARDINGCENTER_BY_ID_SUCCESS,
        data: data,
    };
};

const getBoardingEvent = (id) => {
    return (dispatch) => {
        dispatch(startLoadingBoardingCenter());
        addressApi
            .getBoardingCenterbyId(id)
            .then((response) => {
                dispatch(getBoardingSuccess(response?.data?.info));
                dispatch(stopLoadingBoardingCenter());
            })
            .catch((err) => {
                dispatch(getBoardingError(err?.response?.data));
                dispatch(stopLoadingBoardingCenter());
            });
    };
};

/*----- UPDATE boarding center----- */
const updateBoardingCenterError = (err) => {
    return {
        type: profileConstants.UPDATE_BOARDING_CENTER_FAILURE,
        valuesError: err,
    };
};

const updateBoardingCenterSuccess = (data) => {
    return {
        type: profileConstants.UPDATE_BOARDING_CENTER_SUCCESS,
        data: data,
    };
};

const updateBoardingCenterEvent = (data) => {
    return (dispatch) => {
        addressApi
            .updateBoardingCenter(data)
            .then((response) => {
                dispatch(updateBoardingCenterSuccess(response?.data?.info));
            })
            .catch((err) => {
                dispatch(updateBoardingCenterError(err));
            });
    };
};
export const addressAction = {
    addAddressEvent,
    getAddressesEvent,
    putAddressesEvent,
    getBoardingEvent,
    updateBoardingCenterEvent
};

