import { CheckCircleOutlined } from "@ant-design/icons";
import React from "react";
import styles from "../Styles/tickmark.module.css";

/**
 * TickMark component renders a confirmation message with a tick mark icon.
 * 
 * @component
 * 
 * @param {Object} props - The props for the component.
 * @param {number} props.countdown - The countdown timer that indicates the number of seconds before redirecting.
 * 
 * @returns {React.ReactElement} A visual confirmation of successful verification with a countdown timer.
 * 
 * @description
 * - This component displays a large green tick mark to indicate that verification was successful.
 * - Below the tick mark, a message shows the countdown before redirecting to another page.
 */

const TickMark = ({ countdown }) => {
  return (
    <div className={styles.tickmarkContainer}>
      <div className={styles.tickmark}>
        <CheckCircleOutlined style={{ fontSize: "100px", color: "green" }} />{" "}
        {/* Blue tick mark */}
        <div className={styles.message}>
          Verification Successful. <br />
          Redirecting in {countdown}...
        </div>
      </div>
    </div>
  );
};

export default TickMark;
