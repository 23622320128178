export const helpConstants = {
  ADD_QUESTION_SUCCESS: "ADD_QUESTION_SUCCESS",
  ADD_QUESTION_ERROR: "ADD_QUESTION_ERROR",

  GET_ALL_QUESTIONS_SUCCESS: "GET_ALL_QUESTIONS_SUCCESS",
  GET_ALL_QUESTIONS_ERROR: "GET_ALL_QUESTIONS_ERROR",

  UPDATE_QUESTION_SUCCESS: "UPDATE_QUESTION_SUCCESS",
  UPDATE_QUESTION_ERROR: "UPDATE_QUESTION_ERROR",

  GET_QUESTION_BY_ID_SUCCESS: "GET_QUESTION_BY_ID_SUCCESS",
  GET_QUESTION_BY_ID_ERROR: "GET_QUESTION_BY_ID_ERROR",

  DELETE_QUESTION_SUCCESS: "DELETE_QUESTION_SUCCESS",
  DELETE_QUESTION_ERROR: "DELETE_QUESTION_ERROR",

  GET_QUESTIONS_KEY_PLATFORM_SUCCESS: "GET_QUESTIONS_KEY_PLATFORM_SUCCESS",
  GET_QUESTIONS_KEY_PLATFORM_ERROR: 'GET_QUESTIONS_KEY_PLATFORM_ERROR'
};
