import axios from "axios";

const apiURL = `${process.env.REACT_APP_BACKEND_URL}/payment/payment-link`

// get all payment links
const getPaymentLinks = () => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/get-all-payment-links`, config);
}

// get all payment links
const cancelPaymentLink = async (paymentId) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return await axios.post(`${apiURL}/cancel-payment-link/${paymentId}`, null, config);
}

//update payment link
const updatePaymentLink = async (paymentId, data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return await axios.patch(`${apiURL}/update-payment-link/${paymentId}`, data, config);
}

//update payment link
const createPaymentLink = async ( data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return await axios.post(`${apiURL}/create-payment-link`, data, config);
}


export const paymentLinksApi = {
    getPaymentLinks,
    cancelPaymentLink,
    updatePaymentLink,
    createPaymentLink
};