import { startLoading, stopLoading } from "../../state/actions";
import { cancelRequestsApi } from "../utils/api";
import { cancellationRequestsConstants } from "./action-types";

const getAllCancellationRequestsError = (err) => {
  return {
    type: cancellationRequestsConstants.GET_ALL_CANCELLATION_REQUESTS_ERROR,
    error: err,
  };
};

const getAllCancellationRequestsSuccess = (data) => {
  return {
    type: cancellationRequestsConstants.GET_ALL_CANCELLATION_REQUESTS_SUCCESS,
    data: data,
  };
};

const getAllCancellationRequestsEvent = () => {
  return (dispatch) => {
    dispatch(startLoading());
    cancelRequestsApi
      .getAllCancelRequests()
      .then((response) => {
        dispatch(getAllCancellationRequestsSuccess(response?.data?.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(getAllCancellationRequestsError(err));
        dispatch(stopLoading());
      });
  };
};


const approveCancellationRequestError = (err) => {
  return {
    type: cancellationRequestsConstants.APPROVE_CANCELLATION_ERROR,
    error: err,
  };
};

const approveCancellationRequestSuccess = (data) => {
  return {
    type: cancellationRequestsConstants.APPROVE_CANCELLATION_SUCCESS,
    data: data,
  };
};

const approveCancellationRequestEvent = (bookingId) => {
  return (dispatch) => {
    dispatch(startLoading());
    cancelRequestsApi
      .approveCancelRequest(bookingId)
      .then((response) => {
        dispatch(approveCancellationRequestSuccess(response?.data?.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(approveCancellationRequestError(err));
        dispatch(stopLoading());
      });
  };
};

export const cancellationAction = {
  getAllCancellationRequestsEvent,
  approveCancellationRequestEvent
};
