
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * A utility function that returns an array of language options for a dropdown menu.
 * Each option allows the user to switch the application's language.
 * 
 * @returns {Array<Object>} An array of language items, where each item has a `key`, `label`, and `onClick` handler.
 * 
 * @description
 * - The `useTranslation` hook from `react-i18next` is used to manage language switching.
 * - `changeLanguage` function is defined to switch the language and store the selected locale in sessionStorage.
 * - The returned array includes language options for English, Hindi, Marathi, and Telugu.
 * - Each language option triggers `changeLanguage` with the respective language code when selected.
 */


const LanguageItems = () => {

    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        sessionStorage.setItem("locale", lng);
    };

    return [
        {
            key: "1",
            label: "English",
            onClick: () => changeLanguage("en"),
        },
        {
            key: "2",
            label: "Hindi",
            onClick: () => changeLanguage("hin"),
        },
        {
            key: "3",
            label: "Marathi",
            onClick: () => changeLanguage("mar"),
        },
        {
            key: "4",
            label: "Telugu",
            onClick: () => changeLanguage("tel"),
        },
    ];
};

export default LanguageItems;
