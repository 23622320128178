import { Button, theme, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import CommonModal from "../../CommonModal/CommonModal";
import Tracking from "../../Common/Components/Tracking";
const { Text } = Typography;

/**
 * PDBCToParentDropPet Component
 * 
 * This component handles the different statuses for the process of dropping a pet
 * from the boarding center to the parent's location by the captain. It displays
 * appropriate messages and actions based on the current status.
 * 
 * @component
 * @param {Object} props - Component properties
 * @param {Object} props.boarding_center_to_parent_location_drop_pet - Statuses related to the drop-off process
 * @param {Object} props.boarding_center_to_parent_location_drop_pet.on_the_way_to_parent_location - Status of the captain's ride to the parent's location
 * @param {Object} props.boarding_center_to_parent_location_drop_pet.reached_parent_location - Status of the captain reaching the parent's location
 * @param {Object} props.boarding_center_to_parent_location_drop_pet.parent_verification_while_releasing_pet - Status of parent verification while releasing the pet
 * @param {Object} props.boarding_center_to_parent_location_drop_pet.handed_over_the_pet_to_the_parent - Status of handing over the pet to the parent
 * @param {string} props.bookingUniqueId - Unique ID of the booking
 * @param {string} props.captainId - ID of the captain responsible for dropping the pet
 * @param {Function} props.showModalForTrack - Function to show the tracking modal
 * @param {Object} props.bookingIdToTrack - Ref object holding the booking ID being tracked
 * @param {Function} props.hideModalForTrack - Function to hide the tracking modal
 * @param {boolean} props.modalVisible - Boolean indicating if the tracking modal is visible
 * 
 * @returns {JSX.Element} The PDBCToParentDropPet component
 */
const PDBCToParentDropPet = ({
    boarding_center_to_parent_location_drop_pet,
    bookingUniqueId,
    captainId,
    showModalForTrack,
    bookingIdToTrack,
    hideModalForTrack,
    modalVisible
}) => {
    const { useToken } = theme;
    const { token } = useToken();
    let {
        on_the_way_to_parent_location,
        reached_parent_location,
        parent_verification_while_releasing_pet,
        handed_over_the_pet_to_the_parent,
    } = boarding_center_to_parent_location_drop_pet;
    if (handed_over_the_pet_to_the_parent?.status === "accept") {
        return (
            <Text>
                Pet handed over to parent by captain{" "}
                <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>
            </Text>
        );
    } else if (handed_over_the_pet_to_the_parent?.status === "decline") {
        return (
            <Text style={{ color: token.Red }}>
                Pet not handed over to parent by captain{" "}
                <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>.
            </Text>
        );
    } else if (parent_verification_while_releasing_pet?.status === "accept") {
        return <Text>Done with Parent verification while releasing pet.</Text>;
    } else if (parent_verification_while_releasing_pet?.status === "decline") {
        return (
            <Text> Parent verification unsuccessful while releasing pet.</Text>
        );
    } else if (reached_parent_location?.status == "accept") {
        return (
            <Text>
                Captain <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>{" "}
                reached parent location to drop the pet.
            </Text>
        );
    } else if (reached_parent_location?.status == "decline") {
        return (
            <Text>
                Captain <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>{" "}
                has not reached parent location to drop the pet.
            </Text>
        );
    } else if (on_the_way_to_parent_location?.status === "accept") {
        return (
            <>
                <Text style={{ display: "block" }}>
                    Captain{" "}
                    <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>{" "}
                    started his ride to drop the pet.
                </Text>
                <Button
                    type="primary"
                    size="small"
                    onClick={() => showModalForTrack(bookingUniqueId)}
                >
                    View on map
                </Button>
                {bookingIdToTrack.current === bookingUniqueId && (
                    <CommonModal
                        handleCancel={hideModalForTrack}
                        isModalOpen={modalVisible}
                        body={() => (
                            <Tracking
                                showStatus={"showStatus"}
                                bookingIdFromAdmin={bookingUniqueId}
                            />
                        )}
                    />
                )}
            </>
        );
    } else if (on_the_way_to_parent_location?.status === "decline") {
        return (
            <Text>
                Captain <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>{" "}
                has not started his ride to drop the pet.
            </Text>
        );
    }
};

export default PDBCToParentDropPet;
