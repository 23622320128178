import React from 'react'
import useVerificationMethods from './useVerificationMethods';
import { Button, Input, Popconfirm, Row, Space } from 'antd';
import { LeftOutlined, RightOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
const { TextArea } = Input;

/**
 * @function PetVerificationPickupSteps
 * @description Renders the UI for verifying the pet during the pickup process. Includes functionality for verifying the pet, declining verification, and continuing the ride.
 * - Displays different UI elements based on the state of `decline`.
 * - Provides buttons to verify the pet, decline verification, and continue the ride.
 *
 * @param {Object} props - The component's props.
 * @param {Object} props.user - The user object containing details necessary for pet verification and actions.
 * @param {Function} props.setIsModalOpen - Function to open or close the modal.
 * @param {string} props.watchId - The ID to track the verification or order.
 * @param {React.CSSProperties} props.contentStyle - Style object for the content container.
 * 
 * @returns {JSX.Element} The rendered component, including different UI elements based on the `decline` state.
 */


const PetVerificationPickupSteps = ({ user, setIsModalOpen, watchId, contentStyle }) => {
    const {
        handleVerifyPet,
        handlePickuPetContinueRide,
        handleDeclineOrderVerification,
        decline,
        handleBackPress,
        handleDeclineVerification,
        setDecline,
        contextHolder
    } = useVerificationMethods();

    return (
        decline?.showBox ? (
            <Space size={"middle"} direction="vertical" style={contentStyle}>
                {contextHolder}
                <TextArea
                    rows={3}
                    status="error"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                        setDecline((prevDecline) => ({
                            ...prevDecline,
                            reason: e.target.value,
                        }))
                    }
                />
                <Row style={{ justifyContent: "space-between" }}>
                    <Button
                        size="small"
                        icon={<LeftOutlined />}
                        onClick={handleBackPress}
                    >
                        Back
                    </Button>
                    <Popconfirm
                        title="Sure to decline?"
                        onConfirm={() =>
                            handleDeclineVerification(
                                user,
                                "pet_verification_by_captain",
                                setIsModalOpen,
                                "",
                                watchId
                            )
                        }
                    >
                        <Button size="small" iconPosition="end" icon={<RightOutlined />}>
                            Continue
                        </Button>
                    </Popconfirm>
                </Row>
            </Space>
        ) : (
            <Space size={"middle"} direction="vertical" style={contentStyle}>
                <Row style={{ justifyContent: "space-between" }}>
                    <Button
                        size="small"
                        type="link"
                        icon={<CloseOutlined />}
                        disabled={
                            user.current.bookingStatus?.petPickupAndDrop_status
                                ?.boarding_center_to_parent_location_to_pickup_pet
                                ?.pet_verification_by_captain?.status === "accept"
                        }
                        onClick={() => handleDeclineOrderVerification(user)}
                    >
                        Decline
                    </Button>
                    <Button
                        size="small"
                        type="link"
                        icon={<CheckOutlined />}
                        onClick={() =>
                            handleVerifyPet(user, "pet_verification_by_captain")
                        }
                    >
                        Verify pet
                    </Button>
                </Row>
                <div style={{ textAlign: "end" }}>
                    <Button
                        size="small"
                        type="primary"
                        disabled={
                            user.current.bookingStatus?.petPickupAndDrop_status
                                ?.boarding_center_to_parent_location_to_pickup_pet
                                ?.pet_verification_by_captain?.status !== "accept"
                        }
                        onClick={() => handlePickuPetContinueRide(user, setIsModalOpen)}
                    >
                        Pickup pet and continue ride
                    </Button>
                </div>
            </Space>
        )
    )
}

export default PetVerificationPickupSteps
