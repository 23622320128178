// import React from "react";
// import { Card, Typography } from "antd";
// import { CheckCircleOutlined } from "@ant-design/icons";

// const SubscriptionCard = ({ plan, isSelected, handlePlan }) => {
//   const { Title, Text } = Typography;

//   return (
//     <Card
//       hoverable
//       style={{
//         border: isSelected ? "2px solid blue" : "1px solid #d9d9d9",
//         borderRadius: "8px",
//         padding: "16px",
//         marginBottom: "16px",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         width: "100%",
//       }}
//       onClick={() => handlePlan(plan)}
//     >
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         <div>
//           <Title level={4}>{plan.planName}</Title>
//           <Text>{plan.planDescription}</Text>
//         </div>
//         <div>
//           {isSelected && (
//             <CheckCircleOutlined style={{ color: "blue", fontSize: 24 }} />
//           )}
//         </div>
//       </div>
//       <Title level={5} style={{ marginTop: "16px", textAlign: "right" }}>
//         ₹{plan.amount}/month
//       </Title>
//     </Card>
//   );
// };

// export default SubscriptionCard;

import { Typography } from "antd";
import React from "react";
import "../Styles/changePlan.css";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

/**
 * SubscriptionCard component to display a subscription plan.
 *
 * @component
 * @param {Object} props - React props.
 * @param {string} props.selectedPlan - The currently selected plan ID.
 * @param {Function} props.setSelectedPlan - Function to set the selected plan ID.
 * @param {Object} props.plan - The plan data.
 * @param {string} props.plan.planId - The ID of the plan.
 * @param {string} props.plan.planName - The name of the plan.
 * @param {string} props.plan.planDescription - The description of the plan.
 * @param {Array<string>} props.plan.services - Array of service keys included in the plan.
 * @param {number} props.plan.amount - The price of the plan.
 * @returns {JSX.Element} The rendered SubscriptionCard component.
 */

const SubscriptionCard = ({ selectedPlan, setSelectedPlan, plan }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`plan-card ${selectedPlan === plan?.planId ? "selected" : ""}`}
      onClick={() => setSelectedPlan(plan.planId)}
    >
      <div className="plan-info">
        <div className="plan-header">
          <h3>
            {selectedPlan === plan?.planName ? "Current plan:" : ""}{" "}
            {plan?.planName}
          </h3>
        </div>
        <Text
          style={{
            fontSize: "clamp(0.5em, 4vw, 1em)",
            display: "block",
          }}
        >
          {plan?.planDescription}
        </Text>
        <Text style={{ fontSize: "clamp(0.5em, 4vw, 1em)" }}>
          <span style={{ fontWeight: "300" }}>SERVICES:</span>{" "}
          {plan?.services?.map(e => t(e?.service)).join(", ")}
        </Text>
      </div>
      <div className="plan-price">&#8377; {plan?.amount}</div>
      {selectedPlan === plan?.planId && <div className="checkmark">✔</div>}
    </div>
  );
};

export default SubscriptionCard;
