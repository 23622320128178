import { Badge, Button, Col, Image, Row } from "antd";
import React from "react";
import styles from "../Styles/services.module.css";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';

const ServiceCardBody = ({
    filteredServices,
    x,
    selectedServices,
    handleSelect,
    handleCurrentSubscribe,
}) => {
    const { t } = useTranslation();

    return (
        <Row gutter={[32, 32]} style={{ marginTop: "30px" }}>
            {filteredServices?.map((service) => (
                <Col
                    key={uuidv4()}
                    xs={12}
                    sm={8}
                    md={6}
                    lg={6}
                    className={styles.flex}
                >
                    <div
                        style={{ position: "relative", cursor: "pointer" }}
                        onClick={() => {
                            handleSelect(service);
                        }}
                    >
                        {x.includes(service.name) ? (
                            <Badge.Ribbon text={"addon"} style={{ zIndex: 1 }}>
                                <Image
                                    src={service?.image}
                                    alt="Pet boarding"
                                    preview={false}
                                    width={100}
                                />
                            </Badge.Ribbon>
                        ) : (
                            <Image
                                src={service?.image}
                                alt="Pet boarding"
                                preview={false}
                                width={100}
                            />
                        )}

                        {selectedServices?.some(
                            (selectedService) => selectedService.name === service.name
                        ) && (
                                <div>
                                    <div className={`${styles.selected}`}></div>
                                    <div className={`${styles.tick}`}></div>
                                </div>
                            )}
                    </div>
                    <Button
                        className={styles.serviceName}
                        type="primary"
                        onClick={() => {
                            handleSelect(service);
                        }}
                    >
                        {t(service?.key)}
                    </Button>
                </Col>
            ))}
            <Col
                span={24}
                className={styles.button}
            >
                <Button
                    disabled={selectedServices?.length === 0}
                    type="primary"
                    onClick={() => {
                        handleCurrentSubscribe(selectedServices);
                    }}
                >
                    Save & Continue
                </Button>
            </Col>
        </Row>
    );
};

export default ServiceCardBody;
