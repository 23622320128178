import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { dashboardAction } from "../../Dashboard/State/actions";
import { useDispatch, useSelector } from "react-redux";
import { resetDrawer, setDrawerOpen } from "../../state/actions";
import { appAction } from "../state/actions";
import CommonModal from "../../CommonModal/CommonModal";
import LocationDetailsBody from "./LocationDetailsBody";
import { addressAction } from "../../Common/State/adressactions";
import { CloseOutlined } from "@ant-design/icons";
import AddressDrawerBody from "./AddressDrawerBody";
import { navbarAction } from "../../Navbar/State/actions";

const AddressDrawer = ({ setFinalAddress }) => {
  const [info, setInfo] = useState({
    selectedAddress: "",
    address: {},
    showForm: false,
  });
  const { updatedboardingcenter, boardingcenterError, boardingcenter } =
    useSelector((str) => str.addressReducer);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLoadingMaps, isLoadingAddresses, drawerOpen, option, editAddress } = useSelector((str) => str.loadingReducer);
  const { newAddress } = useSelector((str) => str.addressReducer);
  const dispatch = useDispatch();

  const handleOk = (value) => {
    if (value === "actionreload") {
      dispatch(dashboardAction.getActionCenterDetailsEvent());
      setIsModalOpen(false);
    } else {
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => setIsModalOpen(false);

  useEffect(() => {
    if (option === "edit") {
      setInfo({
        showForm: true,
        address: editAddress,
        selectedAddress: editAddress?.fullAddress,
      });
    } else {
      setInfo((prev) => ({ ...prev, showForm: false, address: {} }));
    }
  }, [option]);

  useEffect(() => {
    console.log("ji")
    dispatch(navbarAction.getUserDetails());
    // eslint-disable-next-line
  }, [boardingcenterError, boardingcenter]);

  const onClose = () => {
    dispatch(resetDrawer());
    setInfo((prev) => ({ ...prev, selectedAddress: "" }));
    dispatch(appAction.searchReset());
  };

  // onclick function for the card that is displayed in the drawer
  const handleCard = (value) => {
    if (option === "edit" || option === "add") {
      dispatch(resetDrawer());
    } else if (option === "setpickup") {
      dispatch(setDrawerOpen("setpickup", value));
      dispatch(resetDrawer());
    } else if (option === "setDrop") {
      dispatch(setDrawerOpen("setDrop", value));
      dispatch(resetDrawer());
    } else {
      dispatch(resetDrawer());
      dispatch(addressAction.getBoardingEvent(value?._id));
      setFinalAddress(value?.fullAddress);
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    dispatch(addressAction.getAddressesEvent());
  }, [newAddress]);

  return (
    <Drawer
      width={520}
      placement="left"
      title="Pick Up Address"
      onClose={onClose}
      open={drawerOpen}
      closeIcon={null}
      loading={isLoadingMaps || isLoadingAddresses}
      extra={<CloseOutlined style={{ cursor: "pointer" }} onClick={onClose} />}
    >
      <AddressDrawerBody
        info={info}
        setInfo={setInfo}
        handleCard={handleCard}
      />
      {isModalOpen && (
        <CommonModal
          handleCancel={handleCancel}
          handleOk={handleOk}
          maskClosable={false}
          isModalOpen={isModalOpen}
          keyboard={false}
          width={500}
          body={() => <LocationDetailsBody handleOk={handleOk} />}
        />
      )}
    </Drawer>
  );
};
export default AddressDrawer;
