import React from "react";
import { Space, Typography, Tag, Image, Avatar } from "antd";
import { v4 as uuidv4 } from "uuid";
import TableComponent from "../Common/Components/TableComponent";
import supportersname from "../Assets/Supporters/petsupporters.png";
import { StarFilled } from "@ant-design/icons";
const { Text } = Typography;
const Trainers = () => {
  const supportersColumns = [
    {
      title: () => <Text style={{ fontSize: "1.2em" }}>Supporter photo</Text>,
      dataIndex: "supporterphoto",
      key: "supporterphoto",
      width: 180,
      align: "center",
      render: (e) => <Avatar size={"large"} src={e} shape="circle" />,
    },
    {
      title: () => <Text style={{ fontSize: "1.2em" }}>Supporter Name</Text>,
      dataIndex: "Supportername",
      key: "Supportername",
      width: 150,
      align: "center",
    },
    {
      title: () => <Text style={{ fontSize: "1.2em" }}>Emp ID</Text>,
      dataIndex: "empid",
      key: "empid",
      width: 300,
      align: "center",
    },
    {
      title: () => <Text style={{ fontSize: "1.2em" }}>Contact Number</Text>,
      dataIndex: "contact",
      key: "contact",
      width: 300,
      align: "center",
    },
    {
      title: () => <Text style={{ fontSize: "1.2em" }}>Assigned cages</Text>,
      dataIndex: "assignedcages",
      key: "assignedcages",
      align: "center",
      width: 300,
      render: (_, { assignedcages }) => (
        <Space wrap={true} size={"small"}>
          {assignedcages?.map((assignedcage) => (
            <Tag key={assignedcage}>{assignedcage}</Tag>
          ))}
        </Space>
      ),
    },
    {
      title: () => <Text style={{ fontSize: "1.2em" }}>Email ID</Text>,
      dataIndex: "emailID",
      width: 150,
      key: "emailID",
      align: "center",
    },
    {
      title: () => <Text style={{ fontSize: "1.2em" }}>Status</Text>,
      dataIndex: "status",
      width: 150,
      key: "status",
      align: "center",
    },
    {
      title: () => <Text style={{ fontSize: "1.2em" }}>Rate Supporter</Text>,
      dataIndex: "ratesupporter",
      key: "ratesupporter",
      width: 200,
      align: "center",
      render: (e) => (
        <Text>
          {e}&nbsp;
          <StarFilled style={{ color: "gold" }} />
        </Text>
      ),
    },
  ];
  const supportersdata = [
    {
      key: uuidv4(),
      supporterphoto:
        "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      Supportername: "Deepak",
      empid: "HHT015",
      contact: "56787 34356",
      assignedcages: ["05", "28",],
      emailID: "deepak@gmail.com",
      status: "On appointment",
      ratesupporter: "4.5",
    },
    {
      key: uuidv4(),
      supporterphoto:
        "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      Supportername: "Sasi",
      empid: "HHT023",
      contact: "90989 34356",
      assignedcages: ["01", "28", "06"],
      emailID: "sasi@gmail.com",
      status: "On appointment",
      ratesupporter: "5",
    },
    {
      key: uuidv4(),
      supporterphoto:
        "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      Supportername: "Deepa",
      empid: "HHT015",
      contact: "56787 34356",
      assignedcages: ["05", "06"],
      emailID: "deepa@gmail.com",
      status: "On appointment",
      ratesupporter: "5",
    },
    {
      key: uuidv4(),
      supporterphoto:
        "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      Supportername: "Deepa",
      empid: "HHT015",
      contact: "56787 34356",
      assignedcages: ["05", "28", "06"],
      emailID: "deepa@gmail.com",
      status: "On appointment",
      ratesupporter: "5",
    },
    {
      key: uuidv4(),
      supporterphoto:
        "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      Supportername: "Deepa",
      empid: "HHT015",
      contact: "56787 34356",
      assignedcages: ["05", "28", "06"],
      emailID: "deepa@gmail.com",
      status: "On appointment",
      ratesupporter: "4.5",
    },
    {
        key: uuidv4(),
        supporterphoto:
          "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
        Supportername: "Deepa",
        empid: "HHT015",
        contact: "56787 34356",
        assignedcages: ["05", "28", "06"],
        emailID: "deepa@gmail.com",
        status: "On appointment",
        ratesupporter: "4.5",
      },
  ];
  return (
    <div style={{ position: "relative", top: 15, marginBottom: "2em" }}>
      <TableComponent columns={supportersColumns} data={supportersdata} />
      <div style={{ position: "absolute", top: -15, left: -10 }}>
        <Image width={200} src={supportersname} preview={false} />
      </div>
    </div>
  );
};

export { Trainers };
