import React from "react";
import { Popconfirm, Tag } from "antd";
import CommonModal from "../../CommonModal/CommonModal";
import VerificationStepsForCaptain from "./VerificationStepsForCaptain";

// Function to handle handing over the pet to the parent.
export const HandoverPetToParent = ({ booking, handleHandOverPetToParent }) => (
  <Popconfirm
    onConfirm={() =>
      handleHandOverPetToParent(
        booking?.bookingUniqueId,
        booking?.bookingId
      )
    }
  >
    <Tag style={{ cursor: "pointer" }}>Handover pet to parent?</Tag>
  </Popconfirm>
);

// Function to handle when captain reaches parent location
export const ReachedParentLocation = ({
  booking,
  handleVerify,
  isModalOpen,
  setIsModalOpen,
  user,
  typeofroute,
  watchId,
  // showMap,
  handleCancel,
  type
}) => (
  <>
    <Popconfirm
      okText={"Yes"}
      onConfirm={() =>
        handleVerify(
          booking?.bookingUniqueId,
          booking?.bookingId,
          booking,
          type
        )
      }
    >
      <Tag style={{ cursor: "pointer" }}>Reached parent location?</Tag>
      {user.current?.bookingUniqueId === booking?.bookingUniqueId && (
        <CommonModal
          maskClosable={false}
          open={isModalOpen}
          onOk={handleCancel}
          handleCancel={handleCancel}
          isModalOpen={isModalOpen}
          closable={false}
          body={() => (
            <VerificationStepsForCaptain
              typeofroute={typeofroute}
              user={user}
              setIsModalOpen={setIsModalOpen}
              watchId={watchId}
            />
          )}
        />
      )}
      {/* {showMap.current && (
        <div style={{ width: "100%", height: "100%", zIndex: 1000 }}>
          <iframe
            src={`https://embed.mappls.com/direction?places=28.5503,77.2502;28.5507,77.2689&token=${process.env.REACT_APP_API_KEY}`}
          ></iframe>
        </div>
      )} */}
    </Popconfirm>
  </>
);

// Function to handle when captain picks pet from boarding center
export const PickupPetFromBoardingCenter = ({
  booking,
  handlePickPetFromBoardingCenter,
}) => (
  <Popconfirm
    onConfirm={() =>
      handlePickPetFromBoardingCenter(
        booking?.bookingUniqueId,
        booking?.bookingId
      )
    }
  >
    <Tag style={{ cursor: "pointer" }}>Pickup pet from boarding center</Tag>
  </Popconfirm>
);

// Function to handle when captain starts ride.
export const StartRide = ({ booking, handleStart, type }) => (
  <Tag
    style={{ cursor: "pointer" }}
    onClick={() =>
      handleStart(booking?.bookingUniqueId, booking?.bookingId, type)
    }
  >
    Start ride to {type} pet
  </Tag>
);