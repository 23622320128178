import { startLoading, startLoadingFinalBookAppointDetails, startLoadingSlots, stopLoading, stopLoadingFinalBookAppointDetails, stopLoadingSlots } from "../../state/actions";
import { bookappointmentapi } from "../utils/bookappointmentapi";
import { bookAppointmentConstants } from "./action-types";


const udpdatedetailsError = (err) => {
  return {
    type: bookAppointmentConstants.UPDATE_DETAILS_FAILURE,
    error: err,
  };
};

const udpdatedetailsSuccess = (data) => {
  return {
    type: bookAppointmentConstants.UPDATE_DETAILS_SUCCESS,
    data: data,
  };
};

const udpdatedetailsEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoading());
    bookappointmentapi
      .updateDetailsById(data)
      .then((response) => {
        dispatch(udpdatedetailsSuccess(response));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(udpdatedetailsError(err?.response?.data));
        dispatch(stopLoading());
      });
  };
};

const updateSelectedServicesError = (err) => {
  return {
    type: bookAppointmentConstants.UPDATE_SELECT_SERVICES_FAILURE,
    error: err,
  };
};

const updateSelectedServicesSuccess = (data) => {
  return {
    type: bookAppointmentConstants.UPDATE_SELECT_SERVICES_SUCCESS,
    data: data,
  };
};

const updateSelectedServicesEvent = (data, id) => {
  return (dispatch) => {
    dispatch(startLoading());
    bookappointmentapi
      .updateSelectServices(data, id)
      .then((response) => {
        dispatch(updateSelectedServicesSuccess(response?.data?.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(updateSelectedServicesError(err));
        dispatch(stopLoading());
      });
  };
};

const getBookAppointmentdetailsError = (err) => {
  return {
    type: bookAppointmentConstants.GET_BOOKING_DETAILS_ERROR,
    error: err,
  };
};

const getBookAppointmentdetailsSuccess = (data) => {
  return {
    type: bookAppointmentConstants.GET_BOOKING_DETAILS_SUCCESS,
    data: data,
  };
};

const getBookAppointmentdetailsEvent = (id) => {
  return (dispatch) => {
    dispatch(startLoadingFinalBookAppointDetails());
    bookappointmentapi
      .getBookAppointmentDetails(id)
      .then((response) => {
        dispatch(getBookAppointmentdetailsSuccess(response?.data?.info));
        dispatch(stopLoadingFinalBookAppointDetails());
      })
      .catch((err) => {
        dispatch(getBookAppointmentdetailsError(err));
        dispatch(stopLoadingFinalBookAppointDetails());
      });
  };
};

const getBookAppointmentdetailsByIdError = (err) => {
  return {
    type: bookAppointmentConstants.GET_BOOKING_DETAILS_BY_ID_ERROR,
    error: err,
  };
};

const getBookAppointmentdetailsByIdSuccess = (data) => {
  return {
    type: bookAppointmentConstants.GET_BOOKING_DETAILS_BY_ID_SUCCESS,
    data: data,
  };
};

const getBookAppointmentdetailsByIdEvent = (id) => {
  return (dispatch) => {
    dispatch(startLoading());
    bookappointmentapi
      .bookAppointmentDetailsById(id)
      .then((response) => {
        dispatch(getBookAppointmentdetailsByIdSuccess(response?.data?.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(getBookAppointmentdetailsByIdError(err));
        dispatch(stopLoading());
      });
  };
};

const getSlotDetailsError = (err) => {
  return {
    type: bookAppointmentConstants.GET_SLOT_DETAILS_ERROR,
    error: err,
  };
};

const getSlotDetailsSuccess = (data) => {
  return {
    type: bookAppointmentConstants.GET_SLOT_DETAILS_SUCCESS,
    data: data,
  };
};

const getSlotDetailsEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoadingSlots());
    bookappointmentapi.getSlotDetailsByMonth(data)
      .then((response) => {
        dispatch(getSlotDetailsSuccess(response?.data?.info));
        dispatch(stopLoadingSlots());
      })
      .catch((err) => {
        dispatch(getSlotDetailsError(err));
        dispatch(stopLoadingSlots());
      });
  };
};

const paymentError = (err) => {
  return {
    type: bookAppointmentConstants.PAYMENT_FAILURE,
    error: err,
  };
};

const paymentSuccess = (data) => {
  return {
    type: bookAppointmentConstants.PAYMENT_SUCCESS,
    data: data,
  };
};

const paymentEvent = (data) => {
  return (dispatch) => {
    bookappointmentapi
      .makePayment(data)
      .then((response) => {
        dispatch(paymentSuccess(response));
      })
      .catch((err) => {
        dispatch(paymentError(err));
      });
  };
};

const paymentBookingError = (err) => {
  return {
    type: bookAppointmentConstants.PAYMENT_FAILURE_BOOKING_ID,
    error: err,
  };
};

const paymentBookingSuccess = (data) => {
  return {
    type: bookAppointmentConstants.PAYMENT_SUCCESS_BOOKING_ID,
    data: data,
  };
};

const paymentBookingEvent = (id) => {
  return (dispatch) => {
    dispatch(startLoading())
    bookappointmentapi
      .makePaymentBooking(id)
      .then((response) => {
        dispatch(paymentBookingSuccess(response));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(paymentBookingError(err));
        dispatch(stopLoading());
      });
  };
};

const bookappointmentByIDresetEvent = () => {
  return {
    type: bookAppointmentConstants.RESET_DETAILSBYID,
  };
};

const updatedDetailsresetEvent = () => {
  return {
    type: bookAppointmentConstants.RESET_UPDATE_DETAILS
  }
}

export const bookAppointmentAction = {
  udpdatedetailsEvent,
  getBookAppointmentdetailsEvent,
  getBookAppointmentdetailsByIdEvent,
  paymentEvent,
  updateSelectedServicesEvent,
  bookappointmentByIDresetEvent,
  getSlotDetailsEvent,
  paymentBookingEvent,
  updatedDetailsresetEvent
};
