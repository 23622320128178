import axios from "axios";
const apiURL = process.env.REACT_APP_BACKEND_URL;


// cancel captain booking
const cancelCaptainBooking = (captainId, bookingId, data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.put(`${apiURL}/cancelCaptainsBooking/${captainId}/${bookingId}`, data, config);
}

// send otp to the user for verifying parent and order
const sendOtpForVerification = (userId, type, captainId) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    let body;
    if (type === "send_otp_parent_pickup" || type === "send_otp_parent_drop") {
        body = {
            type,
            userId,
            adminId: captainId
        }
    } else {
        body = {
            type,
            userId,
            captainId
        }
    }
    return axios.post(`${apiURL}/send-otp`, body, config)
}

// verify otp for the parent and order
const verifyOtpVerification = (userId, bookingId, userOTP, type) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    const body = {
        type,
        userId,
        bookingId,
        userOTP
    }
    return axios.put(`${apiURL}/verify-otp`, body, config)
}

export const captainApi = {
    cancelCaptainBooking,
    sendOtpForVerification,
    verifyOtpVerification
};