import { Button, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import useWarnings from "../../Common/Components/useWarnings";
import { crudPlansApi } from "../utils/api";

const CreateStateForm = () => {
  const [form] = Form.useForm();
  const { success, error, contextHolder } = useWarnings();
  const [laoding, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const responseCreateState = await crudPlansApi.createState(values);
      if (responseCreateState?.data?.info?.code)
        success("State created successfully");
      form.resetFields();
      setLoading(false);
    } catch (err) {
      error(err?.response?.data?.message);
      setLoading(false);
    }
  };
  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      {contextHolder}
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="State"
            name={"stateName"}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Email Id of the state admin"
            name={"email"}
            rules={[{ required: true }]}
          >
            <Input type="email" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" loading={laoding} htmlType="submit" size="small">
          Create a state
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateStateForm;
