import { Col, Input, Row, Spin, Button, Form } from 'antd';
import React from 'react'
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import styles from "../Styles/signup.module.css";

/**
 * SignupForm component provides a form for users to enter their details and sign up for an account.
 * @param {Object} props - The props for the SignupForm component.
 * @param {function} props.handleSignup - The function to handle form submission, passed from the parent component.
 * @param {Object} props.form - The Antd Form instance used to control the form's state and validation.
 * */

const SignupForm = ({ handleSignup, form }) => {
    const { isLoading } = useSelector(str => str.loadingReducer);
    return (
        <Form layout="vertical" form={form} onFinish={handleSignup}>
            <Row gutter={[16, 0]}>
                <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item
                        required
                        name={"firstName"}
                        rules={[
                            {
                                required: true,
                                message: "Please input your firstname!",
                            },
                        ]}
                    >
                        <Input
                            placeholder="First Name"
                            className={styles.formInput}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item
                        required
                        name={"lastName"}
                        rules={[
                            {
                                required: true,
                                message: "Please input your lastname!",
                            },
                        ]}
                    >
                        <Input placeholder="Last Name" className={styles.formInput} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                required
                name={"email"}
                rules={[
                    {
                        required: true,
                        message: "Please input your email!",
                    },
                    {
                        type: "email",
                        message: "Please enter valid email!",
                    },
                ]}
            >
                <Input placeholder="Email Address" className={styles.formInput} />
            </Form.Item>
            <Row gutter={[16, 0]}>
                <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item
                        required
                        name={"password"}
                        rules={[
                            {
                                required: true,
                                message: "Please input your password!",
                            },
                        ]}
                    >
                        <Input.Password
                            placeholder="Password"
                            className={styles.formInput}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item
                        required
                        name={"cpassword"}
                        dependencies={["password"]}
                        rules={[
                            {
                                required: true,
                                message: "Please input your password!",
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("password") === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject("Password do not match!");
                                },
                            }),
                        ]}
                    >
                        <Input
                            placeholder="Confirm Password"
                            className={styles.formInput}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="phoneNumber">
                <PhoneInput
                    countryCodeEditable={false}
                    country="in"
                    inputStyle={{
                        width: "100%",
                        padding: "8px 11px 8px 48px",
                        border: "0.5px solid rgba(0, 0, 0, 0.30)",
                        height: "45.45px",
                        borderRadius: "2px",
                    }}
                    buttonStyle={{
                        border: "0.5px solid rgba(0, 0, 0, 0.30)",
                    }}
                />
            </Form.Item>
            <Form.Item>
                <Button
                    htmlType="submit"
                    style={{
                        width: "100%",
                        height: "45px",
                        backgroundColor: "#3A60A1",
                        color: "white",
                        fontSize: "18px",
                    }}
                    loading={isLoading}
                >
                    Sign Up
                </Button>
            </Form.Item>
        </Form>
    )
}

export default SignupForm
