import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, Popconfirm, Switch, Typography } from "antd";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { paymentLinksAction } from "../State/actions";
import { useState } from "react";
import CommonModal from "../../CommonModal/CommonModal";
const { Text } = Typography;

const PaymentLinksColumns = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const formatDate = (timeStamp) =>
        dayjs.unix(timeStamp).format("MMM D, YYYY h:mm A");

    const [modalOpen, setModalOpen] = useState({
        edit: false,
        id: "",
    });

    const handleCancelLink = async (paymentId) => {
        dispatch(paymentLinksAction.cancelPaymentLinkEvent(paymentId));
    };

    const handleFinish = (values, paymentId) => {
        dispatch(paymentLinksAction.updatePaymentLinkEvent(paymentId, values));
        setModalOpen({ ...modalOpen, edit: false });
    }

    const handleSwitch = (e, id) => {
        const data = {
            reminder_enable: e,
        };
        dispatch(paymentLinksAction.updatePaymentLinkEvent(id, data));
    };

    return [
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            width: 150,
            render: (_, { id, reminder_enable }) => {
                return (
                    <>
                        <EditOutlined
                            style={{ fontSize: "1.2em", marginRight: "1.2em" }}
                            onClick={() => setModalOpen({ ...modalOpen, edit: true, id: id })}
                        />
                        <Switch
                            checked={reminder_enable}
                            size="small"
                            style={{ marginRight: "1.2em" }}
                            onClick={(e) => handleSwitch(e, id)}
                            checkedChildren="Remind"
                        />
                        <Popconfirm
                            title="Are you sure to cancel this payment link"
                            okText="Yes"
                            onConfirm={() => handleCancelLink(id)}
                        >
                            <DeleteOutlined style={{ fontSize: "1.2em", color: "red" }} />
                        </Popconfirm>
                        {modalOpen.edit && id === modalOpen.id && (
                            <CommonModal
                                body={() => (
                                    <Form form={form} layout="vertical" onFinish={(values) => handleFinish(values, id)}>
                                        <Form.Item label="Enter expiration in minutes" name={"expire_by"} rules={[{ required: true }]}>
                                            <InputNumber
                                                suffix="minutes"
                                                style={{
                                                    width: "100%",
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                style={{ marginTop: "1.2em" }}
                                                size="small"
                                                htmlType="submit"
                                            >
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                )}
                                handleCancel={() => setModalOpen({ ...modalOpen, edit: false })}
                                handleOk={() => setModalOpen({ ...modalOpen, edit: false })}
                                isModalOpen={modalOpen.edit}
                            />
                        )}
                    </>
                );
            },
        },
        {
            title: "Payment ID",
            dataIndex: "id",
            key: "paymentId",
            width: 200,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: 200,
            render: (_, { status, cancelled_at }) => {
                return status === "cancelled" ? (
                    <Text>
                        {status} on {formatDate(cancelled_at)}
                    </Text>
                ) : (
                    <Text>{status}</Text>
                );
            },
        },
        {
            title: "Dates",
            dataIndex: "createdAt",
            key: "createdAt",
            width: 200,
            render: (_, { created_at, expire_by }) => (
                <>
                    <Text style={{ display: "block" }}>
                        Created At : {formatDate(created_at)}
                    </Text>
                    <Text style={{ display: "block" }}>
                        Expiry Date : {formatDate(expire_by)}
                    </Text>
                </>
            ),
        },
        {
            title: "Customer Details",
            dataIndex: "customer",
            key: "customer",
            width: 200,
            render: (_, { customer }) => (
                <>
                    <Text style={{ display: "block" }}>{customer?.name}</Text>
                    <Text style={{ display: "block" }}>{customer?.contact}</Text>
                    <Text style={{ display: "block" }}>{customer?.email}</Text>
                    <Text style={{ display: "block" }}>
                        BookingId: {customer?.bookingId || "--"}
                    </Text>
                </>
            ),
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            width: 200,
        },
        {
            title: "Amount to be paid",
            dataIndex: "amount",
            key: "amount",
            width: 100,
            render: (_, { amount, currency }) => (
                <Text>
                    {currency} {amount / 100}
                </Text>
            ),
        },
        {
            title: "Amount paid",
            dataIndex: "amountPaid",
            key: "amountPaid",
            width: 100,
            render: (_, { amount_paid, currency }) => (
                <Text>
                    {currency} {amount_paid / 100}
                </Text>
            ),
        },
    ];
};

export default PaymentLinksColumns;
