
import { startLoadingMaps, stopLoadingMaps } from "../../state/actions";
import { mapsApi } from "../utils/routes";
import { appConstants } from "./action-types";

const mapTokenError = (err) => {
  return {
    type: appConstants.MAP_TOKEN_ERROR,
    tokenError: err,
  };
};

const mapTokenSuccess = (data) => {
  return {
    type: appConstants.MAP_TOKEN_SUCCESS,
    tokenData: data,
  };
};

const mapTokenEvent = () => {
  return (dispatch) => {
    dispatch(startLoadingMaps())
    mapsApi
      .getMapplsToken()
      .then((response) => {
        dispatch(mapTokenSuccess(response.data));
        dispatch(stopLoadingMaps());
      })
      .catch((err) => {
        dispatch(mapTokenError(err));
        dispatch(stopLoadingMaps());
      });
  };
};

const mapValuesError = (err) => {
  return {
    type: appConstants.MAP_VALUES_ERROR,
    valuesError: err,
  };
};

const mapValuesSuccess = (data) => {
  return {
    type: appConstants.MAP_VALUES_SUCCESS,
    data: data,
  };
};

const mapValuesEvent = (token, value) => {
  return (dispatch) => {
    dispatch(startLoadingMaps());
    mapsApi
      .getPlaces(token, value)
      .then((response) => {
        dispatch(mapValuesSuccess(response?.data?.copResults));
        dispatch(stopLoadingMaps());
      })
      .catch((err) => {
        dispatch(mapValuesError(err));
        dispatch(stopLoadingMaps());
      });
  };
};

const searchReset = () => {
  return {
    type: appConstants.SEARCH_RESET,
  };
};

export const appAction = {
  mapTokenEvent,
  mapValuesEvent,
  searchReset,
};