import Chart from "chart.js/auto";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "../Styles/dashboard.module.css";
import serviceHistoryName from "../../Assets/Dashboard/Service history.png";
import { Image, DatePicker, Card, theme } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const ServiceHistoryBarChart = () => {
  const [mode, setMode] = useState("Yearly");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState("Jan");
  const [service, setSelectedService] = useState("boarding");
  const [serviceData, setServiceData] = useState([]);
  const [xValues, setXValues] = useState([]);
  const [selectedMonthlyYear, setSelectedMonthlyYear] = useState(
    new Date().getFullYear()
  );
  const chartRef = useRef(null);
  const { useToken } = theme;
  const { token } = useToken()

  useEffect(() => {
    if (mode === "Yearly") {
      setXValues([
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]);
    } else {
      const daysInMonth = {
        Jan: 31,
        Feb:
          selectedMonthlyYear % 4 === 0 &&
          (selectedMonthlyYear % 100 !== 0 || selectedMonthlyYear % 400 === 0)
            ? 29
            : 28,
        Mar: 31,
        Apr: 30,
        May: 31,
        Jun: 30,
        Jul: 31,
        Aug: 31,
        Sep: 30,
        Oct: 31,
        Nov: 30,
        Dec: 31,
      };
      setXValues(
        Array.from({ length: daysInMonth[selectedMonth] }, (_, i) => i + 1)
      );
    }
  }, [mode, selectedMonth, selectedMonthlyYear]);

  const configuration = useMemo(
    () => ({
      type: "bar",
      data: {
        labels: xValues,
        datasets: [
          {
            fill: true,
            label: "Services",
            tension: 0,
            // backgroundColor: token.Primary,
            // borderColor: "rgba(0,0,255,0.1)",
            data: serviceData,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    }),
    [xValues, serviceData]
  );

  useEffect(() => {
    // Replace this with actual API call to fetch service data
    const fetchServiceData = async () => {
      const mockServiceData = Array.from({ length: xValues.length }, () =>
        Math.floor(Math.random() * 10)
      );
      setServiceData(mockServiceData);
    };
    fetchServiceData();
  }, [mode, selectedMonth, selectedMonthlyYear, xValues.length, service]);

  useEffect(() => {
    if (chartRef.current !== null) {
      chartRef.current.destroy();
    }
    const ctx = document.getElementById("myChart").getContext("2d");
    chartRef.current = new Chart(ctx, configuration);
  }, [configuration]);

  const handleModeChange = (event) => {
    setMode(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleMonthlyYearChange = (event) => {
    setSelectedMonthlyYear(parseInt(event.target.value));
  };
  const handleServiceChange = (e) => {
    setSelectedService(e.target.value);
  };

  return (
    <Card hoverable className={styles.entireLinePage}>
      <div
        style={{
          justifyContent: "flex-end",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div>
          <select
            className={styles.select}
            id="mode"
            value={service}
            onChange={handleServiceChange}
          >
            <option value="baording">Boarding</option>
            <option value="sitting">Sitting</option>
            <option value="fashion">Fashion</option>
            <option value="daycare">Day care</option>
            <option value="walking">Walking</option>
            <option value="pickupdrop">Pick up & drop</option>
          </select>{" "}
          &nbsp;
        </div>
        <div>
          <select
            className={styles.select}
            id="mode"
            value={mode}
            onChange={handleModeChange}
          >
            <option value="Yearly">Yearly</option>
            <option value="Monthly">Monthly</option>
          </select>{" "}
          &nbsp;
        </div>
        {mode === "Yearly" ? (
          <div>
            <select
              className={styles.select}
              id="year"
              value={selectedYear}
              onChange={handleYearChange}
            >
              {Array.from(
                { length: 5 },
                (_, i) => new Date().getFullYear() - i
              ).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            &nbsp;
          </div>
        ) : (
          // <div>
          //     <select className={styles.select} id="monthlyYear" value={selectedMonthlyYear} onChange={handleMonthlyYearChange}>
          //         {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(year => (
          //             <option key={year} value={year}>{year}</option>
          //         ))}
          //     </select>&nbsp;
          //     <select className={styles.select} id="month" value={selectedMonth} onChange={handleMonthChange}>
          //         {["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].map(month => (
          //             <option key={month} value={month}>{month}</option>
          //         ))}
          //     </select>
          // </div>
          <DatePicker
            onChange={(e) => console.log(e, "date")}
            size={"small"}
            picker="month"
          />
        )}
      </div>
      <canvas id="myChart" />
      <div className={styles.serviceHistoryName}>
        <Image
          src={serviceHistoryName}
          width={150}
          alt="Service History"
          preview={false}
        />
      </div>
    </Card>
  );
};

export default ServiceHistoryBarChart;
