import axios from "axios";


const apiURL = process.env.REACT_APP_BACKEND_URL

// search
const search = (key) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
            // "platform": "Web"
        }
    };
    return axios.get(`${apiURL}/search?search=${key}&type=Web`, config);
}

export const searchApi = {
    search,
};