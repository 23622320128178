import { Card, Col, Row, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import { services } from "../../Common/Components/Services";

const { Title } = Typography;
/**
 * Component to display service information for an admin.
 * Shows details such as service name, check-in and check-out dates and times, and booking duration.
 * 
 * @param {Object} props - Component properties.
 * @param {Object} props.service - The service object containing details to display.
 * @param {string} props.service.serviceName - The key for the service name.
 * @param {Object} props.service.slots - Object containing check-in and check-out date and time.
 * @param {string} props.service.slots.checkInDateTime - The check-in date and time.
 * @param {string} props.service.slots.checkOutDateTime - The check-out date and time.
 * @param {string} [props.service.bookingDuration] - The booking duration.
 * @returns {JSX.Element} The rendered component.
 */
const ServiceInfoForAdmin = ({ service }) => {
  return (
    <Card style={{ borderRadius: 0 }} hoverable>
      <Title style={{ fontSize: "18px", marginTop: 0, marginBottom: "20px" }}>
        {services.find((e) => e.key === service.serviceName).name}
      </Title>
      <Row wrap={true}>
        <Col span={10} style={{ fontWeight: 500 }}>
          Check In Date
        </Col>
        <Col span={2} style={{ fontWeight: 500 }}>
          :
        </Col>
        <Col span={12}>
          {dayjs(service.slots?.checkInDateTime).format("MMMM D, YYYY")}
        </Col>
        <Col span={10} style={{ fontWeight: 500 }}>
          Check In Time
        </Col>
        <Col span={2} style={{ fontWeight: 500 }}>
          :
        </Col>
        <Col span={12}>
          {dayjs(service.slots?.checkInDateTime).format("h:mm A")}
        </Col>
        <Col span={10} style={{ fontWeight: 500 }}>
          Check Out Date
        </Col>
        <Col span={2} style={{ fontWeight: 500 }}>
          :
        </Col>
        <Col span={12}>
          {dayjs(service.slots?.checkOutDateTime).format("MMMM D, YYYY")}
        </Col>
        <Col span={10} style={{ fontWeight: 500 }}>
          Check Out Time{" "}
        </Col>
        <Col span={2} style={{ fontWeight: 500 }}>
          :
        </Col>
        <Col span={12}>
          {dayjs(service.slots?.checkOutDateTime).format("h:mm A")}
        </Col>
        <Col span={10} style={{ fontWeight: 500 }}>
          Booking Duration
        </Col>
        <Col span={2} style={{ fontWeight: 500 }}>
          :
        </Col>
        <Col span={12}>
          {service?.bookingDuration ? service?.bookingDuration : "--NA--"}
        </Col>
      </Row>
    </Card>
  );
};

export default ServiceInfoForAdmin;
