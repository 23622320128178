import { Button, theme, Typography } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { adminAction } from "../state/actions";
import { useDispatch, useSelector } from "react-redux";
import useAcceptDecline from "./useAcceptDecline";
import KennelsScreen from "./KennelsScreen";
import CommonModal from "../../CommonModal/CommonModal";
import Tracking from "../../Common/Components/Tracking";
const { Text } = Typography;
/**
 * PDBCTOPickPet component is responsible for rendering various stages of a pet's journey 
 * from a boarding center to a parent's location, including assigning kennels, verifying pets, 
 * and tracking the pet's journey. It also handles modal dialogs for kennel assignment and tracking.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.boarding_center_to_parent_location_to_pickup_pet - Contains status information for various stages of the pet's journey.
 * @param {string} props.bookingId - The booking ID of the current booking.
 * @param {string} props.bookingUniqueId - The unique booking ID for tracking purposes.
 * @param {Object} props.bookingStatus - The status of the booking, including pet pickup and handover.
 * @param {string} props.kennelId - The ID of the kennel assigned to the pet.
 * @param {string} props.captainId - The ID of the captain responsible for the pet's transport.
 * @param {Object} props.petBoarding_status - Contains status information specific to pet boarding actions.
 * @param {Function} props.showModalForTrack - Function to display the modal for tracking the pet.
 * @param {Function} props.hideModalForTrack - Function to hide the tracking modal.
 * @param {Object} props.bookingIdToTrack - Reference to the booking ID currently being tracked.
 * @param {boolean} props.modalVisible - Boolean indicating whether the tracking modal is visible.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const PDBCTOPickPet = ({
    boarding_center_to_parent_location_to_pickup_pet,
    bookingId,
    bookingUniqueId,
    bookingStatus,
    kennelId,
    captainId,
    petBoarding_status,
    showModalForTrack,
    hideModalForTrack,
    bookingIdToTrack,
    modalVisible
}) => {
    const navigate = useNavigate();
    const { useToken } = theme;
    const { token } = useToken();
    const dispatch = useDispatch();
    const { userDetails } = useSelector(str => str.navbarReducer);
    const {
        handleVerifyPet,
        handleVerifyPetAssignKennel,
        handleOkAssignKennel,
        handleCancelAssignKennel,
        isModalOpenAssignKennel,
        kennel,
    } = useAcceptDecline();

    let {
        accepts_ride,
        on_the_way_to_parent_location,
        reached_parent_location,
        order_verification,
        parent_verification,
        pet_verification_by_captain,
        pet_on_the_way_to_boarding_center,
        pet_reached_boarding_center,
        pet_handed_over_to_the_boarding_center,
    } = boarding_center_to_parent_location_to_pickup_pet;

        if (
            bookingStatus?.petBoarding_status?.pet_picked_up_from_boarding_center
                ?.status === "accept"
        ) {
            return (
                <Text>
                    Captain <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>{" "}
                    picked up pet from boarding center.
                </Text>
            );
        } else if (
            bookingStatus?.petBoarding_status?.pet_picked_up_from_boarding_center
                ?.status === "decline"
        ) {
            return (
                <Text>
                    Captain <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>{" "}
                    did not pick up pet from boarding center.
                </Text>
            );
        } else if (petBoarding_status?.pet_assigned_a_kennel?.status === "accept") {
            return (
                <>
                    <Text style={{ color: token.Green }}>
                        Kennel {kennelId} has been assigned.
                    </Text>
                    <Button
                        type="link"
                        onClick={() =>
                            dispatch(
                                adminAction.removeKennelEvent(
                                    userDetails.boardCenterUniqueId,
                                    bookingUniqueId,
                                    { reason: "repair in kennel" }
                                )
                            )
                        }
                    >
                        Remove assigned kennel.
                    </Button>
                    <Button
                        type="link"
                        onClick={() =>
                            dispatch(adminAction.releaseKennelEvent(bookingUniqueId))
                        }
                    >
                        Release pet.
                    </Button>
                </>
            );
        } else if (
            petBoarding_status?.pet_assigned_a_kennel?.status === "decline"
        ) {
            return (
                <Text style={{ color: token.Green }}>
                    Kennel {kennelId} has not been assigned.
                </Text>
            );
        } else if (petBoarding_status?.pet_assigned_a_kennel?.status === "none") {
            return (
                <>
                    <Text
                        style={{ color: token.Red, cursor: "pointer" }}
                        onClick={() => handleVerifyPetAssignKennel(bookingUniqueId)}
                    >
                        Kennel {kennelId} has been removed due to{" "}
                        {petBoarding_status?.pet_assigned_a_kennel?.reason}. Reassign
                        another kennel
                    </Text>
                    {kennel.current === bookingUniqueId && (
                        <CommonModal
                            body={() => (
                                <KennelsScreen
                                    handleOkAssignKennel={handleOkAssignKennel}
                                    bookingId={kennel.current}
                                />
                            )}
                            handleOk={handleOkAssignKennel}
                            handleCancel={handleCancelAssignKennel}
                            isModalOpen={isModalOpenAssignKennel}
                        />
                    )}
                </>
            );
        } else if (
            petBoarding_status?.pet_verification_by_admin?.status === "accept"
        ) {
            return (
                <>
                    <Button
                        type="link"
                        onClick={() => handleVerifyPetAssignKennel(bookingUniqueId)}
                    >
                        Verified pet. Please assign a kennel.
                    </Button>
                    {kennel.current === bookingUniqueId && (
                        <CommonModal
                            body={() => (
                                <KennelsScreen
                                    handleOkAssignKennel={handleOkAssignKennel}
                                    bookingId={kennel.current}
                                />
                            )}
                            handleOk={handleOkAssignKennel}
                            handleCancel={handleCancelAssignKennel}
                            isModalOpen={isModalOpenAssignKennel}
                        />
                    )}
                </>
            );
        } else if (
            petBoarding_status?.pet_verification_by_admin?.status === "decline"
        ) {
            return (
                <Text style={{ color: token.Green }}>
                    Pet verification unsuccessful!
                </Text>
            );
        } else if (pet_handed_over_to_the_boarding_center?.status === "accept") {
            return (
                <>
                    <Text>
                        Pet handed over to the boardhead by captain{" "}
                        <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>.
                    </Text>
                    <Button
                        style={{ border: "none" }}
                        type="link"
                        onClick={() => handleVerifyPet(bookingId, bookingUniqueId)}
                    >
                        Verify pet
                    </Button>
                </>
            );
        } else if (pet_handed_over_to_the_boarding_center?.status === "decline") {
            return (
                <Text style={{ color: token.Red }}>
                    Pet not handed over to the boardhead by captain{" "}
                    <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>.
                </Text>
            );
        } else if (pet_reached_boarding_center?.status === "accept") {
            return <Text>Pet just reached boarding center</Text>;
        } else if (pet_reached_boarding_center?.status === "decline") {
            return <Text>Pet did not reach boarding center</Text>;
        } else if (pet_on_the_way_to_boarding_center?.status === "accept") {
            return (
                <>
                    <Text style={{ display: "block" }}>
                        Pet picked up and captain{" "}
                        <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>{" "}
                        started his ride to boarding center.
                    </Text>
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => showModalForTrack(bookingUniqueId)}
                    >
                        View on map
                    </Button>
                    {bookingIdToTrack.current === bookingUniqueId && (
                        <CommonModal
                            handleCancel={hideModalForTrack}
                            isModalOpen={modalVisible}
                            body={() => (
                                <Tracking
                                    showStatus={"showStatus"}
                                    bookingIdFromAdmin={bookingUniqueId}
                                />
                            )}
                        />
                    )}
                </>
            );
        } else if (pet_on_the_way_to_boarding_center?.status === "decline") {
            return (
                <Text>
                    Pet did not pick up by captain{" "}
                    <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>
                </Text>
            );
        } else if (pet_verification_by_captain?.status === "accept") {
            return <Text>Pet verification successfull!!</Text>;
        } else if (pet_verification_by_captain?.status === "decline") {
            return (
                <Text style={{ color: token.Red }}>
                    Pet verification unsuccessfull due to{" "}
                    {pet_verification_by_captain?.reason}
                </Text>
            );
        } else if (parent_verification?.status === "accept") {
            return <Text>Parent verification successfull!!</Text>;
        } else if (parent_verification?.status === "decline") {
            return (
                <Text style={{ color: token.Red }}>
                    Parent verification unsuccessfull due to {parent_verification?.reason}
                </Text>
            );
        } else if (order_verification?.status === "accept") {
            return <Text>Order verification successful!!</Text>;
        } else if (order_verification?.status === "decline") {
            return (
                <Text style={{ color: token.Red }}>
                    Order verification unsuccessful due to {order_verification?.reason}
                </Text>
            );
        } else if (reached_parent_location?.status === "accept") {
            return (
                <Text>
                    Captain <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>{" "}
                    reached parent location
                </Text>
            );
        } else if (on_the_way_to_parent_location?.status === "accept") {
            return (
                <>
                    <Text style={{ display: "block" }}>
                        Captain{" "}
                        <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link> is on
                        the way to parent location
                    </Text>
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => showModalForTrack(bookingUniqueId)}
                    >
                        View on map
                    </Button>
                    {bookingIdToTrack.current === bookingUniqueId && (
                        <CommonModal
                            handleCancel={hideModalForTrack}
                            isModalOpen={modalVisible}
                            body={() => (
                                <Tracking
                                    showStatus={"showStatus"}
                                    bookingIdFromAdmin={bookingUniqueId}
                                />
                            )}
                        />
                    )}
                </>
            );
        } else if (accepts_ride?.status === "accept") {
            return (
                <Text>
                    Captain <Link to={`/captainsBookings/${captainId}`}>{captainId}</Link>{" "}
                    accepted ride
                </Text>
            );
        }
};

export default PDBCTOPickPet;
