import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { helpAction } from "../State/actions";
import Spinner from "../../Spinner/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { ReactQuillComponentRender } from "./ReactQuillComponentRender";

/**
 * A form component for adding or editing help questions.
 * 
 * @description- Displays a form for creating or updating a question, including fields for title, key, keywords, platforms,
 * roles, and description. Provides a preview of the description using the `ReactQuillComponentRender` component.
 * 
 * @returns {JSX.Element} The rendered form and preview card.
 */

const AddQuestion = () => {
    const [form] = Form.useForm();
    const [desc, setDesc] = useState("");
    const { id } = useParams();
    const dispatch = useDispatch();
    const { isLoading, isLoadingQuestionById } = useSelector(str => str.loadingReducer);
    const { getQuestionById } = useSelector(str => str.helpReducer);
    const navigate = useNavigate();

    useEffect(() => {
        if (getQuestionById) {
            let { keywords, ...data } = getQuestionById;
            let words = keywords.join(" ");
            const formData = { keywords: words, ...data };
            form.setFieldsValue(formData);
            setDesc(getQuestionById.description);
        }
    }, [getQuestionById]);

    useEffect(() => {
        if (id === undefined) {
            form.resetFields();
            setDesc("");
        } else if (id) {
            dispatch(helpAction.getQuestionByIdEvent(id));
        }
    }, [id]);

    const onFinish = (values) => {
        const { keywords, ...data } = values;
        const words = keywords.trim().split(" ");
        const formData = { keywords: words, ...data };
        if (id) {
            dispatch(helpAction.updateQuestionByIdEvent(id, formData));
        } else {
            dispatch(helpAction.addQuestionEvent(formData));
        }
        form.resetFields();
        navigate("/help");
    };

    if (isLoading || isLoadingQuestionById) return <Spinner />;

    return (
        <Row gutter={[12, 12]} justify="center">
            <Col xl={12}>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item label="Title" name="title" rules={[{ required: true, message: "Please add title!" }]}>
                        <Input placeholder="Add Question" />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Key" name="key" rules={[{ required: true, message: "Please add key!" }]}>
                                <Select options={[
                                    { value: "General", label: "General" },
                                    { value: "home", label: "Home" },
                                    { value: "book_appointment", label: "Book Appointment" },
                                    { value: "settings", label: "Settings" }
                                ]} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Keywords" name="keywords">
                                <Input placeholder="Keywords" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Platforms" name="platforms" rules={[{ required: true, message: "Please add Platforms!" }]}>
                        <Select mode="multiple" options={[
                            { value: "Web", label: "Web" },
                            { value: "Mobile", label: "Mobile" },
                            { value: "Marketing", label: "Marketing" }
                        ]} />
                    </Form.Item>
                    <Form.Item label="Accessible to" name="roles" rules={[{ required: true, message: "Accessible to..." }]}>
                        <Select mode="multiple" options={[
                            { value: "CAPTAIN", label: "Captain" },
                            { value: "USER", label: "User" },
                            { value: "VET", label: "Vet" },
                            { value: "ADMIN", label: "Admin" }
                        ]} />
                    </Form.Item>
                    <ReactQuillComponentRender form={form} setDescription={setDesc} />
                    <Form.Item style={{ textAlign: "end" }}>
                        <Button htmlType="submit" size="small">Submit</Button>
                    </Form.Item>
                </Form>
            </Col>
            <Col xl={12}>
                <Card title="Preview" hoverable={false} style={{ height: "80vh", overflow: "scroll" }}>
                    <div dangerouslySetInnerHTML={{ __html: desc }} />
                </Card>
            </Col>
        </Row>
    );
};

export default AddQuestion;
