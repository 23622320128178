import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { ConfigProvider } from "antd";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { SocketProvider } from "./context/SocketContext";
import { RouteProvider } from "./context/RouteContext";
import ThemeToggle from "./theme/ThemeToggle";
// import { SocketProvider } from "./context/SocketContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <GoogleOAuthProvider clientId="524855669404-hlf58s3htn8qi1unu2dmkl6alqq525rv.apps.googleusercontent.com">
          <BrowserRouter>
            <RouteProvider>
              <ThemeToggle>
                <App />
              </ThemeToggle>
            </RouteProvider>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </I18nextProvider>
    </Provider>
  </ConfigProvider >
);
