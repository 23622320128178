import { LinkOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { paymentLinksAction } from "../State/actions";

const CreatePaymentLink = ({setModalOpen}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const onFinish = (values) => {
        dispatch(paymentLinksAction.createPaymentLinkEvent(values));
        form.resetFields();
        setModalOpen(false);
    };

    return (
        <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item
                name={"bookingId"}
                label="Booking Id"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={"payment_link_type"}
                label="Payment link type"
                rules={[{ required: true }]}
            >
                <Select
                    options={[
                        {
                            label: "Standard",
                            value: "standard",
                        },
                        {
                            label: "UPI",
                            value: "upi",
                        },
                    ]}
                />
            </Form.Item>
            <Form.Item name={"amount"} label="Amount in rupees" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name={"description"} label="Description">
                <Input />
            </Form.Item>
            <Form.Item name={"accept_partial"} label="Accept partial payment" rules={[{ required: true }]}>
                <Select
                    options={[
                        {
                            label: "Yes",
                            value: true,
                        },
                        {
                            label: "No",
                            value: false,
                        },
                    ]}
                />
            </Form.Item>
            <Form.Item>
                <Button style={{ width: "100%" }} htmlType="submit" type="primary" icon={<LinkOutlined />}>
                    {" "}
                    Create payment link
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreatePaymentLink;
