import { Space, Tag, theme, Typography } from "antd";
import React from "react";
const { Text } = Typography;

/**
 * @param - takes handleAccept function which is called  when admin accepts decline request and showRejectModal for specifying reason in modal
 * All the column to be shown for admin regarding a particular caotain bookings
 */

const useCaptainColumns = ({ handleAccept, showRejectModal }) => {
  const { useToken } = theme;
  const { token } = useToken();

  const columns = [
    {
      title: "Details",
      dataIndex: "bookingUniqueId",
      key: "bookingUniqueId",
      width: 150,
      render: (_, { bookingUniqueId, customerName, contactNumber }) => (
        <>
          <Text style={{ display: "block" }}>{bookingUniqueId}</Text>
          <Text style={{ display: "block" }}>{customerName}</Text>
          <Text style={{ display: "block" }}>
            {contactNumber.countryCode + contactNumber.phoneNumber}
          </Text>
        </>
      ),
    },
    {
      title: "Pickup Address",
      dataIndex: "pickupLocation",
      key: "pickupLocation",
      width: 250,
    },
    {
      title: "Drop Address",
      dataIndex: "dropOffLocation",
      key: "dropOffLocation",
      width: 250,
    },
    {
      title: "Pickup DateTime",
      dataIndex: "pickupDateTime",
      key: "pickupDateTime",
      width: 125,
    },
    {
      title: "Drop DateTime",
      dataIndex: "dropDateTime",
      key: "dropDateTime",
      width: 125,
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      align: "center",
      render: (_, booking) => {
        let {
          status,
        } = booking?.status?.petPickupAndDrop_status?.boarding_center_to_parent_location_to_pickup_pet?.accepts_ride;
        return (
          <>
            {status === "decline_request" ? (
              <>
                <Space size={"small"} direction="vertical" align="center">
                  <Tag
                    style={{
                      background: token.appbluecolor,
                      color: token.White,
                      cursor: "pointer",
                    }}
                    onClick={() => handleAccept(booking)}
                  >
                    Accept
                  </Tag>
                </Space>
                <Space size={"small"} direction="vertical" align="center">
                  <Tag
                    style={{
                      background: token.Primary,
                      color: token.White,
                      cursor: "pointer",
                    }}
                    onClick={() => showRejectModal(booking, "declineType")}
                  >
                    Decline
                  </Tag>
                </Space>
              </>
            ) : (
              <Space size={"small"} direction="vertical" align="center">
                <Tag
                  style={{
                    background:
                      status === "decline"
                        ? token.Red
                        : status === "accept"
                        ? token.Green
                        : token.lightYellow,
                  }}
                >
                  {status === "accept" ? (
                    <Text>Accepted</Text>
                  ) : status === "decline" ? (
                    <Text>Declined</Text>
                  ) : status === "in_process" ? (
                    <Text>In Process</Text>
                  ) : (
                    <Text>Canceled by Admin</Text>
                  )}
                </Tag>
              </Space>
            )}
            {status !== "none" && status !== "decline" && (
              <Text
                style={{
                  textDecoration: "underline",
                  color: token.appbluecolor,
                  cursor: "pointer",
                  display: "block",
                }}
                onClick={() => showRejectModal(booking, "cancelType")}
              >
                Cancel
              </Text>
            )}
          </>
        );
      },
    },
  ];
  return {
    columns,
  };
};

export default useCaptainColumns;
