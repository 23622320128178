import React, { useEffect } from 'react'
import useWarnings from '../../Common/Components/useWarnings';
import { useDispatch, useSelector } from 'react-redux';
import { adminAction } from '../state/actions';
import { captainAction } from '../../Captain/State/actions';

/**
 * Custom hook to handle the verification steps for an admin.
 * This hook manages side effects related to the verification process, including updating the UI based on verification statuses,
 * and handling OTP verification results.
 * 
 * @param {Object} params - Hook parameters.
 * @param {Object} params.userTobeVerifiedByAdmin - Object containing the details of the user to be verified.
 * @param {Function} params.setCurrent - Function to set the current step in the verification process.
 * @param {Object} params.typeofroute - Object containing information about the type of route (e.g., "drop").
 */
const useVerificationStepsForAdminEffects = ({ userTobeVerifiedByAdmin, setCurrent, typeofroute }) => {
    const dispatch = useDispatch();
    const { success, error } = useWarnings();
    const { userDetails } = useSelector((str) => str.navbarReducer);

    let {
        parent_verification,
        pet_verification_by_admin,
    } = userTobeVerifiedByAdmin.current?.bookingStatus?.petBoarding_status;

    const {
        verifyOtpforVerificationOrderParentErr,
        requestOtpforVerificationOrderParentSuccess,
        verifyOtpforVerificationOrderParentSuccess,
    } = useSelector((str) => str.captainReducer);

    useEffect(() => {
        if (pet_verification_by_admin?.status === "accept") {
            setCurrent(1);
        } else if (parent_verification?.status === "accept") {
            setCurrent(1);
        }
    }, [parent_verification, pet_verification_by_admin]);

    useEffect(() => {
        if (
            verifyOtpforVerificationOrderParentSuccess &&
            typeofroute.current === "drop"
        ) {
            dispatch(
                adminAction.getConfirmedBookingDetails(
                    userDetails.boardCenterUniqueId,
                    {
                        boardCenterId: userDetails.boardingCenter,
                        page: 1,
                        status: "inProcessAppointments",
                    }
                )
            );
        }
    }, [verifyOtpforVerificationOrderParentSuccess]);

    
    useEffect(() => {
        if (requestOtpforVerificationOrderParentSuccess) {
            success("Otp sent successfully!");
        } else if (verifyOtpforVerificationOrderParentSuccess) {
            setCurrent(1);
            success("Verification successfull");
        } else if (verifyOtpforVerificationOrderParentErr) {
            error("Verification unsuccessful");
        }
        dispatch(captainAction.clearOtp());
    }, [
        verifyOtpforVerificationOrderParentSuccess,
        requestOtpforVerificationOrderParentSuccess,
        verifyOtpforVerificationOrderParentErr,
    ]);

}

export default useVerificationStepsForAdminEffects
