import { jwtDecode } from "jwt-decode";
import { signupinConstants } from "./action-types";

const initialState = {
  signupdata: false,
  signupLoading: true,
  signupError: null,
  logoutdata: "",
  logouterr: false,
  requestOtp: false,
  requestOtpError: false,
  verifyOtp: false,
  verifyOtpError: false,
};

const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case signupinConstants.SIGNUP_SUCCESS:
      return {
        ...state,
        signupdata: action.signupData,
        signupLoading: true,
        signupError: null,
      };
    case signupinConstants.SIGUP_FAILURE:
      return {
        ...state,
        signupdata: false,
        signupLoading: false,
        signupError: action.signupError,
      };
    case signupinConstants.LOGIN_SUCCESS:
      localStorage.setItem("authToken", action.signupData.token);
      // let decodedRole = jwtDecode(action.signupData.token);
      // localStorage.setItem("role", decodedRole.currentRole);
      return {
        ...state,
        signupdata: action.signupData,
        signupLoading: true,
        signupError: null,
      };
    case signupinConstants.LOGIN_FAILURE:
      return {
        ...state,
        signupdata: false,
        signupLoading: false,
        signupError: action.signupError,
      };
    case signupinConstants.REQUEST_OTP_SUCCESS:
      return {
        ...state,
        requestOtp: action.data,
        requestOtpError: false,
      };
    case signupinConstants.REQUEST_OTP_FAILURE:
      return {
        ...state,
        requestOtp: false,
        requestOtpError: action.valuesError,
      };
    case signupinConstants.VERIFY_OTP_SUCCESS:
      localStorage.setItem("authToken", action.data.token);
      // let decodeRole = jwtDecode(action.data.token);
      // localStorage.setItem("role", decodeRole.currentRole);
      return {
        ...state,
        verifyOtp: action.data,
        verifyOtpError: false,
      };
    case signupinConstants.VERIFY_OTP_ERROR:
      return {
        ...state,
        verifyOtpError: action.valuesError,
        verifyOtp: false,
      };
    case signupinConstants.VERIFY_SUCCESS:
      return {
        ...state,
        signupdata: action.verifyData,
        signupLoading: true,
        signupError: null,
      };
    case signupinConstants.VERIFY_ERROR:
      return {
        ...state,
        signupdata: false,
        signupLoading: false,
        signupError: action.verifyError,
      };
    case signupinConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        signupdata: action.forgotData,
        signupLoading: true,
        signupError: null,
      };
    case signupinConstants.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        signupdata: false,
        signupLoading: false,
        signupError: action.forgotError,
      };
    case signupinConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        signupdata: action.changedData,
        signupLoading: true,
        signupError: null,
      };
    case signupinConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        signupdata: false,
        signupLoading: false,
        signupError: action.changedError,
      };

    case signupinConstants.SIGNUP_RESET:
      return {
        ...state,
        signupdata: false,
        signupLoading: true,
        signupError: null,
      };

    case signupinConstants.CLEAR_OTP_DATA:
      return {
        ...state,
        requestOtp: false,
        requestOtpError: false,
        verifyOtp: false,
        verifyOtpError: false,
      };
    default:
      return state;
  }
};

export { signupReducer };
