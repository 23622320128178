import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import { settingsApi } from "../utils/api";
import useWarnings from "../../Common/Components/useWarnings";
import { useSelector } from "react-redux";
import SubscriptionCard from "./SubscriptionCard";
/**
 * ChangePlan Component
 *
 * This component displays a list of available subscription plans and allows the user to select a different plan.
 * Each plan is represented by a card that shows the plan type, services included, and the price.
 * Users can select a plan by clicking on the respective card.
 * It also provides "Back" and "Continue" buttons to navigate back or proceed with the selected plan.
 * The "Continue" button triggers a function to update the subscription with the selected plan.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {function} props.setSelectedPlan - Function to set the currently selected plan.
 * @param {string} props.selectedPlan - The ID of the currently selected plan.
 * @param {function} props.setShowChangePlan - Function to toggle the display of the ChangePlan component.
 * @param {Object} props.subscription - The current subscription object of the user.
 * @param {string} props.subscription.plan_id - The ID of the current subscription plan.
 * @param {string} props.subscription.id - The ID of the subscription.
 * @param {function} props.setSubscription - Function to update the subscription state.
 *
 * @returns {JSX.Element} The ChangePlan component that allows users to change their subscription plan.
 */

const ChangePlan = ({
  setSelectedPlan,
  selectedPlan,
  setShowChangePlan,
  subscription,
  setSubscription,
}) => {
  const { error, contextHolder } = useWarnings();
  const [isLoadingUpdatePayment, setIsLoadingUpdatePayment] = useState(false);

  const { subscriptionPlans } = useSelector((state) => state.settingsReducer);

  const handleChangePlan = async () => {
    setIsLoadingUpdatePayment(true);
    try {
      const data = {
        plan_id: selectedPlan,
        quantity: 1,
        schedule_change_at: "now",
      };
      const updateSubscription = await settingsApi.updateSubscription(
        subscription?.id,
        data
      );
      setIsLoadingUpdatePayment(false);
      setSubscription(updateSubscription?.data?.info);
    } catch (err) {
      setIsLoadingUpdatePayment(false);
      error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    return () => {
      setSelectedPlan(subscription.plan_id);
    };
  }, []);

  return (
    <>
      {contextHolder}
      <Row gutter={[16, 16]}>
        {subscriptionPlans?.map((plan) => (
          <Col
            xs={24}
            lg={{ offset: 2, span: 20 }}
            xl={{ offset: 4, span: 16 }}
            key={plan.planType}
          >
            <SubscriptionCard
              plan={plan}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
            />
          </Col>
        ))}
      </Row>
      <Row style={{ justifyContent: "center" }}>
        <Button
          onClick={() => setShowChangePlan(false)}
          size="small"
          type="primary"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          size="small"
          type="default"
          disabled={subscription?.plan_id === selectedPlan}
          onClick={handleChangePlan}
          loading={isLoadingUpdatePayment}
        >
          Continue
        </Button>
      </Row>
    </>
  );
};

export default ChangePlan;
