import { Card, Divider, Form, Image, Typography, message } from "antd";
import React, { useEffect } from "react";
import styles from "./Styles/login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import logindogs from "../Assets/Login/logindogs.png";
import { useDispatch, useSelector } from "react-redux";
import { signupAction } from "./State/actions";
import { verifySignature } from "../Common/Verify";
import "./Styles/login.css";
import { googleAction } from "./State/googleactions";
import LoginForm from "./Components/LoginForm";
import LoginWithGoogle from "./Components/LoginWithGoogle";
const { Text } = Typography;
/**
 * Login component provides a user interface for logging into the application.
 * @description
 * - **Google Login**: Integrates with Google login functionality as an alternative login method.
 * - **Navigation**: Redirects authenticated users to the appropriate page based on their role (e.g., USER, ADMIN, SUPER_ADMIN).
 */

const Login = () => {
  const isTab = useMediaQuery({ maxWidth: 576 });
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signupError } = useSelector((str) => str.signupReducer);
  const [messageApi, contextHolder] = message.useMessage();
  const { googleError } = useSelector((str) => str.googleReducer);

  useEffect(() => {
    if (signupError) {
      messageApi.open({
        type: "error",
        content: signupError,
      });
    }
    dispatch(signupAction.clearSignupData());
    if (googleError) {
      messageApi.open({
        type: "error",
        content: googleError,
      });
    }
    dispatch(googleAction.clearGoogleData());
    // eslint-disable-next-line
  }, [signupError, googleError]);

  let token = localStorage.getItem("authToken");

  const authentication = verifySignature(token);

  useEffect(() => {
    if (authentication) {
      // if (role === "USER") navigate("/");
      // else if (role === "SUPER_ADMIN" || role === "ADMIN" || role === "CAPTAIN")
        navigate("/");
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, [authentication]);

  // when userc logs in manually
  const handleLogin = (formvalues) => {
    dispatch(signupAction.loginEvent({ ...formvalues, type: "web" }));
    form.resetFields();
  };

  return (
    <div className={styles.loginpage} style={{ flexDirection: "column" }}>
      {contextHolder}
      <div
        style={{ display: isTab ? "none" : "", width: isTab ? "" : "500px" }}
      >
        <Image src={logindogs} alt="dogs" preview={false} />
      </div>
      <Card
        hoverable
        style={{ width: isTab ? "100%" : "500px", height: isTab ? "100%" : "" }}
      >
        <Text
          style={{
            fontSize: "1.3em",
            display: "block",
            fontWeight: 500,
            marginBottom: "20px",
          }}
        >
          {" "}
          👋 Hi, Welcome Back to My Pet !
        </Text>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoginForm form={form} handleLogin={handleLogin} />
          <Divider plain dashed style={{ borderColor: "lightgray" }}>
            Or Login with Google
          </Divider>
          <LoginWithGoogle />
        </div>

        <Text style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          Don't have an account ? <Link to={"/signup"}>&nbsp;Signup Now</Link>
        </Text>
      </Card>
    </div>
  );
};

export { Login };
