import React, { createContext, useContext, useState } from 'react';

const RouteContext = createContext();

export const RouteProvider = ({ children }) => {
  const [routeKey, setRouteKey] = useState('');
  const [selectedKey, setSelectedKey] = useState('');
  return (
    <RouteContext.Provider value={{ routeKey, setRouteKey, selectedKey, setSelectedKey }}>
      {children}
    </RouteContext.Provider>
  );
};

export const useRoute = () => {
  return useContext(RouteContext);
};