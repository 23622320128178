import { adminConstants } from "./action-types";

const initialState = {
  getNewConfirmedBookings: false,
  getNewConfirmedBookinsError: false,
  totalGetNewConfirmedBookings: 0,
  getCaptains: [],
  updateBookingStatus: false,
  getAllOnAppointments: [],
  notifyCaptain: false,
  notifyCaptainErr:false,
  getCaptainsBookings: [],
  allAvailableKennels: false,
  allAvailableKennelsErr: false,
  assignKennel: false,
  assignKennelErr: false,
  releaseKennel: false,
  releaseKennelErr: false,
  removeKennel: false,
  removeKennelErr: false,
  getBookingDetails: false,
  getBookingDetailsErr: false,
};

const adminDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminConstants.GET_CONFIRMED_BOOKING_DETAILS_SUCCESS:
      return {
        ...state,
        getNewConfirmedBookings:
          action.data.currentPage === 1
            ? action.data.docs
            : [...state.getNewConfirmedBookings, ...action.data.docs],
        getNewConfirmedBookinsError: false,
        totalGetNewConfirmedBookings: action.data.totalDocs,
      };
    case adminConstants.GET_CONFIRMED_BOOKING_DETAILS_ERROR:
      return {
        ...state,
        getNewConfirmedBookings: false,
        getNewConfirmedBookinsError: action.error,
      };
    case adminConstants.GET_CAPTAINS_OF_BOARDING_CENTER_SUCCESS:
      return {
        ...state,
        getCaptains: action.data,
        getNewConfirmedBookinsError: false,
      };
    case adminConstants.GET_CAPTAINS_OF_BOARDING_CENTER_ERROR:
      return {
        ...state,
        getCaptains: false,
        getNewConfirmedBookinsError: action.error,
      };
    case adminConstants.UPDATE_BOOKING_STATUS_SUCCESS:
      return {
        ...state,
        updateBookingStatus: action.data,
        getNewConfirmedBookinsError: false,
      };
    case adminConstants.UPDATE_BOOKING_STATUS_ERROR:
      return {
        ...state,
        updateBookingStatus: false,
        getNewConfirmedBookinsError: action.error,
      };
    case adminConstants.GET_ALL_ON_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        getAllOnAppointments: action.data,
        getNewConfirmedBookinsError: false,
      };
    case adminConstants.GET_ALL_ON_APPOINTMENTS_ERROR:
      return {
        ...state,
        getAllOnAppointments: false,
        getNewConfirmedBookinsError: action.error,
      };
    case adminConstants.NOTIFY_CAPTAIN_SUCCESS:
      return {
        ...state,
        notifyCaptain: action.data,
        notifyCaptainErr: false,
      };
    case adminConstants.NOTIFY_CAPTAIN_ERROR:
      return {
        ...state,
        notifyCaptain: false,
        notifyCaptainErr: action.error,
      };
    case adminConstants.GET_CAPTAINS_BOOKINGS_SUCCESS:
      return {
        ...state,
        getCaptainsBookings: action.data,
        getNewConfirmedBookinsError: false,
      };
    case adminConstants.GET_CAPTAINS_BOOKINGS_ERROR:
      return {
        ...state,
        getCaptainsBookings: false,
        getNewConfirmedBookinsError: action.error,
      };
    case adminConstants.GET_AVAILABLE_KENNELS_SUCCESS:
      return {
        ...state,
        allAvailableKennels: action.data,
        allAvailableKennelsErr: false,
      };
    case adminConstants.GET_AVAILABLE_KENNELS_ERROR:
      return {
        ...state,
        allAvailableKennels: false,
        allAvailableKennelsErr: action.error,
      };
    case adminConstants.ASSIGN_KENNEL_ERROR:
      return {
        ...state,
        assignKennel: false,
        assignKennelErr: action.error,
      };
    case adminConstants.ASSIGN_KENNEL_SUCCESS:
      return {
        ...state,
        assignKennel: action.data,
        assignKennelErr: false,
      };
    case adminConstants.RELEASE_KENNEL_ERROR:
      return {
        ...state,
        releaseKennel: false,
        releaseKennelErr: action.error,
      };
    case adminConstants.RELEASE_KENNEL_SUCCESS:
      return {
        ...state,
        releaseKennel: action.data,
        releaseKennelErr: false,
      };
    case adminConstants.REMOVE_KENNEL_ERROR:
      return {
        ...state,
        removeKennel: false,
        removeKennelErr: action.error,
      };
    case adminConstants.REMOVE_KENNEL_SUCCESS:
      return {
        ...state,
        removeKennel: action.data,
        removeKennelErr: false,
      };
    case adminConstants.GET_BOOKING_DETAILS_ERROR:
      return {
        ...state,
        getBookingDetails: false,
        getBookingDetailsErr: action.error,
      };
    case adminConstants.GET_BOOKING_DETAILS_SUCCESS:
      return {
        ...state,
        getBookingDetails: action.data,
        getBookingDetailsErr: false,
      };
    case adminConstants.CLEAR_ALLDATA:
      return {
        assignKennel: false,
        assignKennelErr: false,
        releaseKennel: false,
        releaseKennelErr: false,
        removeKennel: false,
        removeKennelErr: false,
        updateBookingStatus: false,
        getNewConfirmedBookings: false,
        getNewConfirmedBookinsError: false,
        totalGetNewConfirmedBookings: 0,
      };
    default:
      return state;
  }
};
export { adminDashboardReducer };
