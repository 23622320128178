import React from "react";
import { useMediaQuery } from "react-responsive";
/**
 * LoginWithGoogle component provides a button that initiates the Google OAuth2 login process.

 * @description
 * - **Google OAuth2**: Constructs the Google OAuth2 URL with the necessary query parameters and redirects the user to Google's authentication page when the button is clicked.
 */

const LoginWithGoogle = () => {
  const isTab = useMediaQuery({ maxWidth: 576 });

  // when user logins through google button
  const handleGoogleLogin = () => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // Replace with your actual client ID
    const redirectUri = process.env.REACT_APP_REDIRECT_URI; // This should match the route you handle in your app
    const scope = process.env.REACT_APP_SCOPE; // Scopes you need
    const state = process.env.REACT_APP_STATE; // Optional, use a random string for CSRF protection
    const responseType = process.env.REACT_APP_RESPONSETYPE; // Indicates that you want an authorization code

    console.log(redirectUri, 'redirectUri')

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;

    window.location.href = authUrl;
  };

  return (
    <div
      onClick={handleGoogleLogin}
      style={{
        padding: "8px",
        borderRadius: "99em",
        border: "1px solid rgb(36,36,36)",
        textAlign: "center",
        marginBottom: "15px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          width: isTab ? "300px" : "400px",
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className="au"
        >
          <g fillRule="evenodd" clipRule="evenodd">
            <path
              d="M20.64 12.2c0-.63-.06-1.25-.16-1.84H12v3.49h4.84a4.14 4.14 0 0 1-1.8 2.71v2.26h2.92a8.78 8.78 0 0 0 2.68-6.61z"
              fill="#4285F4"
            ></path>
            <path
              d="M12 21a8.6 8.6 0 0 0 5.96-2.18l-2.91-2.26a5.41 5.41 0 0 1-8.09-2.85h-3v2.33A9 9 0 0 0 12 21z"
              fill="#34A853"
            ></path>
            <path
              d="M6.96 13.71a5.41 5.41 0 0 1 0-3.42V7.96h-3a9 9 0 0 0 0 8.08l3-2.33z"
              fill="#FBBC05"
            ></path>
            <path
              d="M12 6.58c1.32 0 2.5.45 3.44 1.35l2.58-2.58A9 9 0 0 0 3.96 7.96l3 2.33A5.36 5.36 0 0 1 12 6.6z"
              fill="#EA4335"
            ></path>
          </g>
        </svg>
        <div>Sign in with Google</div>
      </div>
    </div>
  );
};

export default LoginWithGoogle;
