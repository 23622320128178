import { profileConstants } from "./action-types";

const initialState = {
  parentDetails: false,
  parentDetailsError: false,
  petDetails: false,
  petDetailsError: false,
  selectedservices: false,
  selectedservicesError: false,
  parentBoardingDetails: {},
  parentBoardingDetailsError: false,
  updatedProfileDetails: false,
  updatedProfileDetailsErr: false,
  updatedPetProfileDetails: false,
  updatedPetProfileDetailsErr: false,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case profileConstants.UPDATE_PARENT_DETAILS_SUCCESS:
      return {
        ...state,
        // parentDetails: action.data,
        // parentDetailsError: false,
        updatedProfileDetails: action.data,
        updatedProfileDetailsErr: false
      };
    case profileConstants.UPDATE_PARENT_DETAILS_ERROR:
      return {
        ...state,
        // parentDetails: false,
        // parentDetailsError: action.valuesError,
        updatedProfileDetails: false,
        updatedProfileDetailsErr: action.valuesError
      };
    case profileConstants.GET_PARENT_DETAILS_SUCCESS:
      return {
        ...state,
        parentDetails: action.data,
        parentDetailsError: false,
      };
    case profileConstants.GET_PARENT_DETAILS_ERROR:
      return {
        ...state,
        parentDetails: false,
        parentDetailsError: action.valuesError,
      };
    case profileConstants.GET_PET_DETAILS_SUCCESS:
      return {
        ...state,
        petDetails: action.data,
        petDetailsError: false,
      };
    case profileConstants.GET_PET_DETAILS_ERROR:
      return {
        ...state,
        petDetails: false,
        petDetailsError: action.valuesError,
      };
    case profileConstants.POST_PET_DETAILS_SUCCESS:
      return {
        ...state,
        petDetails: action.data,
        petDetailsError: false,
        updatedProfileDetails: action.data,
        updatedProfileDetailsErr: false,
      };
    case profileConstants.POST_PET_DETAILS_ERROR:
      return {
        ...state,
        petDetails: false,
        petDetailsError: action.valuesError,
        updatedProfileDetails: false,
        updatedProfileDetailsErr: action.valuesError,
      };
    case profileConstants.UPDATE_PET_DETAILS_SUCCESS:
      return {
        ...state,
        // petDetails: action.data,
        // petDetailsError: false,
        updatedPetProfileDetails: action.data,
        updatedPetProfileDetailsErr: false,
      };
    case profileConstants.UPDATE_PET_DETAILS_ERROR:
      return {
        ...state,
        // petDetails: false,
        // petDetailsError: action.valuesError,
        updatedPetProfileDetails: false,
        updatedPetProfileDetailsErr: action.valuesError,
      };
    case profileConstants.SELECT_SERVICES_SUCCESS:
      return {
        ...state,
        selectedservices: action.data,
        selectedservicesError: false,
      };
    case profileConstants.SELECT_SERVICES_FAILURE:
      return {
        ...state,
        selectedservices: false,
        selectedservicesError: action.error,
      };
    case profileConstants.GET_PARENT_BOARDINGCENTER_DETAILS_SUCCESS:
      return {
        ...state,
        parentBoardingDetails: action.data,
        parentBoardingDetailsError: false,
      };
    case profileConstants.GET_PARENT_BOARDINGCENTER_DETAILS_FAILURE:
      return {
        ...state,
        parentBoardingDetails: {},
        parentBoardingDetailsError: action.error,
      };
    case profileConstants.CLEAR_PROFILE_DETAILS:
      return {
        ...state,
        updatedProfileDetails: false,
        updatedProfileDetailsErr: false,
        updatedPetProfileDetails: false,
        updatedPetProfileDetailsErr: false,
      };
    default:
      return state;
  }
};
export { profileReducer };
