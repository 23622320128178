import { Button, Input, Popconfirm, Row, Space } from "antd";
import React from "react";
import useVerificationMethods from "./useVerificationMethods";
import {
  CheckOutlined,
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
const { TextArea } = Input;

/**
 * @function OrderVerificationPickupSteps
 * @description Renders the UI for verifying order pickup steps, including sending and verifying OTP and handling order verification decline.
 * - Displays different views based on whether the user is in the decline process or not.
 * - Provides buttons to send OTP, verify OTP, decline verification, and handle navigation.
 *
 * @param {Object} props - The component's props.
 * @param {Object} props.user - User object containing information needed for OTP and verification.
 * @param {Function} props.setIsModalOpen - Function to open or close the modal.
 * @param {string} props.watchId - The ID used for watching or tracking verification status.
 * @param {string} props.otp - The OTP entered by the user.
 * @param {Function} props.setOtp - Function to update the OTP state.
 * @param {React.CSSProperties} props.contentStyle - Style object for the content container.
 *
 * @returns {JSX.Element} The rendered component, including buttons and text areas for order verification and decline.
 */

const OrderVerificationPickupSteps = ({
  user,
  setIsModalOpen,
  watchId,
  otp,
  setOtp,
  contentStyle,
}) => {
  const {
    handleSendOtp,
    handleVerifyOtp,
    handleDeclineOrderVerification,
    decline,
    handleBackPress,
    handleDeclineVerification,
    setDecline,
    contextHolder
  } = useVerificationMethods();

  return decline?.showBox ? (
    <Space size={"middle"} direction="vertical" style={contentStyle}>
      {contextHolder}
      <TextArea
        rows={3}
        status="error"
        style={{ width: "100%" }}
        onChange={(e) =>
          setDecline((prevDecline) => ({
            ...prevDecline,
            reason: e.target.value,
          }))
        }
      />
      <Row style={{ justifyContent: "space-between" }}>
        <Button size="small" icon={<LeftOutlined />} onClick={handleBackPress}>
          Back
        </Button>
        <Popconfirm
          title="Sure to decline?"
          onConfirm={() =>
            handleDeclineVerification(
              user,
              "order_verification",
              setIsModalOpen,
              "",
              watchId
            )
          }
        >
          <Button size="small" iconPosition="end" icon={<RightOutlined />}>
            Continue
          </Button>
        </Popconfirm>
      </Row>
    </Space>
  ) : (
    <Space size={"middle"} direction="vertical" style={contentStyle}>
      <Button
        size="small"
        onClick={() => handleSendOtp("send_otp_parent_cap_pickup", user)}
      >
        Send OTP to parent
      </Button>
      <Input.OTP length={6} onChange={(e) => setOtp(e)} />
      <Row style={{ justifyContent: "space-between" }}>
        <Button
          size="small"
          type="link"
          icon={<CloseOutlined />}
          onClick={() => handleDeclineOrderVerification()}
        >
          Decline
        </Button>
        <Button
          size="small"
          type="link"
          icon={<CheckOutlined />}
          onClick={() =>
            handleVerifyOtp("parent_otp_verification_cap_pickup", user, otp)
          }
        >
          Verify and Next
        </Button>
      </Row>
    </Space>
  );
};

export default OrderVerificationPickupSteps;
