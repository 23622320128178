import { Card, Col, Collapse, Row, Typography } from 'antd';
import React from 'react'
import { useSelector } from 'react-redux'
import styles from '../Styles/settings.module.css';
import dayjs from 'dayjs';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
const { Text, Paragraph } = Typography;

/**
 * @description
 * The `TicketsForUser` component displays a list of support tickets for the user. Each ticket is shown in a card format with details such as ticket ID, booking ID, description, and status.
 */

const TicketsForUser = () => {
  const { allTicketsForUser } = useSelector(str => str.settingsReducer);
  return (
    <Row gutter={16}>
      {allTicketsForUser && allTicketsForUser?.map(ticket => (
        <Col lg={12} xl={12}>
          <Card hoverable style={{ borderRadius: 0, minHeight: "300px", borderColor: "lightgrey"  }} extra={[ticket?.status === "open" ?
            <>
              <CheckCircleOutlined />&nbsp;
              <Text>Open</Text>
            </> :
            <>
              <CloseCircleOutlined />&nbsp;
              <Text>Closed</Text>
            </>
          ]}>
            <Row>
              <Col span={10} className={styles.col}>
                Ticket Id
              </Col>
              <Col span={2} className={styles.col}>
                :
              </Col>
              <Col span={12} className={styles.col}>
                {ticket?.ticketId}
              </Col>
              <Col span={10} className={styles.col}>
                Booking Id
              </Col>
              <Col span={2} className={styles.col}>
                :
              </Col>
              <Col span={12} className={styles.col}>
                {ticket?.bookingId}
              </Col>
              <Col span={10} className={styles.col}>
                Description
              </Col>
              <Col span={2} className={styles.col}>
                :
              </Col>
              <Col span={12}>
                <Paragraph ellipsis={{ rows: 2, expandable: true, symbol:"more" }} className={styles.col}>{ticket?.description}</Paragraph>
              </Col>
              <Collapse
                size='small'
                style={{ width: "100%" }}
                items={[
                  {
                    key: '1',
                    label: 'More details regarding ticket',
                    children: <>
                      <Text>Ticket created on: {dayjs(ticket?.createdAt).format("MMM D, YYYY h:mm A")}</Text>
                      {ticket?.reopenHistory.length > 0 ? ticket?.reopenHistory?.map(ticket => (
                        <>
                          <div>Reopen Date: {dayjs(ticket?.reopenedAt).format("MMM D, YYYY h:mm A")} due to {ticket?.reopenReason}</div>
                          {
                            ticket?.closedEvent ?
                              <div>Closed Date: {dayjs(ticket?.closedAt).format("MMM D, YYYY h:mm A")} due to {ticket?.closingReason} </div>
                              : null
                          }
                        </>
                      )) : null}
                      {
                        ticket?.firstClosedAt ? <div>This ticket first closed on {dayjs(ticket?.firstClosedAt).format("MMM D, YYYY h:mm A")} due to {ticket?.firstClosingReason}</div> : null
                      }
                    </>
                  },
                ]}
              />
            </Row>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default TicketsForUser
