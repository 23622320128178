import React, { useEffect, useState } from "react";
import image1 from "../../Assets/Dashboard/image1.png";
import image2 from "../../Assets/Dashboard/image2.png";
import image3 from "../../Assets/Dashboard/image3.png";
import image4 from "../../Assets/Dashboard/image4.png";
import image5 from "../../Assets/Dashboard/image5.png";
import HappyfacesName from "../../Assets/Dashboard/HappyFaces.png";
import styles from "../Styles/dashboard.module.css";
import {  Image, Upload, message } from "antd";
import camera from '../../Assets/Dashboard/camera.png';

const HappyFaces = () => {
    const [images, setImages] = useState([
        image1,
        image2,
        image3,
        image4,
        image5,
    ]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showUploadIcon, setShowUploadIcon] = useState(false);
    const handleImageHover = (hover) => {
        setShowUploadIcon(hover);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (!showUploadIcon) {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % images?.length);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [images?.length, showUploadIcon]);

    const handleUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must be smaller than 2MB!');
        }
        const reader = new FileReader();
        reader.onload = (event) => {
            const imageDataUrl = event.target.result;
            setImages((prevImages) => [...prevImages, imageDataUrl]);
        };
        reader.readAsDataURL(file);
        return isJpgOrPng && isLt2M;
    };

    return (
        <div onMouseEnter={() => handleImageHover(true)}
            onMouseLeave={() => handleImageHover(false)} style={{ position: "relative" }}>
            <Image
                src={images[currentIndex]}
                style={{ width: "100%" }}
                alt="happy face"
                preview={false}

            />
            {showUploadIcon && (
                <Upload beforeUpload={handleUpload} showUploadList={false}>
                    <div className={styles.upload}>
                        <Image width={50} src={camera} preview={false} alt="camera" />
                        Upload
                    </div>
                </Upload>
            )}
            <div
                className={styles.HappyfacesName}>
                <Image
                    src={HappyfacesName}
                    width={140}
                    alt="Happy Faces"
                    preview={false}
                />
            </div>
        </div>
    );
};

export default HappyFaces;
