import basic from "../../Assets/BookAppointment/basic.png";
import premium from "../../Assets/BookAppointment/premium.png";
import pro from "../../Assets/BookAppointment/pro.png";
import greenpaw from "../../Assets/BookAppointment/greenpaw.png";
import redpaw from "../../Assets/BookAppointment/redpaw.png";

export const plans = [
  {
    planType: "Silver",
    key: "SILVER",
    image: basic,
    planId: "plan_OlrbSoB4H9RUUa",
    allowedServices: ["petBoarding", "petPickupAndDrops", "petWalkings"],
    serviceDetails: [{ serviceName: "petBoarding", serviceType: "primary" }, { serviceName: "petWalkings", serviceType: "primary" }, { serviceName: "petPickupAndDrops", serviceType: "primary" }],
    services: [
      {
        image: greenpaw,
        service:
          "Pet Boarding",
      },
      {
        image: greenpaw,
        service:
          "Pet Pickup & Drops",
      },
      {
        image: greenpaw,
        service:
          "Pet walkings",
      },
      // {
      //   image: greenpaw,
      //   service: "Pet Boarding, Pet Pickup & drop, Pet Walking",
      // },
      // {
      //   image: greenpaw,
      //   service: "Home food & preferred foods",
      // },
      // {
      //   image: greenpaw,
      //   service: "Pick up & drop",
      // },
      // {
      //   image: redpaw,
      //   service: "Complimentaries",
      // },
    ],
    price: "2999",
  },
  {
    planType: "Gold",
    image: premium,
    key: "GOLD",
    planId: "plan_OlrcOGZiRsFy0O",
    serviceDetails: [{ serviceName: "petTraining", serviceType: "primary" }, { serviceName: "petDayCare", serviceType: "primary" }, { serviceName: "petWalkings", serviceType: "primary" }, { serviceName: "petBoarding", serviceType: "primary" }, { serviceName: "petPickupAndDrops", serviceType: "primary" }],
    allowedServices: [
      "petBoarding",
      "petPickupAndDrops",
      "petWalkings",
      "petDayCare",
      "petTraining",
    ],
    services: [
      // {
      //   image: greenpaw,
      //   service: "3 month validity",
      // },
      {
        image: greenpaw,
        service:
          "Pet Boarding",
      },{
        image: greenpaw,
        service:
          "Pet Pickup & Drops",
      },{
        image: greenpaw,
        service:
          "Pet Walkings",
      },
      {
        image: greenpaw,
        service:
          "Pet Spa & groom",
      },{
        image: greenpaw,
        service:
          "Pet Pickup & Drops",
      },{
        image: greenpaw,
        service:
          "Pet Daycare",
      },
      // {
      //   image: greenpaw,
      //   service: "Home food & preferred foods",
      // },
      // {
      //   image: greenpaw,
      //   service: "Pick up & drop",
      // },
      // {
      //   image: redpaw,
      //   service: "Complimentaries",
      // },
    ],
    price: "4599",
  },
  {
    planType: "Platinum",
    image: pro,
    key: "PLATINUM",
    planId: "plan_OlreNhN236l0U0",
    allowedServices: [
      "petBoarding",
      "petPickupAndDrops",
      "petWalkings",
      "petDayCare",
      "petTraining",
      "petSpaAndGrooming",
      "petMaternity",
    ],
    services: [
      // {
      //   image: greenpaw,
      //   service: "3 month validity",
      // },
      {
        image: greenpaw,
        service:
          "Pet Boarding",
      },
      {
        image: greenpaw,
        service:
          "Pet Pickup & Drops",
      },
      {
        image: greenpaw,
        service:
          "Pet walkings",
      },{
        image: greenpaw,
        service:
          "Pet spa & groom",
      },{
        image: greenpaw,
        service:
          "Pet day care",
      },{
        image: greenpaw,
        service:
          "Pet Training",
      },{
        image: greenpaw,
        service:
          "Pet Adoption",
      },
      // {
      //   image: greenpaw,
      //   service: "Home food & preferred foods",
      // },
      // {
      //   image: greenpaw,
      //   service: "Pick up & drop",
      // },
      // {
      //   image: greenpaw,
      //   service: "Complimentaries",
      // },
    ],
    price: "8999",
  },
];
