const verifySignature = (token) => {
  return token;
};
module.exports = { verifySignature };


// import React from 'react';
// import jwt_decode from 'jwt-decode';

// function App() {
//   // Example JWT token
//   const jwtToken = 'YOUR_JWT_TOKEN_HERE';

//   // Decode JWT token
//   const decodedToken = jwt_decode(jwtToken);

//   // Public key used for JWT verification
//   const publicKey = 'YOUR_PUBLIC_KEY_HERE';

//   // Function to verify JWT signature
//   const verifySignature = (token, publicKey) => {
//     // Extract the token header and payload
//     const [header, payload, signature] = token.split('.');

//     // Combine the header and payload back into a token string
//     const unsignedToken = `${header}.${payload}`;

//     // Decode base64url encoded signature
//     const decodedSignature = atob(signature.replace(/-/g, '+').replace(/_/g, '/'));

//     // Convert public key string to CryptoKey object
//     const publicKeyBuffer = new TextEncoder().encode(publicKey);
//     const publicKeyObject = await crypto.subtle.importKey('spki', publicKeyBuffer, { name: 'RSASSA-PKCS1-v1_5', hash: 'SHA-256' }, false, ['verify']);

//     // Verify the signature
//     const isSignatureValid = await crypto.subtle.verify('RSASSA-PKCS1-v1_5', publicKeyObject, new TextEncoder().encode(unsignedToken), new Uint8Array(decodedSignature));

//     return isSignatureValid;
//   };

//   // Verify JWT signature
//   verifySignature(jwtToken, publicKey)
//     .then(isValid => {
//       if (isValid) {
//         console.log('JWT signature is valid');
//       } else {
//         console.log('JWT signature is invalid');
//       }
//     })
//     .catch(error => {
//       console.error('Error verifying JWT signature:', error);
//     });

//   return (
//     <div>
//       <h1>JWT Verification Example</h1>
//       <p>Check browser console for verification result.</p>
//     </div>
//   );
// }