import { boardCenterConstants } from "./action-types";

const initialState = {
    updateBoardCenter: false,
    updateBoardCenterErr: false,
    allBoardcenters: false,
    allBoardcentersErr: false,
    addBoardCenter: false,
    addBoardCenterErr: false,
    getBoardCenterById: false,
    getBoardCenterByIdErr: false,
};

const boardCenterReducer = (state = initialState, action) => {
    switch (action.type) {
        case boardCenterConstants.UPDATE_BOARD_CENTER_SUCCESS:
            return {
                ...state,
                updateBoardCenter: action.data,
                updateBoardCenterErr: false,
            };
        case boardCenterConstants.UPDATE_BOARD_CENTER_ERROR:
            return {
                ...state,
                updateBoardCenter: false,
                updateBoardCenterErr: action.valuesError,
            };
        case boardCenterConstants.GET_ALL_BOARDCENTER_SUCCESS:
            return {
                ...state,
                allBoardcenters: action.data,
                allBoardcentersErr: false,
            };
        case boardCenterConstants.GET_ALL_BOARDCENTER_ERROR:
            return {
                ...state,
                allBoardcenters: false,
                allBoardcentersErr: action.valuesError,
            };
        case boardCenterConstants.ADD_BOARDING_CENTER_SUCCESS:
            return {
                ...state,
                addBoardCenter: action.data,
                addBoardCenterErr: false,
            };
        case boardCenterConstants.ADD_BOARDING_CENTER_ERROR:
            return {
                ...state,
                addBoardCenter: false,
                addBoardCenterErr: action.valuesError,
            };
        case boardCenterConstants.GET_BOARDCENTER_BY_ID_SUCCESS:
            return {
                ...state,
                getBoardCenterById: action.data,
                getBoardCenterByIdErr: false,
            };
        case boardCenterConstants.GET_BOARDCENTER_BY_ID_ERROR:
            return {
                ...state,
                getBoardCenterById: false,
                getBoardCenterByIdErr: action.valuesError,
            };
        case boardCenterConstants.CLEAR_BOARD_CENTER:
            return {
                ...state,
                // getBoardCenterById: false,
                // getBoardCenterByIdErr: false,
                updateBoardCenter: false,
                updateBoardCenterErr: false,
            };
        default:
            return state;
    }
};
export { boardCenterReducer };
