import { pets } from "./pets";

export const staticOptions = [
    {
      label: "Domestic",
      value: "domestic",
      children: [
        {
          label: "Dog",
          value: "dog",
          children: pets?.["dog"]?.map((petBreed) => ({
            label: petBreed,
            value: petBreed,
          })),
        },
        {
          label: "Cat",
          value: "cat",
          children: pets?.["cat"]?.map((petBreed) => ({
            label: petBreed,
            value: petBreed,
          })),
        },
      ],
    },
    {
      label: "Exotic",
      value: "exotic",
      children: [
        {
          label: "Monkey",
          value: "monkey",
          children: [
            { label: "Toy Fish", value: "fish" },
            { label: "Toy Cards", value: "cards" },
            { label: "Toy Bird", value: "bird" },
          ],
        },
      ],
    },
  ];