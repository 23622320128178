import { ArrowRightOutlined } from '@ant-design/icons';
import { Card, theme, Typography } from 'antd';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styles from '../Styles/petcard.module.css';

const { Text } = Typography;

/**
 * AddressCard component displays a card with address details. It supports hover interactions
 * to reveal an icon that navigates to a specified URL when clicked.
 *
 * @param {Array} props.e - An array of address objects to be displayed on the card.
 *
 * @returns {JSX.Element} The rendered AddressCard component.
 *
 * @description
 * - **Hover Effect**: The card changes appearance when hovered over, showing a share icon.
 * - **Navigation**: Clicking the share icon navigates to the URL specified in the `redirectURL` property.
 */


const AddressCard = ({ e }) => {
    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => setHover(true);
    const handleMouseLeave = () => setHover(false);
    const { useToken } = theme;
    const { token } = useToken();
    const navigate = useNavigate();
    return (
        <Card bordered
            hoverable
            style={{ borderRadius: 0 }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            {
                e?.map(address => (
                    <>
                        <Text style={{ display: "block" }}>{address?.fullAddress}</Text><br />
                        {hover && (
                            <div className={styles.shareIcon} onClick={() => navigate(address.redirectURL)}>
                                <ArrowRightOutlined style={{ color: token.White, fontSize: "14px" }} />
                            </div>
                        )}
                    </>
                ))
            }
        </Card>
    )
}

export default AddressCard
