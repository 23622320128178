import { searchConstants } from "./action-types";

const initialState = {
  searchResults: false,
  searchResultsErr: false,
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchConstants.SEARCH_SUCCESS:
      return {
        ...state,
        searchResults: action.data,
        searchResultsErr: false,
      };
    case searchConstants.SEARCH_FAILURE:
      return {
        ...state,
        searchResults: false,
        searchResultsErr: action.error,
      };
    case searchConstants.CLEAR_SEARCH:
      return {
        ...state,
        searchResults: false,
        searchResultsErr: false,
      }
    default:
      return state;
  }
};
export { searchReducer };
