import React, { useEffect } from "react";
import { adminAction } from "../state/actions";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../Spinner/Spinner";
import { useParams } from "react-router-dom";
import { Button, Col, Input, Modal, Row, Select, theme } from "antd";
import { v4 as uuidv4 } from "uuid";
import useAcceptDecline from "./useAcceptDecline";
import ParentInfoForAdmin from "./ParentInfoForAdmin";
import ServiceInfoForAdmin from "./ServiceInfoForAdmin";
import { bookAppointmentAction } from "../../BookAppointment/State/bookAppointmentActions";

const BookingUserDetails = () => {
  const dispatch = useDispatch();
  const { useToken } = theme;
  const { token } = useToken();
  const { bookappointmentdetailsbyID } = useSelector(
    (str) => str.bookAppointmentReducer
  );
  const { isLoading } = useSelector((str) => str.loadingReducer);

  const { id } = useParams();
  const {
    declineBooking,
    handleOk,
    handleCancel,
    handleAccept,
    setDeclineBooking,
    handleSelectChange,
    showModal,
    contextHolder,
    isModalOpen,
  } = useAcceptDecline();

  useEffect(() => {
    dispatch(bookAppointmentAction.getBookAppointmentdetailsByIdEvent(id));
  }, [dispatch, id]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {contextHolder}
      <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
        <Col lg={12} xl={12}>
          <ParentInfoForAdmin completeDetails={bookappointmentdetailsbyID} />
        </Col>
        <Col lg={12} xl={12}>
          <Row gutter={[16, 16]} justify={"center"}>
            {bookappointmentdetailsbyID?.services?.map((service) => (
              <Col key={uuidv4()} xs={24} sm={24} md={12} lg={24} xl={24}>
                <ServiceInfoForAdmin service={service} />
              </Col>
            ))}
          </Row>
          {(bookappointmentdetailsbyID?.status?.petBoarding_status
            ?.booking_confirmation?.status === "none" ||
            bookappointmentdetailsbyID?.status?.petBoarding_status
              ?.booking_confirmation?.status === undefined) && (
            <Row justify={"end"} style={{ marginTop: "1.2em" }}>
              <Button
                size="small"
                style={{
                  backgroundColor: token.appbluecolor,
                  borderColor: "transparent",
                  color: token.White,
                  margin: "1.2em",
                }}
                onClick={() => handleAccept(bookappointmentdetailsbyID)}
              >
                Accept & Assign
              </Button>
              <Button
                size="small"
                style={{ margin: "1.2em" }}
                type="primary"
                onClick={() => showModal(bookappointmentdetailsbyID)}
              >
                Decline
              </Button>
            </Row>
          )}
        </Col>
      </Row>
      <Modal
        title="Decline reason"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
      >
        <Select
          defaultValue="Select your reason"
          style={{
            width: "90%",
          }}
          onChange={handleSelectChange}
          options={[
            {
              value: "Select your reason",
              label: "Select your reason",
            },
            {
              value: "Boarding center closed for the day",
              label: "Boarding center closed for the day",
            },
            { value: "other", label: "other" },
          ]}
        />
        <Input
          placeholder="Specify your decline reason"
          value={declineBooking.customReason}
          onChange={(e) =>
            setDeclineBooking({
              ...declineBooking,
              customReason: e.target.value,
            })
          }
          style={{
            marginTop: "1em",
            width: "90%",
            display:
              declineBooking.declineReason === "other" ? "initial" : "none",
          }}
        />
      </Modal>
    </>
  );
};

export default BookingUserDetails;
