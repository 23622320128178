import { startLoading, startLoadingRequestOtp, stopLoading, stopLoadingRequestOtp } from "../../state/actions";
import { signupApi } from "../utils/api";
import { signupinConstants } from "./action-types";

const signupError = (err) => {
  return {
    type: signupinConstants.SIGUP_FAILURE,
    signupError: err,
  };
};

const signupSuccess = (data) => {
  return {
    type: signupinConstants.SIGNUP_SUCCESS,
    signupData: data,
  };
};

const signupEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoading());
    signupApi
      .signup(data)
      .then((response) => {
        if (
          response.data.hasOwnProperty("statusCode") &&
          response.data.statusCode === 409
        ) {
          dispatch(signupError(response.data.info.message));
          dispatch(stopLoading());
        } else {
          dispatch(signupSuccess(response.data.info.message));
          dispatch(stopLoading());
        }
      })
      .catch((err) => {
        dispatch(signupError(err.response.data.message));
        dispatch(stopLoading());
      });
  };
};

const loginSuccess = (data) => {
  return {
    type: signupinConstants.LOGIN_SUCCESS,
    signupData: data,
  };
};

const loginError = (err) => {
  return {
    type: signupinConstants.LOGIN_FAILURE,
    signupError: err,
  };
};

const loginEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoading());
    signupApi
      .login(data)
      .then((response) => {
        if (
          response.data.hasOwnProperty("statusCode") &&
          response.data.statusCode === 401
        ) {
          dispatch(loginError(response.data.info.message));
          dispatch(stopLoading());
        } else {
          dispatch(loginSuccess(response.data.info));
          dispatch(stopLoading());
        }
      })
      .catch((err) => {
        dispatch(loginError(err.response.data.message));
        dispatch(stopLoading());
      });
  };
};

const verifyOtpSuccess = (data) => {
  return {
    type: signupinConstants.VERIFY_OTP_SUCCESS,
    data: data,
  };
};

const verifyOtpError = (err) => {
  return {
    type: signupinConstants.VERIFY_OTP_ERROR,
    valuesError: err,
  };
};

const verifyOTPEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoading());
    signupApi
      .verifyOtp(data)
      .then((response) => {
        console.log(response, "response")
        // if (
        //   response.data.hasOwnProperty("statusCode") &&
        //   response.data.statusCode === 401
        // ) {
        //   dispatch(verifyOtpError(response.data.info.message));
        //   dispatch(stopLoading());
        // } else {
        dispatch(verifyOtpSuccess(response.data.info));
        dispatch(stopLoading());
        // }
      })
      .catch((err) => {
        console.log(err, "err")
        dispatch(verifyOtpError(err.response.data.message));
        dispatch(stopLoading());
      });
  };
};

const requestOTPSuccess = (data) => {
  return {
    type: signupinConstants.REQUEST_OTP_SUCCESS,
    data: data,
  };
};

const requestOTPError = (err) => {
  return {
    type: signupinConstants.REQUEST_OTP_FAILURE,
    valuesError: err,
  };
};

const requestOTPEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoadingRequestOtp());
    signupApi
      .requestOtp(data)
      .then((response) => {
        dispatch(requestOTPSuccess(response.data.info.message));
        dispatch(stopLoadingRequestOtp());
      })
      .catch((err) => {
        dispatch(requestOTPError(err.response.data.message));
        dispatch(stopLoadingRequestOtp());
      });
  };
};

const verifyuserSuccess = (data) => {
  return {
    type: signupinConstants.VERIFY_SUCCESS,
    verifyData: data,
  };
};

const verifyUserError = (err) => {
  return {
    type: signupinConstants.VERIFY_ERROR,
    verifyError: err,
  };
};

const verifyuserEvent = (id, token) => {
  return (dispatch) => {
    dispatch(startLoading());
    signupApi
      .verifyuser(id, token)
      .then((response) => {
        if (
          (response.data.hasOwnProperty("statusCode") &&
            response.data.statusCode === 401) ||
          response.data.statusCode === 404
        ) {
          dispatch(verifyUserError(response.data.info.message));
          dispatch(stopLoading());
        } else {
          dispatch(verifyuserSuccess(response.data.info.message));
          dispatch(stopLoading());
        }
      })
      .catch((err) => {
        dispatch(verifyUserError(err));
        dispatch(stopLoading());
      });
  };
};

const clearSignupData = () => {
  return {
    type: signupinConstants.SIGNUP_RESET,
  };
};

const clearOTPdata = () => {
  return {
    type: signupinConstants.CLEAR_OTP_DATA
  }
}


export const signupAction = {
  signupEvent,
  loginEvent,
  clearSignupData,
  verifyuserEvent,
  requestOTPEvent,
  verifyOTPEvent,
  clearOTPdata
};
