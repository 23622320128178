import axios from "axios";
import qs from "qs";

const body = {
  grant_type: "client_credentials",
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_secret:process.env.REACT_APP_CLIENT_SECRET
};

// get mappls token
const getMapplsToken = async () => {
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
    },
  };
  const bodyDataEncoded = qs.stringify(body);
  return await axios.post(
    `https://outpost.mappls.com/api/security/oauth/token`,
    bodyDataEncoded,
    config
  );
};

const getPlaces = (token, value) => {
  let res = axios.get(
    `https://atlas.mappls.com/api/places/geocode?access_token=${token}&address=${value}&itemCount=5`,
    {
      headers: null,
    }
  );
  // console.log(res, "res");
  return res;
};

export const mapsApi = {
  getMapplsToken,
  getPlaces,
};

