import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm, Switch, Typography, Tag, theme } from 'antd';
import React from 'react'
import { useDispatch } from 'react-redux';
import { helpAction } from '../State/actions';

/**
 * Provides columns with rendering logic for displaying help questions, including title, screens, platform,
 * accessibility roles, keywords, and action buttons for editing, switching activation status, and deleting.
 * 
 * @param {Object} props - The hook's props.
 * @param {Function} props.handleEdit - Function to handle the editing of a question, invoked with the question ID.
 * @param {Function} props.handleSwitch - Function to handle switching the activation status of a question, invoked with
 * the activation status and question ID.
 * 
 * @returns {Object} An object containing the columns configuration for the Table component.
 */


const useHelpColumns = ({ handleEdit, handleSwitch }) => {
    const dispatch = useDispatch();
    const { useToken } = theme;
    const { token } = useToken();
    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            align: "center",
            width: "30%"
        },
        {
            title: "Screens",
            dataIndex: "screens",
            key: "screens",
            width: "15%",
            align: "center",
            filters: [
                { text: 'Home', value: 'home' },
                { text: 'book_appointment', value: 'book_appointment' },
                { text: 'General', value: 'General' },
                { text: 'Accounts', value: 'accounts' },
            ],
            onFilter: (value, record) => record.key.startsWith(value),
            filterSearch: true,
            render: (_, { key }) => <Typography.Text>{key}</Typography.Text>
        },
        {
            title: "Platform",
            dataIndex: "platform",
            key: "platform",
            width: "15%",
            align: "center",
            render: (_, { platforms }) => platforms?.map(platform => <Tag>{platform}</Tag>)
        },
        {
            title: "Accessible to",
            key: "accessibleTo",
            dataIndex: "accessibleTo",
            width: "15%",
            align: "center",
            filters: [
                { text: 'User', value: 'USER' },
                { text: 'Captain', value: 'CAPTAIN' },
                { text: 'Vet', value: 'VET' },
                { text: 'Admin', value: 'ADMIN' },
            ],
            onFilter: (value, record) => record.roles.includes(value),
            filterSearch: true,
            render: (_, { roles }) => roles.map(role => <Tag>{role}</Tag>)
        },
        {
            title: "Keywords",
            key: "keywords",
            dataIndex: "keywords",
            width: "15%",
            align: "center",
            render: (_, { keywords }) => <Typography.Text>{keywords?.join(",")}</Typography.Text>
        },
        {
            title: "Action",
            key: "action",
            dataIndex: "action",
            align: "center",
            width: "10%",
            render: (_, { questionUniqueId, isActive }) => (
                <>
                    <EditOutlined style={{ fontSize: "1.2em", marginRight: "1.2em" }} onClick={() => handleEdit(questionUniqueId)} />
                    <Switch checked={isActive} size="small" style={{ marginRight: "1.2em" }} onClick={(e) => handleSwitch(e, questionUniqueId)} />
                    <Popconfirm
                        title="Delete the question"
                        description="Are you sure to delete this question?"
                        icon={<QuestionCircleOutlined style={{ color: token.red }} />}
                        onConfirm={() => dispatch(helpAction.deleteQuestionEvent(questionUniqueId))}
                    >
                        <DeleteOutlined style={{ fontSize: "1.2em" }} />
                    </Popconfirm>
                </>
            ),
        },
    ];

    return {
        columns
    }
}

export default useHelpColumns
