import { Table, Typography } from "antd";
import React, { useState } from "react";
import dayjs from "dayjs";
import { CaretDownFilled, CaretRightFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
const { Text } = Typography;

/**
 * SupportTable component displays a list of support tickets in a table format with expandable rows for additional details.
 * It handles  infinite scrolling to load more data as the user scrolls down.
 *
 * @component
 *
 * @param {Function} props.handleSupportDetails - The function to fetch support ticket details from the server.
 * @param {Array} props.columns - The columns configuration for the table.
 *
 * @returns {JSX.Element} The rendered SupportTable component.
 *
 * @description
 * - **Infinite Scrolling**: Automatically loads more tickets as the user scrolls down the table.
 * - **Expandable Rows**: Displays additional details such as ticket creation date, reopen history, and closure reasons in expandable rows.
 */

const SupportTable = ({ handleSupportDetails, columns }) => {
    const { allTickets, totalTickets } = useSelector((str) => str.supportReducer);
    const { isLoading, isLoadingGetAllQuestions } = useSelector(
        (str) => str.loadingReducer
    );
    const [page, setPage] = useState(1);

    // function to load more data of support tickets
    const loadMoreData = () => {
        if (!isLoadingGetAllQuestions && allTickets.length < totalTickets) {
            setPage(page + 1);
            handleSupportDetails(page + 1);
        } else {
            setPage(1);
        }
    };

    return (
        <Table
            columns={columns}
            bordered
            dataSource={allTickets}
            loading={isLoadingGetAllQuestions || isLoading}
            pagination={false}
            rowKey={(record) => record.ticket.ticketId}
            onScroll={(e) => {
                const { scrollTop, scrollHeight, clientHeight } = e.target;
                if (scrollTop + clientHeight >= scrollHeight - 10) {
                    const nextPage = page + 1;
                    setPage(nextPage);
                    loadMoreData(nextPage);
                }
            }}
            scroll={{
                y: 550,
            }}
            expandable={{
                expandedRowRender: ({ ticket }) => {
                    return (
                        <>
                            <Text>
                                Ticket created on:
                                {dayjs(ticket?.createdAt).format("MMM D, YYYY h:mm A")}
                            </Text>
                            {ticket?.reopenHistory.length > 0
                                ? ticket?.reopenHistory?.map((ticket) => (
                                    <>
                                        <div>
                                            Reopen Date:{" "}
                                            {dayjs(ticket?.reopenedAt).format("MMM D, YYYY h:mm A")}{" "}
                                            due to {ticket?.reopenReason}
                                        </div>
                                        {ticket?.closedEvent ? (
                                            <div>
                                                Closed Date:{" "}
                                                {dayjs(ticket?.closedAt).format("MMM D, YYYY h:mm A")}{" "}
                                                due to {ticket?.closingReason}{" "}
                                            </div>
                                        ) : null}
                                    </>
                                ))
                                : null}
                            {ticket?.firstClosedAt ? (
                                <div>
                                    This ticket first closed on{" "}
                                    {dayjs(ticket?.firstClosedAt).format("MMM D, YYYY h:mm A")}{" "}
                                    due to {ticket?.firstClosingReason}
                                </div>
                            ) : null}
                        </>
                    );
                },
                expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                        <CaretDownFilled onClick={(e) => onExpand(record, e)} />
                    ) : (
                        <CaretRightFilled onClick={(e) => onExpand(record, e)} />
                    ),
            }}
        />
    );
};

export default SupportTable;
