import React, { useEffect, useState } from "react";
import useSettingName from "./useSettingName";
import { useParams } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import styles from "../Styles/invoice.module.css";
import { Col, Row, Table, Typography } from "antd";
import dayjs from "dayjs";
import { paymentApi } from "../utils/paymentapi";
import useWarnings from "./useWarnings";
const { Text } = Typography;

const Invoice = () => {
    const { invoiceId } = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const { error, contextHolder } = useWarnings();

    useSettingName("Invoice");

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await paymentApi.getInvoiceById(invoiceId);
                setData((prevData) => [...prevData, response.data.info]);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                error(err?.response?.data?.message);
            }
        };

        fetchData();
    }, [invoiceId]);

    const formatDate = (timestamp) => dayjs.unix(timestamp).format("DD MMM YYYY");


    useEffect(() => {
        return () => {
            setData([]);
        }
    }, [])

    if (loading) {
        return <Spinner />
    }
    const columns = [
        {
            title: "Date",
            key: "date",
            render: (e) => <Text>{formatDate(e.paid_at)}</Text>,
        },
        {
            title: "Plan",
            key: "plan",
            render: ({ line_items }) => <Text>{line_items?.[0]?.name}</Text>,
        },
        {
            title: "Service period",
            key: "servicePeriod",
            render: ({ billing_end, billing_start }) => (
                <Text>{`${formatDate(billing_start)} - ${formatDate(
                    billing_end
                )}`}</Text>
            ),
        },
        {
            title: "Status",
            key: "status",
            render: ({ status }) => (
                <Text>{status}</Text>
            ),
        },
        {
            title: "Amount",
            key: "amount",
            render: ({ currency_symbol, gross_amount }) => (
                <Text>{currency_symbol + " " + gross_amount / 100}</Text>
            ),
        },
        {
            title: "Tax",
            key: "tax",
            render: ({ currency_symbol, tax_amount }) => (
                <Text>{currency_symbol + " " + tax_amount / 100}</Text>
            ),
        },
        {
            title: "Total Paid",
            key: "total",
            render: ({ currency_symbol, amount }) => (
                <Text>{currency_symbol + " " + amount / 100}</Text>
            ),
        },
    ];

    return (
        <>
            {contextHolder}
            <Text className={styles.smalltext}>Pet Services India LLP.</Text>
            <Text className={styles.smalltext}>Level 11, Godrej BKC</Text>
            <Text className={styles.smalltext}>
                Plot C-68, G Block, BKC - Bandra (East)
            </Text>
            <Text className={styles.smalltext}>Mumbai 400051</Text>
            <Text className={styles.smalltext}>Maharashtra, India</Text>
            <Text className={styles.smalltext}>Tax number: 27AANFN9351J1ZN</Text>

            <Text className={styles.text}>
                {data?.[0]?.customer_details?.customer_email}
            </Text>
            <Text className={styles.text}>Invoice No. {data?.[0]?.id}</Text>

            <Table
                pagination={false}
                bordered={false}
                dataSource={data}
                columns={columns}
                size="small"
                footer={() => <Row>
                    <Col xs={{ span: 16, offset: 8 }} lg={{ span: 4, offset: 20 }} style={{ textAlign: 'end' }}>
                        <Row>
                            <Col span={12}>SUBTOTAL</Col>
                            <Col span={12}>{data?.[0]?.currency_symbol + " " + data?.[0]?.gross_amount / 100}</Col>
                        </Row>
                        <Row>
                            <Col span={12}>TAX TOTAL</Col>
                            <Col span={12}>{data?.[0]?.currency_symbol + " " + data?.[0]?.tax_amount / 100}</Col>
                        </Row>
                        <Row>
                            <Col span={12}>TOTAL</Col>
                            <Col span={12}>{data?.[0]?.currency_symbol + " " + data?.[0]?.amount / 100}</Col>
                        </Row>
                    </Col>
                </Row>}
            />
            <Text className={styles.text}>
                Payment Date : {dayjs.unix(data?.[0]?.paid_at).format("MMM D, YYYY h:mm A")}
            </Text>
            <Text className={styles.text}>
                Reference ID : {data?.[0]?.customer_id}
            </Text>
        </>
    );
};

export default Invoice;
