
import { startLoading, stopLoading } from "../../state/actions";
import { searchApi } from "../utils/api";
import { searchConstants } from "./action-types";

const searchError = (err) => {
  return {
    type: searchConstants.SEARCH_FAILURE,
    error: err,
  };
};

const searchSuccess = (data) => {
  return {
    type: searchConstants.SEARCH_SUCCESS,
    data: data,
  };
};

const searchEvent = (key) => {
  return (dispatch) => {
    dispatch(startLoading());
    searchApi
      .search(key)
      .then((response) => {
        if (response.data.statusCode === 400) {
          dispatch(searchError(response.data.message.en));
        } else {
          dispatch(searchSuccess(response?.data?.info));
        }
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(searchError(err));
        dispatch(stopLoading());
      });
  };
};

const clearSearch = () => {
  return {
      type: searchConstants.CLEAR_SEARCH,
  };
}


export const searchAction = {
  searchEvent,
  clearSearch
};
