import React from "react";
import useVerificationMethods from "../../Captain/Components/useVerificationMethods";
import { Button, Input, Popconfirm, Row, Space } from "antd";
import {
    LeftOutlined,
    RightOutlined,
    CloseOutlined,
    CheckOutlined,
} from "@ant-design/icons";
const { TextArea } = Input;

/**
 * @function ParentVerificationSteps
 * @description Renders the UI for verifying the parent OTP during the pickup process. Provides functionality to send OTP to the parent and verify it.
 * - Displays buttons to send OTP and verify the OTP.
 *
 * @param {Object} props - The component's props.
 * @param {Object} props.userTobeVerifiedByAdmin - The user object containing information needed for OTP and verification.
 * @param {string} props.otp - The OTP entered by the user.
 * @param {Function} props.setIsModalOpen - Function to open or close the modal.
 * @param {React.CSSProperties} props.contentStyle - Style object for the content container.
 * @param {Function} props.setOtp - Function to update the OTP state.
 * @returns {JSX.Element} The rendered component, including buttons for sending OTP and verifying it.
 */

const ParentVerificationSteps = ({
    contentStyle,
    userTobeVerifiedByAdmin,
    setIsModalOpen,
    otp,
    setOtp,
}) => {
    const {
        handleSendOtp,
        handleVerifyOtp,
        handleDeclineOrderVerification,
        decline,
        handleBackPress,
        handleDeclineVerification,
        setDecline,
        contextHolder
    } = useVerificationMethods();

    return decline?.showBox ? (
        <Space size={"middle"} direction="vertical" style={contentStyle}>
            {contextHolder}
            <TextArea
                rows={3}
                status="error"
                style={{ width: "100%" }}
                onChange={(e) =>
                    setDecline((prevDecline) => ({
                        ...prevDecline,
                        reason: e.target.value,
                    }))
                }
            />
            <Row style={{ justifyContent: "space-between" }}>
                <Button size="small" icon={<LeftOutlined />} onClick={handleBackPress}>
                    Back
                </Button>
                <Popconfirm
                    title="Sure to decline?"
                    onConfirm={() =>
                        handleDeclineVerification(
                            userTobeVerifiedByAdmin,
                            "parent_verification",
                            setIsModalOpen,
                            "boarding"
                        )
                    }
                >
                    <Button size="small" iconPosition="end" icon={<RightOutlined />}>
                        Continue
                    </Button>
                </Popconfirm>
            </Row>
        </Space>
    ) : (
        <Space size={"middle"} direction="vertical" style={contentStyle}>
            <Button
                size="small"
                onClick={() =>
                    handleSendOtp("send_otp_parent_pickup", userTobeVerifiedByAdmin)
                }
            >
                Send OTP to parent
            </Button>
            <Input.OTP length={6} onChange={(e) => setOtp(e)} />
            <Row style={{ justifyContent: "space-between" }}>
                <Button
                    size="small"
                    type="link"
                    icon={<CloseOutlined />}
                    onClick={() => handleDeclineOrderVerification()}
                >
                    Decline
                </Button>
                <Button
                    size="small"
                    type="link"
                    icon={<CheckOutlined />}
                    onClick={() =>
                        handleVerifyOtp(
                            "parent_otp_verification_pickup",
                            userTobeVerifiedByAdmin,
                            otp
                        )
                    }
                >
                    Verify and Next
                </Button>
            </Row>
        </Space>
    );
};

export default ParentVerificationSteps;
