import React, { useEffect, useState } from "react";
import { services } from "./Components/Services";
import { jwtDecode } from "jwt-decode";
import ServiceCardBody from "./Components/ServiceCardBody";
import { NotFound } from "./Components/NotFound";

const ServiceCard = ({
  handleCurrentSubscribe,
  servicesSelected,
  parentBoardingDetails,
}) => {
  const { subscriptionType } = jwtDecode(localStorage.getItem("authToken"));
  const [selectedServices, setSelectedServices] = useState([]);
  const availableServices =
    Object.keys(parentBoardingDetails)?.length > 0
      ? Object?.values(parentBoardingDetails?.services)
      : [];

  const filteredServices = services?.filter((e) =>
    availableServices?.map((e) => e?.serviceName).includes(e.key)
  );

  let x = selectedServices
    ?.filter((selectedService) => selectedService.serviceType === "addon")
    .map((e) => e.name);

  // when a user selects each service, handleSelect will be called
  const handleSelect = (service) => {
    const isSelected = selectedServices?.some(
      (selectedService) => selectedService?.name === service.name
    );

    if (subscriptionType) {
      // subscribed user
      const isPrimaryService = servicesSelected?.find(
        (selected) =>
          selected.serviceName === service.key &&
          selected.serviceType === "primary"
      );
      if (isSelected && !isPrimaryService) {
        setSelectedServices(
          selectedServices.filter(
            (selectedService) => selectedService?.name !== service.name
          )
        );
      } else if (!isSelected) {
        // Select service
        const updatedService = {
          ...service,
          serviceType: isPrimaryService ? "primary" : "addon",
        };
        setSelectedServices([...selectedServices, updatedService]);
      }
    } else {
      // non subscribed user
      if (isSelected) {
        setSelectedServices(
          selectedServices.filter(
            (selectedService) => selectedService?.name !== service.name
          )
        );
      } else {
        setSelectedServices([...selectedServices, service]);
      }
    }
  };

  useEffect(() => {
    // Filter services based on selected keys
    let selectedServicesDetails = filteredServices?.filter((service) =>
      servicesSelected?.map((e) => e?.serviceName)?.includes(service.key)
    );
    // Format selected services as required
    const formattedSelectedServices = selectedServicesDetails?.map(
      (service) => ({
        image: service.image,
        name: service.name,
        key: service.key,
        serviceType: servicesSelected.find(
          (selected) => selected.serviceName === service.key
        )?.serviceType,
      })
    );
    setSelectedServices(formattedSelectedServices);
    // eslint-disable-next-line
  }, [servicesSelected, parentBoardingDetails]);

  if (Object.keys(parentBoardingDetails)?.length === 0) {
    return (
      <NotFound
        title={ "Services are not available at this pincode. Please choose another location."}
        continueTo={"/"}
      />
    );
  }

  return (
    <ServiceCardBody
      filteredServices={filteredServices}
      x={x}
      selectedServices={selectedServices}
      handleSelect={handleSelect}
      handleCurrentSubscribe={handleCurrentSubscribe}
    />
  );
};

export default ServiceCard;
