import axios from "axios";

const apiURL = `${process.env.REACT_APP_BACKEND_URL}/support`;

// add a ticket
const createTicket = (data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
        },
    };
    return axios.post(`${apiURL}/createATicket`, data, config);
}

// get all tickets
const getAllTickets = (page, boardCenterId, ticketId) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    let url = `${apiURL}/getAllTickets/${boardCenterId}?page=${page}`
    if (ticketId) url += `&ticketId=${ticketId}`;
    return axios.get(url, config);
}

// update a ticket
const updateTicket = (ticketId, data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.put(`${apiURL}/updateATicket/${ticketId}`, data, config);
}

// get all bookings of user
const getBookingsOfUser = () => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/getAllBookingsOfUser`, config);
}

export const supportApi = {
    createTicket,
    getAllTickets,
    updateTicket,
    getBookingsOfUser
};