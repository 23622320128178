
import axios from "axios";

const apiURL = process.env.REACT_APP_BACKEND_URL;

// get key from razorpay
const getApiKey = () => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/getApiKeyId`, config);
};

// make payment
const makePayment = (id) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.post(`${apiURL}/makePayment/${id}`, null, config)
}

// payment verification
const paymentVerification = (id, data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    let res = axios.post(`${apiURL}/payment-verification/${id}`, data, config);
    // console.log(res, "res");
    return res;
}

//make payment for a subcription
const makePaymentSubscription = (data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.post(`${apiURL}/makePayment`, data, config)
}

// payment verification
const paymentVerificationSubscription = (id, data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    let res = axios.post(`${apiURL}/subscription-payment-verification/${id}`, data, config);
    // console.log(res, "res");
    return res;
}
// get invoice by invoiceId
const getInvoiceById = (invoiceId) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/invoices/${invoiceId}`, config);
}

export const paymentApi = {
    getApiKey,
    makePayment,
    paymentVerification,
    makePaymentSubscription,
    paymentVerificationSubscription,
    getInvoiceById
};