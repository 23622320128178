import { Button, FloatButton, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddBoardCenterForm from "./AddBoardCenterForm";
import Spinner from "../../Spinner/Spinner";
import useBoardingCenterEffects from "./useBoardCenterEffects";
import useBoardingCenterMethods from "./useBoardingCenterMethods";
import { useRoute } from "../../context/RouteContext";
import { PlusOutlined } from "@ant-design/icons";
import CommonModal from "../../CommonModal/CommonModal";
import AddKennel from "./AddKennel";

/**
 * @description `AddBoardingCenter` is a React component that renders a form for adding or updating a boarding center.
 * It utilizes several custom hooks to manage the form's state, handle effects, and process submissions.
 * The form includes fields for selecting services, adding locations, and previewing selected categories.
 */
const AddBoardingCenter = () => {
  const { id } = useParams();
  const { isLoading, isLoadingQuestionById } = useSelector(
    (str) => str.loadingReducer
  );
  const [info, setInfo] = useState({
    initialSelectedValues: [],
    selectedPreview: [],
    allowedLocations: [],
    selectedServices: [],
  });
  const { setSelectedKey } = useRoute();

  const {
    onFinish,
    form,
    setDefaultValue,
    setMaps,
    defaultValue,
    setSelectedValues,
    maps,
    contextHolder,
  } = useBoardingCenterMethods();

  const updateSelectedPreview = (values) => {
    const selectedPreviewList = values.map((path) => {
      const [category, petType, breed] = path;
      return `${breed} (${petType}, ${category})`;
    });
    setInfo((prev) => ({ ...prev, selectedPreview: selectedPreviewList }));
  };

  useBoardingCenterEffects({
    form,
    id,
    setDefaultValue,
    setInfo,
    maps,
    setMaps,
    updateSelectedPreview,
    setSelectedValues,
  });

  const handleServicesChange = (selectedServices) => {
    setInfo((prev) => ({ ...prev, selectedServices }));
  };

  useEffect(() => {
    setSelectedKey("UPDATE_BOARDING_CENTER");
  }, []);

  
  useEffect(() => {
    return () => {
      setInfo(prev => ({ ...prev, allowedLocations: [] }));
    }
  }, []);

  if (isLoading || isLoadingQuestionById) {
    return <Spinner />;
  }

  return (
    <>
      {contextHolder}
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <AddBoardCenterForm
          setSelectedValues={setSelectedValues}
          updateSelectedPreview={updateSelectedPreview}
          info={info}
          defaultValue={defaultValue}
          handleServicesChange={handleServicesChange}
          form={form}
          maps={maps}
          setMaps={setMaps}
        />
        <Row justify={"center"}>
          <Button htmlType="submit" size="small" type="primary">
            Submit
          </Button>
        </Row>
      </Form>
      {/* <FloatButton
        icon={<PlusOutlined />}
        tooltip={"Add a kennel"}
        onClick={() => setOpen(true)}
        type="primary"
        style={{
          right: 30,
        }}
      />
      {open && (
        <CommonModal
          handleOk={() => setOpen(false)}
          handleCancel={() => setOpen(false)}
          body={() =><AddKennel />}
          isModalOpen={open}
          closeIcon={true}
        />
      )} */}
    </>
  );
};

export default AddBoardingCenter;
