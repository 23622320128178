import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { captainAction } from "../State/actions";
import { commonBodyForpetBoarding_status, commonBodyForpetBoarding_status_Decline, commonBodyForpetPickupAndDrop_status, commonBodyForpetPickupAndDrop_status_decline } from "../../Common/Components/commonBodyForUpdatingStatus";
import { adminAction } from "../../ServiceDetails/state/actions";
import useWarnings from "../../Common/Components/useWarnings";


// used for verifications like parent, pet and order verification by sending otp, verifying otp.
const useVerificationMethods = () => {
    const { userDetails } = useSelector(str => str.navbarReducer);
    const [decline, setDecline] = useState({
        showBox: false,
        reason: ""
    });

    let { otherWarning, success, error, contextHolder } = useWarnings();
    const dispatch = useDispatch();

    const handleSendOtp = (type, user) => {
        dispatch(captainAction.sendOtpforVerificationOrderParent(user.current.userId, type, userDetails._id))
    }

    const handleVerifyOtp = (type, user, otp, setIsModalOpen) => {
        dispatch(captainAction.verifyOtpforVerificationOrderParent(user.current.userId, user.current.bookingId, otp, type))
        if (type === "parent_otp_verification_cap_drop" || type === "parent_otp_verification_drop") setIsModalOpen(false);
    }

    const handleVerifyPet = (user, typeofVerification) => {
        let body;
        if (typeofVerification === "pet_verification_by_admin") {
            body = commonBodyForpetBoarding_status(user.current.bookingId, typeofVerification)
        } else {
            body = commonBodyForpetPickupAndDrop_status(user.current.bookingId, typeofVerification)
        }
        dispatch(adminAction.updateBookingStatus(
            user.current.bookingUniqueId,
            body
        ))
    }

    const handlePickuPetContinueRide = (user, setIsModalOpen) => {
        let body = commonBodyForpetPickupAndDrop_status(user.current.bookingId, "pet_on_the_way_to_boarding_center");
        dispatch(adminAction.updateBookingStatus(
            user.current.bookingUniqueId,
            body
        ));
        setIsModalOpen(false);
    }

    const handleDeclineOrderVerification = () => {
        setDecline((prevDecline) => ({
            ...prevDecline,
            showBox: true
        }));
    }

    const handleBackPress = () => {
        setDecline(prevDecline => ({ ...prevDecline, showBox: false }));
    }

    const handleDeclineVerification = (user, typeofVerification, setIsModalOpen, typeofservice, watchId) => {
        if (!decline.reason) {
            return otherWarning();
        }
        let body;
        if (typeofservice === "boarding") {
            body = commonBodyForpetBoarding_status_Decline(user?.current?.bookingId, typeofVerification, decline.reason)
        } else {
            body = commonBodyForpetPickupAndDrop_status_decline(user?.current?.bookingId, typeofVerification, decline.reason);
        }

        dispatch(adminAction.updateBookingStatus(
            user.current.bookingUniqueId,
            body
        ));
        setDecline(prevDecline => ({
            ...prevDecline,
            showBox: false,
            reason: ""
        }));
        if (watchId) {
            navigator.geolocation.clearWatch(watchId?.current)
            watchId.current = null;
        }
        setIsModalOpen(false);
    }

    return {
        handleSendOtp, handleVerifyOtp, handleVerifyPet, handlePickuPetContinueRide, handleDeclineOrderVerification, decline, handleBackPress, handleDeclineVerification, setDecline, contextHolder, success, error,
    }
}

export default useVerificationMethods;