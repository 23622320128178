import { Steps, Typography } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner/Spinner";
import ParentVerificationSteps from "./ParentVerificationSteps";
import PetVerificationStepsForAdmin from "./PetVerificationStepsForAdmin";
import ParentVerificationDropSteps from "../../Captain/Components/ParentVerificationDropSteps";
import useVerificationStepsForAdminEffects from "./useVerificationStepsForAdminEffects";
const { Text } = Typography;

/**
 * Component to display verification steps for an admin, including parent and pet verification,
 * as well as specific steps for drop scenarios.
 * 
 * @param {Object} props - Component properties.
 * @param {Object} props.userTobeVerifiedByAdmin - Details of the user to be verified.
 * @param {Function} props.setIsModalOpen - Function to handle opening/closing of the modal.
 * @param {Object} props.typeofroute - Object containing the type of route (e.g., "pickup" or "drop").
 * 
 * @returns {JSX.Element} The rendered component.
 */
const VerificationStepsForAdmin = ({
    userTobeVerifiedByAdmin,
    setIsModalOpen,
    typeofroute,
}) => {
    const [current, setCurrent] = useState(0);
    const {
        isLoadingRequestOtp,
        isLoadingVerifyOtp,
        isLoadingCaptainDetails,
    } = useSelector((str) => str.loadingReducer);
    const [otp, setOtp] = useState("");

    const contentStyle = {
        marginTop: 16,
        width: "100%",
        textAlign: "center",
    };


    useVerificationStepsForAdminEffects({ userTobeVerifiedByAdmin, setCurrent, typeofroute })

    const steps = [
        {
            title: "Parent Verification",
            content: (
                <ParentVerificationSteps
                    contentStyle={contentStyle}
                    userTobeVerifiedByAdmin={userTobeVerifiedByAdmin}
                    setIsModalOpen={setIsModalOpen}
                    otp={otp}
                    setOtp={setOtp}
                />
            ),
        },
        {
            title: "Pet Verification",
            content: (
                <PetVerificationStepsForAdmin
                    userTobeVerifiedByAdmin={userTobeVerifiedByAdmin}
                    setIsModalOpen={setIsModalOpen}
                    contentStyle={contentStyle}
                />
            ),
        },
    ];

    const dropSteps = [
        {
            title: "Parent Verification before handover the pet",
            content: (
                <ParentVerificationDropSteps
                    user={userTobeVerifiedByAdmin}
                    contentStyle={contentStyle}
                    otp={otp}
                    otptypesend="send_otp_parent_drop"
                    otptypeverify="parent_otp_verification_drop"
                    setIsModalOpen={setIsModalOpen}
                    setOtp={setOtp}
                />
            ),
        },
    ];

    if (isLoadingRequestOtp || isLoadingVerifyOtp || isLoadingCaptainDetails) {
        return <Spinner />;
    }

    return (
        <>
            <Steps
                labelPlacement="vertical"
                current={current}
                size="small"
                items={typeofroute.current === "pickup" ? steps : []}
            />
            <Text style={{ display: "block" }}>
                {typeofroute.current === "pickup"
                    ? steps[current].content
                    : dropSteps[0].content}
            </Text>
        </>
    );
};

export default VerificationStepsForAdmin;
