import { Select, Switch, Typography } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { services } from "../../Common/Components/Services";
import useWarnings from "../../Common/Components/useWarnings";
import { useDispatch, useSelector } from "react-redux";
import { supportAction } from "../State/actions";
import useFiltering from "../../Common/Components/useFiltering";
const { Text, Paragraph } = Typography;

/**
 * Custom hook to manage columns configuration for the support tickets table.
 * It includes functionality for handling ticket status, priority changes, and filtering.
 * @returns {Object} The columns configuration and related state management for the support tickets table.
 */
const useSupportColumns = () => {
  const priorities = ["low", "medium", "high"];
  const dispatch = useDispatch();
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const { getColumnSearchProps, setFiltered } = useFiltering((search) =>
    dispatch(
      supportAction.getTicketsEvent(
        1,
        userDetails.boardCenterUniqueId,
        search.ticketId
      )
    )
  );

  const options = priorities?.map((e) => ({
    value: e,
    label: e,
  }));

  // Manages the status toggle of a ticket.
  const handleSwitch = (e, ticketId) => {
    setModal((prev) => ({
      ...prev,
      open: true,
      ticketId: ticketId,
      type: e ? "open" : "closed",
    }));
  };

  // Updates the priority of a ticket.
  const handlePriorityChange = (priority, ticketId) => {
    const data = {
      priority: priority,
    };
    dispatch(supportAction.updateTicketEvent(ticketId, data));
  };

  const { contextHolder } = useWarnings();
  const [modal, setModal] = useState({
    open: false,
    ticketId: "",
    type: "",
  });

  const columns = [
    {
      title: "Ticket ID",
      dataIndex: "ticketId",
      key: uuidv4(),
      width: 150,
      align: "center",
      fixed: "left",
      ...getColumnSearchProps("ticketId"),
      render: (_, { ticket }) => <Text>{ticket?.ticketId}</Text>,
    },
    {
      title: "Title",
      dataIndex: "supportReason",
      key: uuidv4(),
      width: 150,
      align: "center",
      render: (_, { ticket }) => <Text>{ticket?.supportReason}</Text>,
    },
    {
      title: "Booking Details",
      dataIndex: "bookingId",
      key: uuidv4(),
      width: 300,
      align: "center",
      render: (_, { ticket, bookingDetails }) => (
        <>
          <Text
            style={{ display: "block" }}
          >{`ORDER#${ticket?.bookingId}`}</Text>
          <Text style={{ display: "block" }}>
            {bookingDetails?.parent?.firstName +
              " " +
              bookingDetails?.parent?.lastName}
          </Text>
          <Text style={{ display: "block" }}>
            {bookingDetails?.parent?.contactNumber?.phoneNumber}
          </Text>
          {bookingDetails?.steps < 4 ? (
            <Text>ONGOING</Text>
          ) : (
            bookingDetails?.services?.map((e) => (
              <Paragraph style={{ display: "block" }}>{`${
                services.find((service) => service.key === e.serviceName).name
              } FROM ${dayjs(e.slots?.checkInDateTime).format(
                "MMM D, YYYY h:mm A"
              )} TO ${dayjs(e?.slots?.checkOutDateTime).format(
                "MMM D, YYYY h:mm A"
              )}`}</Paragraph>
            ))
          )}
        </>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: uuidv4(),
      width: 150,
      align: "center",
      render: (_, { ticket }) => {
        return (
          <Text>{dayjs(ticket?.createdAt).format("MMM D, YYYY h:mm A")}</Text>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: uuidv4(),
      width: 200,
      align: "center",
      render: (_, { ticket }) => {
        return (
          <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: "more" }}>
            {dayjs(ticket?.createdAt).format("MMM D, YYYY h:mm A")}
          </Paragraph>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: uuidv4(),
      width: 150,
      align: "center",
      render: (_, { ticket }) => {
        return (
          <Switch
            onClick={(e) => handleSwitch(e, ticket?.ticketId)}
            checked={ticket?.status === "open"}
            checkedChildren="Open"
            unCheckedChildren="Closed"
          />
        );
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: uuidv4(),
      width: 150,
      align: "center",
      render: (_, { ticket }) => (
        <Select
          defaultValue={ticket?.priority}
          size="small"
          status={ticket?.priority === "high" ? "error" : "warning"}
          style={{ width: "100%" }}
          options={options}
          onChange={(value) => handlePriorityChange(value, ticket?.ticketId)}
        />
      ),
    },
  ];

  return { columns, contextHolder, modal, setModal, setFiltered };
};

export default useSupportColumns;
