import { startLoading, stopLoading } from "../../state/actions";
import { signupApi } from "../utils/api";
import { signupinConstants } from "./action-types";

const forgotPasswordSuccess = (data) => {
  return {
    type: signupinConstants.FORGOT_PASSWORD_SUCCESS,
    forgotData: data,
  };
};

const forgotPasswordError = (err) => {
  return {
    type: signupinConstants.FORGOT_PASSWORD_FAILURE,
    forgotError: err,
  };
};

const forgotPasswordEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoading());
    signupApi
      .forgotPassword(data)
      .then((response) => {
        if (
          (response.data.hasOwnProperty("statusCode") &&
            response.data.statusCode === 401) ||
          response.data.statusCode === 404
        ) {
          dispatch(forgotPasswordError(response.data.info.message));
          dispatch(stopLoading());
        } else {
          dispatch(forgotPasswordSuccess(response.data.info.message));
          dispatch(stopLoading());
        }
      })
      .catch((err) => {
        dispatch(forgotPasswordError(err));
        dispatch(stopLoading());
      });
  };
};
const changePasswordSuccess = (data) => {
  return {
    type: signupinConstants.CHANGE_PASSWORD_SUCCESS,
    changedData: data,
  };
};

const changePasswordError = (err) => {
  return {
    type: signupinConstants.CHANGE_PASSWORD_FAILURE,
    changedError: err,
  };
};

const changePasswordEvent = (data, id, token) => {
  return (dispatch) => {
    dispatch(startLoading());
    signupApi
      .changePassword(data, id, token)
      .then((response) => {
        if (
          (response.data.hasOwnProperty("statusCode") &&
            response.data.statusCode === 401) ||
          response.data.statusCode === 404
        ) {
          dispatch(changePasswordError(response.data.info.message));
          dispatch(stopLoading());
        } else {
          dispatch(changePasswordSuccess(response.data.info.message));
          dispatch(stopLoading());
        }
      })
      .catch((err) => {
        dispatch(changePasswordError(err.response.data.message));
        dispatch(stopLoading());
      });
  };
};

export const forgotAction = {
  forgotPasswordEvent,
  changePasswordEvent,
};
