import { helpConstants } from "./action-types";

const initialState = {
  addQuestion: false,
  addQuestionErr: false,
  allQuestions: false,
  allQuestionsErr: false,
  updateQuestion: false,
  updateQuestionErr: false,
  getQuestionById: false,
  getQuestionByIdError: false,
  deleteQuestion: false,
  allQuestionsForscreen: false,
  allQuestionsForscreenErr: false,
};

const helpReducer = (state = initialState, action) => {
  switch (action.type) {
    case helpConstants.ADD_QUESTION_SUCCESS:
      return {
        ...state,
        addQuestion: action.data,
        addQuestionErr: false
      }
    case helpConstants.ADD_QUESTION_ERROR:
      return {
        ...state,
        addQuestion: false,
        addQuestionErr: action.valuesError
      }
    case helpConstants.GET_ALL_QUESTIONS_SUCCESS:
      return {
        ...state,
        allQuestions: action.data,
        allQuestionsErr: false
      }
    case helpConstants.GET_ALL_QUESTIONS_ERROR:
      return {
        ...state,
        allQuestions: false,
        allQuestionsErr: action.valuesError
      }
    case helpConstants.UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        updateQuestion: action.data,
        updateQuestionErr: false
      }
    case helpConstants.UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        updateQuestion: false,
        updateQuestionErr: action.valuesError
      }
    case helpConstants.GET_QUESTION_BY_ID_SUCCESS:
      return {
        ...state,
        getQuestionById: action.data,
        getQuestionByIdError: false,
      }
    case helpConstants.GET_QUESTION_BY_ID_ERROR:
      return {
        ...state,
        getQuestionById: false,
        getQuestionByIdError: action.valuesError,
      }
    case helpConstants.DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        deleteQuestion: action.data
      }
    case helpConstants.DELETE_QUESTION_ERROR:
      return {
        ...state,
        deleteQuestion: false
      }
    case helpConstants.GET_QUESTIONS_KEY_PLATFORM_SUCCESS:
      return {
        ...state,
        allQuestionsForscreen: action.data,
        allQuestionsForscreenErr: false,
      }
    case helpConstants.GET_QUESTIONS_KEY_PLATFORM_ERROR:
      return {
        ...state,
        allQuestionsForscreen: false,
        allQuestionsForscreenErr: action.valuesError,
      }
    default:
      return state;
  }
};
export { helpReducer };
