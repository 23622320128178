import { Button, Card, Typography } from "antd";
import React from "react";
import { services } from "../../Common/Components/Services";
import dayjs from "dayjs";
import { ArrowRightOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const { Paragraph, Title, Text } = Typography;

/**
 * BookingCard component displays a card for booking details with a visual indication of selection status and a "See more" button.
 * 
 * @returns {JSX.Element} The rendered BookingCard component.
 * 
 * @description
 * - **Title**: Displays the booking ID in the card's title.
 * - **Status**: Shows "ONGOING" if booking steps are less than 3; otherwise, it displays service details including the service name and time range.
 * - **Selection Indicator**: A checkmark icon appears if the card is selected.
 * - **See More Button**: Navigates to the "/accounts/tickets" route when clicked.
 */

const BookingCard = ({ booking, isSelected, handleCardClick }) => {
  const navigate = useNavigate();

  return (
    <Card
      bordered
      hoverable
      onClick={() => handleCardClick(booking.bookingUniqueId)}
      style={{
        marginRight: "0.5em",
        marginTop: "0.5em",
        marginBottom: "0.5em",
        borderColor: "rgba(0, 0, 0, 0.15)",
        position: "relative",
        height: "150px",
      }}
      styles={{ body: { padding: "10px" } }}
    >
      {isSelected && (
        <div
          style={{
            position: "absolute",
            right: 10,
            top: 0,
            fontSize: "1.6em",
            color: "green",
          }}
        >
          <CheckCircleOutlined />
        </div>
      )}

      <Title style={{ fontSize: "0.9em" }}>
        ORDER#{booking?.bookingUniqueId}
      </Title>
      {booking?.steps < 3 ? (
        <Text>ONGOING</Text>
      ) : (
        <Paragraph ellipsis={{ rows: 2, symbol: "more", expandable: false }}>
          {`${
            services.find(
              (service) => service.key === booking?.services?.[0]?.serviceName
            ).name
          } FROM ${dayjs(booking?.services?.[0]?.slots?.checkInDateTime).format(
            "MMM D, YYYY h:mm A"
          )} TO ${dayjs(booking?.services?.[0]?.slots?.checkOutDateTime).format(
            "MMM D, YYYY h:mm A"
          )}`}
        </Paragraph>
      )}
      <div style={{ position: "absolute", right: 0, bottom: 0 }}>
        <Button
          onClick={() => navigate("/accounts/tickets")}
          size="small"
          type="link"
          iconPosition="end"
          icon={<ArrowRightOutlined />}
        >
          See more
        </Button>
      </div>
    </Card>
  );
};

export default BookingCard;
