import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import styles from "../Styles/login.module.css";
import { Button, Card, Form, Image, Input, Spin, message, theme } from 'antd';
import logindogs from '../../Assets/Login/logindogs.png';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { signupAction } from '../State/actions';
import { navbarAction } from '../../Navbar/State/actions';
import Spinner from '../../Spinner/Spinner';

/**
 * SwitchRole component handles the OTP verification process for role switching.
 * 
 * @returns {React.ReactElement} A form for OTP verification with visual feedback and navigation.
 * 
 * @description
 * - This component is used for verifying an OTP sent for role switching.
 * - Displays a form where users can input their OTP.
 * - Shows success or error messages based on the OTP verification process.
 * - Redirects to the home page upon successful verification.
 * 
 * @returns {React.ReactElement} - A container with a card displaying the OTP form and handling loading states.
 */

const SwitchRole = () => {
  const location = useLocation();
  const isTab = useMediaQuery({ maxWidth: 576 });
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const { isLoading, isLoadingRequestOtp } = useSelector(str => str.loadingReducer);
  const { requestOtp, requestOtpError, verifyOtpError, verifyOtp } = useSelector(str => str.signupReducer);
  const navigate = useNavigate();
  const { useToken } = theme;
  const { token } = useToken();

  // called when user clicks on any of the role
  const onFinish = (values) => {
    dispatch(signupAction.verifyOTPEvent({ ...values, role: location.state }))
  }

  useEffect(() => {
    if (requestOtpError) {
      messageApi.open({
        type: "error",
        content: requestOtpError,
      });
    }
    if (requestOtp) {
      messageApi.open({
        type: "success",
        content: requestOtp,
      });

    }
    dispatch(signupAction.clearOTPdata());
  }, [requestOtp, requestOtpError])

  useEffect(() => {
    if (verifyOtpError) {
      messageApi.open({
        type: "error",
        content: "Invalid credentials",
      });
    }
    if (verifyOtp) {
      messageApi.open({
        type: "success",
        content: verifyOtp,
      });
      dispatch(navbarAction.getUserDetails());
      navigate("/")
    }
    dispatch(signupAction.clearOTPdata());
  }, [verifyOtpError, verifyOtp])

  if (isLoadingRequestOtp) {
    return <Spinner />
  }

  return (
    <div className={styles.loginpage} style={{ flexDirection: "column" }}>
      <div style={{ display: isTab ? "none" : "", width: isTab ? "" : "500px" }}  >
        <Image src={logindogs} alt="dogs" preview={false} />
      </div>
      <div>
        {contextHolder}
        <Card
          hoverable
          style={{ width: isTab ? "100%" : "500px", height: isTab ? "100%" : "" }}
        >
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item name={"otp"} style={{ textAlign: "center" }}
              rules={[
                {
                  required: true,
                  message: "Please input your otp",
                },
              ]}
            >
              <Input.OTP />
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
              <Button
                htmlType="submit"
                style={{
                  width: "100%",
                  height: "45px",
                  backgroundColor: token.appbluecolor,
                  color: token.White,
                  fontSize: "18px",
                }}
                loading={isLoading}
              >
                Enter OTP
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  )
}

export default SwitchRole

