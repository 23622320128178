import { startLoading, startLoadingAllBoardingCenters, startLoadingQuestionById, stopLoading, stopLoadingAllBoardingCenters, stopLoadingQuestionById } from "../../state/actions"
import { boardCenterApi } from "../utils/api"
import { boardCenterConstants } from "./action-types"


const boardCenterUpdateSuccess = (data) => {
    return {
        type: boardCenterConstants.UPDATE_BOARD_CENTER_SUCCESS,
        data: data,
    }
}

const boardCenterUpdateError = (err) => {
    return {
        type: boardCenterConstants.UPDATE_BOARD_CENTER_ERROR,
        valuesError: err,
    }
}

const boardCenterUpdateEvent = (id, data) => {
    return (dispatch) => {
        dispatch(startLoading());
        boardCenterApi.updateBoardCenter(id, data).then(response => {
            dispatch(boardCenterUpdateSuccess(response.data.info));
            dispatch(stopLoading())
        })
            .catch(err => {
                dispatch(boardCenterUpdateError(err?.response?.data?.message));
                dispatch(stopLoading());
            })
    }
}

const getAllBoardCenterSuccess = (data) => {
    return {
        type: boardCenterConstants.GET_ALL_BOARDCENTER_SUCCESS,
        data: data,
    }
}

const getAllBoardCenterErr = (err) => {
    return {
        type: boardCenterConstants.GET_ALL_BOARDCENTER_ERROR,
        valuesError: err,
    }
}

const getAllBoardCentersEvent = () => {
    return (dispatch) => {
        dispatch(startLoadingAllBoardingCenters());
        boardCenterApi.getBoardCenters().then(response => {
            dispatch(getAllBoardCenterSuccess(response.data.info));
            dispatch(stopLoadingAllBoardingCenters())
        })
            .catch(err => {
                dispatch(getAllBoardCenterErr(err));
                dispatch(stopLoadingAllBoardingCenters())
            })
    }
}

const addBoardCenterSuccess = (data) => {
    return {
        type: boardCenterConstants.ADD_BOARDING_CENTER_SUCCESS,
        data: data,
    }
}

const addBoardCenterErr = (err) => {
    return {
        type: boardCenterConstants.ADD_BOARDING_CENTER_ERROR,
        valuesError: err,
    }
}

const addBoardCenterEvent = (data) => {
    return (dispatch) => {
        dispatch(startLoading());
        boardCenterApi.addBoardCenter(data).then(response => {
            dispatch(addBoardCenterSuccess(response.data.info));
            dispatch(stopLoading());
        })
            .catch(err => {
                dispatch(addBoardCenterErr(err?.response?.data?.message));
                dispatch(stopLoading());
            })
    }
}

const getBoardCenterByIdSuccess = (data) => {
    return {
        type: boardCenterConstants.GET_BOARDCENTER_BY_ID_SUCCESS,
        data: data,
    }
}

const getBoardCenterByIdErr = (err) => {
    return {
        type: boardCenterConstants.GET_BOARDCENTER_BY_ID_ERROR,
        valuesError: err,
    }
}

const getBoardCenterByIdEvent = (id) => {
    return (dispatch) => {
        dispatch(startLoadingQuestionById());
        boardCenterApi.getBoardCenterById(id).then(response => {
            dispatch(getBoardCenterByIdSuccess(response.data.info));
            dispatch(stopLoadingQuestionById());
        })
            .catch(err => {
                dispatch(getBoardCenterByIdErr(err));
                dispatch(stopLoadingQuestionById());
            })
    }
}

const clearBoardCenter = () => {
    return {
        type: boardCenterConstants.CLEAR_BOARD_CENTER,
    };
}


export const boardCenterAction = {
    boardCenterUpdateEvent,
    getAllBoardCentersEvent,
    addBoardCenterEvent,
    getBoardCenterByIdEvent,
    clearBoardCenter
};

