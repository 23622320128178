import { theme } from "antd";
import { mappls, mappls_plugin } from "mappls-web-maps";
import { useEffect, useRef } from "react";

const mapplsClassObject = new mappls();
const mapplsPluginObject = new mappls_plugin();
var marker;

/**
 * React component for rendering a Mappls map with a search box and draggable marker.
 * It allows users to search for locations and select them, with the selected address 
 * passed back through a callback function.
 *
 * @param {Object} props - The component props.
 * @param {function} props.handleAddressSelect - Callback function to handle the selected address data.
 * @param {Object} props.address - The initial address data including eLoc, latitude, and longitude.
 * @param {Object} props.tokendata - Token data required for initializing the Mappls map.
 * @param {number|string} props.height - Height of the map container.
 *
 * @returns {JSX.Element} The rendered map with a search box and draggable marker.
 *
 * @description
 * - Initializes the Mappls map using the provided token.
 * - Displays a draggable marker on the map based on the provided address.
 * - Includes a search box that allows users to search for places or eLocs.
 * - Updates the marker position when the map is clicked or the search is used.
 * - Calls `handleAddressSelect` with the new address details when the marker is dragged.
 */


const Mapss = ({ handleAddressSelect, address, tokendata, height }) => {
  const {useToken} = theme;
  const {token} = useToken();
  const mapRef = useRef(null);
  const auto = {
    width: "300px",
    position: "absolute",
    zIndex: 999,
    fontSize: "15px",
    padding: "10px",
    border: "1px solid #ddd",
    outline: "none !important",
  };

  const loadObject = { map: true, plugins: ["search"] };

  useEffect(() => {
    mapplsClassObject?.initialize(tokendata?.access_token, loadObject, () => {
      const newMap = new mapplsClassObject.Map({
        id: "map",
        properties: {
          // center: [28.09, 78.3],
          draggable: true,
          zoom: 5,
        },
      });

      newMap?.on("load", () => {
        if (address && Object.keys(address).length > 0) {
          var eloc = address?.eLoc;
          // var place = address?.fullAddress;

          if (eloc) {
            if (marker) marker.remove();
            mapplsPluginObject.pinMarker(
              {
                map: newMap,
                pin: eloc,
                draggable: true,
              },
              function (data) {
                marker = data;
                marker.fitbounds();
                marker.addListener("dragend", (e) => {
                  var picker;
                  console.log("Marker drag end:", e.latlng.lng, e.latlng.lat);
                  mapplsPluginObject.placePicker(
                    { location: { lat: e.latlng.lat, lng: e.latlng.lng } },
                    callback
                  );
                  function callback(data) {
                    picker = data;
                    handleAddressSelect(picker);
                  }
                });
              }
            );
          } else {
            new mapplsClassObject.Marker(
              {
                position: { lat: address.latitude, lng: address.longitude },
                map: newMap,
                draggable: true,
              },
              // marker.openPopup();
              function (data) {
                marker = data;
                marker.fitbounds();
                marker.addListener("dragend", (e) => {
                  var picker;
                  console.log("Marker drag end:", e.latlng.lng, e.latlng.lat);
                  mapplsPluginObject.placePicker(
                    { location: { lat: e.latlng.lat, lng: e.latlng.lng } },
                    callback
                  );
                  function callback(data) {
                    picker = data;
                    handleAddressSelect(picker);
                    // setSelectedAddress(picker.formatted_address);
                    // console.log(picker, "picker");
                  }
                });
              }
            );
          }
        }
        var optional_config = {
          region: "IND",
          height: 300,
        };
        mapplsPluginObject.search(
          document.getElementById("auto"),
          optional_config,
          callback
        );
        function callback(data) {
          console.log(data);
          var p;
          if (data) {
            var dt = data[0];
            if (!dt) return false;
            if (!dt.placeAddress) {
              mapplsPluginObject.placePicker(
                { location: { lat: dt.latitude, lng: dt.longitude } },
                callback
              );
              function callback(data) {
                p = data;
                handleAddressSelect(p?.data);
              }
            } else {
              handleAddressSelect(dt);
            }

            var eloc = dt?.eLoc;

            /*Use elocMarker Plugin to add marker*/
            if (marker) marker.remove();
            mapplsPluginObject.pinMarker(
              {
                map: newMap,
                pin: eloc,
                draggable: true,
                color: token.Red,
              },
              function (data) {
                marker = data;
                marker.fitbounds();
                marker.addListener("dragend", (e) => {
                  // eslint-disable-next-line
                  let picker;
                  mapplsPluginObject.placePicker(
                    { location: { lat: e.latlng.lat, lng: e.latlng.lng } },
                    callback
                  );
                  function callback(data) {
                    // console.log(data, "data");
                    picker = data;
                    handleAddressSelect(data);
                  }
                });
              }
            );
          }
        }
      });
      mapRef.current = newMap;
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      mapRef.current = null;
      if(marker) {
        marker.remove();
      }
    }
  },[])

  return (
    <div>
      <div
        id="map"
        style={{ width: "100%", height: height, display: "inline-block" }}
      >
        <input
          style={auto}
          type="text"
          // autoFocus
          id="auto"
          name="auto"
          className="search-outer form-control as-input"
          placeholder="Search places or eLoc's..."
          required=""
          spellCheck="false"
        />
      </div>
    </div>
  );
};

export default Mapss;
