import React from "react";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner/Spinner";
import TimeLineComponent from "./TimeLineComponent";
import { Collapse } from "antd";

const UserStatus = () => {
  const { isLoading } = useSelector((str) => str.loadingReducer);
  const { bookappointmentdetailsbyID } = useSelector(
    (str) => str.bookAppointmentReducer
  );

  if (isLoading) {
    return <Spinner />;
  }

  const items = [];

  if (bookappointmentdetailsbyID) {
    const {
      petPickupAndDrop_status,
      petBoarding_status,
    } = bookappointmentdetailsbyID.status;

    if (petPickupAndDrop_status?.initiated?.status) {
      items.push({
        label: "Check Pickup and drop status",
        key: "1",
        children: (
          <TimeLineComponent
            completeDetails={bookappointmentdetailsbyID.status}
            info="petPickupAndDrop_status"
          />
        ),
      });
    }

    if (petBoarding_status?.initiated?.status) {
      items.push({
        label: "Check pet boarding status",
        key: "2",
        children: (
          <TimeLineComponent
            completeDetails={bookappointmentdetailsbyID.status}
            info="petBoarding_status"
          />
        ),
      });
    }
  }

  return (
    <div>
      <Collapse items={items} defaultActiveKey={"1"} size="small" />
    </div>
  );
};

export default UserStatus;
