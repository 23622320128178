// ThemeToggle.js
import React, { createContext, useContext, useState } from 'react';
import { ConfigProvider } from 'antd';
import { lightTheme, darkTheme } from './themeconfig';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

const ThemeToggle = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);


    const toggleTheme = () => {
        setIsDarkMode(!isDarkMode);
    };

    return (
        <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
            <ConfigProvider theme={isDarkMode ? darkTheme : lightTheme}>
                {children}
            </ConfigProvider>
        </ThemeContext.Provider>
    );
};

export default ThemeToggle;
