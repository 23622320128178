import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { profileAction } from "../../Common/State/actions";
import { resetName, setName } from "../../state/actions";

/**
 * @function useCheckEffects
 * @description Custom React hook that handles dispatching actions related to profile and page name effects.
 * - On mount, it triggers an action to fetch parent boarding details.
 * - On mount, it also sets the page name in the state, and cleans up by resetting the name on unmount.
 *
 * @param {string} pageName - The name of the current page, which is set in the state and reset on component unmount.
 *
 * @returns {void} - This hook does not return anything.
 */

const useCheckEffects = (pageName) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profileAction.parentBoardingDetailsEvent());
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    dispatch(setName(pageName));
    return () => {
      dispatch(resetName());
    };
    // eslint-disable-next-line
  }, [dispatch, pageName]);
};

export default useCheckEffects;

