export const pets = {
    dog: [
        "Labrador Retriever",
        "German Shepherd",
        "Golden Retriever",
        "Bulldog",
        "Beagle",
        "Poodle",
        "French Bulldog",
        "Yorkshire Terrier",
        "Boxer",
        "Dachshund",
        "Siberian Husky",
        "Great Dane",
        "Rottweiler",
        "Doberman Pinscher",
        "Shih Tzu",
        "Pembroke Welsh Corgi",
        "Australian Shepherd",
        "Border Collie",
        "Cavalier King Charles Spaniel",
        "Maltese",
    ],
    cat: [
        "Bombay",
        "Siamese",
        "Himalayan cat",
        "Maine Coon",
        "Bengal cat",
        "American Bobtail",
        "Persian cat",
        "Ragdoll",
        "British Shorthair",
        "Abyssinian",
        "Sphynx",
        "Birman",
        "American Shorthair",
        "Rusty-spotted cat",
        "Scottish Fold",
        "Oriental Shorthair",
        "Balinese cat",
        "Leopard cat",
        "Tonkinese cat",
        "Norwegian Forest cat",
        "Devon Rex",
        "Russian Blue",
        "Burmese",
    ],
}