import { CaretRightOutlined, SearchOutlined } from "@ant-design/icons";
import { Col, Collapse, Input, Row, Typography, theme } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchAction } from "./State/actions";
import Spinner from "../Spinner/Spinner";
import PetCard from "./Components/PetCard";
import ParentCard from "./Components/ParentCard";
import AddressCard from "./Components/AddressCard";
import { debounceFun } from "../Common/Components/DebounceSearchFun";
import useSettingName from "../Common/Components/useSettingName";

const { Title, Text } = Typography;

/**
 * Search component provides a search functionality with results categorized by type.
 * It includes a search input field and displays results based on the search query.
 * 
 * @returns {JSX.Element} The rendered Search component.
 *
 * @description
 * - **Search Input**: An `Input` component with a search icon for querying search results.
 * - **Debounced Search**: Uses a debounced function (`debounceFun`) to trigger search actions after a delay.
 * - **Loading State**: Shows a spinner during the loading state.
 * - **Error Handling**: Displays an error message if search results fail to load.
 * - **No Results**: Displays a message if no results are found.
 */


const Search = () => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((str) => str.loadingReducer);
    const { searchResults, searchResultsErr } = useSelector((str) => str.searchReducer);
    const { useToken } = theme;
    const { token } = useToken();

    const searchClientFunc = debounceFun((value) => {
        dispatch(searchAction.searchEvent(value));
    }, 1000);

    const renderItems = (results) => {
        return results.map((e) => ({
            key: e.questionUniqueId,
            label: (
                <Typography.Text style={{ fontWeight: 500 }}>{e.title}</Typography.Text>
            ),
            children: <p dangerouslySetInnerHTML={{ __html: e.description }} />,
        }));
    };

    const renderCategory = (category, results) => {
        if (category === "help") {
            return (
                <div key={category}>
                    <Title level={4} style={{ marginTop: "1em" }}>{category.toUpperCase()}</Title>
                    <Collapse
                        expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                        bordered={false}
                        size="small"
                        items={renderItems(results)}
                    />
                </div>
            );
        }
        if (category === "pet") {
            return (
                <>
                    <Title level={4} style={{ marginTop: "1em" }}>{category.toUpperCase()}</Title>
                    {
                        results?.map(e => <PetCard e={e} />)
                    }
                </>
            )
        }
        if (category === "user") {
            return (
                <>
                    <Title level={4} style={{ marginTop: "1em" }}>{category.toUpperCase()}</Title>
                    {
                        results?.map(e => <ParentCard e={e} />)
                    }
                </>
            )
        }
        if (category === "address") {
            return (
                <>
                    <Title level={4} style={{ marginTop: "1em" }}>{category.toUpperCase()}</Title>
                    <AddressCard e={results} />
                </>
            )
        }
    };

    useSettingName("Search")

    useEffect(() => {
        return () => {
            dispatch(searchAction.clearSearch());
        }
    }, []);

    return (
        <Row style={{ marginTop: "1.2em", marginBottom: "1.2em" }}>
            <Col xs={24} sm={24} md={{ span: 20, offset: 2 }} lg={{ span: 18, offset: 3 }} xl={{ span: 12, offset: 6 }} style={{ position: "sticky", top: "90px", zIndex: 1000 }}>
                <Input
                    prefix={<SearchOutlined style={{ color: token.GreyShadeLight }} />}
                    allowClear
                    autoFocus
                    size="large"
                    placeholder="How can we help?"
                    onChange={(e) => {
                        return (
                            e.target.value.trim().length > 0 && searchClientFunc(e.target.value)
                        );
                    }}
                />
            </Col>
            <Col xs={24} sm={24} md={{ span: 20, offset: 2 }} lg={{ span: 18, offset: 3 }} xl={{ span: 12, offset: 6 }}>
                {isLoading ? (
                    <Spinner />
                ) : searchResults && searchResults.length > 0 ? (
                    searchResults.map((result) => {
                        const [category, results] = Object.entries(result)[0];
                        return renderCategory(category, results);
                    })
                ) : searchResultsErr ? (
                    <Title level={3}>{searchResultsErr}</Title>
                ) : (
                    searchResults && searchResults.length === 0 ? (
                        <Text>No results found!!</Text>
                    ) : ""
                )}
            </Col>
        </Row>
    );
};

export { Search };