import React from "react";
import { Typography, Tag, Row, Col, theme } from "antd";
import styles from "../Styles/finalPage.module.css";
import { EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { services } from "../../Common/Components/Services";
const { Title } = Typography;

/**
 * @component FinalSelectedServices
 * @description Displays selected services, locations, and check-in/out times for a appointment.
 * @param {Object} details - The details of the selected services and locations.
 */

const FinalSelectedServices = ({ details }) => {
  const navigate = useNavigate();
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <>
      <div className={styles.displayedit}>
        <Row justify={"space-between"} style={{ alignItems: "center" }}>
          <Title level={3} style={{ marginTop: 0, color: token.Primary }}>
            Service Details
          </Title>
          <EditOutlined
            style={{ fontSize: "1.2em", color: token.Primary }}
            onClick={() => navigate("/book-appointment/step-2")}
          />
        </Row>
        <Row style={{ marginTop: "1.2em" }}>
          <Col span={10} className={styles.col}>
            Pet Pickup Location
          </Col>
          <Col span={2} className={styles.col}>
            :
          </Col>
          <Col span={12} className={styles.col}>
            {details?.pickUpAddress?.fullAddress}
          </Col>
          <Col span={10} className={styles.col}>
            Pet Drop Location
          </Col>
          <Col span={2} className={styles.col}>
            :
          </Col>
          <Col span={12} className={styles.col}>
            {details?.dropAddress?.fullAddress}
          </Col>
          <Col span={10} className={styles.col}>
            Boarding Center Location
          </Col>
          <Col span={2} className={styles.col}>
            :
          </Col>
          <Col span={12} className={styles.col}>
            {details?.boardCenter?.fullAddress}
          </Col>
          {details?.services?.map((service) => (
            <>
              <Col span={24}>
                <Tag
                  key={uuidv4()}
                  icon={
                    service.serviceType === "addon" ? <PlusCircleFilled /> : ""
                  }
                  className={styles.tag}
                  style={{ marginBottom: "5px" }}
                  // color={Colors.Red}
                >
                  {services.find((e) => e.key === service.serviceName).name}
                  {/* {service?.serviceName} */}
                </Tag>
              </Col>
              <Col span={10} className={styles.col}>
                Check In Date and Time
              </Col>
              <Col span={2} className={styles.col}>
                :
              </Col>
              <Col span={12} className={styles.col}>
                {service?.slots?.checkInDateTime
                  ? ` ${dayjs(service?.slots?.checkInDateTime).format(
                      "MMM D, YYYY h:mm A"
                    )}`
                  : "--"}
              </Col>
              <Col span={10} className={styles.col}>
                Check Out Date and Time
              </Col>
              <Col span={2} className={styles.col}>
                :
              </Col>
              <Col span={12} className={styles.col}>
                {service?.slots?.checkOutDateTime
                  ? ` ${dayjs(service?.slots?.checkOutDateTime).format(
                      "MMM D, YYYY h:mm A"
                    )} `
                  : "--"}
              </Col>
            </>
          ))}
        </Row>
      </div>
    </>
  );
};

export default FinalSelectedServices;
