import { Alert } from "antd";
import { bookAppointmentAction } from "../State/bookAppointmentActions";

//  in step2 when user clicks on next this function would be called
export const handleFinish = (
  address,
  form,
  bookappointmentdetailsbyID,
  navigate,
  dispatch,
  dateTime
) => {
  const { pickupaddress, dropaddress } = address;
  if (
    Object.keys(pickupaddress).length === 0 ||
    Object.keys(dropaddress).length === 0
  ) {
    return <Alert message="Please enter valid address" type="error" />
  }
  const bookingDetails = {
    pickUpAddress: {
      state: pickupaddress?.state,
      city: pickupaddress?.city,
      district: pickupaddress?.district,
      pincode: pickupaddress?.pincode,
      houseNumber: pickupaddress?.houseNumber,
      houseName: pickupaddress?.houseName,
      street: pickupaddress?.street,
      landmark: pickupaddress?.landmark,
      eLoc: pickupaddress?.eLoc,
    },
    dropAddress: {
      state: dropaddress?.state,
      city: dropaddress?.city,
      district: dropaddress?.district,
      pincode: dropaddress?.pincode,
      houseNumber: dropaddress?.houseNumber,
      houseName: dropaddress?.houseName,
      street: dropaddress?.street,
      landmark: dropaddress?.landmark,
      eLoc: dropaddress?.eLoc,
    },
  };
  bookappointmentdetailsbyID?.services.forEach((service) => {
    bookingDetails[service?.serviceName] = {
      checkInDateTime: dateTime[service?.serviceName]?.checkInDateTime,
      checkOutDateTime: dateTime[service?.serviceName]?.checkOutDateTime,
    };
  });
  dispatch(bookAppointmentAction.udpdatedetailsEvent(bookingDetails));
  form.resetFields();
  navigate("/book-appointment/step-3");
};
