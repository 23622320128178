import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Row, Typography } from 'antd'
import React from 'react'
import styles from "../Styles/mycalendar.module.css";
const { Text } = Typography;

/**
 * @component
 * @description The `TimeSlots` component displays a list of available time slots for a selected date. It includes
 * visual indicators for slot availability (e.g., available, filling soon, no slots) and allows the user to select a time slot.
 * Users can navigate back to the calendar view from this component.
 *
 * @param {Function} setShowTimeSlots - Function to toggle the visibility of the time slots view.
 * @param {Array} selectedTimeSlots - Array of time slots available for the selected date.
 * @param {Function} handleTime - Function to handle the selection of a time slot.
 * @param {Object} slotDetails - Details about the slot, including the total number of slots available.
 *
 * @returns {JSX.Element} The `TimeSlots` component.
 */


const TimeSlots = ({ setShowTimeSlots, selectedTimeSlots, handleTime, slotDetails }) => {
    return (
        <>
            <div
                style={{ cursor: "pointer" }}
                onClick={() => setShowTimeSlots(false)}
            >
                <ArrowLeftOutlined /> Back
            </div>
            <Row gutter={[16, 16]} justify={"center"}>
                {selectedTimeSlots?.map((time) => (
                    <Col
                        span={7}
                        className={styles.time}
                        style={{
                            background:
                                time.availableSlots === 0
                                    ? "lightGrey"
                                    : time.availableSlots < slotDetails.totalSlots
                                        ? "lightYellow"
                                        : "lightGreen",
                        }}
                    >
                        <Text onClick={() => handleTime(time)}>{time?.time}</Text>
                    </Col>
                ))}
            </Row>
            <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <div
                    className={styles.indicator}
                    style={{ background: "lightRed" }}
                ></div>{" "}
                &nbsp;
                <Text>No Slots</Text>
            </Row>
            <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <div
                    className={styles.indicator}
                    style={{ background: "lightGreen" }}
                ></div>{" "}
                &nbsp;
                <Text>Slots available</Text>
            </Row>
            <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <div
                    className={styles.indicator}
                    style={{ background: "lightYellow" }}
                ></div>{" "}
                &nbsp;
                <Text>Slots filling soon</Text>
            </Row>
        </>
    )
}

export default TimeSlots
