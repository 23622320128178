// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { navbarAction } from "../Navbar/State/actions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPb_7b8Lm4QPQXMlJkm57_qIW_IecgeFU",
  authDomain: "my-pet-419108.firebaseapp.com",
  projectId: "my-pet-419108",
  storageBucket: "my-pet-419108.appspot.com",
  messagingSenderId: "524855669404",
  appId: "1:524855669404:web:62f4ee7adcbd8eac07b359",
  measurementId: "G-1TXEP6N59W",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
const useGenerateToken = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const requestPermission = async () => {
      try {
        const permision = await Notification.requestPermission();
        console.log(permision);
        if (permision === "granted") {
          const token = await getToken(messaging, {
            vapidKey:
              "BEAB1fv2XvaYho0YfYMWvVZey2mdESrjhyjqmC8xFhnKekoSw_KOLwAivWnRvNcZo1kxocOgkAOW8MLI17rrJG4",
          });
          // console.log(token,"token")
          const storedToken = localStorage.getItem("fcmToken");
          // console.log(token,storedToken,"ds");
          if (token && token !== storedToken) {
            dispatch(navbarAction.sendNotificationsEvent({ token: token }));
          }
          // const data = {
          //     token: token,
          //     message: {
          //         title: "test title",
          //         body: "test body",
          //     }
          // }
          // dispatch(navbarAction.sendNotificationsEvent(data))
        }
      } catch (error) {
        console.log(error, "error getting permission");
      }
    };
    requestPermission();
    // eslint-disable-next-line
  }, []);
};

export default useGenerateToken;
