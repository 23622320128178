
export const commonBodyForpetPickupAndDrop_status = (id, statusName) => {
    let data = {
        bookingId: id,
        petPickupAndDrop_status: {
            boarding_center_to_parent_location_to_pickup_pet: {
                [statusName]: {
                    status: "accept",
                },
            },
        },
    }
    return data;
}

export const commonBodyForpetPickupAndDrop_status_drop = (id, statusName) => {
    let data = {
        bookingId: id,
        petPickupAndDrop_status: {
            boarding_center_to_parent_location_drop_pet: {
                [statusName]: {
                    status: "accept",
                },
            },
        },
    }
    return data;
}


export const commonBodyForpetPickupAndDrop_status_decline = (id, statusName, reason) => {
    let data = {
        bookingId: id,
        petPickupAndDrop_status: {
            boarding_center_to_parent_location_to_pickup_pet: {
                [statusName]: {
                    status: "decline",
                    reason
                },
            },
        },
    }
    return data;
}

export const commonBodyForpetBoarding_status = (id, statusName) => {
    let data = {
        bookingId: id,
        petBoarding_status: {
            [statusName]: {
                status: "accept",
            }
        },
    }
    return data;
}

export const commonBodyForpetBoarding_status_Decline = (id, statusName, reason) => {
    let data = {
        bookingId: id,
        petBoarding_status: {
            [statusName]: {
                status: "decline",
                reason: reason
            }
        },
    }
    return data;
}

