import { Card, Col, Empty, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardAction } from "../State/actions";
import Spinner from "../../Spinner/Spinner";
import { v4 as uuidv4 } from "uuid";
import Slider from "react-slick";
import ActionCenterCard from "./ActionCenterCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
const { Title } = Typography;

/**
 * @description
 * The `ActionCenter` component renders list of all the cards where the pending or continuation actions to be done
 * @param - no params needed
 */
const Actioncentre = () => {
  const dispatch = useDispatch();
  const { actionCenterDetails } = useSelector((str) => str.dashboardReducer);
  const { isLoading } = useSelector((str) => str.loadingReducer);
  const { updatedboardingcenter } = useSelector((str) => str.addressReducer);
  const { t } = useTranslation();
  
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    autoplay: true,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    useCSS: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          centerMode: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(dashboardAction.getActionCenterDetailsEvent());
    // eslint-disable-next-line
  }, [updatedboardingcenter]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Card
      style={{ marginTop: "1.2em", marginBottom: "1.2em" }}
      id="actionCenter"
    >
      <Title level={3} style={{ marginTop: 0 }}>
        {t("action_center")}
      </Title>
      <Row gutter={[16, 0]}>
        {actionCenterDetails?.length === 0 ||
        actionCenterDetails === undefined ? (
          <Empty
            style={{ marginTop: "100px" }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : actionCenterDetails?.length > 3 ? (
          <Col key={uuidv4()}>
            <Slider {...settings} style={{ marginBottom: "20px" }}>
              {actionCenterDetails &&
                actionCenterDetails?.map((action) => (
                  <ActionCenterCard action={action} key={uuidv4()} />
                ))}
            </Slider>
          </Col>
        ) : (
          actionCenterDetails &&
          actionCenterDetails?.map((action) => (
            <Col key={uuidv4()} xs={24} sm={24} md={12} lg={12} xxl={8}>
              <ActionCenterCard action={action} key={uuidv4()} />
            </Col>
          ))
        )}
      </Row>
    </Card>
  );
};

export default Actioncentre;
