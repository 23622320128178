import React, { useEffect, useState } from "react";
import { Col, FloatButton, Row } from "antd";
import ServiceHistoryBarChart from "./Components/ServiceHistoryBarChart";
import TodayHistory from "./Components/TodayHistory";
import RecentServices from "./Components/RecentServices";
import BoardcenterDetails from "./Components/BoardcenterDetails";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CaptainDetail from "./Components/CaptainDetail";
import { useRoute } from "../context/RouteContext";
import { CustomerServiceOutlined } from "@ant-design/icons";
import SupportForm from "../Support/Components/SupportForm";
import CommonModal from "../CommonModal/CommonModal";
import { supportAction } from "../Support/State/actions";
import UserHome from "./Components/UserHome";
import AdminHome from "./Components/AdminHome";
import Spinner from "../Spinner/Spinner";

const Dashboard = () => {
  const { alladdress } = useSelector((str) => str.addressReducer);
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const { isLoadingAddresses, isLoadingUserDetails } = useSelector((str) => str.loadingReducer);
  const navigate = useNavigate();
  const { setRouteKey,setSelectedKey } = useRoute();
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const handleModalOpen = () => {
    setVisible(true);
    dispatch(supportAction.getAllBookingsOfUserEvent());
  };
  const handleModalClose = () => setVisible(false);

  useEffect(() => {
    setRouteKey("home");
    setSelectedKey('DASHBOARD');
  }, [setRouteKey]);

  useEffect(() => {
    if (!isLoadingAddresses && alladdress === false) {
      navigate("/addAddress");
    }
  }, [alladdress, isLoadingAddresses]);

  if (isLoadingUserDetails) return <Spinner />

  return (
    <>
      {userDetails?.currentRole === "USER" && (
        <>
          <UserHome />
          <FloatButton.Group
            icon={<CustomerServiceOutlined />}
            trigger="click"
            onClick={handleModalOpen}
          />
          {visible && (
            <CommonModal
              isModalOpen={visible}
              width={700}
              height={window.innerHeight * 0.8}
              handleCancel={handleModalClose}
              body={() => <SupportForm setVisible={setVisible} />}
              closeIcon={true}
            />
          )}
        </>
      )}
      {(userDetails?.currentRole === "ADMIN" ||
        userDetails?.currentRole === "SUPER_ADMIN") && <AdminHome />}
      {userDetails?.currentRole === "SUPPORT" && (
        <>
          <Row gutter={[32, 16]}>
            <Col xs={24} sm={24} md={24} lg={14} xl={16}>
              <ServiceHistoryBarChart />
            </Col>
            <Col xs={24} sm={24} md={12} lg={10} xl={8}>
              <TodayHistory />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <RecentServices />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <BoardcenterDetails />
            </Col>
          </Row>
        </>
      )}
      {userDetails?.currentRole === "CAPTAIN" && (
        <>
          <Row gutter={[32, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <CaptainDetail />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export { Dashboard };
