import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { services } from "../../Common/Components/Services";
import useWarnings from "../../Common/Components/useWarnings";
import { crudPlansApi } from "../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { boardCenterAction } from "../../BoardingCenter/State/actions";
const { TextArea } = Input;

const CreatePlanForm = () => {
    const [form] = Form.useForm();
    const [laoding, setLoading] = useState(false);
    const { userDetails } = useSelector((str) => str.navbarReducer);
    const planScopeOptions = [
        {
            value: "global",
            label: "Global",
            disabled: userDetails?.currentRole !== "SUPER_ADMIN",
        },
        {
            value: "state",
            label: "State",
            disabled: userDetails?.currentRole === "CITY_ADMIN" || userDetails?.currentRole === "ADMIN",
        },
        {
            value: "city",
            label: "City",
            disabled: userDetails?.currentRole === "STATE_ADMIN" || userDetails?.currentRole === "ADMIN",
        },
        {
            value: "boardingCenter",
            label: "Boarding Center",
            disabled:
                userDetails?.currentRole === "STATE_ADMIN" ||
                userDetails?.currentRole === "CITY_ADMIN",
        },
    ];
    const { success, error, contextHolder } = useWarnings();

    const dispatch = useDispatch();
    const { allBoardcenters } = useSelector((str) => str.boardCenterReducer);
    const { isLoadingAllBoardCenters } = useSelector((str) => str.loadingReducer);

    useEffect(() => {
        if (userDetails?.currentRole === "SUPER_ADMIN")
            dispatch(boardCenterAction.getAllBoardCentersEvent());
    }, [userDetails]);

    const onFinish = async (values) => {
        const { itemName, itemDescription, itemAmount, ...rest } = values;
        const formData = {
            ...rest,
            interval: values?.period === "daily" ? 7 : 1,
            item: {
                name: itemName,
                description: itemDescription,
                amount: itemAmount,
                currency: "INR",
            },
        };
        setLoading(true);
        try {
            const createPlan = await crudPlansApi.createPlan(formData);
            // console.log(createPlan, "createPlan");
            if (createPlan?.data?.info?.id) success("Plan creation successfull");
            if (createPlan?.data?.info?.message) success(createPlan?.data?.info?.message);
            form.resetFields();
            setLoading(false);
        } catch (err) {
            error(err?.response?.data?.message);
            setLoading(false);
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            {contextHolder}
            <Row gutter={16}>
                <Col xs={24} sm={24} md={12} xl={8}>
                    <Form.Item
                        label="Period"
                        name={"period"}
                        rules={[{ required: true }]}
                    >
                        <Select
                            options={[
                                { label: "Daily", value: "daily" },
                                { label: "Monthly", value: "monthly" },
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} xl={8}>
                    <Form.Item
                        label="Plan Name"
                        name={"itemName"}
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} xl={8}>
                    <Form.Item
                        label="Plan Amount"
                        name={"itemAmount"}
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} xl={8}>
                    <Form.Item
                        label="Plan scope"
                        name={"planScope"}
                        rules={[{ required: true }]}
                    >
                        <Select options={planScopeOptions} />
                    </Form.Item>
                </Col>
                {/* conditional Field for global, city, state*/}
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.planScope !== currentValues.planScope
                    }
                >
                    {({ getFieldValue }) => {
                        const planScope = getFieldValue("planScope");
                        return planScope &&
                            (planScope === "state" || planScope === "city") ? (
                            <Col xs={24} sm={24} md={12} xl={8}>
                                <Form.Item
                                    rules={[{ required: true }]}
                                    label={planScope}
                                    name={planScope}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        ) : planScope === "boardingCenter" && userDetails?.currentRole === "SUPER_ADMIN" ? (
                            <Col xs={24} sm={24} md={12} xl={8}>
                                <Form.Item
                                    rules={[{ required: true }]}
                                    label={planScope}
                                    name={planScope}
                                >
                                    <Select
                                        loading={isLoadingAllBoardCenters}
                                        options={
                                            allBoardcenters &&
                                            allBoardcenters?.map((e) => ({
                                                label: `${e.name}-${e.code_word}`,
                                                value: e.code_word,
                                            }))
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        ) : planScope === "boardingCenter" && userDetails?.currentRole === "ADMIN" ?<Col xs={24} sm={24} md={12} xl={8}>
                            <Form.Item
                                rules={[{ required: true }]}
                                label={planScope}
                                name={planScope}
                                initialValue={userDetails?.codeWord}
                            >
                                <Input value={userDetails?.codeWord}/>
                            </Form.Item>
                        </Col>: null
                    }}
                </Form.Item>
                <Col xs={24} sm={24} md={12} xl={8}>
                    <Form.Item
                        label="Services"
                        name={"services"}
                        rules={[{ required: true }]}
                    >
                        <Select
                            mode="multiple"
                            options={services?.map((e) => ({
                                value: e?.key,
                                label: e?.name,
                            }))}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} xl={8}>
                    <Form.Item
                        label="Expiry date"
                        name={"expiresAt"}
                        rules={[{ required: true }]}
                    >
                        <DatePicker size="small" showTime style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} xl={12}>
                    <Form.Item label="Plan Description" name={"itemDescription"}>
                        <TextArea autoSize />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Button type="primary" htmlType="submit" size="small" loading={laoding}>
                    Submit
                </Button>
            </Row>
        </Form>
    );
};

export default CreatePlanForm;
