import {
  startLoading,
  startLoadingAllConfirmedBookings,
  startLoadingKennels,
  stopLoading,
  stopLoadingAllConfirmedBookings,
  stopLoadingKennels,
} from "../../state/actions";
import { admingApi, getBookAppointmentDetails } from "../utils/api";
import { adminConstants } from "./action-types";

const getConfirmedBookingDetailsError = (err) => {
  return {
    type: adminConstants.GET_CONFIRMED_BOOKING_DETAILS_ERROR,
    error: err,
  };
};

const getConfirmedBookingDetailsSuccess = (data) => {
  return {
    type: adminConstants.GET_CONFIRMED_BOOKING_DETAILS_SUCCESS,
    data: data,
  };
};

const getCaptainsOfBoardingCenterError = (err) => {
  return {
    type: adminConstants.GET_CAPTAINS_OF_BOARDING_CENTER_ERROR,
    error: err,
  };
};

const getCaptainsOfBoardingCenterSuccess = (data) => {
  return {
    type: adminConstants.GET_CAPTAINS_OF_BOARDING_CENTER_SUCCESS,
    data: data,
  };
};

const updateBookingStatusError = (err) => {
  return {
    type: adminConstants.UPDATE_BOOKING_STATUS_ERROR,
    error: err,
  };
};

const updateBookingStatusSuccess = (data) => {
  return {
    type: adminConstants.UPDATE_BOOKING_STATUS_SUCCESS,
    data: data,
  };
};

const getAllOnAppointmentError = (err) => {
  return {
    type: adminConstants.GET_ALL_ON_APPOINTMENTS_ERROR,
    error: err,
  };
};

const getAllOnAppointmentSuccess = (data) => {
  return {
    type: adminConstants.GET_ALL_ON_APPOINTMENTS_SUCCESS,
    data: data,
  };
};
const notifyCaptainError = (err) => {
  return {
    type: adminConstants.NOTIFY_CAPTAIN_ERROR,
    error: err,
  };
};

const notifyCaptainSuccess = (data) => {
  return {
    type: adminConstants.NOTIFY_CAPTAIN_SUCCESS,
    data: data,
  };
};

const getCaptainsBookingsError = (err) => {
  return {
    type: adminConstants.GET_CAPTAINS_BOOKINGS_ERROR,
    error: err,
  };
};

const getCaptainsBookingsSuccess = (data) => {
  return {
    type: adminConstants.GET_CAPTAINS_BOOKINGS_SUCCESS,
    data: data,
  };
};

const getConfirmedBookingDetails = (boardCenterId, data) => {
  return (dispatch) => {
    dispatch(startLoadingAllConfirmedBookings());
    admingApi
      .getNewConfirmedBookingDetails(boardCenterId, data)
      .then((response) => {
        dispatch(getConfirmedBookingDetailsSuccess(response?.data?.info));
        dispatch(stopLoadingAllConfirmedBookings());
      })
      .catch((err) => {
        dispatch(getConfirmedBookingDetailsError(err));
        dispatch(stopLoadingAllConfirmedBookings());
      });
  };
};

const getCaptainsOfBoardingCenter = (bookingId) => {
  return (dispatch) => {
    dispatch(startLoading());
    admingApi
      .getAllCaptainsOfBoardingCenter(bookingId)
      .then((response) => {
        dispatch(getCaptainsOfBoardingCenterSuccess(response?.data?.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(getCaptainsOfBoardingCenterError(err));
        dispatch(stopLoading());
      });
  };
};

const updateBookingStatus = (bookingId, data) => {
  return (dispatch) => {
    dispatch(startLoading());
    admingApi
      .updateBookingStatus(bookingId, data)
      .then((response) => {
        dispatch(updateBookingStatusSuccess(response?.data?.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(updateBookingStatusError(err));
        dispatch(stopLoading());
      });
  };
};

const getAllOnAppointments = (boardCenterId) => {
  return (dispatch) => {
    dispatch(startLoading());
    admingApi
      .getAllOnProcessAppointments(boardCenterId)
      .then((response) => {
        console.log(response.data.info);
        dispatch(getAllOnAppointmentSuccess(response?.data?.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(getAllOnAppointmentError(err));
        dispatch(stopLoading());
      });
  };
};

const notifyCaptain = (captainId, bookingId, data) => {
  return (dispatch) => {
    dispatch(startLoading());
    admingApi
      .notifyCaptain(captainId, bookingId, data)
      .then((response) => {
        console.log(response.data.info);
        dispatch(notifyCaptainSuccess(response?.data?.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(notifyCaptainError(err));
        dispatch(stopLoading());
      });
  };
};

const getCaptainsBookings = (captainId) => {
  return (dispatch) => {
    dispatch(startLoading());
    admingApi
      .getCaptainsBookings(captainId)
      .then((response) => {
        console.log(response.data.info);
        dispatch(getCaptainsBookingsSuccess(response?.data?.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(getCaptainsBookingsError(err));
        dispatch(stopLoading());
      });
  };
};

const getAvailableKennelsErr = (err) => {
  return {
    type: adminConstants.GET_AVAILABLE_KENNELS_ERROR,
    error: err,
  };
};

const getAvailableKennelsSuccess = (data) => {
  return {
    type: adminConstants.GET_AVAILABLE_KENNELS_SUCCESS,
    data: data,
  };
};

const getAvailableKennelsEvent = (boardCenterId, bookingId) => {
  return (dispatch) => {
    dispatch(startLoadingKennels());
    admingApi
      .getAllAvailableKennelsForBooking(boardCenterId, bookingId)
      .then((response) => {
        dispatch(getAvailableKennelsSuccess(response?.data?.info));
        dispatch(stopLoadingKennels());
      })
      .catch((err) => {
        dispatch(getAvailableKennelsErr(err));
        dispatch(stopLoadingKennels());
      });
  };
};

const assignKennelErr = (err) => {
  return {
    type: adminConstants.ASSIGN_KENNEL_ERROR,
    error: err,
  };
};

const assignKennelSuccess = (data) => {
  return {
    type: adminConstants.ASSIGN_KENNEL_SUCCESS,
    data: data,
  };
};

const assignKennelEvent = (boardCenterId, bookingId, data) => {
  return (dispatch) => {
    dispatch(startLoadingKennels());
    admingApi
      .assignKennelToPet(boardCenterId, bookingId, data)
      .then((response) => {
        dispatch(assignKennelSuccess(response?.data?.info));
        dispatch(stopLoadingKennels());
      })
      .catch((err) => {
        dispatch(assignKennelErr(err));
        dispatch(stopLoadingKennels());
      });
  };
};

const releaseKennelErr = (err) => {
  return {
    type: adminConstants.RELEASE_KENNEL_ERROR,
    error: err,
  };
};

const releaseKennelSuccess = (data) => {
  return {
    type: adminConstants.RELEASE_KENNEL_SUCCESS,
    data: data,
  };
};

const releaseKennelEvent = (bookingId) => {
  return (dispatch) => {
    dispatch(startLoading());
    admingApi
      .releaseKennel(bookingId)
      .then((response) => {
        dispatch(releaseKennelSuccess(response?.data?.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(releaseKennelErr(err));
        dispatch(stopLoading());
      });
  };
};

const removeKennelErr = (err) => {
  return {
    type: adminConstants.REMOVE_KENNEL_ERROR,
    error: err,
  };
};

const removeKennelSuccess = (data) => {
  return {
    type: adminConstants.REMOVE_KENNEL_SUCCESS,
    data: data,
  };
};

const removeKennelEvent = (boardcenterId, bookingId, data) => {
  return (dispatch) => {
    dispatch(startLoading());
    admingApi
      .removeKennel(boardcenterId, bookingId, data)
      .then((response) => {
        dispatch(removeKennelSuccess(response?.data?.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(removeKennelErr(err));
        dispatch(stopLoading());
      });
  };
};

const getBookAppointmentDetailsErr = (err) => {
  return {
    type: adminConstants.GET_BOOKING_DETAILS_ERROR,
    error: err,
  };
};

const getBookAppointmentDetailsSuccess = (data) => {
  return {
    type: adminConstants.GET_BOOKING_DETAILS_SUCCESS,
    data: data,
  };
};

const getUserBookingDetails = (bookingId) => {
  console.log("bookk", bookingId);
  return (dispatch) => {
    dispatch(startLoading());
    admingApi
      .getBookAppointmentDetails(bookingId)
      .then((response) => {
        dispatch(getBookAppointmentDetailsSuccess(response?.data?.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(getBookAppointmentDetailsErr(err));
        dispatch(stopLoading());
      });
  };
};

const clearAlldata = () => {
  return {
    type: adminConstants.CLEAR_ALLDATA,
  };
};

export const adminAction = {
  getAllOnAppointments,
  getCaptainsOfBoardingCenter,
  getConfirmedBookingDetails,
  notifyCaptain,
  updateBookingStatus,
  getCaptainsBookings,
  getAvailableKennelsEvent,
  assignKennelEvent,
  releaseKennelEvent,
  removeKennelEvent,
  clearAlldata,
  getUserBookingDetails,
};
