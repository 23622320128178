import React from 'react';
import yoursubscriptionName from '../../Assets/Dashboard/Your subscription.png';
import styles from '../Styles/dashboard.module.css';
import { Button, Image, Progress, Tag, Typography, Card } from 'antd';
const { Title } = Typography;
const YourSubscription = () => {
    return (
        <Card hoverable className={styles.entireSubscriptionPage}>
            <div>
                <div style={{ textAlign: "end" }}>
                    <Button style={{ background: "#4068AC", color: "white" }}>Diamond Plan</Button>
                </div><br />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div><span style={{ fontWeight: "bold", fontSize: "1.5em" }}>77</span> service used</div>
                    <div><span style={{ fontWeight: "bold", fontSize: "1.5em" }}>100</span> total services</div>
                </div>
                <Progress percent={77 / 100 * 100} showInfo={false} strokeColor={"#00820D"} strokeLinecap='round' />
                <Title style={{ fontSize: "1.2em" }}>Included Services</Title>
                <div className={styles.includedServices}>
                    <Tag color="#f50">Pet day Care</Tag>
                    <Tag color="#2db7f5">Pet Sitting</Tag>
                    <Tag color="#87d068">Pet Training</Tag>
                    <Tag color="#108ee9">Pet Walker</Tag>
                    <Tag color="#10ffe4">Pet Transportation</Tag>
                    <Tag color='#83e058'>Pet SPA & Groom</Tag>
                    <Tag color='#e53'>Pet Sitting</Tag>
                </div>
                <Title style={{ fontSize: "1.2em" }}>Price & duration</Title>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div>
                        <Button className={styles.button} size='small'>&#8377;4599</Button>
                        <Button className={styles.button} size='small'>6 months</Button>
                    </div>
                    <Button type='primary'>Explore more plans</Button>
                </div>
            </div>
            <div className={styles.yoursubscriptionName}>
                <Image src={yoursubscriptionName} alt='Your Subscription' preview={false} width={150} />
            </div>
        </Card>
    )
}

export default YourSubscription;
