import { Button, Form, Input } from "antd";
import React from "react";

/**
 * `LocationForm` is a React component that renders a form for entering location details,
 * including the location name and pincode.
 * @param {Function} props.handleLocationFinish - Callback function to handle the form submission. 
 *                                                 It is triggered when the form is submitted successfully.*/

const LocationForm = ({ handleLocationFinish }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} layout="vertical" onFinish={handleLocationFinish}>
      <Form.Item
        label="Location Name"
        name={"locationName"}
        rules={[{ required: true }]}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item
        label="Pincode"
        name={"pincode"}
        rules={[{ required: true, type: "hex" }]}
      >
        <Input allowClear maxLength={6} minLength={6} />
      </Form.Item>
      <Button htmlType="submit" type="primary">
        Submit
      </Button>
    </Form>
  );
};

export default LocationForm;
