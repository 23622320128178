export const boardCenterConstants = {
    UPDATE_BOARD_CENTER_SUCCESS: "UPDATE_BOARD_CENTER_SUCCESS",
    UPDATE_BOARD_CENTER_ERROR: "UPDATE_BOARD_CENTER_ERROR",

    GET_ALL_BOARDCENTER_SUCCESS: "GET_ALL_BOARDCENTER_SUCCESS",
    GET_ALL_BOARDCENTER_ERROR: "GET_ALL_BOARDCENTER_ERROR",

    ADD_BOARDING_CENTER_SUCCESS: "ADD_BOARDING_CENTER_SUCCESS",
    ADD_BOARDING_CENTER_ERROR: "ADD_BOARDING_CENTER_ERROR",

    GET_BOARDCENTER_BY_ID_SUCCESS: "GET_BOARDCENTER_BY_ID_SUCCESS",
    GET_BOARDCENTER_BY_ID_ERROR: "GET_BOARDCENTER_BY_ID_ERROR",

    CLEAR_BOARD_CENTER: "CLEAR_BOARD_CENTER",
  };