import React, { useEffect, useState } from 'react'
import { debounceFun } from './DebounceSearchFun';
import { Input, theme, Tag, Calendar } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterFilled } from '@ant-design/icons';

const useFiltering = (dispatchAction) => {

    const [filtered, setFiltered] = useState({});
    const location = useLocation();
    const navigate = useNavigate();
    const { useToken } = theme;
    const { token } = useToken();

    // Initialize filter state from URL parameters on component mount
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const newFiltered = {};
        for (const [key] of searchParams.entries()) {
            newFiltered[key] = true;
        }
        setFiltered(newFiltered);
    }, [location.search]);

    const searchClientFunc = debounceFun((value, column) => {
        setFiltered((prev) => ({ ...prev, [column]: true }));
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(column, value);
        navigate(`?${searchParams.toString()}`);

        dispatchAction(Array.from(searchParams.entries()).reduce(
            (acc, [key, val]) => {
                acc[key] = val;
                return acc;
            },
            {}));
    }, 1000);

    const getColumnSearchProps = (column, type) => ({
        filterDropdown: () => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                {type === "calendar" ? (
                    <div style={{ width: 300, textAlign: "center" }}>
                        <Calendar
                            onSelect={(e) => searchClientFunc(e, column)}
                            fullscreen={false}
                        />
                    </div>
                ) : (
                    <Input
                        size="small"
                        placeholder={`Search ${column}`}
                        onChange={(e) =>
                            e.target.value.trim().length > 0 &&
                            searchClientFunc(e.target.value, column)
                        }
                        style={{
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                )}
                <Tag
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setFiltered((prev) => ({ ...prev, [column]: false }));
                        const params = new URLSearchParams(location.search);
                        params.delete(column);
                        navigate(`${location.pathname}?${params.toString()}`);
                        dispatchAction(Array.from(params.entries()).reduce(
                            (acc, [key, val]) => {
                                acc[key] = val;
                                return acc;
                            },
                            {}
                        ))
                    }}
                >
                    Reset
                </Tag>
            </div>
        ),
        filterIcon: () => (
            <FilterFilled
                style={{ color: filtered[column] ? token.colorPrimary : token.Gray }}
            />
        ),
    });

    return { getColumnSearchProps, setFiltered, filtered }

}

export default useFiltering
