import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, SmileOutlined } from "@ant-design/icons";
const NotFound = ({
  status,
  title,
  subTitle,
  continueTo,
  icon = <SmileOutlined />,
  buttonName="Back"
}) => {
  const navigate = useNavigate();
  return (
    <Result
      icon={icon}
      status={status}
      title={title}
      subTitle={subTitle}
      extra={
        <Button
          type="primary"
          size="small"
          onClick={() => navigate(continueTo)}
          icon={<ArrowLeftOutlined />}
        >
          {buttonName}
        </Button>
      }
    />
  );
};
export { NotFound };
