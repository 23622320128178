import { Button, Card, Form, Input, Typography } from "antd";
import React from "react";
import styles from "./Styles/login.module.css";
import { Link, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { LeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { forgotAction } from "./State/forgotactions";
import useShowMessage from "./Components/useShowMessage";
const { Text } = Typography;

/**
 * @description -ResetPassword component provides a form for users to reset their password.
 */

const ResetPassword = () => {
  const isTab = useMediaQuery({ maxWidth: 576 });
  let { id, token } = useParams();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((str) => str.loadingReducer);

  const [form] = Form.useForm();
  const onFinish = (formvalues) => {
    const { cpassword, ...formData } = formvalues;
    form.resetFields();
    dispatch(forgotAction.changePasswordEvent(formData, id, token));
  };

  const { contextHolder } = useShowMessage();

  return (
    <div className={styles.loginpage}>
      {contextHolder}
      <Card
        hoverable
        style={{ width: isTab ? "100%" : "500px", height: isTab ? "100%" : "" }}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            required
            name={"password"}
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Enter new password"
              className={styles.formInput}
            />
          </Form.Item>
          <Form.Item
            required
            name={"cpassword"}
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              placeholder="Confirm password"
              className={styles.formInput}
            />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              style={{
                width: "100%",
                height: "45px",
                backgroundColor: "#3A60A1",
                color: "white",
                fontSize: "18px",
              }}
              loading={isLoading}
            >
              Reset
            </Button>
          </Form.Item>
        </Form>
        <Text style={{ display: "flex", justifyContent: "center" }}>
          <LeftOutlined style={{ color: "#3A60A1" }} />
          <Link to={"/login"}>&nbsp;Go to Login</Link>
        </Text>
      </Card>
    </div>
  );
};

export default ResetPassword;
