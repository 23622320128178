import { useDispatch } from "react-redux";
import useWarnings from "../../Common/Components/useWarnings";
import { useState } from "react";
import { dashboardAction } from "../State/actions";
import { adminAction } from "../../ServiceDetails/state/actions";
import {
  commonBodyForpetPickupAndDrop_status,
  commonBodyForpetPickupAndDrop_status_decline,
} from "../../Common/Components/commonBodyForUpdatingStatus";

/**
 * @param - not needed
 * This custom hook manages the logic for accepting or declining captain bookings.
 * It provides functionalities to handle booking acceptance, decline, and modal management,
 * including reasons for decline and custom reasons.
 */

const useAcceptDeclineDashboardCaptain = () => {
  const { warning, otherWarning, contextHolder } = useWarnings();
  const [key, setKey] = useState();
  const dispatch = useDispatch();
  const [declineBooking, setDeclineBooking] = useState({
    currentDeclineBooking: null,
    declineReason: "",
    customReason: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (booking, key) => {
    setKey(key);
    setDeclineBooking({ ...declineBooking, currentDeclineBooking: booking });
    setIsModalOpen(true);
  };

  // declining the ride
  const handleOk = () => {
    const {
      declineReason,
      customReason,
      currentDeclineBooking,
    } = declineBooking;

    // Check if declineReason is valid
    const isDeclineReasonValid =
      declineReason !== "Select your reason" && declineReason !== "";
    const isCustomReasonValid =
      declineReason !== "other" || customReason !== "";

    if (!isDeclineReasonValid || !isCustomReasonValid) {
      if (declineReason === "other" && customReason === "") {
        return otherWarning();
      } else {
        return warning();
      }
    }

    // Choose the appropriate handler based on the key
    if (key === "acceptOrDecline") {
      handleDecline(
        currentDeclineBooking,
        declineReason === "other" ? customReason : declineReason
      );
    } else if (key === "accepted") {
      handleDeclineRequest(
        currentDeclineBooking,
        declineReason === "other" ? customReason : declineReason
      );
    }

    setIsModalOpen(false);
  };

  // closing the modal
  const handleCancel = () => {
    setIsModalOpen(false);
    setDeclineBooking({
      currentDeclineBooking: null,
      declineReason: "",
      customReason: "",
    });
  };

  // called when captain accepts the ride
  const handleAccept = (e) => {
    let body = commonBodyForpetPickupAndDrop_status(
      e.bookingId,
      "accepts_ride"
    );
    dispatch(adminAction.updateBookingStatus(e.bookingUniqueId, body));
  };

  const handleDecline = (e, reason) => {
    console.log("iam cmg inside handleDECLINED", e, reason);
    let body = commonBodyForpetPickupAndDrop_status_decline(
      e.bookingId,
      "accepts_ride",
      reason
    );
    dispatch(adminAction.updateBookingStatus(e.bookingUniqueId, body));
  };

  // called when making a decline request after accepting
  const handleDeclineRequest = (e, reason) => {
    dispatch(
      dashboardAction.notifyAdminAboutBookingDecline(
        e.bookingUniqueId,
        {
          reason,
        },
        {}
      )
    );
  };

  const handleSelectChange = (value) => {
    setDeclineBooking({ ...declineBooking, declineReason: value });
  };

  return {
    showModal,
    isModalOpen,
    handleOk,
    handleCancel,
    handleAccept,
    handleDecline,
    handleSelectChange,
    declineBooking,
    setDeclineBooking,
    contextHolder
  };
};
export default useAcceptDeclineDashboardCaptain;
