import React from "react";
import styles from "../Styles/todayhistory.module.css";
import { Row, Col, Typography, Image, Card } from "antd";
import todayhistoryname from "../../Assets/BoardHeadDashboard/todayhistoryname.png";
import { v4 as uuidv4 } from "uuid";

const { Text } = Typography;
const TodayHistory = () => {
  const services = [
    {
      name: "Total Service",
      total: 32,
    },
    {
      name: "Completed Service",
      total: 32,
    },
    {
      name: "New Subscribers",
      total: 32,
    },
    {
      name: "Expired Subscribers",
      total: 32,
    },
    {
      name: "Total Revenue",
      total: `32,400/-`,
    },
  ];
  return (
    <Card hoverable className={styles.entireHistoryPage}>
      {services?.map((e) => (
        <Row key={uuidv4()} style={{ marginBottom: "5px" }}>
          <Col span={12}>
            <Text className={styles.name}>{e?.name}</Text>
          </Col>
          <Col span={12} className={styles.totalCol}>
            <Text className={styles.total}>{e?.total}</Text>
          </Col>
        </Row>
      ))}
      <div style={{ position: "absolute", top: "0px", left: "0px" }}>
        <Image
          src={todayhistoryname}
          preview={false}
          alt="Today History"
          width={150}
        />
      </div>
    </Card>
  );
};

export default TodayHistory;
