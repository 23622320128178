
// src/contexts/SocketContext.js
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const { userDetails } = useSelector((state) => state.navbarReducer);
    const socketRef = useRef(null);
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (userDetails?._id && !socketRef.current) {
            const newSocket = io.connect("https://api.qa.pragmasoft.in");
            socketRef.current = newSocket;
            setSocket(newSocket);
            console.log("Socket connected");
            newSocket.emit("user_connected", { userId: userDetails?._id });
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
                socketRef.current = null;
            }
        };
    }, [userDetails?._id]);

    return (
        <SocketContext.Provider value={{ currentSocket: socket }}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => {
    return useContext(SocketContext);
};

