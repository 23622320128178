export const captainConstants = {
    CANCELCAPTAINBOOKING_SUCCESS: "CANCELCAPTAINBOOKING_SUCCESS",
    CANCELCAPTAINBOOKING_ERROR: "CANCELCAPTAINBOOKING_ERROR",

    SEND_OTP_FOR_VERIFICATION_ORDER_PARENT_SUCCESS: "SEND_OTP_FOR_VERIFICATION_ORDER_PARENT_SUCCESS",
    SEND_OTP_FOR_VERIFICATION_ORDER_PARENT_ERROR: "SEND_OTP_FOR_VERIFICATION_ORDER_PARENT_ERROR",

    VERIFY_OTP_FOR_VERIFICATION_ORDER_PARENT_SUCCESS: "VERIFY_OTP_FOR_VERIFICATION_ORDER_PARENT_SUCCESS",
    VERIFY_OTP_FOR_VERIFICATION_ORDER_PARENT_ERROR: "VERIFY_OTP_FOR_VERIFICATION_ORDER_PARENT_ERROR",

    CLEAR_OTPDATA: "CLEAR_OTPDATA",
};
