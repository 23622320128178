import { Button, Popconfirm, theme, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminAction } from "../state/actions";
import useAcceptDecline from "./useAcceptDecline";
import CommonModal from "../../CommonModal/CommonModal";
import VerificationStepsForAdmin from "./VerificationStepsForAdmin";
import KennelsScreen from "./KennelsScreen";
const { Text } = Typography;
/**
 * PetBoardingStatusForAdmin Component
 * 
 * This component handles the various statuses related to pet boarding and provides
 * appropriate actions for each status, such as assigning a kennel, verifying the parent,
 * and releasing the pet.
 * 
 * @component
 * @param {Object} props - Component properties
 * @param {Object} props.petBoarding_status - Object containing statuses related to pet boarding
 * @param {Object} props.petBoarding_status.booking_confirmation - Status of the booking confirmation
 * @param {Object} props.petBoarding_status.pet_received_at_boarding_center - Status of the pet's arrival at the boarding center
 * @param {Object} props.petBoarding_status.parent_verification - Status of the parent's verification
 * @param {Object} props.petBoarding_status.pet_verification_by_admin - Status of the pet's verification by the admin
 * @param {Object} props.petBoarding_status.pet_assigned_a_kennel - Status of the kennel assignment
 * @param {Object} props.petBoarding_status.parent_verification_while_releasing_pet - Status of the parent's verification while releasing the pet
 * @param {Object} props.petBoarding_status.pet_handed_over_to_parent - Status of handing over the pet to the parent
 * @param {string} props.bookingId - ID of the booking
 * @param {string} props.bookingUniqueId - Unique ID of the booking
 * @param {string} props.bookingStatus - Current status of the booking
 * @param {string} props.userId - ID of the user associated with the booking
 * @param {string} props.kennelId - ID of the kennel assigned to the pet
 * 
 * @returns {JSX.Element} The PetBoardingStatusForAdmin component
 */
const PetBoardingStatusForAdmin = ({
  petBoarding_status,
  bookingId,
  bookingUniqueId,
  bookingStatus,
  userId,
  kennelId,
}) => {
  const { useToken } = theme;
  const token = useToken();
  const dispatch = useDispatch();
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const {
    booking_confirmation,
    pet_received_at_boarding_center,
    parent_verification,
    pet_verification_by_admin,
    pet_assigned_a_kennel,
    parent_verification_while_releasing_pet,
    pet_handed_over_to_parent,
  } = petBoarding_status;
  const {
    handleVerifyPetAssignKennel,
    handleOkAssignKennel,
    handleCancelAssignKennel,
    isModalOpenAssignKennel,
    kennel,
    handleReceivedPet,
    showModalVerify,
    isModalOpenVerify,
    userTobeVerifiedByAdmin,
    setIsModalOpen,
    typeofroute,
  } = useAcceptDecline();

  if (pet_handed_over_to_parent?.status === "accept") {
    return <Text>Pet handed over to parent.</Text>;
  } else if (pet_handed_over_to_parent?.status === "decline") {
    return (
      <Text style={{ color: token.Red }}>Pet not handed over to parent.</Text>
    );
  } else if (parent_verification_while_releasing_pet?.status === "accept") {
    return (
      <>
        <Text>Verification successfull while releasing pet.</Text>
        <Button
          type="link"
          onClick={() =>
            dispatch(adminAction.releaseKennelEvent(bookingUniqueId))
          }
        >
          Release pet.
        </Button>
      </>
    );
  } else if (parent_verification_while_releasing_pet?.status === "decline") {
    return <Text>Verification not successfull while releasing pet</Text>;
  } else if (pet_assigned_a_kennel?.status === "accept") {
    return (
      <>
        <Text style={{ color: token.Green }}>
          Kennel {kennelId} has been assigned.
        </Text>
        <Button
          type="link"
          onClick={() =>
            dispatch(
              adminAction.removeKennelEvent(
                userDetails.boardCenterUniqueId,
                bookingUniqueId,
                { reason: "repair in kennel" }
              )
            )
          }
        >
          Remove assigned kennel.
        </Button>

        <Button
          type="link"
          onClick={() => {
            showModalVerify(
              bookingId,
              bookingUniqueId,
              userId,
              bookingStatus,
              "drop"
            );
          }}
        >
          Verify parent before releasing kennel and handover to parent.
        </Button>
        <CommonModal
          isModalOpen={isModalOpenVerify}
          handleCancel={handleCancelAssignKennel}
          body={() => (
            <VerificationStepsForAdmin
              userTobeVerifiedByAdmin={userTobeVerifiedByAdmin}
              setIsModalOpen={setIsModalOpen}
              typeofroute={typeofroute}
            />
          )}
        />
      </>
    );
  } else if (pet_assigned_a_kennel?.status === "decline") {
    return <Text>Kennel has not assigned to pet.</Text>;
  } else if (pet_assigned_a_kennel?.status === "none") {
    return (
      <>
        <Text
          style={{ color: token.Red, cursor: "pointer" }}
          onClick={() => handleVerifyPetAssignKennel(bookingUniqueId)}
        >
          Kennel {kennelId} has been removed due to{" "}
          {petBoarding_status?.pet_assigned_a_kennel?.reason}. Reassign another
          kennel
        </Text>
        {kennel.current === bookingUniqueId && (
          <CommonModal
            body={() => (
              <KennelsScreen
                handleOkAssignKennel={handleOkAssignKennel}
                bookingId={kennel.current}
              />
            )}
            handleOk={handleOkAssignKennel}
            handleCancel={handleCancelAssignKennel}
            isModalOpen={isModalOpenAssignKennel}
          />
        )}
      </>
    );
  } else if (pet_verification_by_admin?.status === "accept") {
    return (
      <>
        <Text>
          Parent and pet verification successfull before assigning kennel.
        </Text>
        <Button
          type="link"
          onClick={() => handleVerifyPetAssignKennel(bookingUniqueId)}
        >
          Please assign a kennel.
        </Button>
        {kennel.current === bookingUniqueId && (
          <CommonModal
            body={() => (
              <KennelsScreen
                handleOkAssignKennel={handleOkAssignKennel}
                bookingId={kennel.current}
              />
            )}
            handleOk={handleOkAssignKennel}
            handleCancel={handleCancelAssignKennel}
            isModalOpen={isModalOpenAssignKennel}
          />
        )}
      </>
    );
  } else if (pet_verification_by_admin?.status === "decline") {
    return <Text>Pet verification unsuccessful before assigning kennel.</Text>;
  } else if (parent_verification?.status === "decline") {
    return (
      <Text>Parent verification unsuccessful before assigning kennel.</Text>
    );
  } else if (pet_received_at_boarding_center?.status === "accept") {
    return (
      <>
        <Text>Pet received at boarding center</Text>
        <Button
          type="link"
          size="small"
          onClick={() =>
            showModalVerify(
              bookingId,
              bookingUniqueId,
              userId,
              bookingStatus,
              "pickup"
            )
          }
        >
          Verify Now.
        </Button>
        <CommonModal
          isModalOpen={isModalOpenVerify}
          handleCancel={handleCancelAssignKennel}
          body={() => (
            <VerificationStepsForAdmin
              userTobeVerifiedByAdmin={userTobeVerifiedByAdmin}
              setIsModalOpen={setIsModalOpen}
              typeofroute={typeofroute}
            />
          )}
        />
      </>
    );
  } else if (pet_received_at_boarding_center?.status === "decline") {
    return <Text>Pet not received at boarding center</Text>;
  } else if (booking_confirmation?.status === "accept") {
    return (
      <>
        <Text>Booking has been accepted.</Text>
        <Popconfirm
          icon={null}
          cancelText={<Text>No</Text>}
          okText={<Text>Yes</Text>}
          onConfirm={() => handleReceivedPet(bookingId, bookingUniqueId)}
        >
          <Button type="link">Did you receive the pet? </Button>
        </Popconfirm>
      </>
    );
  }
};

export default PetBoardingStatusForAdmin;
