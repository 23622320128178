import { Button, Checkbox, Form, Input } from 'antd'
import React from 'react'
import styles from '../Styles/login.module.css';
import "../Styles/login.css";
import { useSelector } from 'react-redux';
import { LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

/**
 * LoginForm component provides a form for users to input their email and password for authentication.
 * 
 * @param {Object} props - The component props.
 * @param {Function} props.handleLogin - The function to be called when the form is submitted.
 * @param {Object} props.form - The form instance from Antd's Form component.
 */

const LoginForm = ({ handleLogin, form }) => {
    const { isLoading } = useSelector(str => str.loadingReducer);
    return (
        <Form
            style={{ width: "100%" }}
            form={form}
            layout="vertical"
            onFinish={handleLogin}
        >
            <Form.Item
                required
                name={"email"}
                rules={[
                    {
                        required: true,
                        message: "Please input your username!",
                    },
                    {
                        type: "email",
                        message: "Please enter valid email!",
                    },
                ]}
            >
                <Input placeholder="Email id" className={styles.formInput} />
            </Form.Item>
            <Form.Item
                required
                name={"password"}
                rules={[
                    {
                        required: true,
                        message: "Please input your password!",
                    },
                ]}
            >
                <Input.Password
                    placeholder="Enter Your Password"
                    className={styles.formInput}
                />
            </Form.Item>
            <Form.Item>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Checkbox>Remember me</Checkbox>
                    <Link to={"/forgot-password"}>
                        <LockOutlined /> Forgot password ?
                    </Link>
                </div>
            </Form.Item>
            <Button
                htmlType="submit"
                style={{
                    width: "100%",
                    height: "45px",
                    backgroundColor: "#3A60A1",
                    color: "white",
                    fontSize: "18px",
                }}
                loading={isLoading}
            >
                Login
            </Button>
        </Form>
    )
}

export default LoginForm
