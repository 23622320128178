import React from "react";
import { Card, Avatar, Typography } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
const { Text } = Typography;
const BoardcenterDetails = () => {
  return (
    <div>
      <Card hoverable bodyStyle={{ display: "flex" }}>
        <div style={{ paddingRight: "15px" }}>
          <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
        </div>
        <div>
          <Text style={{ display: "block", fontWeight: 500 }}>
            Board head: Karthik Kumar
          </Text>
          <PhoneOutlined style={{ paddingRight: "10px", fontWeight: 500 }} />
          <Text>89898 90898</Text>
        </div>
      </Card>
    </div>
  );
};

export default BoardcenterDetails;
