import React from "react";
import { Typography, theme } from "antd";
import { FilterFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { dashboardAction } from "../State/actions";
import useFiltering from "../../Common/Components/useFiltering";
import CaptainDashboardStatus from "./CaptainDashboardStatus";
const { Text, Paragraph } = Typography;
/**
 * Custom hook to define and manage columns for the captain dashboard table.
 * This hook returns column definitions for a table displaying captain dashboard data, including:
 * - Booking ID
 * - Pickup Location
 * - Drop-off Location
 * - Pickup Date Time
 * - Drop Date Time
 * - Appointment Status
 * 
 * It also handles search filtering and manages the display of filter icons based on the filtering state.
 * 
 * @returns {Object} An object containing:
 * - {Array} columns - Array of column definitions for the Ant Design Table component.
 * - {Function} setFiltered - Function to update the filtered state.
 */

const useCaptainDashboardColumns = () => {
  const { useToken } = theme;
  const { token } = useToken();
  const dispatch = useDispatch();
  const {
    filtered,
    setFiltered,
    getColumnSearchProps,
  } = useFiltering((search) =>
    dispatch(dashboardAction.getCaptainDetailsEvent(1, search))
  );

  const columns = [
    {
      title: "Booking ID",
      dataIndex: "bookingUniqueId",
      key: "bookingUniqueId",
      width: "80",
      fixed: "left",
      align: "center",
      ...getColumnSearchProps("id"),
      render: (_, { bookingUniqueId, customerName }) => (
        <>
          <Text style={{ display: "block" }}>{bookingUniqueId}</Text>
          <Text style={{ display: "block" }}>{customerName}</Text>
        </>
      ),
    },
    {
      title: "Pickup Location",
      dataIndex: "pickupLocation",
      key: "pickupLocation",
      width: "150",
      align: "center",
      render: (pickupLocation) => {
        return (
          <Paragraph
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: "more",
            }}
          >
            {pickupLocation}
          </Paragraph>
        );
      },
    },
    {
      title: "Drop-off Location",
      dataIndex: "dropoffLocation",
      key: "dropoffLocation",
      align: "center",
      width: "150",
      render: (dropoffLocation) => {
        return (
          <Paragraph
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: "more",
            }}
          >
            {dropoffLocation}
          </Paragraph>
        );
      },
    },
    {
      title: "Pickup Date Time",
      dataIndex: "pickupDateTime",
      key: "pickupDateTime",
      align: "center",
      width: "50",
      ...getColumnSearchProps("pick", "calendar"),
      render: (_, { pickupDateTime }) => {
        return (
          <Text>{dayjs(pickupDateTime).format("MMM D, YYYY h:mm A")}</Text>
        );
      },
    },
    {
      title: "Drop Date Time",
      dataIndex: "dropDateTime",
      key: "dropDateTime",
      width: "50",
      align: "center",
      ...getColumnSearchProps("drop", "calendar"),
      render: (_, { dropDateTime }) => {
        return <Text>{dayjs(dropDateTime).format("MMM D, YYYY h:mm A")}</Text>;
      },
    },
    {
      title: "Appointment Status",
      dataIndex: "status",
      key: "status",
      width: "150",
      align: "center",
      fixed: "right",
      filters: [
        { text: "Cancelled by admin", value: "none" },
        { text: "New", value: "in_process" },
        { text: "Accepted", value: "accept" },
        { text: "Declined", value: "decline" },
        { text: "Decline Request", value: "decline_request" },
        { text: "All", value: "all" },
      ],
      filterMultiple: false,
      filterIcon: () => (
        <FilterFilled
          style={{ color: filtered.status ? token.colorPrimary : token.Gray }}
        />
      ),
      render: (_, e) => {
        let {
          status,
        } = e?.bookingStatus?.petPickupAndDrop_status?.boarding_center_to_parent_location_to_pickup_pet?.accepts_ride;
        return <CaptainDashboardStatus status={status} booking={e} />;
      },
    },
  ];

  return {
    columns,
    setFiltered,
  };
};

export default useCaptainDashboardColumns;
