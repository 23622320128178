import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminAction } from "../state/actions";
import { useNavigate } from "react-router-dom";
import useWarnings from "../../Common/Components/useWarnings";
import {
  commonBodyForpetBoarding_status,
  commonBodyForpetBoarding_status_Decline,
} from "../../Common/Components/commonBodyForUpdatingStatus";

const useAcceptDecline = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.navbarReducer);
  const { warning, otherWarning, contextHolder } = useWarnings();
  const kennel = useRef(null);
  const typeofroute = useRef(null);

  let userTobeVerifiedByAdmin = useRef(null);
  const [declineBooking, setDeclineBooking] = useState({
    currentDeclineBooking: null,
    declineReason: "",
    customReason: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenAssignKennel, setIsModalOpenAssignKennel] = useState(false);
  const [isModalOpenVerify, setIsModalOpenVerify] = useState(false);

  const showModal = (booking) => {
    setDeclineBooking({ ...declineBooking, currentDeclineBooking: booking });
    setIsModalOpen(true);
  };

  const showModalOpenAssignKennel = () => {
    setIsModalOpenAssignKennel(true);
  };

  const showModalVerify = (
    bookingId,
    bookingUniqueId,
    userId,
    bookingStatus,
    type
  ) => {
    setIsModalOpenVerify(true);
    userTobeVerifiedByAdmin.current = {
      bookingId,
      bookingUniqueId,
      userId,
      bookingStatus,
    };
    typeofroute.current = type;
  };

  const handleOkAssignKennel = () => {
    setIsModalOpenAssignKennel(false);
    setIsModalOpenVerify(false);
  };

  const handleCancelAssignKennel = () => {
    setIsModalOpenAssignKennel(false);
    setIsModalOpenVerify(false);
  };

  const handleOk = () => {
    // Handle the decline action here
    if (
      declineBooking.declineReason === "Select your reason" ||
      declineBooking.declineReason === ""
    )
      return warning();

    if (
      declineBooking.declineReason === "other" &&
      declineBooking.customReason === ""
    )
      return otherWarning();

    handleDecline(
      declineBooking.currentDeclineBooking,
      declineBooking.declineReason === "other"
        ? declineBooking.customReason
        : declineBooking.declineReason
    );
    setIsModalOpen(false);
    navigate(`/services/declinedAppointments`);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDeclineBooking({
      ...declineBooking,
      declineReason: "Select your reason",
    });
  };

  const handleAccept = (e) => {
    console.log(e, "ee")
    let body = commonBodyForpetBoarding_status(e.bookingId,  "booking_confirmation");
    dispatch(
      adminAction.updateBookingStatus(
        e.bookingUniqueId,
        body,
        userDetails.boardCenterUniqueId,
        { boardCenterId: userDetails.boardingCenter }
      )
    );
    if (e.pickupdrop) navigate(`/captains/${e.bookingUniqueId}`);
    else
      navigate(`/services/inProcessAppointments`);
    // dispatch(
    //   adminAction.getConfirmedBookingDetails(
    //     userDetails.boardCenterUniqueId,
    //     {
    //       boardCenterId: userDetails.boardingCenter,
    //       page: 1,
    //       status: "newAppointments",
    //     }
    //   )
    // );
  };

  const handleDecline = (e, reason) => {
    let body = commonBodyForpetBoarding_status_Decline(
      e.bookingId,
      "booking_confirmation",
      reason
    );

    dispatch(
      adminAction.updateBookingStatus(
        e.bookingUniqueId,
        body,
        userDetails.boardCenterUniqueId,
        { boardCenterId: userDetails.boardingCenter }
        // "reloadadmin"
      )
    );
  };

  const handleSelectChange = (value) => {
    setDeclineBooking({ ...declineBooking, declineReason: value });
  };

  const handleVerifyPet = (bookingId, bookingUniqueId) => {
    let body = commonBodyForpetBoarding_status(
      bookingId,
      "pet_verification_by_admin"
    );
    dispatch(adminAction.updateBookingStatus(bookingUniqueId, body));
  };

  const handleVerifyPetAssignKennel = (id) => {
    setIsModalOpenAssignKennel(true);
    kennel.current = id;
  };

  const handleReceivedPet = (bookingId, bookingUniqueId) => {
    let body = commonBodyForpetBoarding_status(
      bookingId,
      "pet_received_at_boarding_center"
    );
    dispatch(adminAction.updateBookingStatus(bookingUniqueId, body));
  };

  return {
    declineBooking,
    handleOk,
    handleCancel,
    handleAccept,
    handleDecline,
    handleSelectChange,
    showModal,
    isModalOpen,
    contextHolder,
    setDeclineBooking,
    handleVerifyPet,
    handleVerifyPetAssignKennel,
    showModalOpenAssignKennel,
    handleOkAssignKennel,
    handleCancelAssignKennel,
    isModalOpenAssignKennel,
    kennel,
    handleReceivedPet,
    showModalVerify,
    isModalOpenVerify,
    userTobeVerifiedByAdmin,
    setIsModalOpen,
    typeofroute,
  };
};

export default useAcceptDecline;
