export const adminConstants = {
  GET_CONFIRMED_BOOKING_DETAILS_SUCCESS:
    "GET_CONFIRMED_BOOKING_DETAILS_SUCCESS",
  GET_CONFIRMED_BOOKING_DETAILS_ERROR: "GET_CONFIRMED_BOOKING_DETAILS_ERROR",
  GET_CAPTAINS_OF_BOARDING_CENTER_SUCCESS:
    "GET_CAPTAINS_OF_BOARDING_CENTER_SUCCESS",
  GET_CAPTAINS_OF_BOARDING_CENTER_ERROR:
    "GET_CAPTAINS_OF_BOARDING_CENTER_ERROR",
  GET_ALL_ON_APPOINTMENTS_SUCCESS: "GET_ALL_ON_APPOINTMENTS_SUCCESS",
  GET_ALL_ON_APPOINTMENTS_ERROR: "GET_ALL_ON_APPOINTMENTS_ERROR",
  UPDATE_BOOKING_STATUS_SUCCESS: "UPDATE_BOOKING_STATUS_SUCCESS",
  UPDATE_BOOKING_STATUS_ERROR: "UPDATE_BOOKING_STATUS_ERROR",
  NOTIFY_CAPTAIN_SUCCESS: "NOTIFY_CAPTAIN_SUCCESS",
  NOTIFY_CAPTAIN_ERROR: "NOTIFY_CAPTAIN_ERROR",
  GET_CAPTAINS_BOOKINGS_SUCCESS: "GET_CAPTAINS_BOOKINGS_SUCCESS",
  GET_CAPTAINS_BOOKINGS_ERROR: "GET_CAPTAINS_BOOKINGS_ERROR",

  GET_AVAILABLE_KENNELS_SUCCESS: "GET_AVAILABLE_KENNELS_SUCCESS",
  GET_AVAILABLE_KENNELS_ERROR: "GET_AVAILABLE_KENNELS_ERROR",

  ASSIGN_KENNEL_SUCCESS: "ASSIGN_KENNEL_SUCCESS",
  ASSIGN_KENNEL_ERROR: "ASSIGN_KENNEL_ERROR",

  RELEASE_KENNEL_SUCCESS: "RELEASE_KENNEL_SUCCESS",
  RELEASE_KENNEL_ERROR: "RELEASE_KENNEL_ERROR",

  REMOVE_KENNEL_SUCCESS: "REMOVE_KENNEL_SUCCESS",
  REMOVE_KENNEL_ERROR: "REMOVE_KENNEL_ERROR",
  CLEAR_ALLDATA: "CLEAR_ALLDATA",

  GET_BOOKING_DETAILS_SUCCESS: "GET_BOOKING_DETAILS_SUCCESS",
  GET_BOOKING_DETAILS_ERROR: "GET_BOOKING_DETAILS_ERROR",
};
