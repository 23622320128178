import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Divider, Row, theme } from "antd";
import FinalParentDetails from "./FinalParentDetails";
import FinalPetDetails from "./FinalPetDetails";
import FinalSelectedServices from "./FinalSelectedServices";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bookAppointmentAction } from "../State/bookAppointmentActions";
import RightPayment from "./RightPayment";
import useCheckEffects from "./useCheckEffects";
import useCheckAllDetails from "./useCheckAllDetails";
import Spinner from "../../Spinner/Spinner";
import { paymentApi } from "../../Common/utils/paymentapi";

/**
 * @param - no params needed
 * @description This component handles the display and management of booking details for an appointment.
 * It allows users to view, edit, and confirm the final details of their appointment, including parent details,
 * pet details, selected services, and payment. The component fetches appointment details, manages
 * necessary side effects, and handles payment confirmation using razorpay
 * */

const FinalBookAppointmentEditInfoPage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const status = query.get("status");
  const navigate = useNavigate();
  const { useToken } = theme;
  const { token } = useToken();
  const dispatch = useDispatch();
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const { bookappointmentdetails, details, updateddetails } = useSelector(
    (str) => str.bookAppointmentReducer
  );
  const { isLoading } = useSelector((str) => str.loadingReducer);

  const { parentDetails, petDetails } = useSelector(
    (str) => str.profileReducer
  );
  const { parentBoardingDetails } = useSelector((str) => str.profileReducer);

  useEffect(() => {
    dispatch(
      bookAppointmentAction.getBookAppointmentdetailsEvent(
        localStorage.getItem("bookingID")
      )
    );
  }, [dispatch, parentDetails, petDetails, details, updateddetails]);

  useCheckEffects("Book Appointment");

  useEffect(() => {
    return () => {
      dispatch(bookAppointmentAction.updatedDetailsresetEvent());
    };
  }, []);

  // Custom hook to perform checks and return a result component if necessary
  const checkResult = useCheckAllDetails();
  if (checkResult) {
    return checkResult;
  }

  const handleStartTransaction = async () => {
    setIsPaymentLoading(true);

    try {
      const {
        data: {
          info: { key },
        },
      } = await paymentApi.getApiKey();

      const {
        data: { info },
      } = await paymentApi.makePayment(
        bookappointmentdetails.bookingDetails.bookingUniqueId
      );

      const options = {
        key,
        amount: info.amount,
        currency: "INR",
        name: "Pets website",
        description: "Pets service",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbYPH_66lG5dhDAVXuwo7u9iQR4WRNdmHElw&s",
        order_id: info.id,
        // callback_url: `http://localhost:8080/v1/payment-verification/${bookingId}`,
        prefill: {
          name: "Gaurav Kumar",
          email: "gaurav.kumar@example.com",
          contact: "9999999999",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: token.Secondary,
        },
        handler: function (response) {
          handlePaymentSuccess(response);
        },
      };
      setIsPaymentLoading(false);

      const razor = new window.Razorpay(options);
      razor.open();
    } catch (err) {
      setIsPaymentLoading(false);
    }
  };

  const handlePaymentSuccess = async (response) => {
    try {
      const result = await paymentApi.paymentVerification(
        bookappointmentdetails.bookingDetails.bookingUniqueId,
        response
      );

      // Handle the result of payment verification
      if (result.data.info.type === "SUCCESS") {
        const payment_id = result.data.info.payment_id;
        // Payment successful
        navigate(`/paymentsuccess?reference=${payment_id}`);
        // Navigate to success page or update UI accordingly
      } else {
        // Payment failed
        console.log("Payment failed");
        // Handle failure scenario
      }
    } catch (error) {
      console.error("Error verifying payment:", error);
      // Handle error scenario
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {/* final details of parent, pet, services and payment in the right of the page */}
      <Row gutter={[16, 16]} justify={"center"} style={{ marginTop: "30px" }}>
        <Col xs={24} sm={24} md={24} lg={15}>
          {status === "failed" && (
            <Alert
              message="Payment Failed"
              description="Unfortunately, your payment could not be processed. Please try again."
              type="error"
              showIcon
            />
          )}
          <Card hoverable>
            <FinalParentDetails
              details={bookappointmentdetails?.currentParent}
            />
            <FinalPetDetails
              details={bookappointmentdetails?.petDetails}
              parentBoardingDetails={parentBoardingDetails?.pets}
            />
            <FinalSelectedServices
              details={bookappointmentdetails?.bookingDetails}
              address={bookappointmentdetails?.currentParent?.address}
            />
          </Card>
        </Col>

        <Col xs={0} sm={0} md={0} lg={1} style={{ flex: 0 }}>
          <Divider type="vertical" style={{ height: "100%" }} />
        </Col>

        <Col
          xs={24}
          lg={8}
          style={{ position: "sticky", top: "150px", height: "max-content" }}
        >
          <RightPayment details={bookappointmentdetails?.bookingDetails} />
        </Col>
      </Row>

      {/* back and next buttons */}
      <Row
        justify={"space-between"}
        style={{ marginTop: "1.2em", marginBottom: "5em" }}
      >
        <Button
          type="primary"
          onClick={() => {
            navigate("/book-appointment/step-2");
          }}
        >
          ❮ Back
        </Button>

        <Button
          type="primary"
          loading={isPaymentLoading}
          onClick={handleStartTransaction}
        >
          Place order ❯
        </Button>
      </Row>
    </>
  );
};

export default FinalBookAppointmentEditInfoPage;
