import React, { useEffect } from "react";
import { useSocket } from "../../context/SocketContext";
import { useSelector } from "react-redux";

/**
 * @function useCalendarEffects
 * @description Custom React hook that manages the effects related to calendar interactions,
 * including socket events for slot updates and countdown timers for refreshing slot data.
 * @returns {void}
 */

const useCalendarEffects = ({
  setButtonState,
  buttonState,
  getTimeSlotsForParticularMonth,
  currentMonth,
  serviceName,
}) => {
  const { currentSocket } = useSocket();
  const { userDetails } = useSelector((str) => str.navbarReducer);

  useEffect(() => {
    if (currentSocket) {
      currentSocket.on("slotRelease", (data) => {
        const { message, boardingCenterID } = data;
        if (boardingCenterID === userDetails.boardingCenter) {
          setButtonState({
            ...buttonState,
            isVisible: true,
            popoverVisible: true,
          });
        }
      });
    }
  }, [currentSocket]);

  useEffect(() => {
    let timer;
    if (buttonState.isVisible && buttonState.countdownTimer > 0) {
      timer = setInterval(() => {
        setButtonState((prevState) => ({
          ...prevState,
          countdownTimer: prevState.countdownTimer - 1,
        }));
      }, 1000);
    }

    if (buttonState.countdownTimer === 0) {
      setButtonState({
        isVisible: false,
        countdownTimer: 90,
        popoverVisible: false,
      });

      getTimeSlotsForParticularMonth({
        date: currentMonth,
        serviceName: serviceName,
      });
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [buttonState.isVisible, buttonState.countdownTimer]);
};

export default useCalendarEffects;
