import axios from "axios";

const apiURL = process.env.REACT_APP_BACKEND_URL;

// add a question
const addQuestion = (data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.post(`${apiURL}/addQuestion`, data, config);
}

// get all questions
const getAllQuestions = () => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/getAllQuestions`, config);
}

// update question based on id
const updateQuestionById = (id, data) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.put(`${apiURL}/updateQuestion/${id}`, data, config);
}

// get question based on id
const getQuestionById = (id) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/getQuestionById/${id}`, config);
}

// delete question based on id
const deleteQuestionById = (id) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.delete(`${apiURL}/deleteQuestion/${id}`, config);
}

// get question based on screen and platform
const getQuestionByKey = (key, platform) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/getAllQuestions/${key}/${platform}`, config);
}

export const helpApi = {
    addQuestion,
    getAllQuestions,
    updateQuestionById,
    getQuestionById,
    deleteQuestionById,
    getQuestionByKey
};