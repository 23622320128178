import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signupAction } from "./State/actions";
import { useParams, useNavigate } from "react-router-dom";
import { message } from "antd";
import Spinner from "../Spinner/Spinner";
import TickMark from "./Components/TickMark";

/**
 * VerifyPage component handles user account verification via a token, displays a success message with a countdown,
 * and navigates the user to the login page after successful verification.

 * @returns {JSX.Element} The rendered VerifyPage component.
 */

const VerifyPage = () => {
  const { id, token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const { signupdata, signupError } = useSelector((str) => str.signupReducer);
  const { isLoading } = useSelector((str) => str.loadingReducer);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    dispatch(signupAction.verifyuserEvent(id, token));
  }, [dispatch, id, token]);

  useEffect(() => {
    if (signupError) {
      messageApi.open({
        type: "error",
        content: signupError,
      });
    } else if (signupdata) {
      setVerificationSuccess(true);

      // Start countdown after displaying success message
      const countdownInterval = setInterval(() => {
        setCountdown((prevCount) => {
          if (prevCount === 1) {
            clearInterval(countdownInterval);
            navigate("/login");
          }
          return prevCount - 1;
        });
      }, 1000);
    }
    dispatch(signupAction.clearSignupData());
  }, [signupError, signupdata]);

  return (
    <div>
      {contextHolder}
      {isLoading && <Spinner />}
      {verificationSuccess && (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <TickMark countdown={countdown} />
        </div>
      )}
    </div>
  );
};

export default VerifyPage;

