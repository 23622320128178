export const dashboardConstants = {
  GET_ACTION_CENTER_DETAILS_SUCCESS: "GET_ACTION_CENTER_DETAILS_SUCCESS",
  GET_ACTION_CENTER_DETAILS_FAILURE: "GET_ACTION_CENTER_DETAILS_FAILURE",
  GET_CAPTAIN_DETAILS_SUCCESS: "GET_CAPTAIN_DETAILS_SUCCESS",
  GET_CAPTAIN_DETAILS_ERROR: "GET_CAPTAIN_DETAILS_ERROR",
  NOTIFY_ADMIN_OF_DECLINING_BOOKING_SUCCESS:
    "NOTIFY_ADMIN_OF_DECLINING_BOOKING_SUCCESS",
  NOTIFY_ADMIN_OF_DECLINING_BOOKING_ERROR:
    "NOTIFY_ADMIN_OF_DECLINING_BOOKING_ERROR",
  GET_CAPTAINS_BOOKINGS_SUCCESS: "GET_CAPTAINS_BOOKINGS_SUCCESS",
  GET_CAPTAINS_BOOKINGS_ERROR: "GET_CAPTAINS_BOOKINGS_ERROR",
  ACCEPT_OR_REJECT_CAPTAINS_DECLINE_REQUEST_SUCCESS:
    "ACCEPT_OR_REJECT_CAPTAINS_DECLINE_REQUEST_SUCCESS",
  ACCEPT_OR_REJECT_CAPTAINS_DECLINE_REQUEST_ERROR:
    "ACCEPT_OR_REJECT_CAPTAINS_DECLINE_REQUEST_ERROR",

  REQUEST_FOR_CANCELLATION_SUCCESS: "REQUEST_FOR_CANCELLATION_SUCCESS",
  REQUEST_FOR_CANCELLATION_ERROR: "REQUEST_FOR_CANCELLATION_ERROR"
};
