import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { List, theme, Tooltip, Spin } from 'antd';
import dayjs from 'dayjs';
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux';
/**
 * NotificationsList component displays a list of notifications with options to read, delete, and view details.
 * It supports infinite scrolling to load more notifications as the user scrolls.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.handleDelete - Function to handle the deletion of a notification.
 * @param {Function} props.handleRead - Function to handle marking a notification as read.
 * @param {Function} props.handleNotification - Function to handle the navigation or action when a notification is clicked.
 * @param {Function} props.loadMoreNotifications - Function to load more notifications when scrolling.
 *
 * @returns {JSX.Element} The rendered NotificationsList component.
 */

const NotificationsList = ({ handleDelete, handleRead, handleNotification, loadMoreNotifications }) => {
    const { allNotifications, totalNotifications } = useSelector(str => str.navbarReducer);
    const { isLoadingNotifications } = useSelector(str => str.loadingReducer);

    const { useToken } = theme;
    const { token } = useToken();

    return (
        <div id="scrollableDiv" style={{ height: "100%", overflow: "auto" }}>
            <InfiniteScroll
                scrollableTarget="scrollableDiv"
                dataLength={allNotifications.length}
                hasMore={allNotifications.length < totalNotifications}
                next={loadMoreNotifications}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
                loader={
                    isLoadingNotifications && <div style={{ textAlign: 'center' }}><Spin /></div>
                }
            >
                <List
                    itemLayout="horizontal"
                    dataSource={allNotifications}
                    renderItem={(item, index) => (
                        <List.Item
                            actions={[
                                !item?.read && (
                                    <Tooltip title="Mark as read">
                                        <CheckOutlined onClick={() => handleRead(item)} />
                                    </Tooltip>
                                ),
                                <Tooltip title="Delete">
                                    <DeleteOutlined onClick={() => handleDelete(item)} />
                                </Tooltip>,
                            ]}
                            style={{
                                cursor: "pointer",
                                background: item.read ? "none" : token.LightBlue,
                                paddingLeft: "24px",
                                paddingRight: "24px",
                            }}
                        >
                            <List.Item.Meta
                                onClick={() => handleNotification(item)}
                                title={item?.title}
                                description={item?.body}
                            />
                            {dayjs(item.createdAt).format("MMMM D, YYYY h:mm A")}
                        </List.Item>
                    )}
                />

            </InfiniteScroll>
        </div>
    )
}

export default NotificationsList
