import { captainConstants } from "./action-types";

const initialState = {
    cancelcaptainBooking: false,
    cancelcaptainBookingErr: false,
    verifyOtpforVerificationOrderParentSuccess: false,
    verifyOtpforVerificationOrderParentErr: false,
    requestOtpforVerificationOrderParentSuccess: false,
    requestOtpforVerificationOrderParentErr: false,
};

const captainReducer = (state = initialState, action) => {
    switch (action.type) {
        case captainConstants.CANCELCAPTAINBOOKING_ERROR:
            return {
                ...state,
                cancelcaptainBooking: false,
                cancelcaptainBookingErr: action.error,
            };
        case captainConstants.CANCELCAPTAINBOOKING_SUCCESS:
            return {
                ...state,
                cancelcaptainBooking: action.data,
                cancelcaptainBookingErr: false,
            };
        case captainConstants.SEND_OTP_FOR_VERIFICATION_ORDER_PARENT_ERROR:
            return {
                ...state,
                requestOtpforVerificationOrderParentSuccess: false,
                requestOtpforVerificationOrderParentErr: action.error,
            };
        case captainConstants.SEND_OTP_FOR_VERIFICATION_ORDER_PARENT_SUCCESS:
            return {
                ...state,
                requestOtpforVerificationOrderParentSuccess: action.data,
                requestOtpforVerificationOrderParentErr: false,
            };
        case captainConstants.VERIFY_OTP_FOR_VERIFICATION_ORDER_PARENT_ERROR:
            return {
                ...state,
                verifyOtpforVerificationOrderParentSuccess: false,
                verifyOtpforVerificationOrderParentErr: action.error,
            };
        case captainConstants.VERIFY_OTP_FOR_VERIFICATION_ORDER_PARENT_SUCCESS:
            return {
                ...state,
                verifyOtpforVerificationOrderParentSuccess: action.data,
                verifyOtpforVerificationOrderParentErr: false,
            };
        case captainConstants.CLEAR_OTPDATA:
            return {
                ...state,
                verifyOtpforVerificationOrderParentSuccess: false,
                verifyOtpforVerificationOrderParentErr: false,
                requestOtpforVerificationOrderParentSuccess: false,
                requestOtpforVerificationOrderParentErr: false,
            };
        default:
            return state;
    }
};
export { captainReducer };
