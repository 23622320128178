import React, { useEffect, useState } from "react";
import SubscriptionCard from "./SubscriptionCard";
import { Button, Col, Row, theme } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { paymentApi } from "../../Common/utils/paymentapi";
import Spinner from "../../Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { settingsApi } from "../utils/api";
import SubscriprionDetails from "./SubscriprionDetails";
import { navbarAction } from "../../Navbar/State/actions";
import useWarnings from "../../Common/Components/useWarnings";

/**
 * Subscriptions Component
 *
 * This component renders a list of subscription plans for the user to select and subscribe to.
 * If the user is already subscribed to a plan, it displays the subscription details.
 * The component handles the payment process using Razorpay integration and shows loading states during API calls.
 *
 * @component
 * @returns {JSX.Element} Subscriptions component that displays either a list of available plans or subscription details.
 */

const Subscriptions = () => {
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const navigate = useNavigate();
  const { useToken } = theme;
  const { token } = useToken();
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const { isLoadingUserDetails } = useSelector((str) => str.loadingReducer);
  const [subscription, setSubscription] = useState();
  const { commonwarning, contextHolder } = useWarnings();
  const dispatch = useDispatch();
  const { subscriptionPlans } = useSelector((state) => state.settingsReducer);
  const [selectedPlan, setSelectedPlan] = useState("");

  const handlePlan = async (planId) => {
    setIsPaymentLoading(true);

    try {
      const {
        data: {
          info: { key },
        },
      } = await paymentApi.getApiKey();

      const subscriptionData = {
        plan_id: planId,
        total_count: 12,
        quantity: 1,
      };
      const response = await paymentApi.makePaymentSubscription(
        subscriptionData
      );
      const { info } = response.data;

      const options = {
        key,
        subscription_id: info.id,
        currency: "INR",
        name: "Pets website",
        description: "Pets service",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbYPH_66lG5dhDAVXuwo7u9iQR4WRNdmHElw&s",
        // callback_url: `${process.env.REACT_APP_BACKEND_URL}/subscription-payment-verification/${userDetails?.parentUniqueId}`,
        prefill: {
          name: `${userDetails?.firstName} ${userDetails?.lastName}`,
          email: userDetails?.email,
          contact: userDetails?.contactNumber?.phoneNumber,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: token.Secondary,
        },
        handler: function (response) {
          try {
            handlePaymentSuccess(response);
          } catch (error) {
            console.log(error, "errrrr");
          }
        },
      };
      setIsPaymentLoading(false);
      // Load Razorpay checkout script dynamically
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);

      // Trigger Razorpay checkout when the script is loaded
      script.onload = () => {
        const razorpay = new window.Razorpay(options);
        razorpay.open();
      };
    } catch (error) {
      setIsPaymentLoading(false);
      console.error(
        "Error during subscription creation or Razorpay checkout:",
        error
      );
    }
  };

  const handlePaymentSuccess = async (response) => {
    try {
      const result = await paymentApi.paymentVerificationSubscription(
        userDetails?.parentUniqueId,
        response
      );

      // Handle the result of payment verification
      if (result.data.info.type === "SUCCESS") {
        const payment_id = result.data.info.payment_id;
        // Payment successful
        console.log("succccc");
        dispatch(navbarAction.getUserDetails());
        navigate(`/`);
      } else {
        // Payment failed
        console.log("Payment failed");
      }
    } catch (error) {
      commonwarning(error?.resposne?.data?.message);
      console.error("Error verifying payment:", error);
      // Handle error scenario
    }
  };

  useEffect(() => {
    if (userDetails?.subscription_id) {
      try {
        setIsPaymentLoading(true);
        const getSubscriptionDetails = settingsApi.getSubscriptionDetails(
          userDetails?.subscription_id
        );
        getSubscriptionDetails.then((e) => {
          setSubscription(e.data.info);
          setIsPaymentLoading(false);
        });
      } catch (error) {
        setIsPaymentLoading(false);
      }
    }
  }, [userDetails]);

  if (isPaymentLoading || isLoadingUserDetails) {
    return <Spinner />;
  }

  return (
    <>
      {userDetails?.subscription_id && subscription?.status !== "cancelled" ? (
        <SubscriprionDetails
          subscription={subscription}
          setSubscription={setSubscription}
        />
      ) : (
        <>
          <Row gutter={[16, 16]}>
            {subscriptionPlans &&
              subscriptionPlans.map((plan) => (
                <Col
                  xs={24}
                  lg={{ offset: 2, span: 20 }}
                  xl={{ offset: 4, span: 16 }}
                  key={plan.planType}
                >
                  <SubscriptionCard
                    setSelectedPlan={setSelectedPlan}
                    plan={plan}
                    selectedPlan={selectedPlan}
                  />
                </Col>
              ))}
          </Row>
          <Row style={{ justifyContent: "center", marginBottom: "20px" }}>
            <Button
              size="small"
              type="primary"
              disabled={!selectedPlan}
              onClick={() => handlePlan(selectedPlan)}
            >
              Continue
            </Button>
          </Row>
        </>
      )}
    </>
  );
};

export default Subscriptions;
