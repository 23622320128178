import axios from "axios";

const apiURL = process.env.REACT_APP_BACKEND_URL;
// get user details
const getUserDetails = () => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/userDetails`, config);
};

const logoutUser = () => {
  const token = localStorage.getItem("authToken");
  const data = {
    fcmToken: localStorage.getItem("fcmToken"),
    type: "web"
  };
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
      "User-Agent": navigator.userAgent,
    },
  };
  localStorage.clear();
  return axios.post(`${apiURL}/logout`, data, config);
};

const sendNotificationToServer = (data) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.post(`${apiURL}/saveToken`, data, config);
};

const getNotifications = (page) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/getAllNotifications?page=${page}`, config);
};

const updateNotification = (id, data) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.put(`${apiURL}/updateNotification/${id}`, data, config);
};

const markAllNotificationsAsRead = () => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.put(`${apiURL}/markAllAsRead`, null, config);
};

const getNotificationCount = () => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/notificationCount`, config);
}

export const navbarApi = {
  getUserDetails,
  logoutUser,
  sendNotificationToServer,
  getNotifications,
  updateNotification,
  markAllNotificationsAsRead,
  getNotificationCount
};
