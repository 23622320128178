import React, { useEffect, useState } from "react";
import { Layout, message, notification } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import styles from "./Styles/header.module.css";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LargeScreenNavbar from "./Components/LargeScreenNavbar";
import SmallScreenNavbar from "./Components/SmallScreenNavbar";
import { navbarAction } from "./State/actions";
import useGenerateToken, { messaging } from "../Notifications/firebase";
import { onMessage } from "firebase/messaging";
import { signupAction } from "../Signup/State/actions";
import { useSocket } from "../context/SocketContext";
import ProfileItems from "./Components/ProfileItems";
import { appAction } from "../App/state/actions";
import { helpAction } from "../Help/State/actions";
import { useRoute } from "../context/RouteContext";
import NotificationDrawer from "./Components/NotificationDrawer";
import HelpDrawer from "./Components/HelpDrawer";
const { Header } = Layout;

/**
 * A responsive navigation bar component that handles user interactions and notifications.
 *
 * This component adjusts its layout based on the screen size, displaying a large screen
 * navbar or a small screen navbar accordingly. It also integrates with Firebase for push notifications,
 * handles user logout, role switching, and listens to socket events for real-time updates.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.finalAddress - The final address to display in the navbar.
 * @param {string} props.displayName - The display name of the user.
 *
 * @returns {JSX.Element} The rendered navbar, either large or small screen version based on the viewport size.
 */

const Navbar = ({ finalAddress, displayName }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const {
    updatedboardingcenter,
    boardingcenterError,
    boardingcenter,
  } = useSelector((str) => str.addressReducer);
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentSocket } = useSocket();
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, holder] = message.useMessage();
  const { routeKey } = useRoute();

  useGenerateToken();

  useEffect(() => {
    onMessage(messaging, (payload) => {
      api.success({
        message: payload.notification.title,
        description: payload.notification.body,
        duration: 0,
      });
      dispatch(navbarAction.getNotificationCountEvent());
    });
    // eslint-disable-next-line
  }, []);

  const onClickEvent = () => {
    dispatch(navbarAction.logoutEvent());
    dispatch(navbarAction.userDetailsReset());
    navigate("/login");
  };

  const onSwitchRole = (role) => {
    dispatch(
      signupAction.requestOTPEvent({ email: userDetails?.email, role: role })
    );
    navigate("/switchRole", { state: role });
  };

  useEffect(() => {
    if (updatedboardingcenter) dispatch(navbarAction.getUserDetails());
    // eslint-disable-next-line
  }, [updatedboardingcenter]);

  useEffect(() => {
    if (currentSocket) {
      currentSocket?.on("madePayment", (message) => {
        alert(message);
      });
    }
  }, [currentSocket]);

  useEffect(() => {
    dispatch(appAction.mapTokenEvent());
    dispatch(navbarAction.getNotificationCountEvent());
  }, []);

  const [open, setOpen] = useState({
    notification: false,
    help: false,
  });

  const showDrawer = () => {
    setOpen((prev) => ({ ...prev, notification: true }));
  };

  const onClose = () => {
    setOpen((prev) => ({ ...prev, notification: false }));
    dispatch(navbarAction.clearNotifications());
  };

  const showDrawerHelp = () => {
    // setOpenHelp(true);
    setOpen((prev) => ({ ...prev, help: true }));
    dispatch(helpAction.getQuestionByKeyEvent(routeKey, "Web"));
  };

  const onCloseHelp = () => {
    setOpen((prev) => ({ ...prev, help: false }));
  };

  const currentRole = userDetails?.currentRole;

  const profileItems = ProfileItems({
    userDetails,
    currentRole,
    onSwitchRole,
    onClickEvent,
  });

  return (
    <>
      {contextHolder}
      {holder}
      <Header className={styles.header}>
        {!isSmallScreen && (
          <LargeScreenNavbar
            displayName={displayName}
            finalAddress={finalAddress}
            profileItems={profileItems}
            showDrawer={showDrawer}
            showDrawerHelp={showDrawerHelp}
            onClose={onClose}
            onCloseHelp={onCloseHelp}
            open={open}
          />
        )}
        {isSmallScreen && (
          <SmallScreenNavbar
            displayName={displayName}
            finalAddress={finalAddress}
            profileItems={profileItems}
            showDrawer={showDrawer}
            showDrawerHelp={showDrawerHelp}
            onClose={onClose}
            onCloseHelp={onCloseHelp}
            open={open}
          />
        )}
      {open.notification && (
        <NotificationDrawer onClose={onClose} open={open.notification} />
      )}
      {open.help && <HelpDrawer onClose={onCloseHelp} open={open.help} />}
      </Header>
    </>
  );
};

export { Navbar };
