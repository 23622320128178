import { bookAppointmentConstants } from "./action-types";

const initialState = {
  tokendata: false,
  tokenLoading: true,
  tokenError: false,
  valuesofmap: [],
  valuesLoading: true,
  valuesError: false,
};

const mapsReducer = (state = initialState, action) => {
  switch (action.type) {
    case bookAppointmentConstants.MAP_TOKEN_SUCCESS:
      return {
        ...state,
        tokendata: action.tokenData,
        tokenLoading: true,
        tokenError: false,
      };
    case bookAppointmentConstants.MAP_TOKEN_ERROR:
      return {
        ...state,
        tokendata: false,
        tokenLoading: false,
        tokenError: action.tokenError,
      };
    case bookAppointmentConstants.MAP_VALUES_SUCCESS:
      return {
        ...state,
        valuesofmap: action.data,
        valuesLoading: true,
        valuesError: false,
      };
    case bookAppointmentConstants.MAP_VALUES_ERROR:
      return {
        ...state,
        valuesofmap: [],
        valuesLoading: false,
        valuesError: action.valuesError,
      };
    case bookAppointmentConstants.SEARCH_RESET:
      return {
        ...state,
        valuesofmap: [],
        valuesLoading: false,
        valuesError: false,
      };
    default:
      return state;
  }
};
export { mapsReducer };
