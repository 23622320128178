import { Card, Col, Collapse, Row } from "antd";
import dayjs from "dayjs";
import React from "react";
import { services } from "../../Common/Components/Services";
import TimeLineComponent from "../../Common/Components/TimeLineComponent";

/**
 * Displays detailed information about a parent (pet owner) and their booking.
 * @param {Object} props - Component properties.
 * @param {Object} props.completeDetails - The complete details of the booking and parent.
 */
const ParentInfoForAdmin = ({ completeDetails }) => {
  const items = [];

  if (completeDetails) {
    const {
      petPickupAndDrop_status,
      petBoarding_status,
    } = completeDetails.status;

    if (petPickupAndDrop_status?.initiated?.status) {
      items.push({
        label: "Check Pickup and drop status",
        key: "1",
        children: (
          <TimeLineComponent
            completeDetails={completeDetails.status}
            info="petPickupAndDrop_status"
          />
        ),
      });
    }

    if (petBoarding_status?.initiated?.status) {
      items.push({
        label: "Check pet boarding status",
        key: "2",
        children: (
          <TimeLineComponent
            completeDetails={completeDetails.status}
            info="petBoarding_status"
          />
        ),
      });
    }
  }

  // const items = [
  //   {
  //     label: "Check Pickup and drop status",
  //     children: (
  //       <TimeLineComponent
  //         completeDetails={completeDetails?.status}
  //         info="petPickupAndDrop_status"
  //       />
  //     ),
  //   },
  //   {
  //     label: "Check pet boarding status",
  //     children: (
  //       <TimeLineComponent
  //         completeDetails={completeDetails?.status}
  //         info="petBoarding_status"
  //       />
  //     ),
  //   },
  // ];
  return (
    <Card hoverable style={{ borderRadius: 0 }}>
      <Row wrap={true}>
        <Col span={10} style={{ fontWeight: 500 }}>
          Parent Name
        </Col>
        <Col span={2} style={{ fontWeight: 500 }}>
          :
        </Col>
        <Col span={12}>
          {completeDetails?.parent?.firstName +
            " " +
            completeDetails?.parent?.lastName}
        </Col>

        <Col span={10} style={{ fontWeight: 500 }}>
          Boooking ID
        </Col>
        <Col span={2} style={{ fontWeight: 500 }}>
          :
        </Col>
        <Col span={12}>{completeDetails?.bookingUniqueId}</Col>

        <Col span={10} style={{ fontWeight: 500 }}>
          Pet Name
        </Col>
        <Col span={2} style={{ fontWeight: 500 }}>
          :
        </Col>
        <Col span={12}>{completeDetails?.parent?.pet?.name}</Col>

        <Col span={10} style={{ fontWeight: 500 }}>
          Contact Number
        </Col>
        <Col span={2} style={{ fontWeight: 500 }}>
          :
        </Col>
        <Col span={12}>
          {`+${completeDetails?.parent?.contactNumber?.countryCode} ${completeDetails?.parent?.contactNumber?.phoneNumber}`}
        </Col>

        <Col span={10} style={{ fontWeight: 500 }}>
          Parent Address
        </Col>
        <Col span={2} style={{ fontWeight: 500 }}>
          :
        </Col>
        <Col span={12}>{completeDetails?.completeUserAddress}</Col>

        <Col span={10} style={{ fontWeight: 500 }}>
          Vaccination Certificate
        </Col>
        <Col span={2} style={{ fontWeight: 500 }}>
          :
        </Col>
        <Col span={12}>
          {completeDetails?.parentPetInfo?.vaccinationcertificate
            ? "Needed"
            : "Not needed"}
        </Col>

        <Col span={10} style={{ fontWeight: 500 }}>
          Selected Services
        </Col>
        <Col span={2} style={{ fontWeight: 500 }}>
          :
        </Col>
        <Col span={12}>
          {`${completeDetails?.services
            ?.map((service) => service?.serviceName)
            .join(", ")}`}
        </Col>

        <Col span={10} style={{ fontWeight: 500 }}>
          Pickup Address
        </Col>
        <Col span={2} style={{ fontWeight: 500 }}>
          :
        </Col>
        <Col span={12}>{completeDetails?.pickUpAddress?.fullAddress}</Col>

        <Col span={10} style={{ fontWeight: 500 }}>
          Drop Address
        </Col>
        <Col span={2} style={{ fontWeight: 500 }}>
          :
        </Col>
        <Col span={12}>{completeDetails?.dropAddress?.fullAddress}</Col>

        <Col span={10} style={{ fontWeight: 500 }}>
          Booked On
        </Col>
        <Col span={2} style={{ fontWeight: 500 }}>
          :
        </Col>
        <Col span={12}>
          {dayjs(
            completeDetails?.parentPetInfo?.bookingStatus.booking_status.date
          ).format("MMMM D, YYYY h:mm A")}
        </Col>
      </Row>
      <div style={{ marginTop: "1.2em" }}>
        <Collapse items={items} size="small" />
      </div>
    </Card>
  );
};

export default ParentInfoForAdmin;
