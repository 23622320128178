import React from "react";
import {
  Row,
  Col,
  Menu,
  Typography,
  theme,
  Switch,
  Space,
  Dropdown,
  Badge,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SearchOutlined,
  MenuOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  DownOutlined,
  MoonOutlined,
  SunOutlined,
  QuestionCircleOutlined,
  BellOutlined,
} from "@ant-design/icons";
import styles from "../Styles/header.module.css";
import OptionsComponent from "../../Common/Components/MenuData";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerOpen } from "../../state/actions";
import { useTheme } from "../../theme/ThemeToggle";
import LanguageItems from "./LanguageItems";
const { Text } = Typography;

/**
 * SmallScreenNavbar component renders a navigation bar optimized for small screens.
 * It includes a dashboard title, a search icon, and a horizontal menu for navigation.
 *
 * @returns {JSX.Element} The rendered SmallScreenNavbar component.
 *
 * @description
 * - Includes a search icon (`SearchOutlined`) on the right.
 * - Renders a horizontal menu with navigation options and an overflow indicator (`MenuOutlined`).
 * - Navigation is handled by the `onClick` function.
 */

const SmallScreenNavbar = ({
  finalAddress,
  showDrawer,
  showDrawerHelp,
  onClose,
  onCloseHelp,
  open,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { name } = useSelector((str) => str.loadingReducer);
  const dispatch = useDispatch();
  const { useToken } = theme;
  const { token } = useToken();
  const { toggleTheme } = useTheme();
  const languageItems = LanguageItems();

  const onClick = (e) => {
    navigate(`/${e?.item?.props?.path}`);
  };
  const { userDetails, notificationCount } = useSelector((str) => str.navbarReducer);

  return (
    <Row justify={"space-between"} style={{ alignItems: "center" }}>
      <Col xs={8} sm={10}>
        {location.pathname !== "/addAddress" && name === "" ? (
          <Typography.Paragraph
            ellipsis={{ rows: 2, symbol: "...", expandable: false }}
            onClick={() => dispatch(setDrawerOpen())}
            style={{
              paddingLeft: "25px",
              textAlign: "left",
              cursor: "pointer",
              marginBottom: "0",
            }}
          >
            <EnvironmentOutlined
              style={{ color: token.Primary, fontWeight: 500 }}
            />{" "}
            &nbsp;
            <Text className={styles.address}>
              {finalAddress
                ? finalAddress
                : userDetails?.usersCurrentAddressDetails?.fullAddress
                  ? userDetails?.usersCurrentAddressDetails?.fullAddress
                  : "Select location"}
            </Text>
          </Typography.Paragraph>
        ) : (
          <Text className={styles.dashboard}>{name}</Text>
        )}
      </Col>
      <Col xs={12} sm={10} style={{ textAlign: "end" }}>
        <Space direction="horizontal" size={"small"}>
          <Switch
            size="small"
            style={{
              backgroundColor: token.Gray,
              fontSize: "clamp(0.5em, 4vw, 1.2em)",
            }}
            unCheckedChildren={<MoonOutlined />}
            checkedChildren={<SunOutlined />}
            defaultChecked
            onChange={toggleTheme}
          />
          <Dropdown menu={{ items: languageItems }}>
            <div>
              <GlobalOutlined
                style={{ fontSize: "clamp(0.5em, 4vw, 1.2em)" }}
              />
              <DownOutlined style={{ fontSize: "10px" }} />
            </div>
          </Dropdown>
          <SearchOutlined
            style={{ fontSize: "clamp(0.5em, 4vw, 1.2em)" }}
            onClick={() => navigate("/search")}
          />
          <div onClick={showDrawerHelp}>
            <QuestionCircleOutlined
              style={{ fontSize: "clamp(0.5em, 4vw, 1.2em)" }}
            />
          </div>
          <Badge count={notificationCount} overflowCount={9} size="small">
            <div onClick={showDrawer}>
              <BellOutlined style={{ fontSize: "clamp(0.5em, 4vw, 1.2em)" }} />
            </div>
          </Badge>
        </Space>
      </Col>
      <Col xs={3} sm={3}>
        <Menu
          onSelect={onClick}
          theme="light"
          overflowedIndicator={<MenuOutlined style={{ fontSize: "20px" }} />}
          mode="horizontal"
          items={OptionsComponent()}
        />
      </Col>
    </Row>
  );
};

export default SmallScreenNavbar;
