import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Button,
  Divider,
  List,
  Row,
  Col,
  Space,
  Result,
} from "antd";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bookAppointmentAction } from "../../BookAppointment/State/bookAppointmentActions";
import {
  DownloadOutlined,
  CheckCircleOutlined,
  HomeOutlined,
  CompassOutlined,
} from "@ant-design/icons";

const { Title, Paragraph, Text } = Typography;

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const referenceNum = searchParams.get("reference");
  const dispatch = useDispatch();
  const location = useLocation();

  const { bookappointmentdetails } = useSelector(
    (state) => state.bookAppointmentReducer
  );
  const { parentDetails, petDetails } = useSelector(
    (state) => state.profileReducer
  );

  useEffect(() => {
    dispatch(
      bookAppointmentAction.getBookAppointmentdetailsEvent(
        localStorage.getItem("bookingID")
      )
    );
  }, [dispatch, parentDetails, petDetails]);

  const [shouldConfirm, setShouldConfirm] = useState(true);

  useEffect(() => {
    const handlePopState = () => {
      if (location.pathname === `/paymentsuccess?reference=${referenceNum}`) {
        if (shouldConfirm) {
          if (!window.confirm("Are you sure you want to leave?")) {
            navigate(location.pathname, { replace: true });
            return; // Prevent further execution
          }
        }
        setShouldConfirm(true); // Reset for next time
        navigate(
          `/track/${bookappointmentdetails?.bookingDetails?.bookingUniqueId}`
        );
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location, navigate, referenceNum]);

  return (
    <Row justify="center" style={{ marginTop: 50 }}>
      <Col xs={24} sm={20} md={16} lg={12}>
        <Card>
          <Result
            status="success"
            title="Payment Successful!"
            subTitle="Your order has been processed successfully."
            icon={<CheckCircleOutlined style={{ color: "#52c41a" }} />}
          />
          <Divider />
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <div>
              <Title level={4}>Order Details</Title>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Text strong>Booking ID:</Text>
                </Col>
                <Col span={12}>
                  <Text>
                    {bookappointmentdetails?.bookingDetails?.bookingUniqueId}
                  </Text>
                </Col>
                <Col span={12}>
                  <Text strong>Total Amount:</Text>
                </Col>
                <Col span={12}>
                  <Text>
                    ₹{bookappointmentdetails?.bookingDetails?.totalPrice}
                  </Text>
                </Col>
                <Col span={12}>
                  <Text strong>Reference No:</Text>
                </Col>
                <Col span={12}>
                  <Text>{referenceNum}</Text>
                </Col>
              </Row>
            </div>
            <div>
              <Title level={4}>Your Service Details</Title>
              <List
                header={
                  <Row justify="space-between" style={{ width: "100%" }}>
                    <Col span={12}>
                      <Text strong>Service Name</Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <Text strong>Price</Text>
                    </Col>
                  </Row>
                }
                bordered
                dataSource={bookappointmentdetails?.bookingDetails?.services}
                renderItem={(item) => (
                  <List.Item>
                    <Row justify="space-between" style={{ width: "100%" }}>
                      <Col>
                        {item?.serviceName === "petBoarding"
                          ? "Pet Boarding"
                          : item?.serviceName === "petPickupAndDrops"
                          ? "Pet Pickup And Drop"
                          : ""}
                      </Col>
                      <Col>₹{item?.price}</Col>
                    </Row>
                  </List.Item>
                )}
              />
            </div>
            <Row justify="space-between">
              <Col>
                <Button type="primary" icon={<HomeOutlined />}>
                  <Link to="/">Go to Homepage</Link>
                </Button>
              </Col>
              {/* <Col>
                <Button type="primary" icon={<CompassOutlined />}>
                  <Link
                    to={`/track/${bookappointmentdetails?.bookingDetails?.bookingUniqueId}`}
                  >
                    Track status
                  </Link>
                </Button>
              </Col> */}
              <Col>
                <Button icon={<DownloadOutlined />}>Invoice</Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default PaymentSuccess;
