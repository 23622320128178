import { Card, Col, Divider, Row, Tag, Tooltip, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import styles from "../Styles/finalPage.module.css";
import { InfoCircleOutlined, PlusCircleFilled } from "@ant-design/icons";
import { services } from "../../Common/Components/Services";
const { Title } = Typography;

/**
 * @component final payment page
 * @description This component displays the details of a payment.
 * @param {Object} details - services and its prices details to display and edit.
 */
const RightPayment = ({ details }) => {
  const navigate = useNavigate();
  return (
    <Card hoverable>
      <Row>
        {details?.services?.map((service) => (
          <React.Fragment key={uuidv4()}>
            <Col span={18}>
              <Typography.Title style={{ fontSize: "1em" }}>
                {services?.find((e) => e.key === service?.serviceName).name}
                &nbsp;
                {service?.serviceType === "addon" && (
                  <Tooltip placement="topLeft" title="Addon">
                    <PlusCircleFilled />
                  </Tooltip>
                )}
              </Typography.Title>
            </Col>
            <Col span={6}>
              <Title className={styles.pricetext}>
                &#8377; {service?.price}
              </Title>
            </Col>
          </React.Fragment>
        ))}
        <Divider
          style={{
            background: "lightGrey",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <Col span={18}>
          <Title style={{ fontSize: "1em" }}>GST & other charges</Title>
        </Col>
        <Col span={6}>
          <Title className={styles.pricetext}>--</Title>
        </Col>
        <Col span={18}>
          <Title style={{ fontSize: "1.2em" }}>TO PAY</Title>
        </Col>
        <Col span={6}>
          <Title className={styles.pricetext}>
            &#8377; {details?.totalPrice}
          </Title>
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1.2em",
        }}
      >
        <Tag onClick={() => navigate("/accounts/subscriptionPlans")}>
          <InfoCircleOutlined /> Save more by subscribing to our plans
        </Tag>
      </div>
    </Card>
  );
};

export default RightPayment;
