import { startLoading, stopLoading } from "../../state/actions"
import { startLoadingPaymentLinks, stopLoadingPaymentLinks } from "../../state/additionalLoadingActions"
import { paymentLinksApi } from "../utils/api"
import { paymentLinksConstants } from "./action-types"


const getPaymentLinksSuccess = (data) => {
    return {
        type: paymentLinksConstants.GET_ALL_PAYMENT_LINKS_SUCCESS,
        data: data,
    }
}

const getPaymentLinksError = (err) => {
    return {
        type: paymentLinksConstants.GET_ALL_PAYMENT_LINKS_ERROR,
        valuesError: err,
    }
}

const getPaymentLinksEvent = () => {
    return (dispatch) => {
        dispatch(startLoadingPaymentLinks());
        paymentLinksApi.getPaymentLinks().then(response => {
            dispatch(getPaymentLinksSuccess(response.data.info?.payment_links));
            dispatch(stopLoadingPaymentLinks())
        })
            .catch(err => {
                dispatch(getPaymentLinksError(err));
                dispatch(stopLoadingPaymentLinks());
            })
    }
}

const cancelPaymentLinkSuccess = (data) => {
    return {
        type: paymentLinksConstants.CANCEL_PAYMENT_LINKS_SUCCESS,
        data: data,
    }
}

const cancelPaymentLinkErr = (err) => {
    return {
        type: paymentLinksConstants.CANCEL_PAYMENT_LINKS_ERROR,
        valuesError: err,
    }
}

const cancelPaymentLinkEvent = (paymentId) => {
    return (dispatch) => {
        dispatch(startLoading());
        paymentLinksApi.cancelPaymentLink(paymentId).then(response => {
            dispatch(cancelPaymentLinkSuccess(response.data.info?.payment_links));
            dispatch(stopLoading());
        })
            .catch(err => {
                dispatch(cancelPaymentLinkErr(err?.response?.data?.message));
                dispatch(stopLoading());
            })
    }
}

const updatePaymentLinkSuccess = (data) => {
    return {
        type: paymentLinksConstants.UPDATE_PAYMENT_LINKS_SUCCESS,
        data: data,
    }
}

const updatePaymentLinkErr = (err) => {
    return {
        type: paymentLinksConstants.UPDATE_PAYMENT_LINKS_ERROR,
        valuesError: err,
    }
}

const updatePaymentLinkEvent = (paymentId, data) => {
    return (dispatch) => {
        dispatch(startLoading());
        paymentLinksApi.updatePaymentLink(paymentId, data).then(response => {
            dispatch(updatePaymentLinkSuccess(response.data.info?.payment_links));
            dispatch(stopLoading());
        })
            .catch(err => {
                dispatch(updatePaymentLinkErr(err?.response?.data?.message));
                dispatch(stopLoading());
            })
    }
}

const createPaymentLinkSuccess = (data) => {
    return {
        type: paymentLinksConstants.UPDATE_PAYMENT_LINKS_SUCCESS,
        data: data,
    }
}

const createPaymentLinkErr = (err) => {
    return {
        type: paymentLinksConstants.UPDATE_PAYMENT_LINKS_ERROR,
        valuesError: err,
    }
}

const createPaymentLinkEvent = (data) => {
    return (dispatch) => {
        dispatch(startLoading());
        paymentLinksApi.createPaymentLink(data).then(response => {
            dispatch(createPaymentLinkSuccess(response.data.info?.payment_links));
            dispatch(stopLoading());
        })
            .catch(err => {
                dispatch(createPaymentLinkErr(err?.response?.data?.message));
                dispatch(stopLoading());
            })
    }
}


const clearPaymentLink = () => {
    return {
        type: paymentLinksConstants.CLEAR_PAYMENT_LINKS,
    };
}


export const paymentLinksAction = {
    getPaymentLinksEvent,
    cancelPaymentLinkEvent,
    updatePaymentLinkEvent,
    createPaymentLinkEvent,
    clearPaymentLink
};
