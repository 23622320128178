import { Timeline } from "antd";
import React from "react";
import { statuses } from "../statuses";
import dayjs from "dayjs";
const TimeLineComponent = ({ completeDetails, info }) => {

  const formatStatus = (key, statusData) => {
    const keys = key?.split(".");
    let currentData = statusData;
    keys?.forEach((k) => {
      currentData = currentData?.[k] || {};
    });
    return currentData;
  };

  // Filter out the specific status based on the presence of petPickupAndDrop_status
  const filterStatuses = (statuses, details) => {
    const filteredStatuses = JSON.parse(JSON.stringify(statuses));
    if (details?.petPickupAndDrop_status?.initiated?.status) {
      filteredStatuses.petBoarding_status = filteredStatuses.petBoarding_status.filter(
        (status) =>
          ![
            "pet_verification_by_admin",
            "pet_received_at_boarding_center",
            "parent_verification",
            "parent_verification_while_releasing_pet",
            "pet_handed_over_to_parent",
          ].includes(status.key)
      );
    } else {
      filteredStatuses.petBoarding_status = filteredStatuses.petBoarding_status.filter(
        (status) => !["pet_picked_up_from_boarding_center"].includes(status.key)
      );
    }
    return filteredStatuses;
  };
  const filteredStatuses = filterStatuses(statuses, completeDetails);

  // const getComment = (key, status, completeDetails) => {
  //     if (completeDetails?.bookingStatus?.petBoarding_status?.booking_confirmation?.status === "decline") {
  //         return <Text>This booking has been declined</Text>
  //     }
  //     if (key === "boarding_center_to_parent_location_to_pickup_pet.accepts_ride") {
  //         switch (status) {
  //             case "none":
  //                 return <p style={{ color: token.Primary, cursor: "pointer" }} onClick={() => navigate(`/captains/${completeDetails?.bookingUniqueId}`)}>Board head has cancelled the captain. Reassign now.</p>
  //             case "in_process":
  //                 return <p>Awaiting for the response from the captain</p>
  //             case "decline":
  //                 return <Text style={{ color: token.Primary, cursor: "pointer" }} onClick={() => navigate(`/captains/${completeDetails?.bookingUniqueId}`)}>Captain has declined. Please assign another captain!</Text>
  //             case "decline_request":
  //                 return <Text>Decline request has been raised by the captain for this booking.Check now!</Text>
  //             default:
  //                 return "";
  //         }
  //     }
  // }

  const items = filteredStatuses?.[info]?.map((status) => {
    const statusData = formatStatus(status?.key, completeDetails?.[info]);
    const color =
      status?.key === "initiated" || statusData?.status === "accept"
        ? "green"
        : statusData?.status == "decline"
        ? "red"
        : "grey";
    const label = statusData?.date
      ? dayjs(statusData.date).format("MMM D, YYYY h:mm A")
      : "";
    // const comment = getComment(status?.key, statusData?.status, completeDetails);

    return {
      children: (
        <div>
          <p>{status.children}</p>
          {/* {comment && <p>{comment}</p>} */}
        </div>
      ),
      color: color,
      label: label,
    };
  });

  return <Timeline items={items} mode="alternate" />;
};

export default TimeLineComponent;
