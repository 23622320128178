import { Button, Card, Col, Divider, Row, theme, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { plans } from "../../Common/Components/Plans";
import { RightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import ChangePlan from "./ChangePlan";
import styles from "../Styles/subscriptionDetails.module.css";
import AdditionalFeatures from "./AdditionalFeatures";
import { settingsApi } from "../utils/api";
import useWarnings from "../../Common/Components/useWarnings";
import { useSelector } from "react-redux";
const { Text } = Typography;
/**
 * SubscriptionDetails Component
 *
 * This component displays the details of a user's current subscription plan, such as the plan type,
 * start date, next payment date, total billing cycles, and paid cycles. It also allows the user to
 * change the subscription plan by clicking the 'Change Plan' button, which toggles the display of
 * the `ChangePlan` component. Additionally, users can pause or resume their subscription using
 * the provided `handlePause` functionality.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.subscription - The subscription object containing details about the current plan.
 * @param {string} props.subscription.id - The ID of the current subscription.
 * @param {string} props.subscription.plan_id - The ID of the current subscription plan.
 * @param {string} props.subscription.status - The current status of the subscription.
 * @param {number} props.subscription.start_at - The Unix timestamp of the subscription start date.
 * @param {number} props.subscription.charge_at - The Unix timestamp of the next payment date.
 * @param {number} props.subscription.total_count - The total number of billing cycles.
 * @param {number} props.subscription.paid_count - The number of billing cycles that have already been paid.
 * @param {function} setSubscription - Function to update the subscription state.
 *
 * @returns {JSX.Element} The SubscriptionDetails component, which displays the current subscription
 * details and allows changing the plan.
 */

const SubscriprionDetails = ({ subscription, setSubscription }) => {
  const [selectedPlan, setSelectedPlan] = useState(""); // State to manage selected plan
  const { useToken } = theme;
  const { token } = useToken();
  const [showChangePlan, setShowChangePlan] = useState(false);
  const { success, commonwarning, contextHolder } = useWarnings();
  const { subscriptionPlans } = useSelector((state) => state.settingsReducer);
  const formatDate = (timestamp) =>
    dayjs.unix(timestamp).format("DD MMMM YYYY");
console.log(subscription, "subscription")
  useEffect(() => {
    if (subscription) {
      setSelectedPlan(subscription?.plan_id);
    }
  }, [subscription]);

  const handlePause = async () => {
    try {
      if (subscription?.status === "active") {
        const responseFromPause = await settingsApi.pauseSubscription(
          subscription.id
        );
        setSubscription(responseFromPause?.data?.info);
        success("Paused your subscription!!");
      } else if (subscription?.status === "paused") {
        const responseFromPause = await settingsApi.resumeSubscription(
          subscription.id
        );
        setSubscription(responseFromPause?.data?.info);
        success("Resumed your subscription!!");
      }
    } catch (error) {
      console.log(error, "error");
      commonwarning(error?.response?.data?.message);
    }
  };

  console.log(subscriptionPlans, "subscriptionPlans")

  return !showChangePlan ? (
    <Row>
      {contextHolder}
      <Col xs={24} lg={{ offset: 4, span: 16 }}>
        <Card hoverable className={styles.card}>
          <Text
            style={{
              color: token.title,
            }}
            className={styles.currentPlan}
          >
            Current plan :{" "}
            {subscriptionPlans?.find((e) => e.planId === subscription?.plan_id)?.planName}
          </Text>
          <Text className={styles.text}>
            Started on : {formatDate(subscription?.start_at)}
          </Text>
          <Text className={styles.text}>
            Next payment :{" "}
            {subscription?.status === "active"
              ? formatDate(subscription?.charge_at)
              : "--"}
          </Text>
          <Text className={styles.text}>
            Total Billing cycles : {subscription?.total_count}
          </Text>
          <Text className={styles.text}>
            Paid cycles : {subscription?.paid_count}
          </Text>
          <Text className={styles.text}>
            Status :{" "}
            <span style={{ color: token.Primary, fontWeight: 500 }}>
              {subscription?.status.toUpperCase()}
            </span>
          </Text>
          {subscription?.status === "cancelled" && (
            <Text className={styles.text}>
              Cancelled on : {formatDate(subscription?.ended_at)}
            </Text>
          )}
          <Divider />
          <Button
            type="link"
            className={styles.changePlan}
            iconPosition="end"
            icon={<RightOutlined />}
            onClick={() => setShowChangePlan(true)}
          >
            Change Plan
          </Button>
        </Card>
      </Col>
      <Col xs={24} lg={{ offset: 4, span: 16 }} style={{ marginTop: "1.2em" }}>
        <AdditionalFeatures
          handlePause={handlePause}
          subscription={subscription}
          setSubscription={setSubscription}
        />
      </Col>
    </Row>
  ) : (
    <ChangePlan
      setSelectedPlan={setSelectedPlan}
      selectedPlan={selectedPlan}
      setShowChangePlan={setShowChangePlan}
      subscription={subscription}
      setSubscription={setSubscription}
    />
  );
};

export default SubscriprionDetails;
