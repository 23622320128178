import { Card, Col, Row, theme } from 'antd'
import React, { useState } from 'react';
import styles from '../Styles/petcard.module.css';
import { useNavigate } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';

/**
 * ParentCard component displays detailed information about a parent, such as their first name, last name, and email.
 * It also displays available roles if applicable. The card supports hover interactions to reveal a navigation icon.
 *
 * @param {Object} props - The component's props.
 * @param {Object} props.e - The parent data object to be displayed on the card.
 * @returns {JSX.Element} The rendered ParentCard component.
 *
 * @description
 * - **Hover Effect**: The card displays a share icon when hovered over, which can be clicked to navigate to the URL specified in `redirectURL`.
 * - **Responsive**: The layout adapts to the available space, showing parent details and handling hover interactions.
 */


const ParentCard = ({ e }) => {
    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => setHover(true);
    const handleMouseLeave = () => setHover(false);
    const { useToken } = theme;
    const { token } = useToken();
    const navigate = useNavigate();
    return (
        <Card
            bordered
            hoverable
            style={{ borderRadius: 0 }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <Row>
                <Col span={10} className={styles.col}>
                    First Name
                </Col>
                <Col span={2} className={styles.col}>
                    :
                </Col>
                <Col span={12} className={styles.col}>
                    {e?.firstName || "--"}
                </Col>
                <Col span={10} className={styles.col}>
                    Last Name
                </Col>
                <Col span={2} className={styles.col}>
                    :
                </Col>
                <Col span={12} className={styles.col}>
                    {e?.lastName || "--"}
                </Col>
                <Col span={10} className={styles.col}>
                    Email
                </Col>
                <Col span={2} className={styles.col}>
                    :
                </Col>
                <Col span={12} className={styles.col}>
                    {e?.email || "--"}
                </Col>
                {
                    e?.roleType.length > 1 &&
                    <>
                        <Col span={10} className={styles.col}>
                            Available roles
                        </Col>
                        <Col span={2} className={styles.col}>
                            :
                        </Col>
                        <Col span={12} className={styles.col}>
                            {e?.roleType?.join(",")}
                        </Col>
                    </>
                }
            </Row>
            {hover && (
                <div className={styles.shareIcon} onClick={() => navigate(e.redirectURL)}>
                    <ArrowRightOutlined style={{ color: token.White, fontSize: "14px" }} />
                </div>
            )}
        </Card>
    )
}

export default ParentCard
