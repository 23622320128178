import React from "react";
import { Row, Col, Typography, Avatar, theme } from "antd";
import { GoDotFill } from "react-icons/go";
import { UserOutlined } from "@ant-design/icons";
const { Text } = Typography;

const HorizontalCard = ({ service, vet }) => {
    const { useToken } = theme;
    const { token } = useToken();

    return (
        <Row>
            <Col span={12}>
                {vet && <span style={{ marginRight: "5px" }}><Avatar icon={<UserOutlined />} /></span>}
                <Text style={{ fontWeight: 600 }}>
                    {service?.name}
                </Text>
                <Text style={{ display: "block", fontWeight: 600 }}>
                    {service?.nickname}
                </Text>
            </Col>
            <Col span={6}>
                <Text style={{ display: "block", fontWeight: 600 }}>
                    {service?.service}
                </Text>
            </Col>
            <Col span={6}>
                <Text
                    style={{ display: "flex", alignItems: "center", fontWeight: 600 }}
                >
                    <GoDotFill
                        style={{
                            color: service?.online ? token.green : token.red,
                            fontSize: "20px",
                        }}
                    />
                    {service?.online ? "On Service" : "Offline"}
                </Text>
            </Col>
        </Row>
    );
};

export default HorizontalCard;
