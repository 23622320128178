import { Col, Image, Row, Typography, Card } from "antd";
import React from "react";
import styles from "../Styles/dashboard.module.css";
import YourInformationName from "../../Assets/Dashboard/Your information.png";
import { useSelector } from "react-redux";
const { Text } = Typography;

const YourInformation = () => {
    const { userDetails } = useSelector((str) => str.navbarReducer);
    return (
        <Card style={{ position: "relative" }} bordered hoverable>
            <Row gutter={[0, 16]} className={styles.entirePage}>
                <Col xs={24} sm={24} md={12} lg={12} className={styles.parentCol}>
                    {/* <Col span={10} className={styles.col}>
                    {item.label}
                </Col>
                <Col span={2} className={styles.col}>
                    :
                </Col>
                <Col span={12} className={styles.col}>
                    {item.value || "--"}
                </Col> */}
                    <Text className={styles.titles}>Parent Name: </Text>
                    <Text>{userDetails?.firstName + " " + userDetails?.lastName}</Text>
                    <br />
                    <Text className={styles.titles}>Contact: </Text>
                    <Text>
                        +
                        {userDetails?.contactNumber?.countryCode +
                            " " +
                            userDetails?.contactNumber?.phoneNumber}
                    </Text>
                    <br />
                    <Text className={styles.titles}>Email: </Text>
                    <Text>{userDetails?.email}</Text>
                    <br />
                    <Text className={styles.titles}>Gender: </Text>
                    <Text>{userDetails?.gender || "--"}</Text>
                    <br />
                    <Text className={styles.titles}>Location: </Text>
                    <Text>{userDetails?.usersCurrentAddressDetails?.fullAddress}</Text>
                    <br />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Text className={styles.titles}>Pet Name: </Text>
                    <Text>{userDetails?.petDetails?.name || "--"}</Text>
                    <br />
                    <Text className={styles.titles}>Pet type: </Text>
                    <Text>{userDetails?.petDetails?.type || "--"}</Text>
                    <br />
                    <Text className={styles.titles}>Breed: </Text>
                    <Text>{userDetails?.petDetails?.breed || "--"}</Text>
                    <br />
                    <Text className={styles.titles}>Gender: </Text>
                    <Text>{userDetails?.petDetails?.gender || "--"}</Text>
                    <br />
                    <Text className={styles.titles}>Fav Food: </Text>
                    <Text>{userDetails?.petDetails?.favoriteFood || "--"}</Text>
                    <br />
                </Col>
            </Row>
            <div className={styles.YourInformationName}>
                <Image
                    src={YourInformationName}
                    alt="Your Information"
                    width={150}
                    preview={false}
                />
            </div>
        </Card>
    );
};

export default YourInformation;
