import React, { useEffect, useState } from "react";
import useSettingName from "../Common/Components/useSettingName";
import { useDispatch, useSelector } from "react-redux";
import { paymentLinksAction } from "./State/actions";
import Spinner from "../Spinner/Spinner";
import { FloatButton, Table } from "antd";
import PaymentLinksColumns from "./Components/PaymentLinksColumns";
import useWarnings from "../Common/Components/useWarnings";
import { PlusOutlined } from "@ant-design/icons";
import CommonModal from "../CommonModal/CommonModal";
import CreatePaymentLink from "./Components/CreatePaymentLink";
import { useRoute } from "../context/RouteContext";

const PaymentLinks = () => {
    useSettingName("Payment Links");
    const dispatch = useDispatch();
    const { isLoading } = useSelector((str) => str.loadingReducer);
    const columns = PaymentLinksColumns();
    const { error, contextHolder } = useWarnings();
    const [modalOpen, setModalOpen] = useState(false);
    const { setSelectedKey } = useRoute();


    const { isLoadingPaymentLinks } = useSelector(
        (str) => str.additionalloadingReducer
    );
    const {
        allPaymentLinks,
        cancelPaymentLink,
        updatePaymentLink,
        cancelPaymentLinkErr,
        updatePaymentLinkErr,
        createPaymentLink,
        createPaymentLinkErr
    } = useSelector((str) => str.paymentLinksReducer);

    useEffect(() => {
        dispatch(paymentLinksAction.getPaymentLinksEvent());
    }, [cancelPaymentLink, updatePaymentLink, createPaymentLink]);

    useEffect(() => {
        if (cancelPaymentLinkErr) {
            error(cancelPaymentLinkErr);
            dispatch(paymentLinksAction.clearPaymentLink());
        } else if (updatePaymentLinkErr) {
            error(updatePaymentLinkErr);
            dispatch(paymentLinksAction.clearPaymentLink());
        }
    }, [cancelPaymentLinkErr, updatePaymentLinkErr, createPaymentLinkErr]);

    // useEffect(() => {
    //     return () => {
    //         dispatch(paymentLinksAction.clearPaymentLink());
    //     };
    // }, []);

    useEffect(() => {
        setSelectedKey("PAYMENT_STATUS")
    }, [])

    if (isLoadingPaymentLinks || isLoading) {
        return <Spinner />;
    }

    return (
        <>
            {contextHolder}
            <Table
                bordered
                dataSource={allPaymentLinks}
                columns={columns}
                pagination={false}
                scroll={{ y: 500 }}
            />
            <FloatButton
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => setModalOpen(true)}
                style={{
                    right: 30,
                }}
            />
            {modalOpen && (
                <CommonModal
                    body={() => <CreatePaymentLink setModalOpen={setModalOpen} />}
                    handleCancel={() => setModalOpen(false)}
                    isModalOpen={modalOpen}
                />
            )}
        </>
    );
};

export { PaymentLinks };
