import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "mar", "hin", "tel"],
    fallbackLng: sessionStorage.getItem("locale") || "en",
    backend: {
      loadPath: `${process.env.REACT_APP_BACKEND_URL}/translations/{{lng}}`,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
