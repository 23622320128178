import { startLoading, startLoadingbooking, startLoadingParentBoardingCenter, stopLoading, stopLoadingBooking, stopLoadingParentBoardingCenter } from "../../state/actions";
import { startLoadingParentDetails, startLoadingPetDetails, startLoadingPostUpdatePetDetails, stopLoadingParentDetails, stopLoadingPetDetails, stopLoadingPostUpdatePetDetails } from "../../state/additionalLoadingActions";
import { profileapi } from "../utils/profileapi";
import { profileConstants } from "./action-types";


const updateProfileError = (err) => {
  return {
    type: profileConstants.UPDATE_PARENT_DETAILS_ERROR,
    valuesError: err,
  };
};

const updateProfileSuccess = (data) => {
  return {
    type: profileConstants.UPDATE_PARENT_DETAILS_SUCCESS,
    data: data,
  };
};

const updateParentProfileEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoadingParentDetails())
    profileapi
      .updateParentDetails(data)
      .then((response) => {
        dispatch(updateProfileSuccess(response?.data?.info));
        dispatch(stopLoadingParentDetails())
      })
      .catch((err) => {
        dispatch(updateProfileError(err?.response?.data?.message));
        dispatch(stopLoadingParentDetails())
      });
  };
};

const getParentProfileError = (err) => {
  return {
    type: profileConstants.GET_PARENT_DETAILS_ERROR,
    valuesError: err,
  };
};

const getParentProfileSuccess = (data) => {
  return {
    type: profileConstants.GET_PARENT_DETAILS_SUCCESS,
    data: data,
  };
};

const getParentProfileEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoading());
    profileapi
      .parentDetails()
      .then((response) => {
        dispatch(getParentProfileSuccess(response?.data?.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(getParentProfileError(err));
        dispatch(stopLoading());
      });
  };
};

const getPetProfileError = (err) => {
  return {
    type: profileConstants.GET_PET_DETAILS_ERROR,
    valuesError: err,
  };
};

const getPetProfileSuccess = (data) => {
  return {
    type: profileConstants.GET_PET_DETAILS_SUCCESS,
    data: data,
  };
};

const getPetProfileEvent = () => {
  return (dispatch) => {
    dispatch(startLoadingPetDetails());
    profileapi
      .petDetails()
      .then((response) => {
        dispatch(getPetProfileSuccess(response?.data?.info));
        dispatch(stopLoadingPetDetails());
      })
      .catch((err) => {
        dispatch(getPetProfileError(err));
        dispatch(stopLoadingPetDetails());
      });
  };
};

const postPetProfileError = (err) => {
  return {
    type: profileConstants.POST_PET_DETAILS_ERROR,
    valuesError: err,
  };
};

const postPetProfileSuccess = (data) => {
  return {
    type: profileConstants.POST_PET_DETAILS_SUCCESS,
    data: data,
  };
};

const postPetProfileEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoadingPostUpdatePetDetails());
    profileapi
      .postPetdetails(data)
      .then((response) => {
        dispatch(postPetProfileSuccess(response?.data?.info));
        dispatch(stopLoadingPostUpdatePetDetails());
      })
      .catch((err) => {
        dispatch(postPetProfileError(err?.response?.data?.message));
        dispatch(stopLoadingPostUpdatePetDetails());
      });
  };
};

const updatePetProfileError = (err) => {
  return {
    type: profileConstants.UPDATE_PET_DETAILS_ERROR,
    valuesError: err,
  };
};

const updatePetProfileSuccess = (data) => {
  return {
    type: profileConstants.UPDATE_PET_DETAILS_SUCCESS,
    data: data,
  };
};

const updatePetProfileEvent = (data, id) => {
  return (dispatch) => {
    dispatch(startLoadingPostUpdatePetDetails());
    profileapi
      .updatePetDetails(data, id)
      .then((response) => {
        dispatch(updatePetProfileSuccess(response?.data?.info));
        dispatch(stopLoadingPostUpdatePetDetails());
      })
      .catch((err) => {
        dispatch(updatePetProfileError(err?.response?.data?.message));
        dispatch(stopLoadingPostUpdatePetDetails());
      });
  };
};

const bookAppointmentSelectServicesError = (err) => {
  return {
    type: profileConstants.SELECT_SERVICES_FAILURE,
    error: err,
  };
};

const bookAppointmentSelectServicesSuccess = (data) => {
  return {
    type: profileConstants.SELECT_SERVICES_SUCCESS,
    data: data,
  };
};

const selectServicesEvent = (data) => {
  console.log("hello")
  return (dispatch) => {
    console.log("hello2")
    dispatch(startLoadingbooking("start"));
    profileapi
      .selectServices(data)
      .then((response) => {
        console.log("hello3")
        dispatch(bookAppointmentSelectServicesSuccess(response?.data?.info));
        localStorage.setItem("authToken", response?.data?.info?.token);
        localStorage.setItem("bookingID", response?.data?.info?.services?.bookingUniqueId);
        dispatch(stopLoadingBooking("stop"));
        console.log("hello4")
      })
      .catch((err) => {
        dispatch(bookAppointmentSelectServicesError(err));
        dispatch(stopLoadingBooking("stop"));
      });
  };
};

const parentBoardingDetailsError = (err) => {
  return {
    type: profileConstants.GET_PARENT_BOARDINGCENTER_DETAILS_FAILURE,
    error: err,
  };
};

const parentBoardingDetailsSuccess = (data) => {
  return {
    type: profileConstants.GET_PARENT_BOARDINGCENTER_DETAILS_SUCCESS,
    data: data,
  };
};

const parentBoardingDetailsEvent = () => {
  return (dispatch) => {
    dispatch(startLoadingParentBoardingCenter());
    profileapi
      .parentBoardingCenterDetails()
      .then((response) => {
        dispatch(parentBoardingDetailsSuccess(response?.data?.info));
        dispatch(stopLoadingParentBoardingCenter())
      })
      .catch((err) => {
        dispatch(parentBoardingDetailsError(err));
        dispatch(stopLoadingParentBoardingCenter())
      });
  };
};

const cleardetailsProfile = () => {
  return {
      type: profileConstants.CLEAR_PROFILE_DETAILS,
  };
}

export const profileAction = {
  updateParentProfileEvent,
  getParentProfileEvent,
  getPetProfileEvent,
  postPetProfileEvent,
  updatePetProfileEvent,
  selectServicesEvent,
  parentBoardingDetailsEvent,
  cleardetailsProfile
};
