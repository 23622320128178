export const statuses = {
  petPickupAndDrop_status: [
    { key: "initiated", children: "Initiated" },
    {
      key: "boarding_center_to_parent_location_to_pickup_pet.accepts_ride",
      children: "Accepts Ride",
    },
    {
      key:
        "boarding_center_to_parent_location_to_pickup_pet.on_the_way_to_parent_location",
      children: "On the Way to Parent Location",
    },
    {
      key:
        "boarding_center_to_parent_location_to_pickup_pet.reached_parent_location",
      children: "Reached Parent Location",
    },
    {
      key:
        "boarding_center_to_parent_location_to_pickup_pet.order_verification",
      children: "Order Verification",
    },
    {
      key:
        "boarding_center_to_parent_location_to_pickup_pet.parent_verification",
      children: "Parent Verification",
    },
    {
      key:
        "boarding_center_to_parent_location_to_pickup_pet.pet_verification_by_captain",
      children: "Pet Verification by Captain",
    },
    {
      key:
        "boarding_center_to_parent_location_to_pickup_pet.pet_on_the_way_to_boarding_center",
      children: "Pet on the Way to Boarding Center",
    },
    {
      key:
        "boarding_center_to_parent_location_to_pickup_pet.pet_reached_boarding_center",
      children: "Pet Reached Boarding Center",
    },
    {
      key:
        "boarding_center_to_parent_location_to_pickup_pet.pet_handed_over_to_the_boarding_center",
      children: "Pet Handed Over to the Boarding Center",
    },
    // { key: "pet_verification_by_admin", children: "Pet Verification by Admin" },
    // { key: "assign_kennel_to_pet", children: "Assign Kennel to pet" },
    // {
    //   key:
    //     "boarding_center_to_parent_location_drop_pet.pet_picked_up_from_boarding_center",
    //   children: "Pet picked up from boarding center by captain to drop",
    // },
    {
      key:
        "boarding_center_to_parent_location_drop_pet.on_the_way_to_parent_location",
      children: "Captain is on the way to parent location to drop the pet",
    },
    {
      key:
        "boarding_center_to_parent_location_drop_pet.reached_parent_location",
      children: "Captain reached parent location to drop the pet",
    },
    {
      key:
        "boarding_center_to_parent_location_drop_pet.parent_verification_while_releasing_pet",
      children: "Parent verification while releasing",
    },
    {
      key:
        "boarding_center_to_parent_location_drop_pet.handed_over_the_pet_to_the_parent",
      children: "Handed over the pet to theparent",
    },
  ],
  petBoarding_status: [
    { key: "initiated", children: "Initiated" },
    { key: "booking_confirmation", children: "Booking Confirmation" },
    {
      key: "pet_received_at_boarding_center",
      children: "Pet received at boarding center",
    },
    { key: "parent_verification", children: "Parent verification" },
    { key: "pet_verification_by_admin", children: "Pet verification by admin" },
    {
      key: "pet_assigned_a_kennel",
      children: "Assignment of Kennel to the pet",
    },
    {
      key: "parent_verification_while_releasing_pet",
      children: "Parent verification while releasing pet",
    },
    { key: "pet_handed_over_to_parent", children: "Pet handed over to parent" },
    { key: "pet_picked_up_from_boarding_center", children: "Pet pickedup from boardingcenter by captain" },
  ],
};
