import {
  applyMiddleware,
  legacy_createStore as createStore,
  combineReducers,
} from "redux";
import { thunk } from "redux-thunk";
import { signupReducer } from "./Signup";
import { loadingReducer } from "./state/reducer";
import { googleReducer } from "./Signup/State/googleReducer";
import { bookAppointmentReducer, mapsReducer } from "./BookAppointment";
import { addressReducer, profileReducer } from "./Common";
import { settingsReducer } from "./Settings";
import { dashboardReducer } from "./Dashboard";
import { navbarReducer } from "./Navbar";
import { adminDashboardReducer } from "./ServiceDetails";
import { searchReducer } from "./Search/State/reducer";
import { cancellationRequestsReducer } from "./CancellationOfBookings";
import { boardCenterReducer } from "./BoardingCenter";
import { supportReducer } from "./Support";
import { helpReducer } from "./Help";
import { captainReducer } from "./Captain";
import { appReducer } from "./App";
import { additionalloadingReducer } from "./state/additionalLoadingReducer";
import { paymentLinksReducer } from "./PaymentLinks";
import { crudPlansReducer } from "./CrudPlans";
const reducers = {
  adminDashboardReducer,
  signupReducer,
  loadingReducer,
  googleReducer,
  mapsReducer,
  bookAppointmentReducer,
  profileReducer,
  settingsReducer,
  dashboardReducer,
  appReducer,
  addressReducer,
  navbarReducer,
  helpReducer,
  searchReducer,
  captainReducer,
  cancellationRequestsReducer,
  boardCenterReducer,
  supportReducer,
  additionalloadingReducer,
  paymentLinksReducer,
  crudPlansReducer
};

const rootReducers = combineReducers(reducers);
const store = applyMiddleware(thunk)(createStore)(rootReducers);

export { store, reducers };
