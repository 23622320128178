import { cancellationRequestsConstants } from "./action-types";


const initialState = {
  getAllcancelRequests: false,
  getAllcancelRequestsErr: false,
  approveCancelRequest: false,
  approveCancelRequestErr: false
};

const cancellationRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case cancellationRequestsConstants.APPROVE_CANCELLATION_SUCCESS:
      return {
        ...state,
        approveCancelRequest: action.data,
        approveCancelRequestErr: false
      };
    case cancellationRequestsConstants.APPROVE_CANCELLATION_ERROR:
      return {
        ...state,
        approveCancelRequest: false,
        approveCancelRequestErr: action.error
      };
    case cancellationRequestsConstants.GET_ALL_CANCELLATION_REQUESTS_ERROR:
      return {
        ...state,
        getAllcancelRequests: false,
        getAllcancelRequestsErr: action.error,
      };
    case cancellationRequestsConstants.GET_ALL_CANCELLATION_REQUESTS_SUCCESS:
      return {
        ...state,
        getAllcancelRequests: action.data,
        getAllcancelRequestsErr: false,
      };
    default:
      return state;
  }
};
export { cancellationRequestsReducer };
