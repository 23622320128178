import axios from "axios";
const apiURL = process.env.REACT_APP_BACKEND_URL;

// make payment
const makePayment = (data) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.post(`${apiURL}/makePayment`, data, config);
};

// get all orders
const getAllOrders = (page, type, limit = 5) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(
    `${apiURL}/getAllBookings?page=${page}&limit=${limit}&type=${type}`,
    config
  );
};

// get all tickets for user
const getTicketsForUser = () => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/support/getAllTicketsOfUser`, config);
};

// get subscription details based on subscription id
const getSubscriptionDetails = async (subscriptionId) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return await axios.get(`${apiURL}/subscription/${subscriptionId}`, config);
};

// pause subscription
const pauseSubscription = (subscriptionId) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.post(
    `${apiURL}/subscriptions/${subscriptionId}/pause`,
    null,
    config
  );
};

// resume subscription
const resumeSubscription = (subscriptionId) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.post(
    `${apiURL}/subscriptions/${subscriptionId}/resume`,
    null,
    config
  );
};

// cancel subscription
const cancelSubscription = (subscriptionId) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.post(
    `${apiURL}/subscriptions/${subscriptionId}/cancel`,
    {
      cancel_at_cycle_end: false,
    },
    config
  );
};

//update a subscription
const updateSubscription = (subscriptionId, data) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.patch(`${apiURL}/subscriptions/${subscriptionId}`, data, config);
};

//get billing invoices
const billingActivity = (subscriptionId) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(
    `${apiURL}/subscriptions/${subscriptionId}/invoices`,
    config
  );
};

// GET ALL SUBSCRIPTION PLANS
const getAllSubscriptionPlans = () => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/plans`, config);
};


// Delete address
const deleteAddress = (addressId) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.delete(`${apiURL}/deleteAddress/${addressId}`, config);
}

export const settingsApi = {
  makePayment,
  getAllOrders,
  getTicketsForUser,
  getSubscriptionDetails,
  pauseSubscription,
  resumeSubscription,
  cancelSubscription,
  updateSubscription,
  billingActivity,
  getAllSubscriptionPlans,
  deleteAddress
};
