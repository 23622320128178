import React, { useState } from "react";
import styles from "../Styles/petcard.module.css";
import { Card, Col, Row, Typography, theme } from "antd";
import { ArrowRightOutlined, CaretRightFilled, ShareAltOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const { Text } = Typography;

/**
 * PetCard component displays detailed information about a pet, including its name, type, age, breed, gender, character, and favorite foods.
 * It supports hover interactions to reveal a navigation icon for more details.
 *
 * @param {Object} props - The component's props.
 * @param {Object} props.e - The pet data object to be displayed on the card.
 *
 * @returns {JSX.Element} The rendered PetCard component.
 *
 * @description
 * - **Hover Effect**: The card displays a share icon when hovered over, which can be clicked to navigate to the URL specified in `redirectURL`.
 * - **Responsive**: The layout adapts to the available space, showing pet details and handling hover interactions.
 */


const PetCard = ({ e }) => {
    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => setHover(true);
    const handleMouseLeave = () => setHover(false);
    const { useToken } = theme;
    const { token } = useToken();
    const navigate = useNavigate();

    return (
        <Card
            bordered
            hoverable
            style={{ borderRadius: 0 }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Row>
                <Col span={10} className={styles.col}>
                    Pet Name
                </Col>
                <Col span={2} className={styles.col}>
                    :
                </Col>
                <Col span={12} className={styles.col}>
                    {e?.name || "--"}
                </Col>
                <Col span={10} className={styles.col}>
                    Pet Type
                </Col>
                <Col span={2} className={styles.col}>
                    :
                </Col>
                <Col span={12} className={styles.col}>
                    {e?.type || "--"}
                </Col>
                <Col span={10} className={styles.col}>
                    Pet Age
                </Col>
                <Col span={2} className={styles.col}>
                    :
                </Col>
                <Col span={12} className={styles.col}>
                    {e?.age || "--"}
                </Col>
                <Col span={10} className={styles.col}>
                    Pet Breed
                </Col>
                <Col span={2} className={styles.col}>
                    :
                </Col>
                <Col span={12} className={styles.col}>
                    {e?.breed || "--"}
                </Col>
                <Col span={10} className={styles.col}>
                    Pet gender
                </Col>
                <Col span={2} className={styles.col}>
                    :
                </Col>
                <Col span={12} className={styles.col}>
                    {e?.gender || "--"}
                </Col>
                <Col span={10} className={styles.col}>
                    Pet character
                </Col>
                <Col span={2} className={styles.col}>
                    :
                </Col>
                <Col span={12} className={styles.col}>
                    {e?.character || "--"}
                </Col>
                <Col span={10} className={styles.col}>
                    Favorite foods
                </Col>
                <Col span={2} className={styles.col}>
                    :
                </Col>
                <Col span={12} className={styles.col}>
                    {e?.favoriteFood || "--"}
                </Col>
            </Row>
            {hover && (
                <div className={styles.shareIcon} onClick={() => navigate(e.redirectURL)}>
                    <ArrowRightOutlined style={{ color: token.White, fontSize: "14px" }} />
                </div>
            )}
        </Card>
    );
};

export default PetCard;
