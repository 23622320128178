import {
  startLoading,
  startLoadingAllBookings,
  stopLoading,
  stopLoadingAllBookings,
} from "../../state/actions";
import {
  startLoadingSubBilling,
  stopLoadingSubBilling,
} from "../../state/additionalLoadingActions";
import { settingsApi } from "../utils/api";
import { settingConstants } from "./action-types";

const paymentError = (err) => {
  return {
    type: settingConstants.PAYMENT_FAILURE,
    error: err,
  };
};

const paymentSuccess = (data) => {
  return {
    type: settingConstants.PAYMENT_SUCCESS,
    data: data,
  };
};

const paymentEvent = (data) => {
  return (dispatch) => {
    settingsApi
      .makePayment(data)
      .then((response) => {
        dispatch(paymentSuccess(response?.data?.info));
        localStorage.setItem("authToken", response?.data?.info?.token);
      })
      .catch((err) => {
        dispatch(paymentError(err));
      });
  };
};

const getAllBookingsErr = (err) => {
  return {
    type: settingConstants.GET_ALL_BOOKINGS_ERROR,
    error: err,
  };
};

const getAllBookingsSuccess = (data) => {
  return {
    type: settingConstants.GET_ALL_BOOKINGS_SUCCESS,
    data: data,
  };
};

const getAllBookingsEvent = (page, type) => {
  return (dispatch) => {
    dispatch(startLoadingAllBookings());
    settingsApi
      .getAllOrders(page, type)
      .then((response) => {
        dispatch(getAllBookingsSuccess(response?.data?.info));
        dispatch(stopLoadingAllBookings());
      })
      .catch((err) => {
        dispatch(getAllBookingsErr(err));
        dispatch(stopLoadingAllBookings());
      });
  };
};

const getAllTickersForUserErr = (err) => {
  return {
    type: settingConstants.GET_ALL_TICKETS_USER_ERROR,
    error: err,
  };
};

const getAllTickersForUserSuccess = (data) => {
  return {
    type: settingConstants.GET_ALL_TICKETS_USER_SUCCESS,
    data: data,
  };
};

const getAllTickersForUserEvent = () => {
  return (dispatch) => {
    dispatch(startLoadingAllBookings());
    settingsApi
      .getTicketsForUser()
      .then((response) => {
        dispatch(getAllTickersForUserSuccess(response?.data?.info));
        dispatch(stopLoadingAllBookings());
      })
      .catch((err) => {
        dispatch(getAllTickersForUserErr(err));
        dispatch(stopLoadingAllBookings());
      });
  };
};

const getSubBillingErr = (err) => {
  return {
    type: settingConstants.GET_SUBSCRIPTION_BILLING_SUCCESS,
    error: err,
  };
};

const getSubBillingSuccess = (data) => {
  return {
    type: settingConstants.GET_SUBSCRIPTION_BILLING_SUCCESS,
    data: data,
  };
};

const getSubBillingEvent = (id) => {
  return (dispatch) => {
    dispatch(startLoadingSubBilling());
    settingsApi
      .billingActivity(id)
      .then((response) => {
        dispatch(getSubBillingSuccess(response?.data?.info));
        dispatch(stopLoadingSubBilling());
      })
      .catch((err) => {
        dispatch(getSubBillingErr(err));
        dispatch(stopLoadingSubBilling());
      });
  };
};
const getAllSubscriptionPlansError = (err) => {
  return {
    type: settingConstants.GET_ALL_SUBSCRIPTION_PLANS_ERROR,
    error: err,
  };
};

const getAllSubscriptionPlansSuccess = (data) => {
  return {
    type: settingConstants.GET_ALL_SUBSCRIPTION_PLANS_SUCCESS,
    data: data,
  };
};

const getAllSubscriptionPlans = () => {
  return (dispatch) => {
    dispatch(startLoadingSubBilling());
    settingsApi
      .getAllSubscriptionPlans()
      .then((response) => {
        dispatch(getAllSubscriptionPlansSuccess(response?.data?.info));
        dispatch(stopLoadingSubBilling());
      })
      .catch((err) => {
        dispatch(getAllSubscriptionPlansError(err));
        dispatch(stopLoadingSubBilling());
      });
  };
};

const clearAllBookings = () => {
  return {
    type: settingConstants.CLEAR_ALL_BOOKINGS,
  };
};

export const settingsAction = {
  paymentEvent,
  getAllBookingsEvent,
  getAllTickersForUserEvent,
  clearAllBookings,
  getSubBillingEvent,
  getAllSubscriptionPlans,
};
