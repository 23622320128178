import { crudPlanConstants } from "./action-types";

const initialState = {
  getAllPendingPlans: false,
  getAllPendingPlansErr: false,
};

const crudPlansReducer = (state = initialState, action) => {
  switch (action.type) {
    // case cancellationRequestsConstants.APPROVE_CANCELLATION_SUCCESS:
    //   return {
    //     ...state,
    //     approveCancelRequest: action.data,
    //     approveCancelRequestErr: false
    //   };
    // case cancellationRequestsConstants.APPROVE_CANCELLATION_ERROR:
    //   return {
    //     ...state,
    //     approveCancelRequest: false,
    //     approveCancelRequestErr: action.error
    //   };
    case crudPlanConstants.GET_ALL_PENDING_PLANS_ERROR:
      return {
        ...state,
        getAllPendingPlans: false,
        getAllPendingPlansErr: action.error,
      };
    case crudPlanConstants.GET_ALL_PENDING_PLANS_SUCCESS:
      return {
        ...state,
        getAllPendingPlans: action.data,
        getAllPendingPlansErr: false
      };
    default:
      return state;
  }
};
export { crudPlansReducer };
