import React, { useEffect } from "react";
import styles from "../Styles/servicedetails.module.css";
import { useDispatch, useSelector } from "react-redux";
import { adminAction } from "../state/actions";
import Spinner from "../../Spinner/Spinner";
/**
 * KennelsScreen component manages the display and assignment of available kennels for a specific booking.
 * 
 * @component
 * @param {Object} props - Component props
 * @param {string} props.bookingId - The ID of the booking for which the kennel is being assigned
 * @param {Function} props.handleOkAssignKennel - Callback function to execute after a kennel has been assigned
 * @returns {JSX.Element} The KennelsScreen component
 */
const KennelsScreen = ({ bookingId, handleOkAssignKennel }) => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const { isLoadingKennels } = useSelector((str) => str.loadingReducer);
  const { allAvailableKennels, assignKennel } = useSelector(
    (str) => str.adminDashboardReducer
  );

  useEffect(() => {
    if (assignKennel) {
      dispatch(
        adminAction.getConfirmedBookingDetails(
          userDetails.boardCenterUniqueId,
          {
            boardCenterId: userDetails.boardingCenter,
            page: 1,
            status: "inProcessAppointments",
          }
        )
      );
      dispatch(adminAction.clearAlldata());
    }
  }, [assignKennel]);

  useEffect(() => {
    if (bookingId) {
      dispatch(
        adminAction.getAvailableKennelsEvent(
          userDetails?.boardCenterUniqueId,
          bookingId
        )
      );
    }
  }, []);

  const handleAssignKennel = (e) => {
    dispatch(
      adminAction.assignKennelEvent(
        userDetails?.boardCenterUniqueId,
        bookingId,
        { kennelId: e }
      )
    );
    handleOkAssignKennel();
  };

  if (isLoadingKennels) {
    return <Spinner />;
  }

  return (
    <div className={styles.kennelContainer}>
      {allAvailableKennels &&
        allAvailableKennels?.map((e) => (
          <div className={styles.kennel} onClick={() => handleAssignKennel(e)}>
            {e}
          </div>
        ))}
    </div>
  );
};

export default KennelsScreen;
