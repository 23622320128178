import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Cascader,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import CommonModal from "../../CommonModal/CommonModal";
import LocationForm from "./LocationForm";
import Mapss from "../../Navbar/Components/Mapss";
import { staticOptions } from "../../Common/Components/allowedPets";
import { services } from "../../Common/Components/Services";
import { handleChange } from "../utils/handleChangeUtils";
const { Title } = Typography;

/**
 * @description- `AddBoardCenterFormComponent` is a React component that provides a form for adding or updating
 * a boarding center. The form includes fields for entering the center's name, head, services offered,
 * pricing for selected services, allowed locations, and the physical location of the center.
 * */

const AddBoardCenterFormComponent = ({
  showModal,
  handleCancel,
  isModalOpen,
  handleLocationFinish,
  maps,
  setMaps,
  updateSelectedPreview,
  handleAddressSelect,
  defaultValue,
  handleServicesChange,
  id,
  setSelectedValues,
  info,
  form,
}) => {
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const options = services?.map((e) => ({
    value: e.key,
    label: e.name,
  }));
  const { tokendata } = useSelector((str) => str.appReducer);
  const { selectedPreview, allowedLocations, selectedServices } = info;

  const isSuperAdmin = userDetails.currentRole === "SUPER_ADMIN";

  useEffect(() => {
    form.setFieldsValue(maps.fullLocation);
  }, [maps]);


  return (
    <Row gutter={16}>
      <Col xs={24} lg={12}>
        <Form.Item
          label="Board center name"
          name={"name"}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        {isSuperAdmin && (
          <Form.Item
            label="Email of the Board center head"
            name="boardCenterHeadEmail"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          label="Services"
          name={"services"}
          rules={[{ required: true }]}
        >
          <Select
            options={options}
            mode="multiple"
            onChange={handleServicesChange}
          />
        </Form.Item>
        {selectedServices.map((serviceName) => (
          <>
            <Form.Item
              key={`price_${serviceName}`}
              label={`Price for ${serviceName}`}
              name={`price_${serviceName}`}
              rules={[{ required: true, message: "Please input the price!" }]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              key={`slots_${serviceName}`}
              label={`Total Slots for ${serviceName}`}
              name={`slots_${serviceName}`}
              rules={[{ required: true, message: "Please input the total slots!" }]}
            >
              <Input type="number" />
            </Form.Item>
          </>
        ))}
        <Form.Item label="Allowed Locations" name={"allowedLocations"}>
          <Button
            icon={<PlusOutlined />}
            onClick={showModal}
            type="primary"
            size="small"
          >
            Add allowed location
          </Button>
          <ol>
            {allowedLocations?.map((location) => (
              <li key={uuidv4()}>{location.locationName}</li>
            ))}
          </ol>
          <CommonModal
            handleOk={handleCancel}
            handleCancel={handleCancel}
            isModalOpen={isModalOpen}
            body={() => (
              <LocationForm handleLocationFinish={handleLocationFinish} />
            )}
          />
        </Form.Item>
        {/* } */}
      </Col>
      <Col xs={24} lg={12}>
        <Form.Item
          label="Location of boarding center"
          name={"address"}
          rules={[{ required: true }]}
        >
          <Input
            value={maps?.location}
            onClick={() => setMaps({ ...maps, show: true })}
          />
          {maps.show && (
            <>
              <Mapss
                tokendata={tokendata}
                handleAddressSelect={handleAddressSelect}
                height={"300px"}
              />
              <Row gutter={[16, 0]} style={{ fontWeight: "normal" }}>
                <Col span={12}>
                  <Form.Item
                    name={"houseNumber"}
                    label="House / Flat no."
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={"houseName"}
                    label="House Name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={"street"}
                    label="Street"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={"landmark"}
                    label="Landmark"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={"city"}
                    label="City"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={"state"}
                    label="State"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={"pincode"}
                    label="Pincode"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ display: "none" }}>
                  <Form.Item name={"eLoc"} label="eLoc">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ display: "none" }}>
                  <Form.Item name={"latitude"} label="latitude">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ display: "none" }}>
                  <Form.Item name={"longitude"} label="longitude">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.Item>
        {id && (
          <Form.Item label="Categories" name={"categories"}>
            <Cascader
              style={{ width: "100%" }}
              onChange={(value) =>
                handleChange(
                  value,
                  info,
                  updateSelectedPreview,
                  setSelectedValues
                )
              }
              options={staticOptions}
              defaultValue={defaultValue}
              multiple
              maxTagCount="responsive"
            />
            {selectedPreview?.length > 0 && (
              <div style={{ marginTop: "20px" }}>
                <Title level={5}>Selected Categories Preview:</Title>
                <ol>
                  {selectedPreview.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ol>
              </div>
            )}
          </Form.Item>
        )}
      </Col>
    </Row>
  );
};

export default AddBoardCenterFormComponent;
