import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { settingsAction } from "../State/actions";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import { Button, Table, Typography } from "antd";
import dayjs from "dayjs";
import useSettingName from "../../Common/Components/useSettingName";
const { Text } = Typography;

/**
 * BillingActivity component displays the billing history for a specific subscription.
 *
 * @component
 * @returns {JSX.Element} The rendered BillingActivity component.
 */
const BillingActivity = () => {
    const dispatch = useDispatch();
    const { isLoadingBillingSub } = useSelector(
        (str) => str.additionalloadingReducer
    );
    const { subscriptionId } = useParams();
    const navigate = useNavigate();
    const { billingSub } = useSelector((str) => str.settingsReducer);

    const formatDate = (timestamp) => dayjs.unix(timestamp).format("DD MMM YYYY");

    useEffect(() => {
        dispatch(settingsAction.getSubBillingEvent(subscriptionId));
    }, []);

    useSettingName("Billing activity");

    if (isLoadingBillingSub) {
        return <Spinner />;
    }

    const columns = [
        {
            title: "Date",
            key: "date",
            render: ({ paid_at, id }) => (
                <Button type="link" onClick={() => navigate(`/invoice/print/${id}`)}>
                    {formatDate(paid_at)}
                </Button>
            ),
        },
        {
            title: "Invoice Id",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Plan",
            key: "plan",
            render: ({ line_items }) => <Text>{line_items?.[0]?.name}</Text>,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Service period",
            key: "servicePeriod",
            render: ({ billing_end, billing_start }) => (
                <Text>{`${formatDate(billing_start)} - ${formatDate(
                    billing_end
                )}`}</Text>
            ),
        },
        {
            title: "Amount paid",
            key: "gross_amount",
            render: ({ currency_symbol, gross_amount }) => (
                <Text>{`${currency_symbol} ${gross_amount / 100}`}</Text>
            ),
        },
    ];

    return (
        <Table
            size="small"
            bordered={false}
            pagination={false}
            columns={columns}
            dataSource={billingSub?.items}
        />
    );
};

export default BillingActivity;
